import React, { useEffect, useState } from "react";
import Input from "../../../../../ReusableComponents/Input";
import { Button } from "../../../../../Components/Helper";
import { getLocations } from "../../../../../apis/locations";
import { addService, updateService } from "../../../../../apis/services";
import { DRONE_CAPACITIES, GLOBAL_STYLES, LIMITS } from "../../../../../appConstants";
import Spraying from "../../../../../assets/spraying.svg";
import Plus from "../../../../../assets/Plus.svg";
import Toggle from "react-toggle";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import {
  isEmptyString,
  isSpecialCharExists,
  isValidGst,
  reduceLongName,
} from "../../../../../ReusableFunctions/reusableFunctions";
import Popup from '../../../../../ReusableFunctions/Popup';
import Loading from "../../../../../ReusableFunctions/Loader";
import AddLocation from "../../../../MyLocations/SubComponents/AddLocation";
import { ROUTES } from "../../../../../routes";
import { useNavigate } from "react-router-dom"
import MainContentWrapper from "../../../../../Components/MainContentWrapper";
import "react-toggle/style.css";
import { getCropsByLocation } from "../../../../../apis/crops";
import LoaderComponent from "../../../../../ReusableComponents/LoaderComponent";
import AddPesticides from "./AddPesticides";
import closeIcon from "../../../../../assets/CrossDark.svg";
import CommonCropPricingPopup from "./CommonCropPricingPopup";

const AddSprayingService = ({ aeroMeghService, service }) => {
  const navigate = useNavigate()
  const isServiceToBeUpdated = service?.aeromegh_service_id;
  const dateFormatOption = { day: '2-digit', month: '2-digit', year: 'numeric' };
  const [openAddLocationPopup, setOpenAddLocationPopup] = useState(false);
  const [selectedLocation, setselectedLocation] = useState(null)
  const [selectOptionLoading, setSelectOptionLoading] = useState(null);
  const [selectedAgroChemical, setSelectedAgroChemical] = useState(null)
  const [spraying_service, setSprayingService] = useState(
    isServiceToBeUpdated
      ? service
      : {
        aeromegh_service_id: aeroMeghService.id,
        is_active: true,
        start_date: new Date(),
        parameters: {
          pesticides: [],
          drone_capacities: [],
          is_common_pricing_for_locations: false,
          min_request_acreage: 1
        },
        locations: [],
      }
  );
  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openPesticidesPopup, setOpenPesticidesPopup] = useState(false)
  const [openCommonPricingPopup, setOpenCommonPricingPopup] = useState(false)
  const handleToggleCommonCropPricing = (e) => {
    if (e.target.checked) { // if button is on
      setOpenCommonPricingPopup(true)
    } else { // if off
      const sprayingService = { ...spraying_service, parameters: { ...spraying_service.parameters, is_common_pricing_for_locations: false } }
      setSprayingService(sprayingService)
    }
  }
  const handleOnChangeLocation = async (e, location) => {
    if (e.target.checked) {
      if (spraying_service?.parameters?.is_common_pricing_for_locations) {
        const addedLocation = locations.find((loc) => loc.id === location.id);
        if (!spraying_service.locations.find((loc) => loc.id === location.id)) {
          setSprayingService((prev) => ({
            ...prev,
            locations: [...prev.locations, addedLocation],
          }));
        }
      } else {

        const { locationcropslist } = await getCropsByLocation(location?.id);
        if (locationcropslist?.length > 0) {
          const addedLocation = locations.find((loc) => loc.id === location.id);
          if (!spraying_service.locations.find((loc) => loc.id === location.id)) {
            setSprayingService((prev) => ({
              ...prev,
              locations: [...prev.locations, addedLocation],
            }));
          }
        } else {
          if (!spraying_service.parameters?.is_common_pricing_for_locations) {
            setOpenAddLocationPopup(true);
          }
          setselectedLocation(location);
        }
      }
      setSelectOptionLoading(null);
    } else {
      setSprayingService((prev) => ({
        ...prev,
        locations: prev.locations.filter((loc) => loc.id !== location.id),
      }));
      setSelectOptionLoading(null);
    }
  };

  const handleOnChangeDroneCapacities = (e, selected_cap) => {
    if (e.target.checked) {
      //drone is being added
      const addedCap = DRONE_CAPACITIES.find((cap) => cap === selected_cap);
      setSprayingService((prev) => ({
        ...prev,
        parameters: {
          ...prev.parameters,
          drone_capacities: [...prev.parameters.drone_capacities, addedCap],
        },
      }));
    } else {
      setSprayingService((prev) => ({
        ...prev,
        parameters: {
          ...prev.parameters,
          drone_capacities: prev.parameters.drone_capacities.filter(
            (cap) => cap !== selected_cap
          ),
        },
      }));
    }
  };

  const handleAddService = () => {
    Loading.set(true);
    addService(spraying_service)
      .then((res) => {
        Loading.set(false);

        Popup.alert(
          "Spraying Service",
          "Service added successfully",
          "SUCCESS",
          () => {
            navigate(ROUTES.MYSERVICES)
          }
        );
      })
      .catch((err) => {
        Loading.set(false);
        console.log(err);
        Popup.alert("Error", err.errorMessage, "ERROR");
      });
  };

  const updateSprayingServiceBeforeAPI = (service) => {
    const updatedService = { ...service };
    const isCommonPricing = updatedService.parameters.is_common_pricing_for_locations;

    if (typeof isCommonPricing === 'object' && isCommonPricing.target && 'checked' in isCommonPricing.target) {
      updatedService.parameters.is_common_pricing_for_locations = isCommonPricing.target.checked;
    }

    return updatedService;
  };

  const handleUpdateService = () => {
    Loading.set(true);
    const updatedService = updateSprayingServiceBeforeAPI(spraying_service);
    updateService(updatedService)
      .then((res) => {
        Loading.set(false);

        Popup.alert(
          "Spraying Service",
          "Service updated successfully",
          "SUCCESS",
          () => {
            navigate(ROUTES.MYSERVICES)
          }
        );
      })
      .catch((err) => {
        Loading.set(false);
        console.log(err);
        Popup.alert("Error", err.errorMessage, "ERROR");
      });
  };

  const onUpdateLocation = (location) => {
    setLocations(() => locations.map((l) => (l.id === location.id ? location : l)));
  }

  const isAddServiceFormFilled = () => {
    return (
      spraying_service.parameters.drone_capacities.length > 0 &&
      spraying_service.parameters.min_request_acreage > 0.9 &&
      locations.length > 0
    );
  };

  const onAddLocation = (location, existingCrop) => {
    setLocations([location, ...locations]);
    if (existingCrop.length > 0) {
      setSprayingService((prev) => ({
        ...prev,
        locations: [...prev.locations, ...locations]
      }))
    }
  }

  useEffect(() => {
    Loading.set(true);
    setLoading(true)
    getLocations()
      .then((locations) => {
        setLocations(locations);
        setLoading(false);
        Loading.set(false);
      })
      .catch((err) => {
        console.log(err);
        Loading.set(false);
        setLoading(false);
        Popup.alert("Error", err.errorMessage, "ERROR");
      });
  }, []);

  useEffect(() => {
    if (service.aeromegh_service_id) {
      setSprayingService(service);
    }
  }, [service.id]);

  console.log("sparying service", spraying_service);
  return (
    <>

      {/* Common Crop Pricing Popup */}
      {openCommonPricingPopup &&
        <CommonCropPricingPopup
          sprayingServiceParameters={spraying_service.parameters}
          locationId={locations.find(l => l.is_active)?.id}
          handleToggleCommonCropPricing={(value) => {
            setSprayingService({ ...spraying_service, parameters: { ...spraying_service.parameters, is_common_pricing_for_locations: value } })
          }}
          onRequestClose={(turnOffCommonPricing) => {
            if (turnOffCommonPricing !== undefined) handleToggleCommonCropPricing({ target: { checked: !turnOffCommonPricing } })
            setOpenCommonPricingPopup(false)
          }}
        />
      }
      {/* Add location popup */}
      {openAddLocationPopup && (
        <AddLocation
          isCommonPricing={spraying_service?.parameters?.is_common_pricing_for_locations}
          getCommonCrops={async () => {
            return new Promise(async (resolve, reject) => {
              try {
                const cropsOfLocation = await getCropsByLocation(locations?.[0]?.id)
                return resolve(cropsOfLocation)
              } catch (e) {
                console.log(e)
                reject()
              }
            })
          }}
          open={openAddLocationPopup}
          onClose={() => {
            setOpenAddLocationPopup(false)
            setselectedLocation(null);
          }}
          location={selectedLocation}
          onAddLocation={(location, existingCrops) => {
            onAddLocation(location, existingCrops)
          }}
          onAllCropsDelete={async (loaction_id) => {
            try {
              const updatedService = {
                ...spraying_service,
                locations: spraying_service.locations.filter((loc) => loc.id != loaction_id),
              };
              setSprayingService(() => updatedService)
              await updateService(updatedService);
            } catch (err) {
              Popup.alert("Error", err || "Something went wrong while updating service", 'ERROR')
            }
          }}
          onUpdateLocation={onUpdateLocation}
          showCrops={!spraying_service?.parameters?.is_common_pricing_for_locations || locations.length == 0}
        />
      )}
      {(openPesticidesPopup || selectedAgroChemical) &&
        <AddPesticides
          pesticide={selectedAgroChemical}
          open={openPesticidesPopup || selectedAgroChemical}
          onClose={() => {
            setOpenPesticidesPopup(false);
            setSelectedAgroChemical(null);
          }}
          onPesticidesAdd={(data) => {
            if (spraying_service.parameters.pesticides.find((p) => p.chemical_name === data.chemical_name)) {
              Popup.alert("Error", "Already added.", "ERROR")
            }
            else {
              setSprayingService((prev) => ({
                ...prev,
                parameters: {
                  ...prev.parameters,
                  pesticides: [...prev.parameters.pesticides, data],
                },
              }));
              setOpenPesticidesPopup(false)
            }
          }}
          onPesticidesUpdate={(pest) => {
            setSprayingService((prev => ({
              ...prev, parameters: {
                ...prev.parameters, pesticides: prev.parameters.pesticides.map(p => {
                  if (p.id == pest.id) return pest;
                  else return p
                })
              }
            })))
            setOpenPesticidesPopup(false)
            setSelectedAgroChemical(null)

          }}
        />
      }

      <MainContentWrapper containerStyle={{ overflow: "hidden" }} header={"My services"}>
        <div style={{ padding: "0px 47px", height: "95%" }}>
          <form
            noValidate
            onSubmit={(e) => {
              e.preventDefault();
              if (isAddServiceFormFilled()) {
                if (isServiceToBeUpdated) handleUpdateService();
                else handleAddService();
              }
            }}
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              border: `1px solid rgb(217 217 217 / 33%)`,
              background: "rgba(252, 252, 252, 1)",
              boxShadow: '0px 0px 4px 0px rgba(114, 114, 114, 0.15)',
              borderRadius: "10px",

            }}
          >
            <div className="sprying-service-header">
              <div style={{ display: "flex", alignItems: "center", gap: "15px", }}>
                <img src={Spraying} alt="" style={{ width: "55px" }} />
                <div className="flex flex-col ">
                  <span style={{ fontSize: "28px", color: "#1E1E1E", fontWeight: 500 }}>
                    Spraying
                  </span>
                  <span style={{ fontSize: "14px", color: "#1E1E1E", fontWeight: 600 }}>
                    <span style={{ fontSize: "12px", color: "rgba(114, 114, 114, 1)", fontWeight: 600 }}>

                    </span>
                  </span>
                </div>
              </div>
              <div className="header_toggle flex flex-col items-end gap-[2px]">
                <Toggle
                  height={"50px"}
                  icons={false}
                  checked={spraying_service.is_active}
                  onChange={(e) => {
                    setSprayingService((prev) => ({
                      ...prev,
                      is_active: e.target.checked ? true : false,
                    }));
                  }}
                />
                <p style={{ fontSize: "14px", color: "#727272", fontWeight: 500, letterSpacing: "0.2px" }}>Service is <span style={{ fontWeight: 600, color: spraying_service.is_active ? "#109316" : "#727272" }}>{spraying_service.is_active ? "Active" : "Inactive"}</span> </p>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "column", height: "75%", overflowY: "auto", gap: "30px", marginTop: "25px" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  gap: "20px",
                  padding: "0px 60px",
                  height: "fit-content",
                }}
              >

                <div
                  style={{
                    display: "grid",
                    justifyContent: "space-between",
                    columnGap: "70px",
                    rowGap: "5px",
                    alignItems: "center",
                    gridTemplateColumns: "1fr 1fr 1fr 1fr",
                  }}
                >
                  {/* service name */}
                  <div style={{ width: "100%", minHeight: "100px" }}>
                    <label
                      style={{
                        display: "block",
                        fontSize: "15px",
                        color: "#3b3b3b",
                        marginBottom: "5px"
                      }}
                    >
                      Min Request Acreage
                    </label>
                    <Input
                      style={{ background: "rgba(243, 243, 243, 1)", height: "45px" }}
                      inputStyle={{
                        background: "rgba(243, 243, 243, 1)"
                      }}
                      type={"number"}
                      value={spraying_service.parameters.min_request_acreage}
                      placeholder={"Min Request Acreage"}
                      name={"min_request_acreage"}
                      step={1}
                      min={0}
                      max={99}
                      onChange={(e) => {
                        let newValue = e.target.value;

                        if ((Number.isInteger(Number(newValue)) || newValue === "") && newValue.length <= 2) {
                          setSprayingService((prev) => ({
                            ...prev,
                            parameters: { ...prev.parameters, min_request_acreage: newValue }
                          }));
                        }
                      }}
                    />

                  </div>
                </div>
              </div>
              {/* locations */}
              <div
                style={{
                  width: "100%",
                  height: "200px",
                  minHeight: "200px",
                  padding: "0px 60px",
                  display: "flex",
                  flexDirection: "column",
                  rowGap: "0px",
                }}
              >
                <div
                  style={{
                    color: GLOBAL_STYLES.FONT_COLOR_SUB,
                    fontSize: GLOBAL_STYLES.FONT_SIZE_MEDIUM,
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                    alignItems: "baseline",
                  }}
                >
                  <div style={{
                    padding: "0px",
                    marginBottom: "5px",
                    fontSize: "16px",
                    fontWeight: 500,
                    color: "rgba(59, 59, 59, 1)",
                  }}>
                    <span style={{
                    }}>Locations</span>
                    <span style={{ marginLeft: "5px" }}>{`(${locations.length})`}</span>
                  </div>
                  <div style={{ display: 'flex', alignItems: "center", gap: '20px' }}>
                    {locations.length > 0 && <><div style={{ display: "flex", alignItems: "center", gap: "10px", marginBottom: '8px' }}>
                      <span>Common crop pricing</span>
                      <Toggle
                        height={"50px"}
                        icons={false}
                        checked={spraying_service.parameters?.is_common_pricing_for_locations}
                        onChange={(e) => {
                          handleToggleCommonCropPricing(e)
                        }}
                      />
                    </div>
                      {spraying_service.parameters?.is_common_pricing_for_locations && <Button type={"button"}
                        text={"Manage "}
                        style={{ marginBottom: "8px", background: "#0F5EFF", padding: "8px, 16px, 8px, 16px", width: "110px", height: "35px", fontSize: "11px" }}
                        onClick={() => setOpenCommonPricingPopup(true)} />}
                    </>
                    }

                    <Button type={"button"} text={"Add New"} icon={Plus} style={{ marginBottom: "8px", background: "#0F5EFF", padding: "8px, 16px, 8px, 16px", width: "110px", height: "35px", fontSize: "11px" }} onClick={() => setOpenAddLocationPopup(true)} />
                  </div>
                </div>
                {loading ?
                  <LoaderComponent width={"2%"} height={"2%"} />
                  :
                  <div
                    style={{
                      overflowY: "auto",
                      display: "grid",
                      gridTemplateColumns: "repeat(auto-fill,155px)",
                      gridTemplateRows: "repeat(auto-fill,30px)",
                      alignItems: "flex-start",
                      justifyContent: "space-around",
                      flexWrap: "wrap",
                      rowGap: "20px",
                      minHeight: "88%",
                      border: `1px solid ${GLOBAL_STYLES.BORDER_COLOR}`,
                      boxShadow: "0px 0px 4px 0px rgba(114, 114, 114, 0.15)",
                      borderRadius: "5px",
                      padding: "22px 10px",
                      fontSize: "12px",
                      fontWeight: 500
                    }}
                  >
                    {locations.map((location, i) => (
                      <Tippy content={location.address}>
                        <div
                          id={location.id}
                          key={i}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            padding: "5px 10px",
                            gap: "10px",
                            position: "relative",
                            color: "rgba(30, 30, 30, 1)",
                            fontSize: "15px",
                            fontWeight: "500",
                          }}
                        >
                          {selectOptionLoading === location.id ?
                            <div style={{ display: 'flex', minWidth: "25px", height: "25px", alignItems: "center" }}>
                              <LoaderComponent customClassName={'select_location_loading'} height={"16%"} width={"16%"} />
                            </div>
                            :
                            <input
                              disabled={!location.is_active}
                              className="input-checkbox"
                              checked={!!spraying_service.locations.find(
                                (loc) => loc.id === location.id
                              )}
                              type="checkbox"
                              onChange={(e) => {
                                setSelectOptionLoading(location.id);
                                handleOnChangeLocation(e, location)
                              }}
                            />
                          }
                          <label
                            onClick={() => {
                              setOpenAddLocationPopup(true);
                              setselectedLocation(location);
                            }}
                            style={{ cursor: "pointer", position: "relative", opacity: location.is_active ? 1 : 0.5 }}
                          >
                            {reduceLongName(
                              location.name,
                              10
                            )}

                          </label>
                        </div>
                      </Tippy>
                    ))}
                  </div>
                }
                {(locations.length === 0 && !loading) && (
                  <div style={{ color: GLOBAL_STYLES.ERROR, fontSize: "12px", fontWeight: 500, marginTop: "3px" }}>
                    Please select atleast one location
                  </div>
                )}
              </div>
              <div style={{ display: "flex", alignItems: "center", width: "100%", marginTop: "10px" }}>
                {/* Drones */}
                <div style={{
                  display: "flex",
                  padding: "0px 60px",
                  height: "190px",
                  gap: "3.2rem",
                  marginBottom: "10px",
                }}>
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      rowGap: "0px",
                    }}
                  >
                    <div
                      style={{
                        color: GLOBAL_STYLES.FONT_COLOR_SUB,
                        fontSize: GLOBAL_STYLES.FONT_SIZE_MEDIUM,
                        display: "flex",
                        width: "100%",
                        justifyContent: "space-between",
                        alignItems: "center",
                        paddingBottom: '11px'
                      }}
                    >
                      <div style={{
                        padding: "0px",
                        marginBottom: "5px",
                        fontSize: "16px",
                        fontWeight: 500,
                        color: "rgba(59, 59, 59, 1)",

                      }}>
                        <span>Tank Capacities</span>
                        <span style={{ marginLeft: "5px" }}>{`(${DRONE_CAPACITIES.length})`}</span>
                      </div>
                    </div>
                    <div
                      style={{
                        overflowY: "auto",
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                        alignItems: "flex-start",
                        justifyContent: "space-between",
                        flexWrap: "wrap",
                        rowGap: "35px",
                        height: "78%",
                        minHeight: "73%",
                        background: "#FCFCFC",
                        border: `1px solid ${GLOBAL_STYLES.BORDER_COLOR}`,
                        borderRadius: "5px",
                        padding: "20px 15px",
                        fontSize: "12px",
                        fontWeight: 500,
                        columnGap: "50px"
                      }}
                    >
                      {DRONE_CAPACITIES.map((cap, i) => (
                        <div
                          key={i}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            padding: "5px 10px",
                            gap: "10px",
                            fontSize: "15px",
                            fontWeight: 500
                          }}
                        >
                          <input
                            className="input-checkbox"
                            checked={spraying_service.parameters.drone_capacities.find(
                              (c) => c === cap
                            )}
                            type="checkbox"
                            onChange={(e) => handleOnChangeDroneCapacities(e, cap)}
                          />
                          <label
                          >
                            {cap}
                          </label>
                        </div>
                      ))}
                    </div>
                    {spraying_service.parameters.drone_capacities.length === 0 && (
                      <div style={{ color: GLOBAL_STYLES.ERROR, fontSize: "12px", fontWeight: 500, marginTop: "3px" }}>
                        Please select atleast one drone
                      </div>
                    )}
                  </div>
                </div>
                <div style={{
                  display: "flex",
                  padding: "0px 60px",
                  height: "190px",
                  width: "40%",
                  gap: "3.2rem",
                  marginBottom: "10px",
                }}>
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      rowGap: "0px",
                    }}
                  >
                    <div
                      style={{
                        color: GLOBAL_STYLES.FONT_COLOR_SUB,
                        fontSize: GLOBAL_STYLES.FONT_SIZE_MEDIUM,
                        display: "flex",
                        width: "100%",
                        justifyContent: "space-between",
                        alignItems: "center",
                        paddingBottom: '5px',
                        gap: "40px"
                      }}
                    >
                      <div style={{
                        padding: "0px",
                        marginBottom: "5px",
                        fontSize: "16px",
                        fontWeight: 500,
                        color: "rgba(59, 59, 59, 1)",

                      }}>
                        <span>AgroChemicals</span>
                        <span style={{ marginLeft: "5px" }}>{`(${spraying_service?.parameters?.pesticides?.length})`}</span>
                      </div>
                      <Button
                        type={"button"}
                        text={"Add New"}
                        icon={Plus}
                        style={{ background: "#0F5EFF", padding: "8px, 16px, 8px, 16px", width: "110px", height: "35px", fontSize: "11px" }}
                        onClick={() => {
                          setOpenPesticidesPopup(true)
                        }} />
                    </div>
                    <div
                      style={{
                        overflowY: "auto",
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr 1fr",
                        columnGap: "50px",
                        alignItems: "flex-start",
                        justifyContent: "space-between",
                        flexWrap: "wrap",
                        rowGap: "10px",
                        height: "78%",
                        minHeight: "73%",
                        background: "#FCFCFC",
                        border: `1px solid ${GLOBAL_STYLES.BORDER_COLOR}`,
                        borderRadius: "5px",
                        padding: "20px 15px",
                        fontSize: "12px",
                        fontWeight: 500
                      }}
                    >
                      {spraying_service.parameters.pesticides.map((pest, i) => (
                        <Tippy content={pest.chemical_name}>
                          <div
                            onClick={() => {
                              setSelectedAgroChemical(pest)
                            }}
                            key={i}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              padding: "5px 10px",
                              gap: "10px",
                              fontSize: "15px",
                              fontWeight: 500
                            }}
                          >
                            <label className="ellipsis" style={{ width: "70px", cursor: "pointer" }}>
                              {pest.chemical_name}
                            </label>
                            <img src={closeIcon} alt="clos icon" style={{ cursor: "pointer" }} onClick={(e) => {
                              e.stopPropagation();
                              setSprayingService((prev) => ({
                                ...prev,
                                parameters: {
                                  ...prev.parameters,
                                  pesticides: prev.parameters.pesticides.filter((p) => p.id !== pest.id),
                                },
                              }));
                            }} />
                          </div>
                        </Tippy>
                      ))}
                    </div>
                    {/* {spraying_service.parameters.pesticides.length === 0 && (
                      <div style={{ color: GLOBAL_STYLES.ERROR, fontSize: "12px", fontWeight: 500, marginTop: "3px" }}>
                        Please select atleast one pesticides
                      </div>
                    )} */}
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "right",
                marginTop: "",
                columnGap: "20px",
                padding: "5px 45px",
              }}
            >
              {isServiceToBeUpdated ? (
                <Button
                  style={{ width: "140px", height: "32px", fontSize: "16px" }}
                  text={"Save"}
                  isDisabled={!isAddServiceFormFilled()}
                />
              ) : (
                <Button
                  style={{ width: "140px", height: "32px", fontSize: "16px" }}
                  text={"Save"}
                  isDisabled={!isAddServiceFormFilled()}
                />
              )}
              <Button
                type={"button"}
                style={{ width: "140px", height: "32px", fontSize: "16px", color: "#606571", border: "1px solid #BFC8D9" }}
                text={"Cancel"}
                isBtnNotActive
                redirectTo={ROUTES.MYSERVICES}
              />
            </div>
          </form>
        </div >
      </MainContentWrapper >
    </>
  );
};

export default AddSprayingService;
