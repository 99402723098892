import React, { useState } from 'react';
import DateTimePicker from '../../../ReusableComponents/DateTimePicker';
import Modal from '../../../ReusableComponents/Modal';
import Popup from '../../../ReusableFunctions/Popup';
import { setDsp } from '../../../reducers/userReducer';
import { GLOBAL_STYLES } from '../../../appConstants';
import { Button } from '../../../Components/Helper';

const DspFilter = ({ filter, onFilterChange, open, onClose }) => {
    const [dspFilter, setDspFilter] = useState(filter);

    const handleFilterChange = (updatedFilter) => {
        setDspFilter(updatedFilter);
    };

    return (
        <Modal
            header={"Filter"}
            contentStyle={{ width: "730px", height: "366px" }}
            headingStyle={{ fontSize: "18px", fontWeight: 600 }}
            headerContainerStyle={{ padding: "20px" }}
            open={open}
            onClose={onClose}
        >
            <div style={{ padding: "20px 30px", display: "flex", flexDirection: "column", width: "100%", gap: "20px" }}>
                {/* Date Filter Section */}
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%", gap: "10%" }}>
                    <DateTimePicker
                        title="From"
                        hideNxt={new Date()}
                        selectedDateTime={dspFilter.fromDate}
                        minTime={new Date().setHours(0, 0, 0, 0)}
                        maxTime={new Date().setHours(23, 59)}
                        onChange={(date) => {
                            handleFilterChange({
                                ...dspFilter,
                                fromDate: new Date(date).toISOString()
                            });
                        }}
                    />
                    <DateTimePicker
                        title="To"
                        hidePrev={dspFilter.fromDate}
                        selectedDateTime={dspFilter.toDate}
                        minTime={new Date(dspFilter?.fromDate).toDateString() === new Date(dspFilter?.toDate).toDateString()
                            ? new Date(new Date(dspFilter.fromDate).setMinutes(new Date(dspFilter.fromDate).getMinutes() + 1))
                            : new Date().setHours(0, 0, 0, 0)}
                        maxTime={new Date().setHours(23, 59)}
                        onChange={(date) => {
                            if (new Date(dspFilter.fromDate) < new Date(date)) {
                                handleFilterChange({
                                    ...dspFilter,
                                    toDate: new Date(date).toISOString()
                                });
                            } else {
                                handleFilterChange({
                                    ...dspFilter,
                                    toDate: dspFilter.toDate ? new Date(dspFilter.toDate) : new Date()
                                })
                            }
                        }}
                        isDisable={!(dspFilter.fromDate)}
                    />
                </div>

                {/* Active/Inactive/All Filter Section */}
                <div style={{ marginTop: "20px" }}>
                    <h4 style={{ marginBottom: '10px', fontWeight: 600 }}>Status Filter</h4>
                    <div style={{ display: "flex", gap: "10px" }}>
                        <button
                            style={{
                                padding: "10px 20px",
                                borderRadius: "5px",
                                border: "1px solid",
                                borderColor: dspFilter.isActive === null ? "#007bff" : "#ccc",
                                backgroundColor: dspFilter.isActive === null ? "#007bff" : "#fff",
                                color: dspFilter.isActive === null ? "#fff" : "#000",
                                cursor: "pointer",
                                transition: "all 0.3s ease",
                                fontWeight: dspFilter.isActive === null ? "600" : "400"
                            }}
                            onClick={() => handleFilterChange({ ...dspFilter, isActive: null })}
                        >
                            All
                        </button>
                        <button
                            style={{
                                padding: "10px 20px",
                                borderRadius: "5px",
                                border: "1px solid",
                                borderColor: dspFilter.isActive === true ? "#28a745" : "#ccc",
                                backgroundColor: dspFilter.isActive === true ? "#28a745" : "#fff",
                                color: dspFilter.isActive === true ? "#fff" : "#000",
                                cursor: "pointer",
                                transition: "all 0.3s ease",
                                fontWeight: dspFilter.isActive === true ? "600" : "400"
                            }}
                            onClick={() => handleFilterChange({ ...dspFilter, isActive: true })}
                        >
                            Active
                        </button>
                        <button
                            style={{
                                padding: "10px 20px",
                                borderRadius: "5px",
                                border: "1px solid",
                                borderColor: dspFilter.isActive === false ? "#dc3545" : "#ccc",
                                backgroundColor: dspFilter.isActive === false ? "#dc3545" : "#fff",
                                color: dspFilter.isActive === false ? "#fff" : "#000",
                                cursor: "pointer",
                                transition: "all 0.3s ease",
                                fontWeight: dspFilter.isActive === false ? "600" : "400"
                            }}
                            onClick={() => handleFilterChange({ ...dspFilter, isActive: false })}
                        >
                            Inactive
                        </button>
                    </div>
                </div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between", padding: "20px 30px", borderTop: '0.5px solid #BFC8D9' }}>
                <div
                    onClick={async () => {
                        setDspFilter({
                            fromDate: null,
                            toDate: null,
                            isActive: null
                        })
                    }} style={{ width: "100px", color: GLOBAL_STYLES.BG_ACTIVE, fontWeight: 600, fontSize: "14px", cursor: 'pointer', display: "flex", alignItems: "center", justifyContent: "start" }}>
                    Clear all
                </div>
                <Button
                    text={"Apply"}
                    style={{ width: "130px", height: "38px", fontSize: "15px", marginRight: "10px" }}
                    onClick={() => {
                        onFilterChange(dspFilter)
                    }}
                />
            </div>
        </Modal>
    );
};

export default DspFilter;