import { useEffect, useState } from "react";
import MainContentWrapper from "../../Components/MainContentWrapper";
import TableView from "../../ReusableComponents/TableView/TableView";
import { getAllStores } from "../../apis/BhuMeetTeams/Oparations/store";
import Popup from "../../ReusableFunctions/Popup";
import { PDRL_ADMIN_ROUTES } from "../../routes";
import { useNavigate } from 'react-router'


const Stores = () => {
    const [stores, setStores] = useState([])
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();
    const [filters, setFilters] = useState({
        pageNumber: 1,
        pageSize: 15,
        fromDate: undefined,
        toDate: undefined,
    })


    const getAllStoresFunc = async (filters) => {
        try {
            setLoading(true)
            const { fromDate, toDate, pageSize, pageNumber } = filters
            const stores = await getAllStores(fromDate, toDate, pageSize, pageNumber)
            setStores(stores);
            setLoading(false)
        } catch (err) {
            setLoading(false)
            Popup.alert("Error", err, 'ERROR')
        }
    }

    useEffect(() => {
        getAllStoresFunc(filters);
    }, [filters])



    return (
        <MainContentWrapper header={`Stores (${stores[0]?.total_stores ? stores[0]?.total_stores : 0})`} rightContent={<div style={{ display: "flex", justifyContent: "flex-end", paddingBottom: "20px" }}>
        </div>
        }>
            <div style={{
                margin: "20px 0",
                height: "90%"
            }}>
                <TableView
                    showSrNo
                    loadingHeight={"500px"}
                    isLoading={loading}
                    onStateChange={async (changedState) => {
                        const { pageNumber } = changedState;
                        console.log("pageNumber", pageNumber);
                        setFilters({ ...filters, pageNumber });
                    }}
                    onClickRow={(store) => {
                        console.log("store", store);
                        navigate(PDRL_ADMIN_ROUTES.STOREDETAILS, { state: { store: store } })
                    }}
                    columns={[
                        { Header: "Store No.", accessor: "store_code", width: "9%" },
                        { Header: "Store Name", accessor: "store_name", width: "9%" },
                        { Header: "Owner Name", accessor: "owner_name", width: "9%" },
                        { Header: "Email", accessor: "email", width: "15%", },
                        { Header: "Mobile No.", accessor: "mobile_number", width: "9%" },
                        { Header: "Address", accessor: "address", width: "15%" },
                        { Header: "Added Date", accessor: "date_added", isDate: true, format: "dd/mm/yyyy", width: "10%" },
                        { Header: "Status", accessor: "active_label", width: "5%", showColor: true },
                    ]}
                    count={stores[0]?.total_stores}
                    pageSize={filters.pageSize}
                    pageNumber={filters.pageNumber}
                    data={stores.map(st => {
                        return {
                            ...st,
                            active_label: st.is_active ? "Active" : "Inactive",
                            color: st.is_active ? 'green' : 'red'
                        }
                    })}
                    tableContainerHeight={"100%"}
                    hidePagination={false}
                    headerStyle={{ zIndex: 0 }}
                />
            </div>
        </MainContentWrapper>
    )
}

export default Stores