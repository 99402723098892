import { useLocation } from "react-router"
import bhumeet from "../../assets/bhumeet.svg"
import { useEffect, useState } from "react"
import Loading from "../../ReusableFunctions/Loader"
import Popup from "../../ReusableFunctions/Popup"
import dateFormat from 'dateformat';
import { getSettlementInvoiceDetails } from "../../apis/settlements"
import { formatToIndianRupees, squareKilometersToAcres } from "../../ReusableFunctions/reusableFunctions"
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import dashLine from "../../assets/dashLine.svg"
import { SETTELEMENT_STATUSES } from "../../appConstants"

const DspInvoiceDetails = () => {
    const { dsp, invoice } = useLocation().state
    const [settInvoice, setSettInvoice] = useState({})
    const [isDownload, setIsDownload] = useState(false)
    const [serviceInvoices, setServiceInvoices] = useState([])

    const splitArrayIntoChunks = (array, chunkSize) => {
        let result = [];
        for (let i = 0; i < array.length; i += chunkSize) {
            result.push(array.slice(i, i + chunkSize));
        }
        return result;
    };

    const getSettelementInvoiceDetailsFunc = async () => {
        try {
            Loading.set(true)
            const settleInvoice = await getSettlementInvoiceDetails(invoice?.id)
            const array = settleInvoice?.serviceInvoices
            setServiceInvoices(array)
            const splitInvolices = splitArrayIntoChunks(array || [], 16);
            setSettInvoice({
                ...settleInvoice,
                time: new Date(settleInvoice?.time),
                invoice_id: invoice?.id,
                generated_time: new Date(settleInvoice?.generated_time),
                serviceInvoices: splitInvolices
            })
            Loading.set(false)
        } catch (error) {
            Loading.set(false)
            Popup.alert("Error", error, "ERROR")
        }
    }

    const wait = async () => {
        return new Promise((resolve) => {
            setTimeout(resolve, 0)
        })
    }

    const downloadPDF = async () => {
        setIsDownload(true);
        const splitArray1 = splitArrayIntoChunks(serviceInvoices, 24)
        setSettInvoice((prev) => ({
            ...prev,
            serviceInvoices: splitArray1
        }))
        await wait(); // Assuming wait() is some delay or other asynchronous task
        const input = document.querySelector('.myDiv');

        html2canvas(input, {
            scale: 2,
            useCORS: true,
            backgroundColor: null,
        }).then(canvas => {
            const imgWidth = 210; // A4 width in mm
            const pageHeight = 297; // A4 height in mm
            const imgHeight = (canvas.height * imgWidth) / canvas.width; // Proportional image height
            let heightLeft = imgHeight;

            const pdf = new jsPDF({
                orientation: 'portrait',
                unit: 'mm',
                format: 'a4',
            });

            let position = 0; // Position on the first page
            let pageCount = 1;
            pdf.setFontSize(10);

            // Add the first image
            pdf.addImage(
                canvas.toDataURL('image/png'),
                'PNG',
                0,
                position,
                imgWidth,
                imgHeight
            );
            heightLeft -= pageHeight; // Subtract page height from image height
            pdf.text(`Page ${pageCount}`, 100, 290);

            // Add new pages if there's more content
            while (heightLeft > 0) {
                position = heightLeft - imgHeight; // Update position for next page
                pdf.addPage(); // Add new page
                pdf.addImage(
                    canvas.toDataURL('image/png'),
                    'PNG',
                    0,
                    position,
                    imgWidth,
                    imgHeight
                );
                pageCount++
                pdf.text(`Page ${pageCount}`, 100, 290);
                heightLeft -= pageHeight; // Subtract again for the next page
            }

            pdf.save('invoice.pdf');
            const splitArray2 = splitArrayIntoChunks(serviceInvoices, 16)
            setSettInvoice((prev) => ({
                ...prev,
                serviceInvoices: splitArray2
            }))
            setIsDownload(false);
        });
    };

    useEffect(() => {
        getSettelementInvoiceDetailsFunc()
    }, [])

    return (
        <div style={{ overflow: "auto", position: "relative", display: "flex", flexDirection: "column", alignItems: "center", gap: '20px' }}>
            {isDownload ? (
                <div style={{
                    position: 'relative',
                    top: '10px',
                    right: '-240px',
                    fontSize: '14px',
                }}>
                    Downloading...
                </div>
            ) : (
                <a
                    onClick={downloadPDF}
                    style={{
                        position: 'relative',
                        top: '10px',
                        right: '-240px',
                        textDecoration: 'underline',
                        color: '#007BFF',
                        cursor: 'pointer',
                        fontSize: '14px',
                    }}
                >
                    Download Invoice
                </a>
            )}
            <div className='myDiv' style={{ width: "620px" }}>
                <div style={{
                    width: '620px',
                    border: isDownload ? 'none' : '1px solid rgba(215, 218, 224, 0.4)',
                    borderRadius: '5px',
                    boxShadow: '0 0 10px rgba(0,0,0,0.1)',
                    color: "#3B3B3B",
                    backgroundColor: '#fff'
                }}>
                    <div>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'flex-start',
                            margin: '0px 30px',
                            padding: '20px 0px',
                            borderBottom: '1px solid rgba(215, 218, 224, 0.3)',
                        }}>
                            <div>
                                <img src={bhumeet} alt="bhumeet" width={"100px"} />
                            </div>
                            <div style={{ textAlign: 'right' }}>
                                <p style={{ margin: '0', fontSize: "13px", }}><span style={{ fontWeight: 600, color: "#1A1C21" }}>Invoice No.</span> - {settInvoice?.invoice_number || "-"}</p>
                            </div>
                        </div>

                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            color: "#727272",
                            padding: "20px 30px"
                        }}>
                            <div>
                                <div style={{ marginBottom: '20px' }}>
                                    <p style={{ margin: '0', fontWeight: 500 }}>To</p>
                                    <p style={{ margin: '0', fontWeight: 'bold', color: "#1E1E1E" }}>{settInvoice?.to?.name}</p>
                                    <p style={{ margin: '0' }}>{settInvoice?.to?.address}</p>
                                    <p style={{ margin: '0' }}>{settInvoice?.to?.gstin}</p>
                                </div>
                                <div>
                                    <p style={{ margin: '0', fontWeight: 500 }}>From</p>
                                    <p style={{ margin: '0', fontWeight: 'bold', color: "#1E1E1E" }}>{settInvoice?.from?.name || "-"}</p>
                                    <p style={{ margin: '0' }}>{settInvoice?.from?.address || "-"}</p>
                                </div>
                            </div>
                        </div>

                        <div style={{
                            borderTop: '1px solid rgba(215, 218, 224, 0.6)',
                            paddingTop: '10px',
                            margin: "10px 30px 30px"
                        }}>
                            <p style={{ margin: '0', fontSize: "14px" }}><span style={{ fontWeight: 600, color: "#5E6470" }}>Invoice Date -</span><span style={{ color: "#5E6470" }}>{dateFormat(settInvoice?.generated_for_date, "dd mmm yyyy")}</span></p>
                        </div>

                        <div style={{
                            backgroundColor: '#D9D9D9',
                            padding: '10px 25px',
                            display: 'flex',
                            justifyContent: 'space-between',
                            fontWeight: 700,
                            color: '#1E1E1E',
                            height: "50px",
                            alignItems: "center",
                            fontSize: "18px"
                        }}>
                            <span>Payment Summary</span>
                            <span>Rates</span>
                        </div>

                        <div style={{ padding: '10px 30px 10px' }}>
                            <div style={{ marginBottom: '10px' }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', fontWeight: 500 }}>
                                    <span>Service Charges</span>
                                    <span>₹ {formatToIndianRupees(settInvoice?.service_amount || 0)}</span>
                                </div>
                                {settInvoice?.descriptions?.service_amount &&
                                    <div style={{ fontSize: '12px', color: "#848484" }}>({settInvoice?.descriptions?.service_amount})</div>
                                }
                            </div>
                            {settInvoice?.bhumeet_coupons_amount > 0 &&
                                <div style={{ marginBottom: '10px' }}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', fontWeight: 500 }}>
                                        <span>BhuMeet Coupon Redeemed</span>
                                        <span style={{}}>+ ₹ {formatToIndianRupees(settInvoice?.bhumeet_coupons_amount || 0)}</span>
                                    </div>
                                    {settInvoice?.descriptions?.bhumeet_coupons_amount &&
                                        <div style={{ fontSize: '12px', color: "#848484" }}>({settInvoice?.descriptions?.bhumeet_coupons_amount})</div>
                                    }
                                </div>
                            }
                            {settInvoice?.incentive_from_bhumeet_amount > 0 &&
                                <div style={{ marginBottom: '10px' }}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', fontWeight: 500 }}>
                                        <span>Incentive Offer</span>
                                        <span style={{}}>+ ₹ {formatToIndianRupees(settInvoice?.incentive_from_bhumeet_amount || 0)}</span>
                                    </div>
                                    {settInvoice?.descriptions?.incentive_from_bhumeet_amount &&
                                        <div style={{ fontSize: '12px', color: "#848484" }}>({settInvoice?.descriptions?.incentive_from_bhumeet_amount})</div>
                                    }
                                </div>
                            }
                            <img src={dashLine} alt="dashLine" style={{ maxWidth: "103%" }} />
                            <div style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                marginTop: '10px',
                                fontWeight: 500
                            }}>
                                <span>Subtotal</span>
                                <span>₹ {formatToIndianRupees(settInvoice?.total_dsp_amount || 0)}</span>
                            </div>
                            {settInvoice?.platform_fees_amount > 0 &&
                                <div style={{ marginTop: '10px' }}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', fontWeight: 500 }}>
                                        <span>Platform Fees
                                            {settInvoice?.descriptions?.platform_fees_amount &&
                                                <span style={{
                                                    padding: '2px 5px',
                                                    borderRadius: '3px',
                                                    fontSize: '12px',
                                                    color: "#848484"
                                                }}>
                                                    ({settInvoice?.descriptions?.platform_fees_amount})
                                                </span>
                                            }
                                        </span>
                                        <span style={{}}>- ₹ {formatToIndianRupees(settInvoice?.platform_fees_amount || 0)}</span>
                                    </div>
                                </div>
                            }
                            {settInvoice?.tds_charges > 0 &&
                                <div style={{ marginTop: '10px' }}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', fontWeight: 500 }}>
                                        <span>TDS
                                            {settInvoice?.descriptions?.tds_charges &&
                                                <span style={{
                                                    padding: '2px 5px',
                                                    borderRadius: '3px',
                                                    fontSize: '12px',
                                                    color: "#848484"
                                                }}>
                                                    {settInvoice?.descriptions?.tds_charges}
                                                </span>
                                            }
                                        </span>
                                        <span style={{}}>- ₹ {formatToIndianRupees(settInvoice?.tds_charges)}</span>
                                    </div>
                                </div>
                            }
                            <div style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                paddingTop: '10px',
                                marginTop: '10px',
                                marginBottom: '20px',
                                fontWeight: 'bold',
                                borderTop: '1px solid rgba(215, 218, 224, 0.6)',
                                borderBottom: '1px solid rgba(215, 218, 224, 0.6)',
                                paddingBottom: '10px',
                                fontSize: "18px"
                            }}>
                                <span>Total Amount</span>
                                <span>₹ {formatToIndianRupees(settInvoice?.total_dsp_amount_to_be_paid || 0)}</span>
                            </div>
                        </div>
                    </div>
                </div>

                {settInvoice?.serviceInvoices?.map((invoice, index) => {
                    return (
                        <div key={index} style={{
                            width: '620px',
                            marginTop: isDownload ? (index === 0 ? '121px' : '81px') : "40px",
                            border: '1px solid rgba(215, 218, 224, 0.4)',
                            borderRadius: '5px',
                            boxShadow: '0 0 10px rgba(0,0,0,0.1)',
                            color: "#3B3B3B",
                            backgroundColor: '#fff'
                        }}>
                            <div key={index} className={`reusable-table-containar scroll-container`} style={{ marginBottom: "20px" }}>
                                <div
                                    style={{
                                        fontWeight: "500",
                                        display: "flex",
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <table className={`reusable-table-view`}>
                                        <thead style={{ width: "100%" }}>
                                            <tr className="reusable-table-view-header-row" style={{ height: isDownload ? "30px" : '45px', display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                                                <th style={{ textAlign: "center", width: "20%", display: "inline-block", fontSize: isDownload ? "11px" : '12px', }}>Request No.</th>
                                                <th style={{ textAlign: "center", width: "20%", display: "inline-block", fontSize: isDownload ? "11px" : '12px', }}>Acre</th>
                                                <th style={{ textAlign: "center", width: "40%", display: "inline-block", fontSize: isDownload ? "11px" : '12px', }}>Date</th>
                                                <th style={{ textAlign: "center", width: "20%", display: "inline-block", fontSize: isDownload ? "11px" : '12px', }}>Charges (₹)</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {invoice?.map((sI, index) => {
                                                return (
                                                    <>
                                                        <tr key={index} className="reusable-table-view-data-row" style={{ height: isDownload ? "30px" : '45px', display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }} align="center">
                                                            <td style={{
                                                                display: "flex",
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                                height: "100%",
                                                                border: "none",
                                                                width: "20%"
                                                            }}>
                                                                {sI.req_number}
                                                            </td>
                                                            <td style={{
                                                                display: "flex",
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                                height: "100%",
                                                                border: "none",
                                                                width: "20%"
                                                            }}>
                                                                {sI?.price_by == "acre" ? squareKilometersToAcres(sI?.service_completed_area) : Number(sI?.service_completed_area)}
                                                            </td>
                                                            <td style={{
                                                                display: "flex",
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                                height: "100%",
                                                                border: "none",
                                                                width: "40%"
                                                            }}>
                                                                {dateFormat(sI.payment_date, "dd mmm yyyy  HH:MM:ss TT")}
                                                            </td>
                                                            <td style={{
                                                                display: "flex",
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                                height: "100%",
                                                                border: "none",
                                                                width: "20%"
                                                            }}>
                                                                {formatToIndianRupees(sI.total_amount || 0)}
                                                            </td>
                                                        </tr>
                                                        <hr></hr>
                                                    </>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    )
                })}
                <div style={{
                    width: '620px',
                    border: '1px solid rgba(215, 218, 224, 0.4)',
                    borderRadius: '5px',
                    boxShadow: '0 0 10px rgba(0,0,0,0.1)',
                    color: "#3B3B3B",
                    backgroundColor: '#fff',
                    margin: "40px 0px"
                }}>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        padding: '20px',
                        fontWeight: 'bold',
                        fontSize: "18px",
                        alignItems: "center"
                    }}>
                        <span>Total Service Charges</span>
                        <span>₹ {formatToIndianRupees(settInvoice?.service_amount || 0)}</span>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default DspInvoiceDetails