import React, { useEffect, useRef, useState } from 'react'
import Popup from '../../ReusableFunctions/Popup'
import Loading from '../../ReusableFunctions/Loader'
import MainContentWrapper from '../../Components/MainContentWrapper'
import TableView from '../../ReusableComponents/TableView/TableView'
import Search from '../../ReusableComponents/Search'
import _debounce from 'lodash/debounce';
import { deleteSupportEmail, getAllSupportEmails, getAllSupportEmailsCount } from '../../apis/adminAPIs/supportEmails'
import ViewEmail from './SubComponents/ViewEmail'
import Plus from "../../assets/Plus.svg";
import { Button } from '../../Components/Helper'
const PAGE_SIZE = 15

const SupportEmails = () => {
    const isFirstRender = useRef(true);
    const [loading, setLoading] = useState()
    const [supportEmails, setSupportEmails] = useState([])
    const [supportEmailsCount, setSupportEmailsCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [selectedSupportEmail, setySelectedSupportEmail] = useState(undefined)
    const [searchString, setSearchString] = useState(undefined)
    const [openAddMailPopup, setOpenAddMailPopup] = useState(false)

    const getAllSupportEmailsFunc = async () => {
        try {
            Loading.set(true)
            const supportEmails = await getAllSupportEmails(PAGE_SIZE, currentPage, searchString)
            Loading.set(false)
            setLoading(false)
            setSupportEmails(supportEmails?.emails)
            if (searchString) {
                setSupportEmailsCount(supportEmails?.emails[0]?.total_count || 0)
            } else {
                getAllSupportEmailsCountFunc()
            }
        } catch (err) {
            setLoading(false)
            Loading.set(false)
            Popup.alert("Error", err, "ERROR")
        }
    }

    const getAllSupportEmailsCountFunc = async () => {
        try {
            const count = await getAllSupportEmailsCount()
            setSupportEmailsCount(count?.emails)
            Loading.set(false)
        } catch (err) {
            setLoading(false)
            Loading.set(false)
            Popup.alert("Error", err, "ERROR")
        }
    }

    const deleteSupportEmailFunc = async (id) => {
        try {
            const deletedEmail = await deleteSupportEmail(id)
            const emails = supportEmails.filter((s) =>
                s.id !== deletedEmail?.email.id
            );
            setSupportEmails(emails)
            Loading.set(false)
        } catch (err) {
            setLoading(false)
            Loading.set(false)
            Popup.alert("Error", err, "ERROR")
        }
    }

    useEffect(() => {
        Loading.set(true)
        getAllSupportEmailsCountFunc()
        getAllSupportEmailsFunc();
    }, [])

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }

        const debouncedGetAllUsers = _debounce(() => {
            getAllSupportEmailsFunc();
        }, 3000);

        debouncedGetAllUsers();

        return () => {
            debouncedGetAllUsers.cancel();
        };
    }, [searchString, currentPage]);

    return (

        <MainContentWrapper leftContent={`Support E-mails (${supportEmailsCount})`}
            rightContent={
                <div style={{ display: "flex", gap: "30px" }}>
                    <Search
                        searchValue={searchString}
                        onChange={(value) => {
                            setLoading(true);
                            setSearchString(value)
                            setCurrentPage(1)
                        }}
                    />
                    <Button
                        icon={Plus}
                        text={"Add New"}
                        onClick={() => {
                            setySelectedSupportEmail(undefined)
                            setOpenAddMailPopup(true)
                        }}
                        style={{ padding: "4px, 24px, 4px, 24px", height: "35px", width: "120px", fontSize: "14px", fontWeight: 500 }}
                    />
                </div>
            }
        >
            {openAddMailPopup &&
                <ViewEmail
                    open={openAddMailPopup}
                    onClose={() => {
                        setOpenAddMailPopup(false)
                    }}
                    email={selectedSupportEmail}
                    onEmailEdit={(email) => {
                        if (selectedSupportEmail?.id) {
                            const emails = supportEmails.map((s) =>
                                s.id === email.id ? email : s
                            );
                            setSupportEmails(emails);
                        } else {
                            setSupportEmails((prev) => [...prev, email])
                        }


                        setOpenAddMailPopup(false);
                    }}
                />
            }

            <TableView
                isLoading={loading}
                tableContainerHeight={supportEmailsCount < 15 ? "calc(100% - 40px)" : "calc(100% - 65px)"}
                showSrNo
                onStateChange={(changedState) => {
                    setLoading(true);
                    setCurrentPage(changedState.pageNumber)
                }}
                onClickRow={(email) => {
                    setySelectedSupportEmail(email)
                }}
                onClickLink={(request) => { }}
                columns={[
                    { Header: "E-mail", accessor: "email" },
                    { Header: "Created date", accessor: "created_date", isDate: true },
                ]}
                data={supportEmails}
                count={supportEmailsCount}
                pageNumber={currentPage}
                pageSize={PAGE_SIZE}
                srNoColumnWidth={8}
                activities={{
                    onClickDelete: (email) => {
                        Popup.alert("WARNING", "Are you sure , you want to delete this email?", "WARNING", () => { deleteSupportEmailFunc(email?.id) })
                    },
                    onClickEdit: (email) => {
                        setySelectedSupportEmail(email)
                        setOpenAddMailPopup(true)
                    },
                    canEdit: true
                }}
            />
        </MainContentWrapper>
    )
}

export default SupportEmails