import { buildUrl } from "../../../ReusableFunctions/reusableFunctions";
import { SERVER_URLS } from "../../../appConstants";
import { getToken } from "../../authentication";

export const getRevenueStats = (fromDate, toDate, dspId) => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = getToken();
            const url = buildUrl(SERVER_URLS.BACKEND_SERVER + "/bhumeet_team/finance/revenue/stats", {
                fromDate: fromDate ? fromDate : undefined,
                toDate: toDate ? toDate : undefined,
                dspId: dspId ? dspId : undefined
            })
            const response = await fetch(url,
                {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const data = await response.json();
            if (response.ok) {
                resolve(data?.revenueOverview);
            } else {
                reject(
                    data?.message || "Something went wrong while getting revenue stats."
                );
            }
        } catch (error) {
            reject("Something went wrong while getting revenue stats.");
        }
    });
};