import React from 'react'
import AdminStatusCountCard from '../SubComponents/AdminStatusCountCard'
import { PDRL_ADMIN_ROUTES } from '../../../../routes';
import serviceCardIcon from "../../../../assets/serviceCardIcon.svg"
const StatusCountAndAmountContainer = ({ requestsStatuses }) => {
     return (
          <div style={{
               display: "grid",
               gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
               gap: "20px",
               marginBottom: "40px"
          }}>
               <AdminStatusCountCard
                    loading={false}
                    view={PDRL_ADMIN_ROUTES.USERS}
                    data={requestsStatuses?.find(st => st.order_status === "Rescheduled") ?? { order_status: "Rescheduled", total_orders: 0, total_amount: 0 }}
                    icon={serviceCardIcon}
               />
               <AdminStatusCountCard
                    loading={false}
                    view={PDRL_ADMIN_ROUTES.USERS}
                    data={requestsStatuses?.find(st => st.order_status === "InProgress") ?? { order_status: "InProgress", total_orders: 0, total_amount: 0 }}
                    icon={serviceCardIcon}
               />
               <AdminStatusCountCard
                    loading={false}
                    view={PDRL_ADMIN_ROUTES.USERS}
                    data={requestsStatuses?.find(st => st.order_status === "Out for Service") ?? { order_status: "Out for Service", total_orders: 0, total_amount: 0 }}
                    icon={serviceCardIcon}
               />
               <AdminStatusCountCard
                    loading={false}
                    view={PDRL_ADMIN_ROUTES.USERS}
                    data={requestsStatuses?.find(st => st.order_status === "Paid") ?? { order_status: "Paid", total_orders: 0, total_amount: 0 }}
                    icon={serviceCardIcon}
               />
               <AdminStatusCountCard
                    loading={false}
                    view={PDRL_ADMIN_ROUTES.USERS}
                    data={requestsStatuses?.find(st => st.order_status === "Placed") ?? { order_status: "Placed", total_orders: 0, total_amount: 0 }}
                    icon={serviceCardIcon}
               />
               <AdminStatusCountCard
                    loading={false}
                    view={PDRL_ADMIN_ROUTES.USERS}
                    data={requestsStatuses?.find(st => st.order_status === "Cancelled") ?? { order_status: "Cancelled", total_orders: 0, total_amount: 0 }}
                    icon={serviceCardIcon}
               />
               <AdminStatusCountCard
                    loading={false}
                    view={PDRL_ADMIN_ROUTES.USERS}
                    data={requestsStatuses?.find(st => st.order_status === "On Hold") ?? { order_status: "On Hold", total_orders: 0, total_amount: 0 }}
                    icon={serviceCardIcon}
               />
               <AdminStatusCountCard
                    loading={false}
                    view={PDRL_ADMIN_ROUTES.USERS}
                    data={requestsStatuses?.find(st => st.order_status === "Accepted") ?? { order_status: "Accepted", total_orders: 0, total_amount: 0 }}
                    icon={serviceCardIcon}
               />
               <AdminStatusCountCard
                    loading={false}
                    view={PDRL_ADMIN_ROUTES.USERS}
                    data={requestsStatuses?.find(st => st.order_status === "Completed") ?? { order_status: "Completed", total_orders: 0, total_amount: 0 }}
                    icon={serviceCardIcon}
               />
          </div>
     )
}

export default StatusCountAndAmountContainer