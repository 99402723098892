import { SERVER_URLS } from "../../appConstants";
import { getToken } from "../authentication";

export const getAllUsersCount = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = getToken();
            const response = await fetch(
                SERVER_URLS.BACKEND_SERVER + "/aggregator/user/getAllUsersCount",
                {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const data = await response.json();
            if (response.ok) {
                resolve(data.usersCount);
            } else {
                reject(
                    data?.message || "Something went wrong while getting user count."
                );
            }
        } catch (error) {
            reject("Something went wrong while getting user count.");
        }
    });
}

export const getAllUsers = (pageSize, pageNumber, searchQuery, fromDate, toDate) => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = getToken();
            const response = await fetch(
                SERVER_URLS.BACKEND_SERVER + `/aggregator/user/getAllUsers?searchQuery=${searchQuery && searchQuery != "" ? searchQuery : ' '}${pageNumber ? `&pageNumber=${pageNumber}` : ''}${pageSize ? `&pageSize=${pageSize}` : ''}${(fromDate && toDate) ? `&fromDate=${new Date(fromDate).toISOString()}&toDate=${new Date(toDate).toISOString()}` : ''}`,
                {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const data = await response.json();
            if (response.ok) {
                resolve(data);
            } else {
                reject(
                    data?.message || "Something went wrong while getting users."
                );
            }
        } catch (error) {
            reject("Something went wrong while getting users.");
        }
    });
}
