import React, { useState } from 'react';
import '../Operations/DspDetails.css';
import { useLocation } from 'react-router';
import ViewOperationDsp from '../Operations/ViewOperationDsp';
import Requests from '../../AdminComponents/Requests/Requests';



function FinanceDspDetailsDashboard() {
    const [activeTab, setActiveTab] = useState('details');
    const location = useLocation();
    const [dsp, setDsp] = useState(location.state?.dsp)
    const tabs = ['details', 'requests'];
    return (
        <div style={{ backgroundColor: "rgb(236, 239, 243)", overflow: "hidden" }} className='flex flex-col gap-5 h-[100%]  px-[20px] py-[50px]'>
            <div className="drone-tabs ">
                {tabs.map((tab) => (
                    <button
                        key={tab}
                        className={`tab-button ${activeTab === tab ? 'active' : ''}`}
                        onClick={() => setActiveTab(tab)}
                    >
                        {tab.charAt(0).toUpperCase() + tab.slice(1)}
                    </button>
                ))}
            </div>
            <div className="flex-grow overflow-y-auto">
                {activeTab === "details" ? (
                    <ViewOperationDsp hideBankDetails={true} dsp={dsp} callback={(dsp) => {
                        setDsp(dsp);
                    }} />
                ) : activeTab === "requests" ? (
                    <Requests dsp={dsp} />
                ) : null}
            </div>
        </div>
    );
}

export default FinanceDspDetailsDashboard;