import { validateAdhaarCardNo } from "../ReusableFunctions/reusableFunctions"

export const nameValidation = {
  required: {
    value: true,
    message: 'Required',
  },
  maxLength: {
    value: 30,
    message: 'Max 30 characters.',
  },
  minLength: {
    value: 3,
    message: "At least 3 characters.",
  },
  pattern: {
    value: /^[A-Za-z\s]+$/,
    message: 'Only letters allowed.',
  },
  format: {
    value: /^[A-Za-z\s]+$/,
    message: 'Only letters allowed.',
  },
  validate: (value) => {
    if (value.trim() === '') {
      return "Name can't be empty.";
    }
    return true;
  }
}

export const agroChemicleNameValidation = {
  required: {
    value: true,
    message: 'Required',
  },
  maxLength: {
    value: 20,
    message: 'Max 20 characters.',
  },
  minLength: {
    value: 3,
    message: "At least 3 characters.",
  },
  validate: (value) => {
    if (value.trim() === '') {
      return "Name can't be empty.";
    }
    return true;
  }
}


export const addressValidation = {
  required: {
    value: true,
    message: 'Required',
  },
  minLength: {
    value: 3,
    message: "At least 3 characters.",
  },
  maxLength: {
    value: 50,
    message: "Max 50 characters.",
  },
  pattern: {
    value: /^[A-Za-z.'-:\s]+$/,
    message: 'Only letters allowed.',
  },
  format: {
    value: /^[A-Za-z.'-:\s]+$/,
    message: 'Only letters allowed.',
  },
};

export const descValidation = {
  required: {
    value: true,
    message: 'Required',
  },
  minLength: {
    value: 5,
    message: "At least 5 characters.",
  },
  maxLength: {
    value: 200,
    message: 'Max 200 characters.',
  }
}

export const passwordValidation = {
  required: {
    value: true,
    message: 'Required.',
  },
  minLength: {
    value: 8,
    message: 'At least 8 characters.',
  },
  maxLength: {
    value: 30,
    message: 'Max 30 characters.',
  },
  pattern: {
    value: /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W)[A-Za-z\d\W]{8,}$/,
    message: 'Must include lowercase, uppercase, digit & special char.',
  }
}

export const newPasswordValidation = {
  required: {
    value: true,
    message: 'Required.',
  },
  minLength: {
    value: 8,
    message: 'At least 8 characters.',
  },
  maxLength: {
    value: 30,
    message: 'Max 30 characters.',
  },
  pattern: {
    value: /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W)[A-Za-z\d\W]{8,}$/,
    message: 'Must include lowercase, uppercase, digit & special char.',
  },
  validate: (value, context) => {
    if (context.oldPassword && value === context.oldPassword) {
      return "New password must differ from the old one.";
    }
    if (context.confirmPassword && value !== context.confirmPassword) {
      return "Passwords do not match.";
    }
    if (context.verifyPassword && value !== context.verifyPassword) {
      return "Passwords do not match.";
    }
    return true;
  },
}

export const confirmPasswordValidation = {
  required: {
    value: true,
    message: "Required",
  },
  minLength: {
    value: 8,
    message: 'At least 8 characters.',
  },
  maxLength: {
    value: 30,
    message: 'Max 30 characters.',
  },
  validate: (value, context) => {
    if (context.newPassword && value !== context.newPassword) {
      return "Passwords do not match.";
    }
    if (context.password && value !== context.password) {
      return "Passwords do not match.";
    }
    return true;
  },
};

export const phoneNumValidation = {
  required: {
    value: true,
    message: 'Required.',
  },
  minLength: {
    value: 10,
    message: 'Must be 10 digits.',
  },
  maxLength: {
    value: 10,
    message: 'Must be 10 digits.',
  },
  pattern: {
    value: /^\d{10}$/,
    message: 'Enter a valid 10-digit number.',
  },
  format: {
    value: /^[0-9]+$/,
    message: 'Only digits are allowed.',
  }
}

export const emailValidation = {
  required: {
    value: true,
    message: 'Required',
  },
  maxLength: {
    value: 50,
    message: 'Max 50 characters.',
  },
  pattern: {
    value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    message: 'Enter a valid email.',
  },
  format: {
    value: /^[a-zA-Z0-9.@]+$/,
    message: 'Character are not allowed.',
  }
}

export const selectFieldValidation = {
  required: {
    value: true,
    message: 'Required',
  }
}

export const DroneUINValidation = {
  required: {
    value: true,
    message: "Required",
  },
  minLength: {
    value: 6,
    message: 'At least 6 characters.',
  },
  maxLength: {
    value: 16,
    message: 'Max 16 characters.',
  },
  pattern: {
    value: /^[^!@#$%^&*()_+{}\[\]:;<>,.?~\\\/\-='`\s]+$/,
    message: 'No spaces or special characters.',
  },
  format: {
    value: /^[a-zA-Z0-9\p{S}\p{P}\p{Zs}]+$/,
    message: 'Character are not allowed.',
  }
}

export const BatteryCapacityValidation = {
  required: 'Required',
  max: {
    value: 100000,
    message: 'Max 100000 mAh.',
  },
  validate: (value) => {
    if (isNaN(value)) {
      return 'Must be a number.';
    }
  },
  format: {
    value: /^[0-9]+$/,
    message: 'Must be a number.',
  }
}

export const enduranceValidation = {
  required: 'Required',
  max: {
    value: 10000,
    message: 'Max 10000 minutes.',
  },
  validate: (value) => {
    if (isNaN(value)) {
      return 'Must be a number.';
    }
  },
  format: {
    value: /^[0-9]+$/,
    message: 'Must be a number.',
  }
}

export const pincodeValidation = {
  required: 'Required',
  pattern: {
    value: /^[1-9][0-9]{5}$/,
    message: 'Invalid format.',
  },
  minLength: {
    value: 6,
    message: 'Must be 6 digits.',
  },
  maxLength: {
    value: 6,
    message: 'Must be 6 digits.',
  },
  format: {
    value: /^[0-9]+$/,
    message: 'Must be a number.',
  }
}

export const aadharCardValidation = {
  required: {
    value: true,
    message: "Required",
  },
  minLength: {
    value: 12,
    message: 'Must be 12 digits.',
  },
  maxLength: {
    value: 12,
    message: 'Must be 12 digits.',
  },
  pattern: {
    value: /^\d{12}$/,
    message: "Must be 12 digits.",
  },
  validate: (value) => {
    const error = validateAdhaarCardNo(value);
    return error === "" || error;
  },
  format: {
    value: /^[0-9]+$/,
    message: 'Must be a number.',
  }
};

export const panValidation = {
  required: {
    value: true,
    message: 'Required',
  },
  pattern: {
    value: /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
    message: 'Invalid format.',
  },
  maxLength: {
    value: 10,
    message: 'Must be 10 characters.',
  },
  minLength: {
    value: 10,
    message: 'Must be 10 characters.',
  },
  isUpperCase: true,
  format: {
    value: /^[A-Z0-9]+$/,
    message: 'Invalid input.',
  }
};

export const licenceValidation = {
  required: {
    value: true,
    message: 'Required',
  },
  maxLength: {
    value: 20,
    message: "Max 20 characters.",
  },
  minLength: {
    value: 3,
    message: 'Must be 3 characters.',
  },
  pattern: {
    value: /^[a-zA-Z0-9]+(-[a-zA-Z0-9]+)*$/,
    message: 'Invalid format.',
  },
  format: {
    value: /^[A-Za-z0-9-\s]+$/,
    message: 'Only alphanumeric allowed.',
  },
}

export const droneNameValidation = {
  required: {
    value: true,
    message: "Required",
  },
  pattern: {
    value: /^[a-zA-Z0-9\s]+$/,
    message: "Only alphanumeric and spaces allowed.",
  },
  validate: {
    noLeadingOrTrailingSpaces: (value) => {
      if (value) {
        const strValue = String(value);
        return strValue.trim() === strValue || "No leading or trailing spaces.";
      }
    },
    noMultipleSpaces: (value) => {
      if (value) {
        const strValue = String(value);
        return !/\s{2,}/.test(strValue) || "No consecutive spaces.";
      }
    },
  },
  maxLength: {
    value: 30,
    message: "Max 30 characters.",
  },
  format: {
    value: /^[A-Za-z0-9\s]+$/,
    message: 'Only alphanumeric and spaces allowed.',
  }
};

export const modelNameValidation = {
  required: {
    value: true,
    message: "Required",
  },
  pattern: {
    value: /^[a-zA-Z0-9\s]+$/,
    message: "Only alphanumeric and spaces allowed.",
  },
  validate: {
    noLeadingOrTrailingSpaces: (value) => {
      if (value) {
        const strValue = String(value);
        return strValue.trim() === strValue || "No leading or trailing spaces.";
      }
    },
    noMultipleSpaces: (value) => {
      if (value) {
        const strValue = String(value);
        return !/\s{2,}/.test(strValue) || "No consecutive spaces.";
      }
    },
  },
  maxLength: {
    value: 30,
    message: "Max 30 characters.",
  },
  format: {
    value: /^[A-Za-z0-9\s]+$/,
    message: 'Only alphanumeric and spaces allowed.',
  }
};

export const firstLastNameValidation = {
  required: {
    value: true,
    message: 'Required',
  },
  maxLength: {
    value: 20,
    message: 'Max 20 characters.',
  },
  minLength: {
    value: 3,
    message: "At least 3 characters.",
  },
  pattern: {
    value: /^[A-Za-z\s]+$/,
    message: 'Only letters allowed.',
  },
  validate: (value) => {
    if (value.trim() === '') {
      return "Name can't be empty.";
    }
    if (/^\s|\s$/.test(value)) {
      return "No spaces allowed.";
    }
    return true;
  },
  format: {
    value: /^[A-Za-z\s]+$/,
    message: 'Only letters allowed.',
  },
}

export const companyName = {
  required: {
    value: true,
    message: 'Required',
  },
  maxLength: {
    value: 50,
    message: 'Max 50 characters.',
  },
  minLength: {
    value: 3,
    message: "At least 3 characters.",
  },
  pattern: {
    value: /^[A-Za-z0-9\s]+$/,
    message: 'Only AlphaNumeric value allowed',
  },
  validate: (value) => {
    if (value.trim() === '') {
      return "Name can't be empty.";
    }
    return true;
  },
  format: {
    value: /^[A-Za-z0-9\s]+$/,
    message: 'Only AlphaNumeric value allowed.',
  },
}

export const discountValidation = {
  required: 'Required',
  maxLength: {
    value: 3,
    message: 'Max 100 percent.',
  },
  validate: (value) => {
    if (isNaN(value)) {
      return 'Must be a number.';
    } else if (value < 1 || value > 100) {
      return 'Discount percentage should be between 1 to 100';
    }
  },
  format: {
    value: /^[0-9]+$/,
    message: 'Must be a number.',
  },
}

export const discountCostValidation = {
  required: 'Required',
  maxLength: {
    value: 5,
    message: 'Max allowed discount is 99999.',
  },
  validate: (value) => {
    if (isNaN(value)) {
      return 'Must be a number.';
    } else if (value <= 0 || value > 99999) {
      return 'Discount cost should be between 1 to 99999';
    }
  },
  format: {
    value: /^[0-9]+$/,
    message: 'Must be a number.',
  },
}

export const NoOfUserValidation = {
  required: 'Required',
  maxLength: {
    value: 3,
    message: 'Max allowed usage is 999.',
  },
  validate: (value) => {
    if (isNaN(value)) {
      return 'Must be a number.';
    } else if (value <= 0 || value > 999) {
      return 'No. of uses should be between 1 to 999';
    }
  },
  format: {
    value: /^[0-9]+$/,
    message: 'Must be a number.',
  },
}

export const couponMessageValidation = {
  required: {
    value: true,
    message: 'Required',
  },
  maxLength: {
    value: 50,
    message: 'Max 50 characters.',
  },
  minLength: {
    value: 3,
    message: "At least 3 characters.",
  },
  validate: (value) => {
    if (value.trim() === '') {
      return "Name can't be empty.";
    }
    return true;
  }
}

export const redemptionCountValidation = {
  required: 'Required',
  maxLength: {
    value: 6,
    message: 'Max allowed redemption count is 100000.',
  },
  validate: (value) => {
    if (isNaN(value)) {
      return 'Must be a number.';
    } else if (value <= 0 || value > 100000) {
      return 'Redemption count should be between 1 to 100000';
    }
  },
  format: {
    value: /^[0-9]+$/,
    message: 'Must be a number.',
  },
}

export const OTPValidation = {
  required: {
    value: true,
    message: 'Required',
  },
  maxLength: {
    value: 6,
    message: 'Max 6 characters.',
  },
  minLength: {
    value: 6,
    message: 'Enter 6 characters OTP',
  },
}

export const UTRValidation = {
  required: {
    value: true,
    message: 'Required',
  },
  maxLength: {
    value: 22,
    message: 'Max 22 characters.',
  },
  minLength: {
    value: 16,
    message: 'Min 16 characters.',
  }
}

export const IFSCValidation = {
  required: "IFSC code is required",
  pattern: {
    value: /^[A-Z]{4}0[A-Z0-9]{6}$/,
    message: "please enter valid IFSC code",
  },
};

export const BankAccountNoValidation = {
  required: "account number is required",
  validate: {
    isNumeric: (value) => /^\d+$/.test(value) || "account number must contain only digits",
    length: (value) => (value.length >= 9 && value.length <= 18) || "account number must be between 9 and 18 digits long",
    noWhitespaces: (value) => !/\s/.test(value) || "account number should not contain any whitespaces",
    noSpecialChars: (value) => /^[0-9]+$/.test(value) || "account number should not contain special characters",
  },
}

export const bankNameValidation = {
  required: "Bank name is required",
  minLength: {
    value: 3,
    message: "Bank name must be at least 3 characters long",
  },
  maxLength: {
    value: 50,
    message: "Bank name cannot exceed 50 characters",
  },
  pattern: {
    value: /^[a-zA-Z\s]+$/,
    message: "Bank name can only contain letters and spaces",
  }
}

export const accountHolderName = {
  required: "Account holder name is required",
  minLength: {
    value: 5,
    message: "Account holder name at least 5 characters long",
  },
  maxLength: {
    value: 50,
    message: "Account holder name exceed 50 characters",
  },
  pattern: {
    value: /^[A-Za-z]+(\s[A-Za-z]+)*$/,
    message: "Account holder name can only contain alphabets"
  }
}


export const ConfirmAccountNumber = {
  required: {
    value: true,
    message: "Required",
  },
  validate: (value, context) => {
    if (context.account_no && value !== context.account_no) {
      return "Account mumber do not match.";
    }
    return true;
  },
};

export const branchAddressValidation = {
  required: {
    value: true,
    message: "Required",
  },
  minLength: {
    value: 3,
    message: "Bank  Address should  least 3 characters long",
  },
  maxLength: {
    value: 50,
    message: "Bank Address exceed 50 characters",
  },

}

export const couponCodeValidation = {
  required: 'Required',
  maxLength: {
    value: 15,
    message: 'Max 15 characters.',
  },
  minLength: {
    value: 3,
    message: "At least 3 characters.",
  },
  validate: (value) => {
    const alphanumericRegex = /^[a-zA-Z0-9]+$/;
    if (value.trim() === '') {
      return "Name can't be empty.";
    }
    if (!alphanumericRegex.test(value)) {
      return 'Spaces and special characters not allowed.';
    }
    return true;
  }
}
export const subsidyAmountValidation = {
  min: {
    value: 0,
    message: "Number must be at least 0",
  },
  maxLength: {
    value: 5,
    message: 'Max 5 characters.',
  },
  minLength: {
    value: 1,
    message: "At least 1 characters.",
  },
  validate: {
    isPositiveNumber: (value) => {
      const parsedValue = Number(value);
      return !isNaN(parsedValue) && parsedValue >= 0 || "Number must be at least 0";
    },
    isWholeNumber: (value) => {
      const parsedValue = Number(value);
      return !isNaN(parsedValue) && Number.isInteger(parsedValue) || "Number must be a whole number";
    },
  },
}
