import React from 'react'

const DetailsWrapper = ({ children, wrapperStyle, className }) => {
    return (
        <div className={className} style={wrapperStyle}>
            {children}
        </div>
    )
}

export default DetailsWrapper