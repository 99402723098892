import React, { useState } from "react";
import ReactLoading from "react-loading";
import { GLOBAL_STYLES, STATUS } from "../../../../appConstants";
import { reduceLongName } from "../../../../ReusableFunctions/reusableFunctions";
import Paid from "../../../../assets/paid.svg"
import InProgress from "../../../../assets/inprogress.svg"
import Received from "../../../../assets/recieved.svg"
import accepted from "../../../../assets/accepted.svg"
import cancle from "../../../../assets/cancle.svg"
import outForService from "../../../../assets/outForService.svg"
import reschedule from "../../../../assets/reschedule.svg"
import Completed from "../../../../assets/Completed.svg"
import OnHold from "../../../../assets/OnHold.svg"
import Tippy from "@tippyjs/react";


const AdminStatusCountCard = ({ backGround, loading, title, data, icon, isDisabled, iconStyle, }) => {
    const [imgIcon, setImgIcon] = useState(icon)
    const pickIcon = (status) => {
        switch (status) {
            case STATUS.ACCEPTED:
                return accepted;

            case STATUS.OUT_FOR_SERVICE:
                return outForService;

            case STATUS.COMPLETED:
                return Completed;

            case STATUS.INPROGRESS:
                return InProgress;

            case STATUS.RESCHEDULED:
                return reschedule;

            case STATUS.PAID:
                return Paid;

            case STATUS.PLACED:
                return Received;

            case STATUS.CANCELLED:
                return cancle;
            case STATUS.HOLD:
                return OnHold;
        }
    }
    return (
        <div className="orders-card-parent"
            onClick={(e) => {

            }}
            onMouseOver={() => setImgIcon(icon)}
            onMouseOut={() => setImgIcon(icon)}
            style={{ cursor: isDisabled ? "not-allowed" : "pointer", pointerEvents: isDisabled ? "none" : undefined, background: backGround, color: backGround ? "white" : undefined }}
        >
            {
                loading ?
                    <div style={{ position: "relative", height: "100%", width: "100%" }}>
                        <ReactLoading
                            className="loading"
                            type={"spin"}
                            color={GLOBAL_STYLES.BG_ACTIVE}
                            height={"13%"}
                            width={"10%"}
                        />
                    </div> : <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }} >
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", gap: "5px", height: "90%" }}>
                            <div style={{ color: backGround ? "white" : undefined, marginTop: "20px" }} className="dashboard-card-parent-inner1 dashboard-cards-heading"  >{data?.order_status}</div>
                            <div className="dashboard-card-parent-inner2 dashboard-cards-main-data" style={{ fontSize: "22px" }}> {data?.total_orders}</div>
                            <Tippy content={Number(data?.total_amount).toFixed()}>
                                <div className="dashboard-card-parent-inner2 dashboard-cards-main-data" style={{ fontSize: "20px" }}> {`₹ ${reduceLongName(Number(data?.total_amount).toFixed(), 12)} ` || ''}</div>
                            </Tippy>
                        </div>
                        {imgIcon && <div className="orders-card-parent-inner3-container">
                            <img className="dashboard-card-parent-inner3" style={{ ...iconStyle }} src={pickIcon(data?.order_status)} />
                        </div>}
                    </div>
            }
        </div>
    );
};

export default AdminStatusCountCard;
