import React from 'react'
import AdminStatusCountCard from './AdminStatusCountCard'

const StatusCountContainer = ({ requestsStatuses }) => {
    return (
        <div style={{ display: "grid", justifyContent: "space-between", rowGap: "20px", gridTemplateColumns: "repeat(auto-fill, minmax(216px, 1fr))", marginBottom: "40px" }}>
            {requestsStatuses.map((rs) => (
                <AdminStatusCountCard
                    request={rs}
                />
            ))}
        </div>
    )
}

export default StatusCountContainer