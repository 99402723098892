import { buildUrl } from "../ReusableFunctions/reusableFunctions";
import { SERVER_URLS } from "../appConstants";
import { getToken } from "./authentication";


export const getSettlementInvoices = async (dspId, fromDate, toDate, pageSize, pageNumber) => {
    try {
        const token = getToken();
        const url = buildUrl(`${SERVER_URLS.BACKEND_SERVER}/dsp/settlements/invoices/${dspId}`, {
            fromDate: fromDate ? fromDate : undefined,
            toDate: toDate ? toDate : undefined,
            pageSize: (pageSize && pageNumber) ? pageSize : undefined,
            pageNumber: (pageSize && pageNumber) ? pageNumber : undefined
        });
        const response = await fetch(
            url,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        const res = await response.json();
        if (!response.ok) {
            throw (res.message);
        }
        return res?.settlementInvoices;
    } catch (err) {
        throw err;
    }
}

export const getSettlementInvoiceDetails = async (invoiceId) => {
    try {
        const token = getToken();
        const response = await fetch(
            SERVER_URLS.BACKEND_SERVER + `/dsp/settlements/invoice/${invoiceId}`,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        const res = await response.json();
        if (!response.ok) {
            throw (res.message);
        }
        return res?.settlementInvoiceDetails;
    } catch (err) {
        throw err;
    }
}