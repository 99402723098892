import React, { useEffect, useRef, useState } from 'react'
import Modal from '../../../ReusableComponents/Modal'
import { Button } from '../../../Components/Helper'
import Filter from "../../../assets/filter.svg"
import DatePicker from "react-datepicker";
import calender from "../../../assets/blueCalendar.svg"
import { setHours, setMinutes } from 'date-fns';
import SelectComponent from '../../../ReusableComponents/SelectComponent';
import { GLOBAL_STYLES } from '../../../appConstants';
import CloseIcon from "../../../assets/closebtn.svg"
import Tippy from '@tippyjs/react';
import CustomTooltip from '../../../ReusableComponents/CustomTooltip';
import useWindowSize from '../../../hooks/useWindowSize';
const couponTypeOptions = [
    { value: undefined, label: 'All Coupons' },
    { value: 'LIVE', label: 'Live Coupons' },
    { value: 'REDEEM', label: 'Redeemed Coupons' },
    { value: 'EXPIRED', label: 'Expired Coupons' },
]
const visibilityOptions = [
    { value: "Both", label: 'Both' },
    { value: "Public", label: 'Public ' },
    { value: 'Private', label: 'Private ' },
]
export default function FilterCoupons({
    onFilterApply,
    appliedFilters,
}) {
    const [couponFilterPopup, setCouponFilterPopup] = useState(false);
    const { currentWidth, currentHeight } = useWindowSize();
    const [filters, setFilters] = useState({
        from_date: undefined,
        to_date: undefined,
        type: { label: 'Select coupon type', value: undefined },
        visibility: { label: 'Select visibility', value: undefined },
    })
    const [openDatePicker, setOpenDatePicker] = useState({
        from_date: false,
        to_date: false,
    })

    const handleFilterChanged = (name, value) => {
        if (name === 'type' && name == "visibility") {
            const { label, value: selectedType } = value;
            let updatedFilters = { ...filters, [name]: { label: label, value: selectedType } };
            setFilters(updatedFilters)
        } else {
            let updatedFilters = { ...filters, [name]: value };
            setFilters(updatedFilters)
        }
    }
    const getVisibilityType = (value) => {
        let type = value === null
            ? { label: "Both", value: "Both" } : value === undefined ?
                { label: 'Select visibility', value: undefined }
                : value === true
                    ? visibilityOptions[1]
                    : visibilityOptions[2];
        return type;
    }

    const handleClose = () => {
        setCouponFilterPopup(false);
    }
    useEffect(() => {
        if (couponFilterPopup && appliedFilters) {
            let type = getVisibilityType(appliedFilters.is_public);
            let updatedFilters = {
                ...appliedFilters,
                type: appliedFilters.type == undefined ? { label: 'Select coupon type', value: undefined } : appliedFilters.type,
                visibility: type
            }
            setFilters(updatedFilters);
        }
    }, [couponFilterPopup]);

    return (
        <div style={{ width: "35%", }}>
            <div
                style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                    position: "relative",
                    alignItems: "center",
                }}
            >
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: "2rem" }}>
                    {(appliedFilters?.type?.value === "LIVE" || appliedFilters?.type?.value === "REDEEM" || appliedFilters?.type?.value === "EXPIRED") && (
                        <div style={{
                            display: "flex",
                            fontSize: "12px",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "5px",
                            background: "#0F5EFF1A",
                            border: "1px solid #0F5EFF",
                            height: "30px",
                            width: "100%",
                            borderRadius: "5px",
                            color: "#0F5EFF",
                            marginRight: "25px",
                            padding: "0 15px"
                        }} >
                            <Tippy allowHTML={true} content={<CustomTooltip content={appliedFilters?.type?.label} customStyle={{ maxWidth: "230px" }} />} placement="top-start">
                                <span > {appliedFilters?.type?.label}</span>
                            </Tippy>
                            <img className='cursor-pointer' onClick={() => {
                                let updatedFilters = { ...filters, type: { label: 'Select coupon type', value: undefined }, }
                                setFilters(updatedFilters);
                                onFilterApply(updatedFilters);
                            }} src={CloseIcon} alt='closebtn' />
                        </div>
                    )}
                    <div className='w-[50%]'>
                        <Button
                            innerClassName={"filter-btn-inner"}
                            iconStyle={{ width: "18px", marginRight: "10px" }}
                            style={{ marginRight: "20px" }}
                            text={"Filter"}
                            icon={Filter}
                            className={"filter-btn"}
                            onClick={() => setCouponFilterPopup(true)}
                            textClassName={"user-details-filter-text"}
                        />

                    </div>

                </div>
                <div className='modal-wrapper'>
                    <Modal
                        header={"Filter"}
                        open={couponFilterPopup}
                        onClose={handleClose}
                        contentStyle={{
                            width: "407px",
                            height: "450px",
                            maxHeight: "80vh",
                            borderRadius: "8px",
                            boxShadow: "0px 1px 4px 2px rgba(118, 118, 118, 0.15)",
                            overflow: "auto",
                            border: "1px solid #BFC8D9",
                            top: currentWidth < 1370 ? "7%" : "9%",
                            left: currentWidth < 1370 ? "28%" : "33%",
                        }}
                        headerContainerStyle={{ padding: "18px 25px" }}
                        overlayStyle={{ background: "none", backdropFilter: "none", }}
                    >
                        <div style={{ width: "100%", padding: "18px 30px", display: "flex", flexDirection: "column", gap: "2rem" }}>
                            <div style={{ display: "flex", alignItems: "center", width: "100%", gap: "1.2rem" }} className='from_to_date_filters'>
                                <div style={{}}>
                                    <span style={{ fontSize: "14px" }}>From</span>
                                    <div
                                        className="input-date "
                                        style={{ display: "flex", alignItems: "center", cursor: "pointer", height: "100%", width: "150px", position: "relative", background: "transparent" }}
                                        onClick={() => setOpenDatePicker((prev) => ({ ...prev, from_date: true }))}
                                    >
                                        <DatePicker
                                            onClickOutside={() => setOpenDatePicker((prev) => ({ ...prev, from_date: false }))}
                                            open={openDatePicker.from_date}
                                            maxDate={filters.to_date ? new Date(Math.min(new Date(), new Date(filters.to_date))) : new Date()}
                                            placeholderText='From'
                                            readOnly
                                            className="input-date-hidden"

                                            selected={filters.from_date ? new Date(filters.from_date) : null}
                                            onChange={date => {
                                                if (date) {
                                                    handleFilterChanged("from_date", date)
                                                }
                                            }} />
                                        <img src={calender} style={{ width: "18px", position: "absolute", right: "15px" }} />
                                    </div>
                                </div>
                                <div>
                                    <span style={{ fontSize: "14px" }}>To</span>
                                    <div
                                        className="input-date  "
                                        style={{ display: "flex", alignItems: "center", cursor: "pointer", height: "100%", width: "150px", position: "relative", background: "transparent" }}
                                        onClick={() => setOpenDatePicker((prev) => ({ ...prev, to_date: true }))}
                                    >
                                        <DatePicker
                                            placeholderText='To'
                                            disabled={!filters.from_date}
                                            onClickOutside={() => setOpenDatePicker((prev) => ({ ...prev, to_date: false }))}
                                            open={openDatePicker.to_date}
                                            readOnly
                                            minDate={filters?.from_date && new Date(filters?.from_date)}
                                            className="input-date-hidden "
                                            selected={filters.to_date ? new Date(filters.to_date) : null}
                                            onChange={date => {
                                                if (date) {
                                                    handleFilterChanged("to_date", date)
                                                    setFilters(prev => ({ ...prev, to_date: date }));
                                                }
                                            }} />
                                        <img src={calender} style={{ width: "18px", position: "absolute", right: "15px", opacity: !filters.from_date ? 0.5 : 1 }} />
                                    </div>
                                </div>


                            </div>
                            <div style={{ display: "flex", flexDirection: "column", gap: "2px" }}>
                                <span style={{ fontSize: "14px" }}>Coupon Type</span>
                                <SelectComponent
                                    menuPlacement={"bottom"}
                                    list={couponTypeOptions}
                                    value={filters?.type}
                                    singleValueStyle={{
                                        color: filters?.type?.label === "Select coupon type" ? "#1E1E1E80" : "#1E1E1E",
                                    }}
                                    placeholderText={"Select coupon type"}
                                    onChange={(selectedType) => {
                                        handleFilterChanged("type", selectedType)
                                    }}
                                />

                            </div>
                            <div style={{ display: "flex", flexDirection: "column", gap: "2px" }}>
                                <span style={{ fontSize: "14px" }}>Visibility</span>
                                <SelectComponent
                                    menuPlacement={"bottom"}
                                    list={visibilityOptions}
                                    singleValueStyle={{
                                        color: filters?.visibility?.label === "Select visibility" ? "#1E1E1E80" : "#1E1E1E",
                                    }}
                                    placeholderText={"Select visibility"}
                                    value={filters?.visibility}
                                    onChange={(selectedType) => {
                                        handleFilterChanged("visibility", selectedType)
                                    }}
                                />

                            </div>
                            <div style={{ display: "flex", justifyContent: "space-between", height: '38px', gap: "20px", marginTop: "15px" }}>
                                <div
                                    onClick={() => {
                                        setFilters((prev) => ({
                                            ...prev,
                                            from_date: undefined,
                                            to_date: undefined,
                                            type: { label: 'Select coupon type', value: undefined },
                                            visibility: { label: 'Select visibility', value: undefined },
                                        }))
                                    }}
                                    style={{ width: "54px", height: "38px", color: GLOBAL_STYLES.BG_ACTIVE, fontWeight: 600, fontSize: "14px", cursor: 'pointer', display: "flex", alignItems: "center", justifyContent: "start" }}>
                                    Clear all
                                </div>
                                <Button
                                    text={"Apply"}
                                    style={{ width: "66px", height: "35px", fontSize: "15px" }}
                                    onClick={() => {
                                        onFilterApply(filters);
                                        handleClose();
                                    }}
                                />
                            </div>
                        </div>
                    </Modal>
                </div>
            </div>
        </div>
    )
}
