import React, { useEffect, useRef, useState } from 'react'
import { Button } from '../../Components/Helper'
import { ROUTES } from '../../routes'
import { capitalizeFirstLetter, reduceLongName, squareKilometersToAcres } from '../../ReusableFunctions/reusableFunctions'
import { AEROMEGH_SERVICES, STATUS, STATUS_MESSAGES } from '../../appConstants'
import dateFormat from 'dateformat';
import useWindowSize from '../../hooks/useWindowSize'
import MainContentWrapper from '../../Components/MainContentWrapper'
import { useLocation } from 'react-router'
import VerticalStepper from '../../ReusableComponents/VerticalStepper'
import { getRequest } from '../../apis/requests'
import RequestDiscount from '../../ReusableComponents/RequestDiscount'
import Loading from '../../ReusableFunctions/Loader'
import L from 'leaflet'
import roadMapView from '../../assets/roadMapView.png'
import sateliteView from '../../assets/sateliteView.png'
import Map from "../../features/LeafletMap/Map"
import Popup from "../../ReusableFunctions/Popup";
import MarkerIcon from "../../assets/marker.png";
import { MAP_URLS } from "../../appConstants";
import fullScreenIcon from '../../assets/fullScreenIcon.png';
import collapseIcon from '../../assets/collapse.png';

let baseLayer;

const RequestDetails = () => {
    const { state } = useLocation();
    const [steps, setSteps] = useState([])
    const [request, setRequest] = useState(state.request)
    const isTrainingService = request?.type === AEROMEGH_SERVICES.TRAINING
    const isSprayingService = request?.type === AEROMEGH_SERVICES.SPRAYING;
    const isSurveyService = request?.type === AEROMEGH_SERVICES.SURVEY;
    const [activeBaseLayer, setActiveBaseLayer] = useState(baseLayer)
    const [center, setCenter] = useState(
        { lat: 19.993982, lng: 73.790416 }
    );
    const [map, setMap] = useState(null)
    const fieldData = [
        { label: 'Request Number', value: request?.req_number },
        { label: 'Customer Name', value: request?.user_name },
        { label: 'Location', value: request?.location_address, overflow: true },
        { label: 'Contact No.', value: request?.user_phone },
        { label: 'Service', value: request?.type },
        { label: 'Service Location', value: request?.field_address, overflow: true },
        (isSprayingService || isSurveyService) && { label: 'Drone Type', value: request?.type },
        isSprayingService && { label: 'Crop', value: request?.crop_name },
        isSprayingService && { label: 'Agro Chemicals', value: request?.pesticides },
        (isSprayingService || isSurveyService) && { label: 'Drone UIN', value: request?.drone_uin },
        isTrainingService && { label: 'Date', value: request?.date_created ? dateFormat(request.date_created, "dd/mm/yyyy") : "-" },
        isTrainingService && { label: 'Time', value: request?.date_created ? dateFormat(request.date_created, "hh:MM TT") : "-" },
        (isSprayingService || isSurveyService) && { label: 'Date', value: request?.time ? dateFormat(request.time, "dd/mm/yyyy") : "-" },
        // (isSprayingService || isSurveyService) && { label: 'Time', value: request?.time ? dateFormat(request.time, "hh:MM TT") : "-" },
        (isSprayingService || isSurveyService) && {
            label: 'Pilot',
            value: request?.pilot_name ? reduceLongName(request.pilot_name, 20) : "-"
        },
        {
            label: `Status`,
            value: `${request?.status} ${request.status === STATUS.PAID ? `(${request.payment_method === "Offline" ? "Cash" : "UPI"})` : ""}`
        },
        isTrainingService && { label: 'Price', value: request?.price ? request.price + " Rs." : "-" },
        (isSprayingService) && { label: request?.price_by === "acre" ? `Requested Area` : "Requested No of Tank ", value: request?.price_by === "tank" ? request?.field_area : request?.price_by === "acre" ? Number(squareKilometersToAcres(request?.field_area)).toFixed(2) + " Acre" : "-" },
        (isSurveyService) && { label: 'Requested Area', value: request?.field_area ? Number(request?.field_area).toFixed(4) + " Sq/km" : "-" },
        ((isSprayingService && request?.service_completed_area) && {
            label: request.price_by === "acre" ? "Completed Area" : "Completed No Of Tank", value: (request.price_by === "acre" ? Number(squareKilometersToAcres(request.service_completed_area)).toFixed(4) + " Acre" : request.service_completed_area) || "-"
        })
    ].filter(Boolean);

    const Field = ({ label, value, overflow }) => (
        <div className='single_field_wrapper' style={{ lineHeight: overflow ? 1.15 : 1.1 }}>
            <div className='single_field_label' >{label}</div>
            <div className='single_field_value' style={{
                maxHeight: overflow && value?.length > 120 ? "100px" : "none",
                overflowY: overflow && value?.length > 120 ? "auto" : "hidden"
            }}>
                {value || "-"}
            </div>
        </div>
    );

    const getRequestDetails = async () => {
        let requestData = await getRequest({ type: state.request.type, id: state.request.id })
        setRequest({ ...request, ...requestData.request })
        setSteps(requestData.request.status_history.map(record => {
            return {
                label: record.updated_status,
                content: <>
                    <div style={{
                        color: "rgb(59, 59, 59)",
                        fontSize: "13px",
                        fontWeight: "500",
                        wordBreak: "break-word",
                        marginBottom: "0px",
                    }}>{record.updated_status == STATUS.PLACED ? STATUS_MESSAGES.PLACED :
                        record.updated_status == STATUS.ACCEPTED ? STATUS_MESSAGES.ACCEPTED :
                            record.updated_status == STATUS.RESCHEDULED ? STATUS_MESSAGES.RESCHEDULED :
                                record.updated_status == STATUS.OUT_FOR_SERVICE ? STATUS_MESSAGES.OUT_FOR_SERVICE :
                                    record.updated_status == STATUS.INPROGRESS ? STATUS_MESSAGES.INPROGRESS :
                                        record.updated_status == STATUS.COMPLETED ? STATUS_MESSAGES.COMPLETED :
                                            record.updated_status == STATUS.PAID ? STATUS_MESSAGES.PAID :
                                                record.updated_status == STATUS.CANCELLED ? STATUS_MESSAGES.CANCELLED :
                                                    record.updated_status == STATUS.HOLD ? STATUS_MESSAGES.HOLD :
                                                        ""
                        }</div>
                    {record?.note && <div style={{ lineHeight: '16px', margin: '5px 0px', fontSize: '13px', }}>
                        <span style={{ marginRight: '3px', fontWeight: 500, }}>Note :</span><span style={{ color: "#3b3b3b", fontWeight: 500 }}>{record.note}</span>
                    </div>}
                    <div style={{ color: "#727272", fontSize: "13px", fontWeight: "600" }}>{`${dateFormat(new Date(record.time), "h : MM TT")}, ${dateFormat(new Date(record.time), "d mmmm yyyy")}`}</div>
                </>,
                statusColor: record.updated_status == STATUS.RESCHEDULED ? "#E27444" : record.updated_status == STATUS.CANCELLED ? "#EB0000" : "#32B338",
                highlightStatus: record.updated_status == STATUS.RESCHEDULED || record.updated_status == STATUS.CANCELLED
            }
        }))
    }

    const markerIcon = L.icon({
        iconUrl: MarkerIcon,
        iconSize: [24, 32],
    });

    const initMap = (map) => {
        setMap(map)
    };

    const switchBaseLayer = () => {
        const activeLayer = activeBaseLayer == "satelite" ? "terrain" : "satelite"
        setActiveBaseLayer(activeLayer)
        if (baseLayer) {
            baseLayer.setUrl(activeLayer === "satelite" ? MAP_URLS.SATELITE : MAP_URLS.ROAD_MAP)
        }
    }

    const initMapData = () => {
        var markerOptions = {
            clickable: true,
            draggable: false,
            icon: markerIcon,
        };
        const center = { lat: request.field_lat, lng: request.field_lng };
        let marker = new L.marker(center, markerOptions);
        marker.on('click', function () {
            map.flyTo(center, Math.min(map.getZoom() + 1, map.getMaxZoom()));
        });
        map?.addLayer(marker);
        const bounds = L.latLngBounds([center]);
        map?.fitBounds(bounds, { maxZoom: 16 })
    }

    useEffect(() => {
        Loading.set(true)
        getRequestDetails()
    }, [])

    useEffect(() => {
        initMapData();
        Loading.set(false);
    }, [request])

    return (<><MainContentWrapper >
        <div style={{ background: "white", height: "auto", width: "100%", }}>

            <div style={{
                position: "relative",
                width: "100%",
                height: "12%",
                padding: "20px 30px",
                borderBottom: "1px solid rgba(128, 128, 128, 0.24)",
                fontSize: "1rem",
                display: "flex",
                justifyContent: "left",
                alignItems: "center",
                color: "#1E1E1E",
                fontWeight: "500",
            }}>
                <div>
                    Request Details
                </div>
                <div className='request_details_footer' style={{ justifyContent: (request?.user_id == null || request?.user_id == '') ? "space-between" : "flex-end" }}>
                    {(request?.user_id == null || request?.user_id == '') && (
                        <div style={{
                            color: "crimson",
                            maxWidth: "400px",
                            wordWrap: "break-word",
                            lineHeight: 1.1,
                            fontSize: "13px",
                        }}>
                            Request cannot be modified as the user has deleted their account.
                        </div>)}
                    {request?.status === STATUS.PAID ? null
                        : <Button
                            isDisabled={request?.user_id === null || request?.user_id === ''}
                            text={"Modify Request"}
                            redirectTo={request?.status === STATUS.PAID || request?.user_id === null || request?.user_id === '' ? null : ROUTES.VIEWREQUEST}
                            state={{ requestId: request?.id, requestType: request?.type, assingedPilot: request?.pilot_name }}
                            className={"request_details_modify_btn"}
                        />}
                </div>
            </div>
            <div style={{ background: "white", position: "relative", display: "flex", width: "100%" }}>
                <div style={{ width: "100%", height: "auto", borderRight: "1px solid #8080803d" }}>
                    <div style={{ position: "relative", padding: "20px 30px", height: "auto", width: "100%", }}>
                        <div className='request_fields_wrapper'>
                            {fieldData.map((field, index) => (
                                <Field key={index} label={field.label} value={field.value} overflow={field.overflow} />
                            ))}
                        </div>
                    </div>
                    <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                        <div style={{ width: "35%", margin: "25px 25px 30px 25px" }}>
                            {request?.invoice && <RequestDiscount
                                hideToggle
                                oldRequest={request || {}}
                                request={request}
                            />}
                        </div>
                    </div>
                </div>
                {steps.length > 0 && <div style={{ position: "relative", height: "100%", width: "30%", }}>
                    <VerticalStepper steps={steps} />
                </div>}
            </div>
        </div>
    </MainContentWrapper > </>);
};
export default RequestDetails;