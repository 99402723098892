import React, { useEffect, useRef, useState } from "react";
import Input from "../../../../../ReusableComponents/Input";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import {
  acresToSquareKilometers,
  capitalizeFirstLetter,
  combineDateTime,
  getFormatedDate, getFormattedTimeFromDate,
  squareKilometersToAcres
} from "../../../../../ReusableFunctions/reusableFunctions";
import { Button } from "../../../../../Components/Helper";
import { applyDiscount, getRequest, updateRequest } from "../../../../../apis/requests";
import { DISCOUNT_TYPES, GLOBAL_STYLES, LIMITS, SERVICE_TYPES, STATUS } from "../../../../../appConstants";
import { getDronePilots } from "../../../../../apis/dronePilots";
import Popup from "../../../../../ReusableFunctions/Popup";
import Loading from "../../../../../ReusableFunctions/Loader";
import { setHours, setMinutes } from "date-fns";
import { getDSPDrones } from "../../../../../apis/drones";

import MainContentWrapper from "../../../../../Components/MainContentWrapper";
import Calender from "../../../../../assets/Calender2.svg"
import Clock from "../../../../../assets/clock.svg"
import { ROUTES } from "../../../../../routes";
import { Link, useNavigate } from "react-router-dom"
import SelectComponent from "../../../../../ReusableComponents/SelectComponent";
import RequestDiscount from "../../../../../ReusableComponents/RequestDiscount";

export const allStatus = [
  { value: STATUS.PLACED, label: STATUS.PLACED, },
  { value: STATUS.ACCEPTED, label: STATUS.ACCEPTED, },
  { value: STATUS.OUT_FOR_SERVICE, label: STATUS.OUT_FOR_SERVICE, },
  { value: STATUS.INPROGRESS, label: STATUS.INPROGRESS, },
  { value: STATUS.COMPLETED, label: STATUS.COMPLETED, },
  { value: STATUS.PAID, label: STATUS.PAID, },
]

const ViewSprayingRequest = ({ user, req, }) => {
  const navigate = useNavigate()
  const invoiceRef = useRef()
  const [sprayingRequest, setSprayingRequest] = useState({ location_id: null });
  const [dronePilots, setDronePilots] = useState([]);
  const [assignedLocationsDronePilots, setAssignedLocationsDronePilots] = useState([])
  const [drones, setDrones] = useState([]);
  const [selectedDroneUIN, setSelectedDroneUIN] = useState(null)
  const [showTimePicker, setShowTimePicker] = useState(false)
  const [showDatePicker, setShowDatePicker] = useState(false)
  const [droneType, setDroneType] = useState();
  const [noValueChanged, setNoValueChanged] = useState(true)
  const [statusList, setstatusList] = useState([]);
  const [isStatusChange, setisStatusChange] = useState(false);
  const [isInputEditable, setisInputEditable] = useState(false);
  const [isDiscountChanged, setIsDiscountChanged] = useState(false);
  const [appliedDiscount, setAppliedDiscount] = useState(0);
  const [anyInputChange, setAnyInputChange] = useState(false)
  const [disableUpdateBtn, setDisableUpdateBtn] = useState(true)
  const [showNote, setShowNote] = useState(false)
  const [noteError, setNoteError] = useState(null);
  const [isShowAllPilots, setIsShowAllPiltos] = useState(req.drone_pilot ? true : false)
  const [error, setError] = useState({
    completed_area: req.status == STATUS.COMPLETED ? null : "Required"
  })
  const [note, setNote] = useState("")

  const oldStatus = req.status;

  const requestUpdate = () => {
    const invoice = invoiceRef.current?.getInvoice()
    return new Promise((resolve, reject) => {
      updateRequest({
        ...sprayingRequest,
        ...invoice,
        applied_coupons: invoice.applied_coupons,
        applied_discounts: [
          {
            discount_type: "dsp_request_discount",
            discount: Number(invoice.total_dsp_discount_percentage),
            discounted_amount: invoice.total_discounted_amount_via_dsp
          }
        ],
        service_completed_area: sprayingRequest.price_by === "acre" ? acresToSquareKilometers(sprayingRequest.service_completed_area) : sprayingRequest.service_completed_area
        , status: sprayingRequest.status == req.status ? null : sprayingRequest.status,
        note
      }, req.type).then(res => { resolve(res) }).catch(err => { reject(err) })
    })
  }

  const discountApply = () => {
    return new Promise((resolve, reject) => {
      applyDiscount({ id: sprayingRequest.id, dsp_discount: appliedDiscount }).then(res => resolve(res)).catch(err => { reject(err) })
    })
  }

  const saveRequestDetails = async () => {
    try {
      Loading.set(true);
      // if (new Date(sprayingRequest.time).getTime() <= new Date().getTime() && [STATUS.ACCEPTED, STATUS.RESCHEDULED].includes(sprayingRequest.status)) {
      //   Loading.set(false);
      //   Popup.alert("Error", "Please select a date and time that is equal to or later than the current day and time!", "ERROR", () => { });
      // }
      // else {
      if (isDiscountChanged) { await discountApply() }
      await requestUpdate()
      Loading.set(false);
      Popup.alert(
        "Spraying Request",
        "Request updated successfully",
        "SUCCESS",
        () => {
          const queryParams = [];
          if (user?.user?.pilotId) {
            queryParams.push(`pilotId=${user.user.pilotId}`);
          }
          if (user?.user?.pilotName) {
            queryParams.push(`pilotName=${user.user.pilotName}`);
          }
          if (user?.user?.isCalenderView) {
            queryParams.push(`isCalenderView=${user.user.isCalenderView}`);
          }
          if (user?.user?.userId) {
            queryParams.push(`userId=${user.user.userId}`);
          }
          const queryString = queryParams.length > 0 ? `?${queryParams.join('&')}` : '';
          navigate(user?.user?.userId ? ROUTES.CUSTOMER_DETAILS + queryString : ROUTES.MYREQUESTS + queryString);
        }
      );
      // }


    }
    catch (err) {
      Loading.set(false);
      Popup.alert("Error", err, "ERROR", () => { });
      console.log(err);
    }
  }


  useEffect(() => {
    const linkedDroneToPilot = (dronePilots.find(p => p.id === sprayingRequest?.drrone_pilot))?.assigned_drone
    if (linkedDroneToPilot) handleDroneSelection({ value: linkedDroneToPilot })
    setSprayingRequest((prev) => ({
      ...prev,
      drone_pilot: sprayingRequest?.drone_pilot,
    }));
  }, [sprayingRequest.drone_pilot])


  const handleDroneSelection = (e) => {
    setNoValueChanged(false)
    const value = e.value;
    const selectedDrone = drones.find(drone => drone.drone_uin === value);
    if (selectedDrone) {
      setDroneType(selectedDrone.type);
      setSelectedDroneUIN(value);
      setSprayingRequest((prev) => ({
        ...prev,
        drone_id: selectedDrone.id,
      }));
    } else {
      setSelectedDroneUIN(null);
      setDroneType(null);
    }
  };

  const isUpdateButtonDisabled = () => {
    const drone = drones.find((d) => d.id === req.drone_id)
    let disabled = true;

    if (sprayingRequest.status && req.status && req.status != sprayingRequest.status) {
      if ((sprayingRequest.status == STATUS.ACCEPTED || sprayingRequest.status == STATUS.RESCHEDULED)) {
        if (!sprayingRequest.drone_pilot || !selectedDroneUIN || !sprayingRequest.time) {
          disabled = true
        }
        else {
          disabled = false
        }
      }
      else if (sprayingRequest.status == STATUS.COMPLETED) {
        if (!sprayingRequest.service_completed_area || error.completed_area) {
          disabled = true
        }
        else {
          disabled = false
        }
      }
      else {
        disabled = false
      }
    }
    else if ((sprayingRequest.status == STATUS.ACCEPTED || sprayingRequest.status == STATUS.RESCHEDULED)) {
      if (sprayingRequest.drone_pilot != req.drone_pilot || drone?.drone_uin != selectedDroneUIN) {
        if (!sprayingRequest.drone_pilot || !selectedDroneUIN || !sprayingRequest.time) {
          disabled = true
        } else {
          disabled = false
        }
      }
      else {
        disabled = true
      }
    }
    if (isDiscountChanged) {
      if ((sprayingRequest.status == STATUS.ACCEPTED || sprayingRequest.status == STATUS.RESCHEDULED)) {
        if (!sprayingRequest.drone_pilot || !selectedDroneUIN || !sprayingRequest.time) {
          disabled = true
        }
        else { disabled = false }
      }
      else if (sprayingRequest.status == STATUS.COMPLETED) {
        if (!sprayingRequest.service_completed_area || error.completed_area) {
          disabled = true
        }
        else {
          disabled = false
        }
      }
      else disabled = false
    }
    if (sprayingRequest.status == STATUS.CANCELLED || sprayingRequest.status == STATUS.RESCHEDULED || sprayingRequest.status == STATUS.HOLD) {
      if (sprayingRequest.status == STATUS.CANCELLED) {
        disabled = false
      }
      if (!note || note.length < 20 || note.length > 30) {
        disabled = true;
      }
    }


    return disabled
  }

  useEffect(() => {
    Loading.set(true);
    setSprayingRequest({ ...req, service_completed_area: (req.service_completed_area === 0 || req.service_completed_area === null) ? "" : req.price_by == "acre" ? squareKilometersToAcres(req.service_completed_area).toFixed(2) : req.service_completed_area });
    setError({
      completed_area: req.status == STATUS.COMPLETED ? null : "Required"
    })
    getDronePilots()
      .then((res) => {
        setDronePilots(res.dronePilots.filter((d) => d.is_active));
        setAssignedLocationsDronePilots(res.dronePilots.filter((d) => d.is_active && d.location_id === req.location_id))
      })
      .catch((err) => {
        Loading.set(false);
        console.log(err);
        Popup.alert("Error", err.errorMessage, "ERROR", () => { });
      });
    getDSPDrones()
      .then((res) => {
        const drone = res.find((d) => d.id === req.drone_id)
        const drones = res?.filter((d) => d?.service_type === SERVICE_TYPES[0])
        setDrones(drones)
        if (drone) {
          setSelectedDroneUIN(drone?.drone_uin);
          setDroneType(drone?.type)
        }
        Loading.set(false);
      })
      .catch((err) => {
        Loading.set(false);
        console.log(err);
        Popup.alert("Error", err, "ERROR", () => { });
      });
  }, [req]);

  useEffect(() => {
    const isEditableStatus = [STATUS.ACCEPTED, STATUS.RESCHEDULED, STATUS.OUT_FOR_SERVICE].includes(sprayingRequest.status)

    if (sprayingRequest.status === STATUS.OUT_FOR_SERVICE) {
      setSprayingRequest((prev) => ({ ...prev, time: new Date() }))
    }
    setisInputEditable(isStatusChange && isEditableStatus);

    const currentStatusIndex = allStatus.findIndex(st => st.value === req.status);
    let updatedStatusList = [];

    switch (req.status) {
      case STATUS.HOLD:
        updatedStatusList = [
          { value: STATUS.HOLD, label: STATUS.HOLD },
          { value: STATUS.INPROGRESS, label: STATUS.INPROGRESS },
          { value: STATUS.RESCHEDULED, label: STATUS.RESCHEDULED },
          { value: STATUS.CANCELLED, label: STATUS.CANCELLED },
        ];
        break;
      case STATUS.PLACED:
        updatedStatusList = [
          ...allStatus.slice(currentStatusIndex, currentStatusIndex + 2),
          { value: STATUS.CANCELLED, label: STATUS.CANCELLED },
        ];
        break;
      case STATUS.RESCHEDULED:
        updatedStatusList = [
          { value: STATUS.RESCHEDULED, label: STATUS.RESCHEDULED },
          { value: STATUS.OUT_FOR_SERVICE, label: STATUS.OUT_FOR_SERVICE },
          { value: STATUS.CANCELLED, label: STATUS.CANCELLED },
        ];
        break;
      case STATUS.COMPLETED:
        updatedStatusList = allStatus.slice(currentStatusIndex, currentStatusIndex + 2);
        break;
      case STATUS.INPROGRESS:
        updatedStatusList = [
          ...allStatus.slice(currentStatusIndex, currentStatusIndex + 2),
          { value: STATUS.HOLD, label: STATUS.HOLD },
          { value: STATUS.CANCELLED, label: STATUS.CANCELLED },
          { value: STATUS.RESCHEDULED, label: STATUS.RESCHEDULED },
        ];
        break;
      case STATUS.ACCEPTED:
      case STATUS.OUT_FOR_SERVICE:
        updatedStatusList = [
          ...allStatus.slice(currentStatusIndex, currentStatusIndex + 2),
          { value: STATUS.CANCELLED, label: STATUS.CANCELLED },
          { value: STATUS.RESCHEDULED, label: STATUS.RESCHEDULED },
        ];
        break;
      case STATUS.CANCELLED:
        break;
      default:
        updatedStatusList = [];
        break;
    }

    setstatusList(updatedStatusList);
  }, [sprayingRequest.status, isStatusChange])

  useEffect(() => {
    const d = isUpdateButtonDisabled()
    setDisableUpdateBtn(d)
    if ((sprayingRequest.status == STATUS.RESCHEDULED || sprayingRequest.status == STATUS.CANCELLED || sprayingRequest?.status === STATUS.HOLD) && req.status !== sprayingRequest.status) {
      setShowNote(true)
    } else {
      setShowNote(false)
    }
  }, [error, sprayingRequest.service_completed_area, sprayingRequest.drone_pilot, selectedDroneUIN, sprayingRequest.time, sprayingRequest.status, req.status, anyInputChange, isDiscountChanged, note])

  return (
    <>
      <MainContentWrapper>
        <div style={{ display: "flex", justifyContent: "center", width: "100%", height: "100%" }}>
          {sprayingRequest?.id
            && (
              <div style={{ display: "flex", flexDirection: "column", gap: "5px", width: "1180px" }}>
                <div style={{ display: "flex", justifyContent: "space-between", padding: "15px 0px" }}>
                  <div style={{ fontSize: "24px", fontWeight: 700 }}>My Request</div>
                  <div style={{ display: "flex", gap: "1.5rem", marginTop: "8px" }}>
                    <Link className='underlined-bg-active' style={{ display: "block", color: GLOBAL_STYLES.BG_ACTIVE, fontSize: '16px', fontWeight: 600, position: "relative" }} to={ROUTES.MYREQUESTS}>My requests</Link>
                    <Link className='underlined-bg-active' style={{ display: "block", color: GLOBAL_STYLES.BG_ACTIVE, fontSize: '16px', fontWeight: 600, position: "relative" }} to={'/'}>Home</Link>
                  </div>
                </div>

                <div
                  style={{
                    // padding: "20px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "start",
                    height: "fit-content",
                    background: "rgba(244, 244, 244, 1)",
                    width: "100%",
                    borderRadius: "10px",
                    // overflow: "hidden"
                  }}
                >
                  <div style={{ display: "flex", justifyContent: "space-between", background: "rgba(217, 217, 217, 1)", padding: "25px 40px", borderRadius: "15px 15px 0px 0px " }}>
                    {/* id */}
                    <div style={{ color: "#1E1E1E", fontSize: "24px", fontWeight: 600 }}>
                      <span style={{ marginRight: "5px", }}>Request Number</span>
                      <span>:</span>

                      <span style={{ marginLeft: "22px" }}>{"    " + sprayingRequest?.req_number}</span>
                    </div>
                    <div style={{ display: "flex", justifyContent: "right", gap: "15px" }}>
                      {/* <div
                        onClick={() => { alert('Get invoice click !') }}
                        style={{ cursor: "pointer", display: "flex", fontWeight: "500", justifyContent: "center", alignItems: "center", color: "#0F5EFF", borderRadius: "4px", width: "140px", height: "32px", fontSize: "16px", border: "1px solid #0F5EFF" }}>
                        Get Invoice
                      </div> */}

                      <Button onClick={saveRequestDetails} text={"Update"} style={{ width: "140px", height: "32px", fontSize: "16px" }}
                        isDisabled={disableUpdateBtn} />
                    </div>
                  </div>
                  <div style={{ display: "flex", flexDirection: "column", gap: "1.8rem", height: "100%", fontSize: GLOBAL_STYLES.FONT_SIZE_MEDIUM, padding: "40px 70px", width: "100%", background: "#FCFCFC", borderRadius: "0px 0px 15px 15px" }}>
                    {/* customer details */}
                    <div style={{ display: "flex", flexDirection: "column", rowGap: "30px", width: "100%" }}>
                      <div style={{ fontSize: GLOBAL_STYLES.FONT_SIZE_REGULAR, fontWeight: "600", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <span style={{ fontSize: "22px", letterSpacing: "0.3px", fontWeight: 600 }}>Customer Details</span>
                        <div style={{ fontSize: GLOBAL_STYLES.FONT_SIZE_MEDIUM, display: "flex", alignItems: "center" }}>
                          <span style={{ fontSize: "18px", color: "#3B3B3B", fontWeight: 600 }}>Status</span>
                          <span style={{ marginRight: "10px", marginLeft: "5px" }}>:</span>
                          <SelectComponent
                            controlStyle={{ width: "160px", height: "40px", fontSize: "15px", fontWeight: "600", borderRadius: "4px", background: "#F3F3F3", border: "1px solid #D9D9D9" }}
                            list={statusList}
                            value={{ value: sprayingRequest.status, label: sprayingRequest.status === STATUS.PLACED ? STATUS.PLACED : sprayingRequest.status }}
                            onChange={(e) => {
                              setNote("")
                              setisStatusChange(() => oldStatus !== e.value);
                              setNoValueChanged(false)
                              const status = e.value;
                              if (status === STATUS.COMPLETED) {
                                setSprayingRequest((prev) => ({
                                  ...prev,
                                  status: status,
                                  time: req.time,
                                  service_completed_area: ""
                                }))
                              } else {
                                setSprayingRequest((prev) => ({
                                  ...prev,
                                  status: status,
                                  time: req.time
                                }))
                              }
                            }
                            }
                          />
                        </div>
                      </div>
                      <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", columnGap: "", gap: "20px 194px", width: "100%" }}>
                        {/*customer name  */}
                        <div style={{ display: "flex" }}>
                          <div style={{ color: "rgba(59, 59, 59, 0.8)", width: "fit-content", minWidth: "130px", fontSize: "16px", fontWeight: 400 }}>Customer Name</div>
                          <span style={{ marginRight: "10px" }}>:</span>
                          <div style={{ width: "212px", marginLeft: "5px", fontSize: "16px", fontWeight: 400 }}>{sprayingRequest.user?.name || "-"}</div>
                        </div>
                        {/*contact number */}
                        <div style={{ display: "flex", justifyContent: "" }}>
                          <div style={{ color: "rgba(59, 59, 59, 0.8)", width: "fit-content", minWidth: "130px", fontSize: "16px", fontWeight: 400 }}>Contact Number</div>
                          <span style={{ marginRight: "10px" }}>:</span>
                          <div style={{ width: "212px", marginLeft: "5px", fontSize: "16px", fontWeight: 400 }}>{sprayingRequest.user?.mobile_number || "-"}</div>
                        </div>
                        {/*Location */}
                        <div style={{ display: "flex" }}>
                          <div style={{ color: "rgba(59, 59, 59, 0.8)", width: "fit-content", minWidth: "130px", fontSize: "16px", fontWeight: 400 }}>Location</div>
                          <span style={{ marginRight: "10px" }}>:</span>
                          <div style={{ width: "212px", marginLeft: "5px", fontSize: "16px", fontWeight: 400 }}>{sprayingRequest?.location_address || "-"}</div>
                        </div>
                        {/*Note */}
                        {showNote && <div style={{ display: "flex" }}>
                          <div style={{ color: "rgba(59, 59, 59, 0.8)", width: "fit-content", minWidth: "130px", fontSize: "16px", fontWeight: 400 }}>Note</div>
                          <span style={{ marginRight: "10px" }}>:</span>
                          <div style={{ width: "100%" }}>
                            <textarea
                              onChange={(e) => {
                                const value = e.target.value;
                                if (value.length < 20) {
                                  setNoteError("Note should atleast 20 characters")
                                } else if (value.length > 30) {
                                  setNoteError("Note should be maximum 30 characters")
                                } else {
                                  setNoteError(null);
                                }
                                setNote(e.target.value)
                              }}
                              value={note}
                              placeholder={`Enter reason for ${sprayingRequest.status == STATUS.RESCHEDULED ? "rescheduling" : ""}${sprayingRequest.status == STATUS.CANCELLED ? "cancelling" : ""}${sprayingRequest.status == STATUS.HOLD ? "holding" : ""}  request`}
                              style={{ width: "100%", marginLeft: "5px", fontSize: "14px", color: "#3b3b3b", outline: 'none', fontWeight: 400, resize: 'none', padding: "10px", border: '1px solid rgb(212 212 212)' }}></textarea>
                            <span style={{ marginLeft: "5px", color: "red", fontSize: "10px" }}>{noteError}</span>
                          </div>
                        </div>}
                        {/*Email id */}
                        {/* <div style={{ display: "flex", justifyContent: "" }}>
                          <div style={{ color: "rgba(59, 59, 59, 0.8)", width: "fit-content", minWidth: "130px", fontSize: "16px", fontWeight: 400 }}>Email ID</div>
                          <span style={{ marginRight: "10px" }}>:</span>
                          <div style={{ width: "200px", wordWrap: 'break-word', fontSize: "16px", fontWeight: 400 }}>{sprayingRequest.user?.email || "-"}</div>
                        </div> */}
                      </div>
                    </div >
                    {/* Service Details */}
                    < div style={{ display: "flex", flexDirection: "column", rowGap: "30px", width: "100%" }}>
                      <div style={{ fontSize: "22px", fontWeight: "600", display: "flex", gap: "10px" }}>Service Details  </div>
                      <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "20px 75px" }}>
                        {/*service name  */}
                        <div style={{ display: "flex" }}>
                          <div style={{ color: "rgba(59, 59, 59, 0.8)", width: "fit-content", minWidth: "130px", fontSize: "16px", fontWeight: 400 }}>Service</div>
                          <span style={{ marginRight: "10px" }}>:</span>
                          <div style={{ width: "212px", marginLeft: "5px", fontSize: "16px", fontWeight: 400 }}>{sprayingRequest?.type || "-"}</div>
                        </div>
                        <div style={{ display: "flex" }}>
                          <div style={{ color: "rgba(59, 59, 59, 0.8)", width: "fit-content", minWidth: "130px", fontSize: "16px", fontWeight: 400 }}>Crop</div>
                          <span style={{ marginRight: "10px" }}>:</span>
                          <div style={{ width: "212px", marginLeft: "5px", fontSize: "16px", fontWeight: 400 }}>
                            {sprayingRequest?.crop_name || "-"}
                          </div>
                        </div>
                        {/*Location */}
                        <div style={{ display: "flex", width: "100%" }}>
                          <div style={{ color: "rgba(59, 59, 59, 0.8)", width: "fit-content", minWidth: "130px", fontSize: "16px", fontWeight: 400 }}>Service location</div>
                          <span style={{ marginRight: "10px" }}>:</span>
                          <div style={{ overflowWrap: "break-word", wordWrap: "break-word", width: "350px", lineHeight: 1.3, marginLeft: "5px", fontSize: "16px", fontWeight: 400, overflowY: "auto", maxHeight: "70px", overflow: "auto" }}>
                            {sprayingRequest?.field_address || "-"}
                          </div>
                        </div>
                        <div style={{ display: "flex", width: "100%" }}>
                          <div style={{ color: "rgba(59, 59, 59, 0.8)", width: "fit-content", minWidth: "130px", fontSize: "16px", fontWeight: 400 }}>Agro Chemicals</div>
                          <span style={{ marginRight: "10px" }}>:</span>
                          <div style={{ overflowWrap: "break-word", wordWrap: "break-word", width: "350px", lineHeight: 1.3, marginLeft: "5px", fontSize: "16px", fontWeight: 400, overflowY: "auto", maxHeight: "70px", overflow: "auto" }}>
                            {sprayingRequest.pesticides || "-"}
                          </div>
                        </div>
                        <div style={{ display: "flex", width: "100%" }}>
                          <div style={{ color: "rgba(59, 59, 59, 0.8)", width: "fit-content", minWidth: "170px", fontSize: "16px", fontWeight: 400 }}>{sprayingRequest?.price_by === "acre" ? `Requested ${capitalizeFirstLetter(sprayingRequest.price_by)} Area` : "Requested No of Tank"}</div>
                          <span style={{ marginRight: "10px" }}>:</span>
                          <div style={{ overflowWrap: "break-word", wordWrap: "break-word", width: "350px", lineHeight: 1.3, marginLeft: "5px", fontSize: "16px", fontWeight: 400, overflowY: "auto", maxHeight: "70px", overflow: "auto" }}>
                            {sprayingRequest.field_area ? sprayingRequest.price_by === "acre" ? `${squareKilometersToAcres(sprayingRequest.field_area).toFixed(2)} Acre` : sprayingRequest.field_area : "-"}
                          </div>
                        </div>
                        {
                          isStatusChange && sprayingRequest?.status === STATUS.COMPLETED ? (
                            <div style={{ display: "flex", width: "100%", alignItems: "baseline" }}>
                              <div style={{ color: "rgba(59, 59, 59, 0.8)", width: "fit-content", minWidth: "130px", fontSize: "16px", fontWeight: 400 }}>{sprayingRequest.price_by === "acre" ? "Completed Area" : "Completed No Of Tank"}</div>
                              <span style={{ marginRight: "10px" }}>:</span>
                              <div style={{ display: "flex", flexDirection: "column", gap: "0.2rem" }}>
                                <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                  <Input
                                    type={"number"}
                                    value={sprayingRequest.service_completed_area}
                                    maxLength={LIMITS.COMPELETED_AREA}
                                    className="text_input_field"
                                    placeholder={sprayingRequest.price_by === "acre" ? "Completed Area" : "Completed No Of Tank"}
                                    name={"Completed Area"}
                                    onChange={(e) => {
                                      let value = e.target.value;
                                      if (value == "") {
                                        setSprayingRequest((prev) => ({
                                          ...prev,
                                          service_completed_area: "",
                                        }));
                                        setError((prev) => ({
                                          ...prev,
                                          completed_area: "Required",
                                        }));
                                      }
                                      else
                                        if (value.match(/^\d*\.?\d*$/)) {
                                          const decimalValue = parseFloat(value);
                                          if (!isNaN(decimalValue) && (decimalValue < 0.1 || decimalValue > 1000.0)) {
                                            setError((prev) => ({
                                              ...prev,
                                              completed_area: "Completed area in the range of 0.1 to 1000"
                                            }));
                                          } else {
                                            setSprayingRequest((prev) => ({
                                              ...prev,
                                              service_completed_area: decimalValue,
                                            }));
                                            setError((prev) => ({ ...prev, completed_area: null }));
                                          }
                                        }
                                    }}
                                  />
                                  {sprayingRequest.price_by === "acre" && (
                                    <span style={{ fontSize: "14px", fontWeight: 500 }}>Acre</span>
                                  )}
                                </div>
                                <div className={"drone_add_error-messsage"}>{error.completed_area} </div>
                              </div>

                            </div>
                          ) : sprayingRequest.service_completed_area && sprayingRequest.service_completed_area !== '' && sprayingRequest.service_completed_area !== "0"
                            ?
                            <div style={{ display: "flex", width: "100%", alignItems: "center" }}>
                              <div style={{ color: "rgba(59, 59, 59, 0.8)", width: "fit-content", minWidth: "130px", fontSize: "16px", fontWeight: 400 }}>{sprayingRequest.price_by === "acre" ? "Completed Area" : "Completed No Of Tank"}</div>
                              <span style={{ marginRight: "10px" }}>:</span>
                              <div style={{ overflowWrap: "break-word", wordWrap: "break-word", width: "350px", lineHeight: 1.3, marginLeft: "5px", fontSize: "16px", fontWeight: 400, overflowY: "auto", maxHeight: "70px", overflow: "auto", }}>{ }
                                <span>{sprayingRequest.service_completed_area ? (sprayingRequest.service_completed_area) : "-"}</span>
                                {sprayingRequest.price_by === "acre" && (<span style={{ marginLeft: "10px" }}>Acre</span>)}
                              </div>
                            </div>
                            : null
                        }
                      </div>
                    </div>
                    <div style={{ display: "flex", width: "100%", gap: "200px", alignItems: "baseline" }}>
                      <div style={{ display: "flex", width: "40%", flexDirection: "column", gap: "30px" }}>
                        <span style={{ fontSize: "22px", fontWeight: "600" }}>
                          Resource Details
                        </span>
                        <div style={{ display: "grid ", gridTemplateRows: "1fr 1fr 1fr ", gap: "5px" }}>
                          {/* Assigned plot  */}
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <div style={{ color: "rgba(59, 59, 59, 0.8)", width: "fit-content", minWidth: "150px", fontSize: "16px", fontWeight: 400 }}>Assigned Pilot</div>
                            <span style={{ marginRight: "10px" }}>:</span>
                            <div style={{ width: "275px", marginLeft: "5px", fontSize: "16px", fontWeight: 600 }}>
                              {!(sprayingRequest.status == STATUS.ACCEPTED || sprayingRequest.status == STATUS.RESCHEDULED)
                                ? (dronePilots.find(pilot => pilot.id === sprayingRequest.drone_pilot)?.first_name
                                  ||
                                  <span className="drone_add_error-messsage" style={{ fontSize: "13px" }}>Pilot not assigned yet.</span>
                                )
                                : dronePilots.length !== 0 ?
                                  <>
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                      <SelectComponent
                                        menuStyles={{ width: "176px" }}
                                        controlStyle={{ width: "176px", height: "30px", background: "#F3F3F3", border: "1px solid #D9D9D9", borderRadius: "4px" }}
                                        value={sprayingRequest.drone_pilot ? { value: sprayingRequest.drone_pilot, label: dronePilots.find(p => p.id === sprayingRequest.drone_pilot)?.first_name } : ""}
                                        onChange={(selectedPilot) => {
                                          const linkedDroneToPilot = (dronePilots.find(p => p.id === selectedPilot.value))?.assigned_drone
                                          if (linkedDroneToPilot) { handleDroneSelection({ value: linkedDroneToPilot }) }
                                          else {
                                            setSelectedDroneUIN(null);
                                            setDroneType(null);
                                          }
                                          setSprayingRequest((prev) => ({
                                            ...prev,
                                            drone_pilot: selectedPilot.value,
                                          }));
                                          if (sprayingRequest.drone_pilot != selectedPilot.value) setAnyInputChange(true)
                                        }}
                                        list={(isShowAllPilots ? dronePilots : assignedLocationsDronePilots).map(el => ({ value: el.id, label: el.first_name }))}
                                        isSearchable={true}
                                      />

                                      <input
                                        style={{ margin: "0 10px" }}
                                        checked={isShowAllPilots}
                                        onChange={(e) => setIsShowAllPiltos(e.target.checked)}
                                        value={isShowAllPilots}
                                        type={"checkbox"}
                                        className="input-checkbox"
                                      />
                                      <div style={{ color: 'rgba(59, 59, 59, 0.8)', fontSize: "13px" }}>Show All</div>
                                    </div>
                                  </>
                                  : <div className="drone_add_error-messsage" style={{ fontSize: "13px" }}>
                                    Pilots not exists. Please register pilots first.
                                  </div>
                              }
                            </div>
                          </div>
                          {/*Date */}

                          <div style={{ display: "flex", alignItems: "center" }}>
                            <div style={{ color: "rgba(59, 59, 59, 0.8)", width: "fit-content", minWidth: "150px", fontSize: "16px", fontWeight: 400 }}>Assigned Drone UIN</div>
                            <span style={{ marginRight: "10px" }}>:</span>
                            <div style={{ width: "282px", marginLeft: "5px", fontSize: "16px", fontWeight: 600 }}>
                              {!(sprayingRequest.status == STATUS.ACCEPTED || sprayingRequest.status == STATUS.RESCHEDULED)
                                ? (selectedDroneUIN || <span className="drone_add_error-messsage" style={{ fontSize: "13px" }}>Drone not assigned yet.</span>) :
                                drones.length !== 0 ?
                                  <SelectComponent
                                    controlStyle={{ width: "176px", height: "40px", background: "#F3F3F3", border: "1px solid #D9D9D9", borderRadius: "4px" }}
                                    menuStyles={{ width: "176px" }}
                                    value={selectedDroneUIN ? { value: selectedDroneUIN, label: selectedDroneUIN } : ""}
                                    onChange={handleDroneSelection}
                                    list={selectedDroneUIN ? [{ value: selectedDroneUIN, label: selectedDroneUIN }] : drones?.filter((d) => d?.service_type === SERVICE_TYPES[0]).map(el => ({ value: el.drone_uin, label: el.drone_uin }))}
                                    isSearchable={true}
                                  />
                                  : <div className="drone_add_error-messsage" style={{ fontSize: "13px", fontWeight: 500 }}>
                                    Drones not exists. Please register drones first.
                                  </div>
                              }
                            </div>
                          </div>
                          {/*assigned drone type */}
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <div style={{ color: "rgba(59, 59, 59, 0.8)", width: "fit-content", minWidth: "150px", fontSize: "16px", fontWeight: 400 }}>Assigned Drone Type</div>
                            <span style={{ marginRight: "10px" }}>:</span>
                            { }  <div style={{ width: "212px", marginLeft: "5px", fontSize: "16px", fontWeight: 600 }}>
                              {droneType ? droneType : "-"}
                            </div>
                          </div>
                          {/*Time */}

                        </div>
                      </div>
                      <div style={{ display: "flex", flexDirection: "column", width: "40%", gap: "20px", marginTop: "10px" }}>
                        <span style={{ fontSize: "22px", fontWeight: "600" }}>
                          Schedule
                        </span>
                        <div style={{ display: "flex", flexDirection: "column", gap: "0.4rem", fontSize: "15px", fontWeight: 400 }}>
                          <div style={{ display: "flex", alignItems: "center", height: "40px" }}>
                            <div style={{ color: "rgba(59, 59, 59, 0.8)", width: "fit-content", fontSize: "16px", fontWeight: 400 }}>Date</div>
                            <span style={{ marginRight: "10px", marginLeft: "5px" }}>:</span>
                            {(!isInputEditable || sprayingRequest.status === STATUS.OUT_FOR_SERVICE) ? (getFormatedDate(sprayingRequest.time) || "-")
                              : <div
                                className="input-date"
                                style={{ display: "flex", alignItems: "center", cursor: "pointer", height: "100%", width: "180px", position: "relative" }}
                                onClick={() => setShowDatePicker(true)}
                              >
                                <DatePicker
                                  onClickOutside={() => setShowDatePicker(false)}
                                  open={showDatePicker}
                                  readOnly
                                  className="input-date-hidden"
                                  minDate={new Date()}
                                  selected={new Date(sprayingRequest.time)}
                                  onChange={date => {
                                    setNoValueChanged(false)
                                    setSprayingRequest(prev => ({ ...prev, time: combineDateTime((new Date(sprayingRequest.time)), new Date(date)) }))
                                  }} />
                                <img src={Calender} style={{ width: "18px", position: "absolute", right: "15px" }} />
                              </div>}
                          </div>
                          {/* <div style={{ display: "flex", alignItems: "center", height: "40px" }}>
                            <div style={{ color: "rgba(59, 59, 59, 0.8)", width: "fit-content", fontSize: "16px", fontWeight: 400 }}>Time</div>
                            <span style={{ marginRight: "10px", marginLeft: "5px" }}>:</span>
                            {!isInputEditable ? (getFormattedTimeFromDate(sprayingRequest.time) || "-")
                              : <div
                                className="input-date"
                                style={{ display: "flex", alignItems: "center", cursor: "pointer", height: "100%", width: "180px", position: "relative" }}
                                onClick={() => setShowTimePicker(true)}
                              >
                                <DatePicker
                                  onClickOutside={() => setShowTimePicker(false)}
                                  open={showTimePicker}
                                  readOnly
                                  className="input-date-hidden"
                                  minTime={(sprayingRequest.time && (new Date(sprayingRequest.time)).getDate() === (new Date()).getDate())
                                    ? new Date((new Date()).getFullYear(), (new Date()).getMonth(), (new Date()).getDate(), (new Date()).getHours(), (new Date()).getMinutes())
                                    : new Date((new Date()).getFullYear(), (new Date()).getMonth(), (new Date()).getDate(), 0, 0)
                                  }
                                  maxTime={setHours(setMinutes(new Date(), 59), 23)}
                                  showTimeSelect
                                  showTimeSelectOnly
                                  timeIntervals={15}
                                  timeCaption="Time"
                                  dateFormat="h:mm aa"
                                  selected={new Date(sprayingRequest.time)}
                                  onChange={date => {
                                    setNoValueChanged(false)
                                    setSprayingRequest(prev => ({ ...prev, time: combineDateTime((new Date(date)), new Date(sprayingRequest.time)) }))
                                  }} />
                                <img src={Clock} style={{ width: "18px", position: "absolute", right: "15px" }} />
                              </div>}
                          </div> */}
                        </div>
                      </div>
                    </div>


                    <div style={{ width: "30%" }}>
                      <RequestDiscount
                        ref={invoiceRef}
                        oldRequest={req || {}}
                        hideToggle={sprayingRequest?.status === STATUS.PLACED}
                        request={{
                          ...sprayingRequest,
                          service_completed_area: sprayingRequest.service_completed_area && sprayingRequest.price_by === 'acre' ? acresToSquareKilometers(Number(sprayingRequest.service_completed_area)) : Number(sprayingRequest.service_completed_area)
                        }}
                        callback={(invoice) => {
                          setSprayingRequest(prev => ({ ...prev, invoice }))
                        }}
                        changeValue={(value) => {
                          let discountData = req.invoice.applied_discounts.find(ad => ad.discount_type === DISCOUNT_TYPES.DSP_REQUEST_DISCOUNT);
                          if (discountData) {
                            if (Number(discountData.discount) == Number(value)) setIsDiscountChanged(false)
                            else setIsDiscountChanged(true)
                            setNoValueChanged(false)
                          }
                          else if (value > 0) {
                            setNoValueChanged(false)
                            setIsDiscountChanged(true)
                          }
                          else {
                            setNoValueChanged(true)
                            setIsDiscountChanged(false)
                          }
                          setAppliedDiscount(Number(value))
                        }} />
                    </div>
                  </div>
                </div>
              </div>
            )}
        </div >
      </MainContentWrapper>


    </>
  );
};

export default ViewSprayingRequest;
