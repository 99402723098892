import React, { useState } from 'react'
import DetailsWrapper from './DetailsWrapper'
import profileActive from "../../../../../assets/profileActive.svg"
import profileInActive from "../../../../../assets/UserCircle.svg"
import WalletInactive from "../../../../../assets/WalletInactive.svg"
import WalletActive from "../../../../../assets/WalletActive.svg"
import invoiceInactive from "../../../../../assets/invoiceInactive.png"
import invoiceActive from "../../../../../assets/invoiceActive.png"
import { useSelector } from 'react-redux'

const ProfileMenu = ({
    onMenuSelect
}) => {
    const user = useSelector(state => state.user?.user)

    const menu = [
        {
            title: "My Profile",
            activeIcon: profileActive,
            inActiveIcon: profileInActive,
            isHide: false
        },
        {
            title: "My Wallet",
            activeIcon: WalletActive,
            inActiveIcon: WalletInactive,
            isHide: user?.is_admin ? false : true
        },
        {
            title: "My Invoices",
            activeIcon: invoiceActive,
            inActiveIcon: invoiceInactive,
            width: "22px",
            isHide: user?.is_admin ? false : true
        }
    ]

    const [selected, setSelected] = useState(menu[0])

    return (
        <DetailsWrapper className="sidebar_container" >
            <div className='sidebar_wrapper'>
                {menu?.map((m) => (
                    m.isHide ?
                        <></> :
                        <div className='profile-menu-hover' style={{ display: "flex", alignItems: "center", gap: "20px", background: selected.title === m.title ? "#0F5EFF1A" : "", padding: "10px 20px", borderRadius: "8px", cursor: "pointer" }}
                            onClick={() => {
                                setSelected(m)
                                onMenuSelect(m)
                            }}>
                            <img src={selected.title === m.title ? m.activeIcon : m.inActiveIcon} alt='icon' width={m?.width || ""} />
                            <div style={{ color: selected.title === m.title ? "#0F5EFF" : "#68717E", fontWeight: 600 }}>{m.title}</div>
                        </div>
                ))}
            </div>
        </DetailsWrapper>
    )
}

export default ProfileMenu