import React from 'react'
import Modal from '../../ReusableComponents/Modal'
import { CrossCloseButton } from '../../Components/Helper'
import { GLOBAL_STYLES } from '../../appConstants'
import { getFormatedDate } from '../../ReusableFunctions/reusableFunctions'

const ViewCustSuccessDsp = ({ open, onClose, dsp, callback }) => {

    return (
        <Modal
            open={open}
            onClose={onClose}
            contentStyle={{ height: "500px", width: "700px", borderRadius: "10px", overflow: "hidden" }}>
            <div style={{ height: "100%", background: "rgb(244, 244, 244)", padding: "0px 0px 20px 0px" }}>
                <div style={{ display: "flex", justifyContent: "space-between", gap: "20px", background: "#D9D9D9D9", padding: "20px" }}>

                    <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                        <div style={{ fontSize: "14px" }}>DSP Details</div>
                    </div>
                    <CrossCloseButton onClick={onClose} />
                </div>
                <div style={{ overflowY: "auto", height: "calc(100% - 50px)", fontSize: GLOBAL_STYLES.FONT_SIZE_MEDIUM, padding: "20px" }}>
                    <div style={{ fontWeight: 600, marginBottom: "10px" }}>Owner Details</div>
                    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "20px" }}>
                        <div style={{ display: "flex" }}>
                            <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Name</div>
                            <div style={{ margin: "0px 10px" }}>:</div>
                            <div style={{}}>{dsp.operator_name || "-"}</div>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Address</div>
                            <div style={{ margin: "0px 10px" }}>:</div>
                            <div style={{}}>{dsp.operator_address || "-"}</div>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Phone number</div>
                            <div style={{ margin: "0px 10px" }}>:</div>
                            <div style={{}}>{dsp.operator_phone || "-"}</div>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Account Status</div>
                            <div style={{ margin: "0px 10px" }}>:</div>
                            <div style={{ color: dsp.operator_is_active ? "green" : "red" }}>{dsp.operator_is_active ? "Active" : "Inactive" || "-"}</div>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Email</div>
                            <div style={{ margin: "0px 10px" }}>:</div>
                            <div style={{}}>{dsp.operator_email || "-"}</div>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Created On</div>
                            <div style={{ margin: "0px 10px" }}>:</div>
                            <div style={{}}>{getFormatedDate(dsp.dsp_date_created) || "-"}</div>
                        </div>
                    </div>

                    <br />
                    <div style={{ fontWeight: 600, margin: "10px 0px" }}>Company details</div>
                    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "20px" }}>
                        <div style={{ display: "flex" }}>
                            <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Dsp Id</div>
                            <div style={{ margin: "0px 10px" }}>:</div>
                            <div style={{}}>{dsp.dsp_no || "-"}</div>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Name</div>
                            <div style={{ margin: "0px 10px" }}>:</div>
                            <div style={{}}>{dsp.dsp_name || "-"}</div>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Status</div>
                            <div style={{ margin: "0px 10px" }}>:</div>
                            <div style={{ color: dsp.dsp_is_active ? "green" : "red" }}>{dsp.dsp_is_active ? "Active" : "Inactive" || "-"}</div>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Address</div>
                            <div style={{ margin: "0px 10px" }}>:</div>
                            <div style={{}}>{dsp.dsp_address || "-"}</div>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Verified</div>
                            <div style={{ margin: "0px 10px" }}>:</div>
                            <div style={{ color: dsp.dsp_is_verified ? "green" : "red" }}>{dsp.dsp_is_verified ? "Yes" : "No" || "-"}</div>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Created on</div>
                            <div style={{ margin: "0px 10px" }}>:</div>
                            <div style={{}}>{getFormatedDate(dsp.dsp_date_created) || "-"}</div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default ViewCustSuccessDsp