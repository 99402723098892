import { useEffect, useRef, useState } from "react";
import Map from "../../features/LeafletMap/Map"
import LocationDSPList from "./LocationDSPList";
import Popup from "../../ReusableFunctions/Popup";
import { getAllLocationsWithDSPs } from "../../apis/adminAPIs/locations";
import MarkerIcon from "../../assets/marker.png";
import SelectedMarkerIcon from "../../assets/selectedMarker.png";
import L from 'leaflet'
import roadMapView from '../../assets/roadMapView.png'
import sateliteView from '../../assets/sateliteView.png'
import { GLOBAL_STYLES, MAP_URLS } from "../../appConstants";
import generateRandomColor from "../../utils/generateRandomColor";
import './Location.css'
let baseLayer
const ViewLocationsOnMap = () => {
    const dspRef = useRef(null)
    const [activeBaseLayer, setActiveBaseLayer] = useState(baseLayer)
    const [center, setCenter] = useState(
        { lat: 19.993982, lng: 73.790416 }
    );
    const [locations, setLocations] = useState([])
    const [dsps, setDsps] = useState([])
    const [map, setMap] = useState(null)

    const deSelectedMarkerIcon = L.icon({
        iconUrl: MarkerIcon,
        iconSize: [20, 28],
    });
    const selectedMarkerIcon = L.icon({
        iconUrl: SelectedMarkerIcon,
        iconSize: [28, 28],
    });


    const initMap = (map) => {
        setMap(map)

    };

    const drawLocationsOnMap = (locations) => {
        var markerOptions = {
            clickable: true,
            draggable: true,
            icon: deSelectedMarkerIcon,
        };
        const newLocations = locations.map(loc => {
            const center = { lat: loc.lat, lng: loc.lng };

            // Create a marker and add it to the map
            let marker1 = new L.Marker(center, markerOptions).addTo(map);
            // Create a circle and add it to the map
            let circle = new L.circle(center, {
                color: loc.color,
                fillColor: loc.color,
                fillOpacity: 0.3,
                weight: 3,
                radius: Number(loc.radius) * 1000
            }).addTo(map);
            const tooltipContent = `
                <div style="display:flex; height:150px; min-width:200px; ">
                    <div style="width:10px; background:${loc.color}; height:100%;"></div>
                    <div style="padding:10px;">
                        <div>DSP name: <span style="font-weight:600;">${loc.dsp_name} </span></div>
                        <div>Location name:  <span style="font-weight:600;">${loc.name} </span></div>
                        <div>Address:  <span style="font-weight:600;">${loc.address} </span></div>
                        <div>Radius:  <span style="font-weight:600;">${Number(loc.radius)} km  (${Number(loc.radius) * 1000} m)</span></div>
                        <div>Number of requests:  <span style="font-weight:600;">${loc.total_requests ?? 0} </span></div>
                        <div>Number of pilots:  <span style="font-weight:600;">${loc.pilots_count ?? 0} </span></div>
                        <div>Number of drones:  <span style="font-weight:600;">${loc.drones_count ?? 0} </span></div>
                    </div>
                </div>
                `
            // Create and bind the tooltip to the circle
            circle.bindTooltip(tooltipContent, {
                className: 'location-tooltip',
                permanent: false,  // Set to true if you want the tooltip to always be visible
            });
            marker1.bindTooltip(tooltipContent, {
                className: 'location-tooltip',
                permanent: false,  // Set to true if you want the tooltip to always be visible
            });
            circle.on('click', (e) => {
                circle.bringToBack()
            })
            return { ...loc, circleLeafletLayer: circle, markerLeafletLayer: marker1 }
        })
        setLocations(newLocations)
    }
    const initData = async () => {
        try {
            let { locations, dsps } = await getAllLocationsWithDSPs()
            const locationsWithColor = []
            const dspsWithColor = dsps.filter(dsp => dsp.dsp_is_active).map((dsp) => {
                const color = generateRandomColor()
                dsp.color = color
                const relatedLocations = locations
                    .filter((location) => location.dsp_id === dsp.dsp_id && location.is_active)
                    .map((location) => ({ ...location, color, dsp_name: dsp?.dsp_name }))

                dsp.total_locations = relatedLocations?.length

                locationsWithColor.push(...relatedLocations)

                return dsp
            })
            setLocations(locationsWithColor)
            drawLocationsOnMap(locationsWithColor)
            setDsps(dspsWithColor)
        } catch (e) {
            console.log(e)
            Popup.alert("ERROR", e?.message||"Soething went wrong while displaying information", "ERROR")
        }
    }
    useEffect(() => {
        if (map && locations.length == 0) {
            initData()
        }
    }, [map])


    const switchBaseLayer = () => {
        const activeLayer = activeBaseLayer == "satelite" ? "terrain" : "satelite"
        setActiveBaseLayer(activeLayer)
        if (baseLayer) {
            baseLayer.setUrl(activeLayer === "satelite" ? MAP_URLS.SATELITE : MAP_URLS.ROAD_MAP)
        }
    }
    const highlightLayer = (layer) => {
        layer.circleLeafletLayer.setStyle({
            color: 'yellow',        // Border color for highlighting
            fillColor: 'yellow',    // Fill color for highlighting
            fillOpacity: 0.3,       // Increase the opacity for more visibility
            weight: 3
        })
        layer.circleLeafletLayer.bringToFront()
        // Set the custom icon to the marker
        layer.markerLeafletLayer.setIcon(selectedMarkerIcon)
        layer.markerLeafletLayer.setZIndexOffset(30)
        // layer.markerLeafletLayer.bringToFront()
    }
    const dehighlightLayer = (layer) => {
        layer.circleLeafletLayer.setStyle({
            color: layer.color,
            fillColor: layer.color,
            fillOpacity: 0.3,
            weight: 3
        })
        layer.markerLeafletLayer.setIcon(deSelectedMarkerIcon)
        layer.markerLeafletLayer.setZIndexOffset(20)
    }
    return <div style={{ width: "100%", height: "100%", position: 'relative' }}>
        <LocationDSPList
            ref={dspRef}
            onClickDSP={((dsp, on) => {

                locations.map(loc => {
                    if (dsp.dsp_id == loc.dsp_id) {
                        if (on) {
                            highlightLayer(loc)
                        } else {

                            dehighlightLayer(loc)
                        }
                    } else {
                        dehighlightLayer(loc)
                    }
                })
            })}
            dsps={dsps}
        />
        <Map
            setBaseLayerToState={(bL) => {
                baseLayer = bL
            }}
            initZoom={13}
            handleMapClick={() => { }}
            maxZoom={23}
            style={{
                height: "100%",
                borderRadius: "0 0 5px 5px",
            }}
            mapInitialized={(map) => {
                initMap(map);
            }}
        />
        <div style={{ border: 'solid 1px rgb(102, 102, 102, 0.3)', height: '25px', backgroundColor: 'rgba(255, 255, 255, 0.9)', borderRadius: '5px', cursor: 'pointer', zIndex: '999', position: "absolute", right: "10px", bottom: '24px' }}
            onClick={switchBaseLayer}>
            <img style={{ width: '80px', height: '25px', borderRadius: '4px' }} src={activeBaseLayer === "satelite" ? roadMapView : sateliteView} />
        </div>
    </div>
}
export default ViewLocationsOnMap