import React, { useEffect, useRef, useState } from 'react'
import Popup from '../../ReusableFunctions/Popup'
import Loading from '../../ReusableFunctions/Loader'
import MainContentWrapper from '../../Components/MainContentWrapper'
import { getAllUsers } from '../../apis/adminAPIs/users'
import ViewUser from './SubComponents/ViewUser'
import TableView from '../../ReusableComponents/TableView/TableView'
import Search from '../../ReusableComponents/Search'
import _debounce from 'lodash/debounce';
import UsersFilter from './SubComponents/UsersFilter'
import { Button } from '../../Components/Helper'
import { generateCSV } from '../../utils/generateAndDownloadCsv'
import { useNavigate } from 'react-router'
const PAGE_SIZE = 15

const csvHeader = [
    { label: "Name", key: "name" },
    { label: "Phone Number", key: "mobile_number" },
    { label: "Language Preference", key: "language_preference" },
    { label: "Occupation", key: "occupation" },
    { label: "Created Date", key: "date_created", isDate: true }
]

const Users = () => {
    const isFirstRender = useRef(true);
    const [loading, setLoading] = useState()
    const [users, setUsers] = useState([])
    const [userCount, setUserCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [selectedUser, setSelectedUser] = useState()
    const [searchString, setSearchString] = useState("")
    const [filter, setFilter] = useState({
        fromDate: null,
        toDate: null
    })
    const navigate = useNavigate();

    const getAllUsersFunc = async () => {
        try {
            setLoading(true)
            const { fromDate, toDate } = filter
            getAllUsers(PAGE_SIZE, currentPage, searchString, fromDate, toDate).then(users => {
                Loading.set(false)
                setLoading(false)
                setUsers(users?.allUsers)
                setUserCount(users.count || 0)
            })
        } catch (err) {
            setLoading(false)
            Loading.set(false)
            Popup("Error", err, "ERROR")
        }
    }

    const downloadCSV = async () => {
        Loading.set(true)
        const { fromDate, toDate } = filter
        const users = await getAllUsers(PAGE_SIZE, currentPage, searchString, fromDate, toDate)
        generateCSV(csvHeader, users?.allUsers, "output")
        Loading.set(false)
    }

    useEffect(() => {
        Loading.set(true)
        setLoading(true)
        getAllUsersFunc()
    }, [])

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }

        const debouncedGetAllUsers = _debounce(() => {
            getAllUsersFunc();
        }, 2000);

        debouncedGetAllUsers();

        return () => {
            debouncedGetAllUsers.cancel();
        };
    }, [searchString, currentPage, filter.fromDate, filter.toDate]);
    return (

        <MainContentWrapper
            leftContent={`Users (${userCount})`}
            rightContent={
                <div style={{ display: "flex", alignItems: "end", gap: "30px" }}>
                    <Search
                        searchValue={searchString}
                        onChange={(value) => {
                            setLoading(true);
                            setSearchString(value)
                            setCurrentPage(1)
                        }}
                        height={"45px"}
                    />
                    <UsersFilter
                        filter={filter}
                        onFilterChange={(filter) => {
                            setFilter(filter)
                        }}
                    />
                    {/* <Button
                        text={"Export CSV"}
                        style={{ width: "140px", height: "38px", fontSize: "15px" }}
                        onClick={() => {
                            downloadCSV()
                        }}
                    /> */}
                </div>
            }
        >

            <TableView
                isLoading={loading}
                tableContainerHeight={userCount < 15 ? "calc(100% - 40px)" : "calc(100% - 65px)"}
                showSrNo
                onStateChange={(changedState) => {
                    setLoading(true);
                    setCurrentPage(changedState.pageNumber)
                }}
                onClickRow={(user) => {
                    localStorage.setItem("dsp_id", user.id)
                    setSelectedUser(user)
                    navigate('/userDetails', { state: { user: user } });
                }}
                onClickLink={(request) => { }}
                columns={[
                    { Header: "Name", accessor: "name", width: "10%" },
                    { Header: "Phone number", accessor: "mobile_number", width: "10%", },
                    { Header: "Language Preference", accessor: "language_preference", width: "10%" },
                    { Header: "Occupation", accessor: "occupation", width: "5%", },
                    { Header: "Created date", accessor: "date_created", width: "10%", isDate: true },
                    { Header: "Dsp Name", accessor: "dsp_name", width: "10%" },
                    { Header: "Status", accessor: "user_active_label", showColor: true, width: "10%" },
                ]}
                data={users.map((u) => {
                    return (
                        {
                            ...u,
                            user_active_label: u.is_active ? "Active" : "Inactive",
                            color: u.is_active ? 'green' : 'red'
                        }
                    )
                })}
                count={userCount}
                pageNumber={currentPage}
                pageSize={PAGE_SIZE}
            />
        </MainContentWrapper>
    )
}

export default Users