import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { ALL_ROUTES_ARRAY, PDRL_ADMIN_ROUTES_COMPONENTS, PDRL_FINANCE_COMPONENTS, PDRL_SUCCESS_ENGI_COMPONENTS } from "./routes";
import NavBar from "./Components/NavBar";
import Login from "./features/Login/Login";
import SignUp from "./features/SignUp/SignUp";
import ResetPassword from "./features/ResetPassword/ResetPassword";
import ProtectedRoute from "./ReusableComponents/ProtectedRoute";
import Profile from "./features/Profile/Profile";
import ProSidebar from "./Components/ProSidebar";
import { ErrorBoundary } from "react-error-boundary"
import { useSelector } from "react-redux";
import NotFounds from "./ReusableComponents/NotFounds";
import { BHUMEET_TEAM_ROLES } from "./appConstants";

const App = () => {
  const user = useSelector(state => state.user?.user)
  const [routes, setRoutes] = useState([])

  useEffect(() => {
    if (user) {
      let ROUTES = []
      switch (user.bhumeet_role) {
        case BHUMEET_TEAM_ROLES.ADMIN.NAME:
          ROUTES = PDRL_ADMIN_ROUTES_COMPONENTS
          break;

        case BHUMEET_TEAM_ROLES.CUSTOMER_SUPPORT_ENGINEER.NAME:
          ROUTES = PDRL_SUCCESS_ENGI_COMPONENTS
          break;

        case BHUMEET_TEAM_ROLES.FINANCE.NAME:
          ROUTES = PDRL_FINANCE_COMPONENTS
          break;

        default:
          ROUTES = ALL_ROUTES_ARRAY;
          break;
      }
      setRoutes(ROUTES)
    }
    else if (!window.location.pathname.includes('login')) window.location.href = ("/login")

  }, [user])

  return (
    <Router>
      <div style={{ display: "flex", height: "100vh" }}>
        <ProSidebar />
        <div className="main-app-wrapper" style={{ transition: "width 0.8 ease-in" }}>
          {user && <NavBar />}
          <Routes>
            <Route path="/resetPassword" element={<ResetPassword />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/notFound" element={<NotFounds msg={"Page not found 404"} hideBtn />} />

            {
              // user && !user?.bhumeet_role && user?.dsp?.is_verified &&
              routes.map((route, key) => (
                <Route key={key} path={route.path} element={
                  <ErrorBoundary>
                    <ProtectedRoute>{route.element}</ProtectedRoute>
                  </ErrorBoundary>
                }
                />
              ))
            }
            {/* 
            {
              // user && user?.bhumeet_role == BHUMEET_TEAM_ROLES.ADMIN.NAME &&
              PDRL_ADMIN_ROUTES_COMPONENTS.map((route, key) => (
                <Route key={key} path={route.path} element={
                  <ErrorBoundary>
                    <ProtectedRoute type={"Admin"} >{route.element}</ProtectedRoute>
                  </ErrorBoundary>
                } />
              ))
            } */}

            {
              user && user?.bhumeet_role == BHUMEET_TEAM_ROLES.ADMIN.NAME &&
              <Route path="/profile" element={
                <ProtectedRoute type="Admin" >
                  <Profile />
                </ProtectedRoute>}
              />
            }

            {/* {
              // user && user?.bhumeet_role == BHUMEET_TEAM_ROLES.FINANCE.NAME &&
              PDRL_FINANCE_COMPONENTS.map((route, key) => (
                <Route key={key} path={route.path} element={
                  <ErrorBoundary>
                    <ProtectedRoute type={"Finance"} >{route.element}</ProtectedRoute>
                  </ErrorBoundary>
                } />
              ))
            } */}

            {
              user && !user?.bhumeet_role &&
              <Route path="/profile" element={
                <ProtectedRoute >
                  <Profile />
                </ProtectedRoute>}
              />
            }
            {
              user && user?.bhumeet_role == BHUMEET_TEAM_ROLES.FINANCE.NAME &&
              <Route path="/profile" element={
                <ProtectedRoute type="Finance" >
                  <Profile />
                </ProtectedRoute>}
              />
            }

            {/* {
              // user && user?.bhumeet_role == BHUMEET_TEAM_ROLES.CUSTOMER_SUPPORT_ENGINEER.NAME &&
              PDRL_SUCCESS_ENGI_COMPONENTS.map((route, key) => (
                <Route key={key} path={route.path} element={
                  <ErrorBoundary>
                    <ProtectedRoute type={"Customer support engineer"} >{route.element}</ProtectedRoute>
                  </ErrorBoundary>
                } />
              ))
            } */}

            {
              user && user?.bhumeet_role == BHUMEET_TEAM_ROLES.CUSTOMER_SUPPORT_ENGINEER.NAME &&
              <Route path="/profile" element={<ProtectedRoute type="Customer support engineer">
                <Profile />
              </ProtectedRoute>} />
            }

            {user && <Route path="*" element={<NotFounds msg={"Page not found 404"} hideBtn />} />}
          </Routes>
        </div>
      </div>
    </Router>
  );
};

export default App;