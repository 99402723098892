import React, { useEffect, useState } from 'react'
import { validatePassword } from '../../../../../ReusableFunctions/reusableFunctions'
import { Button } from '../../../../../Components/Helper'
import { GLOBAL_STYLES } from '../../../../../appConstants'
import Popup from '../../../../../ReusableFunctions/Popup'
import Modal from '../../../../../ReusableComponents/Modal'
import { resetPasswordAPI } from '../../../../../apis/authentication'
import Loading from '../../../../../ReusableFunctions/Loader'
import Form from '../../../../../ReusableComponents/Form'
import InputField from '../../../../../ReusableComponents/InputField'
import { OTPValidation, passwordValidation, selectFieldValidation } from '../../../../../utils/inputValidations'
import ShowPassword from "../../../../../assets/Eye.svg";
import HidePassword from "../../../../../assets/EyeSlash.svg";

const ChangePasswordWithOTP = ({ open, onClose, userEmail, showForgotPasswordPopup }) => {
    const [otp, setOtp] = useState("")
    const [newPassword, setNewPassord] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const [error, setError] = useState(null)

    useEffect(() => {
        if (newPassword || confirmPassword) checkPassword()
    }, [newPassword, confirmPassword])

    const resetForm = () => {
        setOtp("");
        setNewPassord("");
        setConfirmPassword("");
    }

    const checkPassword = () => {
        return new Promise(async (resolve, reject) => {
            if (newPassword) {
                const message = validatePassword(newPassword);
                if (message) {
                    setError(message)
                    resolve(false)
                }
                else if (confirmPassword === newPassword) {
                    setError("")
                    resolve(true)
                }
                else {
                    resolve(false)
                    setError("Password and confirm password not matched.")
                }
            }
        }
        )
    }

    const updatePassword = async (otp, newPassword, confirmPassword) => {
        if ((otp && newPassword && confirmPassword && !error)) {
            try {
                Loading.set(true)
                await resetPasswordAPI(userEmail, otp, newPassword)
                onClose()
                showForgotPasswordPopup()
                resetForm()
                Loading.set(false)
                Popup.alert("Change Password", <p>
                    Congratulations ! <br />
                    We are delighted to inform you that your password has been reset successfully!
                </p>, "SUCCESS", onClose)
            } catch (e) {
                Popup.alert("Error", e || "something went wrong while changing password.", "ERROR");
                Loading.set(false)
            }
        }
    }
    return (
        <Modal
            header={'Reset password'}
            open={open}
            onClose={onClose}
            contentStyle={{ width: "495px", height: "auto" }}
            divider={true}
            headingStyle={{ fontSize: "18px" }}
            iconSize={"18px"}
            headerContainerStyle={{ padding: '20px 40px' }}
        >
            <Form
                onSubmitForm={(data) => {
                    const { otp, newPassword, confirmPassword } = data
                    if (newPassword === confirmPassword) updatePassword(otp, newPassword, confirmPassword)
                    else Popup.alert("WARNING", "Oops! The passwords you entered aren't the same. Please check and re-enter.", "ERROR");
                }}
                style={{ padding: "15px 40px 20px", display: "flex", flexDirection: "column", gap: "1.1rem" }}
            >
                {(onsubmit, formValue) =>
                    <>
                        <InputField
                            label={"E-mail OTP"}
                            name='otp'
                            type="text"
                            style={{ height: "45px", background: "#F3F3F3", border: "1px solid #BFC8D9", borderRadius: "4px" }}
                            validation={OTPValidation}
                        />
                        <InputField
                            label={"New password"}
                            name='newPassword'
                            value={formValue.newPassword}
                            type={showNewPassword ? "text" : "password"}
                            style={{ height: "45px", background: "#F3F3F3", border: "1px solid #BFC8D9", borderRadius: "4px" }}
                            validation={passwordValidation}
                            onClickIcon={() => setShowNewPassword(!showNewPassword)}
                            icon={showNewPassword ? ShowPassword : HidePassword}
                            iconStyle={{ width: "20px" }}
                        />
                        <InputField
                            label={" Confirm Password"}
                            name='confirmPassword'
                            style={{ height: "45px", background: "#F3F3F3", border: "1px solid #BFC8D9", borderRadius: "4px" }}
                            type={showConfirmPassword ? "text" : "password"}
                            validation={passwordValidation}

                            onClickIcon={() => setShowConfirmPassword(!showConfirmPassword)}
                            icon={showConfirmPassword ? ShowPassword : HidePassword}
                            iconStyle={{ width: "20px" }}
                        />
                        <div>
                            <div style={{ color: "red", padding: "5px 0px 0px 0px", minHeight: "20px", fontSize: GLOBAL_STYLES.FONT_SIZE_SMALL }}>
                                {error}
                            </div>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row-reverse", gap: "15px", paddingTop: "20px" }}>
                            <Button
                                type={"submit"}
                                text="Update"
                                style={{ width: "110px", height: "40px", fontSize: '16px' }}
                                onClick={onsubmit}
                            />
                            <Button
                                type={"button"}
                                onClick={() => { onClose() }}
                                text={"Cancel"}
                                isBtnNotActive={true}
                                style={{ width: "110px", height: "40px", fontSize: '16px' }}
                            />
                        </div>
                    </>
                }
            </Form>
        </Modal>
    )
}

export default ChangePasswordWithOTP