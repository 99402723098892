import React, { useState } from 'react'
import { Button } from '../../../Components/Helper'
import Modal from '../../../ReusableComponents/Modal';
import Form from '../../../ReusableComponents/Form';
import InputField from '../../../ReusableComponents/InputField';
import SelectField from '../../../ReusableComponents/SelectField';
import { BankAccountNoValidation, ConfirmAccountNumber, IFSCValidation, accountHolderName, bankNameValidation, branchAddressValidation, selectFieldValidation } from '../../../utils/inputValidations';
import { addDspBankDetails, updateDspBankDetails } from '../../../apis/bankDetails';
import Popup from '../../../ReusableFunctions/Popup';
import Loading from '../../../ReusableFunctions/Loader';
const { ACCOUNT_TYPES } = require("../../../appConstants")
export default function AddBankAccountPopup({
    bankdetails,
    onClose,
    onBankDetailsAdd
}) {
    const [loading, setLoading] = useState(false);
    const [bankDetails, setbankDetails] = useState(bankdetails?.id ? {
        ...bankdetails,
        account_type: { label: `${bankdetails.account_type} Account `, value: bankdetails.account_type }
    } : {
        account_holder_name: "",
        bank_name: "",
        ifsc_code: "",
        account_no: "",
        status: true,
        branch_address: "",
        account_type: null,
        confirm_account_no: "",
    })
    const handleAddBankDetails = async (bankDetails) => {
        bankDetails = { ...bankDetails, account_type: bankDetails?.account_type?.value }
        Loading.set(true)
        try {
            const data = await addDspBankDetails(bankDetails);
            Popup.alert("Bank details", data?.message, 'SUCCESS')
            console.log("after add Bank details", data);
            onBankDetailsAdd(data?.bankDetails);
        } catch (err) {
            Popup.alert("Error", err || "Something went wrong while adding bank details.", "ERROR");
        } finally {
            Loading.set(false)
        }
    }

    const handleUpdateBankDetails = async (bankDetails) => {
        bankDetails = { ...bankDetails, account_type: bankDetails?.account_type?.value }
        Loading.set(true);
        try {
            const data = await updateDspBankDetails(bankDetails?.id, bankDetails);
            Popup.alert("Bank details", data?.message, 'SUCCESS')
            console.log("after add Bank details", data);
            onBankDetailsAdd(data?.updatedDetails);
        } catch (err) {
            Popup.alert("Error", err || "Something went wrong while updating bank details.", "ERROR");
        } finally {
            Loading.set(false)
        }
    };
    return (
        <Modal
            header={bankDetails?.id ? "Edit Bank Account" : "Add Bank Account"}
            open={true}
            onClose={onClose}
            contentStyle={{ width: "725px", height: "auto", borderRadius: "10px" }}
            headerContainerStyle={{ padding: "25px 30px" }}
        >
            <Form
                onSubmitForm={(coupon) => {
                    bankDetails?.id ? handleUpdateBankDetails(coupon) : handleAddBankDetails(coupon)
                }}
                initialValues={bankDetails}
                style={{ display: 'flex', flexDirection: 'column', width: "100%", height: 'calc(100% - 75px)', padding: "20px 30px" }}
            >
                {(onSubmit, formValue) => (
                    <>
                        <div
                            id='coupen-form'
                            style={{
                                width: "100%",
                                height: '100%',
                                display: 'grid',
                                gridTemplateColumns: '1fr 1fr',
                                gridAutoRows: 'auto',
                                gap: '8px 25px',
                                overflow: 'auto'
                            }} >
                            <InputField
                                label={"Account Holder Name"}
                                placeholder={"Enter Account Holder Name "}
                                name={'account_holder_name'}
                                validation={accountHolderName}
                                type={'text'}
                            />
                            <InputField
                                label={"Bank Name"}
                                placeholder={"Enter Bank Name"}
                                name={'bank_name'}
                                validation={bankNameValidation}
                                type={'text'}
                            />
                            <SelectField
                                name={"account_type"}
                                label={"Account Type"}
                                list={[
                                    { label: "Saving Account", value: "Saving" },
                                    { label: "Current Account", value: "Current" },
                                ]}
                                styles={{ width: "215px", height: "18px", fontSize: "13px", margin: "0 0 0 -1px" }}
                                validation={selectFieldValidation}
                                containerStyle={{
                                    width: "100%",
                                    maxWidth: "100%",
                                    minHeight: "80px"
                                }}
                                isSearchable={true}
                                defaultValue={bankDetails?.id ? bankDetails?.account_type : { label: "Saving Account", value: "Saving" }}

                            />

                            <InputField
                                label={"IFSC"}
                                placeholder={"Enter IFSC Code"}
                                name={'ifsc_code'}
                                validation={IFSCValidation}
                                type={'text'}
                            />
                            <div style={{ gridColumn: '1 / span 2' }}>
                                <InputField
                                    label={"Branch Address"}
                                    placeholder={"Enter Branch Address"}
                                    name={'branch_address'}
                                    style={{ height: "40px" }}
                                    validation={branchAddressValidation}
                                    type={'text'}
                                />
                            </div>
                            <InputField
                                label={"Account Number"}
                                placeholder={"Enter Account Number"}
                                name={'account_no'}
                                validation={BankAccountNoValidation}
                                type={'text'}
                            />
                            {!bankDetails.id && (
                                <InputField
                                    label={"Confirm Account Number"}
                                    placeholder={"Confirm Bank Number"}
                                    name={'confirm_bank_no'}
                                    validation={ConfirmAccountNumber}
                                    type={'text'}
                                />

                            )}
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row-reverse",
                                padding: "10px 0px 15px",
                                gap: "20px"
                            }}
                        >
                            <Button
                                text={bankDetails?.id ? "Update" : "Add"}
                                className={"btn-large"}
                                type={"submit"}
                                onClick={onSubmit}
                            />
                            <Button
                                text={"Cancel"}
                                type={"button"}
                                onClick={onClose}
                                isBtnNotActive={true}
                                className={"btn-large "}
                            />
                        </div>
                    </>
                )}
            </Form>
        </Modal >
    )
}
