import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { AEROMEGH_SERVICES, DISCOUNT_TYPES, PRICE_BY_TYPES, SERVICE_TYPES, STATUS } from "../appConstants";
import Toggle from "react-toggle";
import { acresToSquareKilometers, squareKilometersToAcres } from "../ReusableFunctions/reusableFunctions";

const RequestDiscount = forwardRef(({ request, oldRequest, changeValue, hideToggle, style }, ref) => {
    const [toggle, setToggle] = useState(false)
    const [invoice, setInvoice] = useState({})
    const [dspDiscount, setDspDiscount] = useState({})
    const [discount, setDiscount] = useState(0)
    const [paymentSummaryCalculations, setPaymentSummaryCalculations] = useState({
        price_by: PRICE_BY_TYPES.TANK,
        pesticide_per_acre_subsidy: 0,
        service_amount_per_acre: 0,
        field_area: 0
    })
    useImperativeHandle(ref, () => ({
        getInvoice: () => invoice,
    }));
    const applyDiscount = (discountValue) => {
        const newInvoice = {
            ...invoice,
            ...(getInvoiceInDetail({
                ...request, invoice: {
                    ...request.invoice, applied_discounts: [{
                        discount: discountValue
                    }]
                }
            }))
        }
        setInvoice(newInvoice)
        // callback(newInvoice)
        setDspDiscount({ ...dspDiscount, discount: discountValue })
    }

    const calculatePercentageTotal = (value, percentage) => (value * percentage) / 100;
    const getAppliedTotalAmountOfCoupon = (service_amount, coupon) => {
        if (coupon) {
            if (coupon.is_discount_in_percentage) {
                const totalAppliedAmountOfCouponViaPercentage = calculatePercentageTotal(service_amount, coupon.discount_percentage)
                return Number(totalAppliedAmountOfCouponViaPercentage < Number(coupon.max_discount_value) ? totalAppliedAmountOfCouponViaPercentage : coupon.max_discount_value)
            } else {
                return Number(coupon.discount_cost)
            }
        } else return 0
    }

    useEffect(() => {
        if (dspDiscount?.discount) {
            setDiscount(dspDiscount.discount);
            setToggle(!(request.status == STATUS.PAID || request.status == STATUS.CANCELLED))
        }
        if (toggle) {
            document.getElementById('discount-input')?.addEventListener('wheel', function (event) {
                event.preventDefault();
            });
        }
    }, [dspDiscount?.discount, toggle])

    useEffect(() => {
        const dspDiscount = (discount ? { discount, discount_type: "dsp_request_discount" } : (request.invoice.applied_discounts.find(ad => ad.discount_type === DISCOUNT_TYPES.DSP_REQUEST_DISCOUNT))) || { discount: 0, discount_type: "dsp_request_discount" }
        setDspDiscount(dspDiscount)
        setInvoice({
            ...invoice,
            ...(getInvoiceInDetail({
                ...request,
                invoice: {
                    ...request.invoice,
                    applied_coupons: oldRequest.status !== STATUS.PAID && request.status === STATUS.PAID ? request.invoice.applied_coupons.filter(c => c.coupon_type !== "SYSTEM") : request.invoice.applied_coupons,
                    applied_discounts: [dspDiscount]
                }
            }))
        })
    }, [request.invoice, request.service_completed_area, request.status])

    const calculateTotalDiscount = (applied_discounts) => {
        return applied_discounts.reduce((total, discountObj) => {
            return total + discountObj.discount;
        }, 0);
    };

    const getInvoiceInDetail = ({ field_area, service_completed_area, price_by, invoice: { pesticide_per_acre_subsidy, service_amount, total_amount, cgst, sgst, pesticides_subsidy, service_amount_with_subsidy, applied_coupons, applied_discounts } }) => {
        service_completed_area = Number(service_completed_area)
        field_area = Number(oldRequest?.service_completed_area) || field_area
        const service_amount_per_acre = service_amount / field_area
        service_amount = service_amount_per_acre * (service_completed_area || field_area)
        pesticides_subsidy = price_by === PRICE_BY_TYPES.TANK ? (Number(pesticide_per_acre_subsidy) * Number(service_completed_area || field_area)) : Number(pesticide_per_acre_subsidy) * squareKilometersToAcres(Number(service_completed_area || field_area))
        service_amount_with_subsidy = Number(service_amount) - pesticides_subsidy
        applied_coupons = applied_coupons.filter(c => service_amount_with_subsidy >= Number(c.min_order_value))
        /// 
        const bhumeetCoupons = applied_coupons.filter(ac => ac.coupon_type === "SYSTEM")
        const dspCoupons = applied_coupons.filter(ac => ac.coupon_type === "DSP")
        const applied_dsp_coupon_total_amount = getAppliedTotalAmountOfCoupon(service_amount_with_subsidy, dspCoupons[0])
        const applied_bhumeet_coupon_total_amount = getAppliedTotalAmountOfCoupon(service_amount_with_subsidy, bhumeetCoupons[0])
        const total_discounted_amount_via_coupons = applied_bhumeet_coupon_total_amount + applied_dsp_coupon_total_amount
        const total_dsp_discount_percentage = calculateTotalDiscount(applied_discounts)
        const total_discounted_amount_via_dsp = calculatePercentageTotal(service_amount_with_subsidy, total_dsp_discount_percentage)
        //
        const service_amount_with_applied_discount_and_coupons = service_amount_with_subsidy - total_discounted_amount_via_coupons - total_discounted_amount_via_dsp
        const applied_csgt_amount = calculatePercentageTotal(service_amount_with_applied_discount_and_coupons, cgst)
        const applied_sgst_amount = calculatePercentageTotal(service_amount_with_applied_discount_and_coupons, sgst)
        const total_amount_with_applied_coupons_and_gst_and_discounts = service_amount_with_applied_discount_and_coupons + applied_csgt_amount + applied_sgst_amount
        setPaymentSummaryCalculations({
            service_amount_per_acre: (price_by == PRICE_BY_TYPES.ACRE || !price_by) ?
                acresToSquareKilometers(service_amount_per_acre) :
                (service_amount_per_acre),
            field_area: (price_by == PRICE_BY_TYPES.ACRE || !price_by) ?
                squareKilometersToAcres(service_completed_area ?? field_area) :
                service_completed_area ?? field_area,
            price_by,
            pesticide_per_acre_subsidy: (price_by == PRICE_BY_TYPES.ACRE || !price_by) ?
                acresToSquareKilometers(pesticide_per_acre_subsidy) :
                (pesticide_per_acre_subsidy),
        })

        return {
            service_amount,
            total_dsp_discount_percentage,
            applied_csgt_amount,
            pesticides_subsidy,
            applied_sgst_amount,
            service_amount_with_applied_discount_and_coupons,
            total_discounted_amount_via_dsp,
            total_amount_with_applied_coupons_and_gst_and_discounts,
            discount_amount: total_discounted_amount_via_dsp,
            total_amount: Number((total_amount_with_applied_coupons_and_gst_and_discounts).toFixed(2)),
            applied_dsp_coupon_total_amount,
            applied_bhumeet_coupon_total_amount,
            total_discounted_amount_via_coupons,
            applied_coupons,
            cgst,
            sgst
        };
    };
    return (<>
        <div style={{ height: "100%", width: "100%", ...style }}>
            {!hideToggle && <div style={{ display: "flex", width: "100%", gap: "10px", justifyContent: "left", alignItems: "center", marginBottom: "15px" }}>
                <div style={{ display: "flex", gap: "10px", width: "50%" }}>
                    <div style={{ width: "50%", fontSize: "13px", color: "#3B3B3B" }}>Discount (%) </div>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Toggle
                            height={"px"}
                            icons={false}
                            checked={toggle}
                            onChange={(e) => {
                                if (!(request.status == STATUS.PAID || request.status == STATUS.CANCELLED)) {
                                    setDiscount(0);
                                    applyDiscount(0)
                                    changeValue(0)
                                    setToggle(e.target.checked)
                                }
                            }}
                        />
                    </div>
                </div>
                <div style={{ width: "60%", height: "25px" }}>
                    {toggle &&
                        <input
                            id="discount-input"
                            type="number"
                            step="0.01"
                            max={99.99}
                            min={0}
                            onChange={(e) => {
                                if (e.target.value <= 99.99 && e.target.value >= 0 && e.target.value.length <= 5) {
                                    setDiscount(e.target.value);
                                    applyDiscount(e.target.value)
                                    changeValue(e.target.value)
                                }
                            }}
                            value={discount}
                            style={{
                                padding: "0 10px",
                                background: "#F3F3F3",
                                border: "1px solid #D9D9D9",
                                outline: "none",
                                borderRadius: "5px",
                                width: "100%",
                                fontSize: "13px",
                                color: "#1E1E1E",
                                height: "100%"
                            }}
                        />}
                </div>
            </div>}

            <div style={{ padding: "0 20px", border: "1px solid #BFC8D9", borderRadius: "7px" }}>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "10px 0", fontSize: "15px", fontWeight: "500" }}>Payment Summary</div>
                <div style={{ borderBottom: "1px solid #BFC8D9", display: "flex", flexDirection: "column", gap: "5px", paddingBottom: "5px" }}>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <div>
                            <span style={{ fontSize: "13px", color: "#3B3B3B", marginRight: '5px' }}>Service Charges</span>
                            {/* <span style={{ fontSize: "12px", color: "#848484" }}>{`(${paymentSummaryCalculations.field_area} ${(paymentSummaryCalculations?.price_by == PRICE_BY_TYPES.ACRE || !paymentSummaryCalculations?.price_by) ? "Acres" : "Tanks"} x ${(paymentSummaryCalculations.service_amount_per_acre).toFixed(2)} Rs. per ${(paymentSummaryCalculations?.price_by == PRICE_BY_TYPES.ACRE || !paymentSummaryCalculations?.price_by) ? "Acre" : "Tank"})`}</span> */}
                        </div>
                        <div style={{ fontSize: "13px", color: "#3B3B3B" }}>₹ {invoice.service_amount ? (invoice.service_amount).toFixed(2) : '00'}</div>

                    </div>
                    {(request.type === AEROMEGH_SERVICES.SPRAYING && Number(invoice?.pesticides_subsidy) != 0) && (
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <div>
                                <span style={{ fontSize: "13px", color: "#3B3B3B", marginRight: '5px' }}>Total Subsidy</span>
                                {/* <span style={{ fontSize: "12px", color: "#848484" }}>{`(${paymentSummaryCalculations.field_area} ${(paymentSummaryCalculations?.price_by == PRICE_BY_TYPES.ACRE || !paymentSummaryCalculations?.price_by) ? "Acres" : "Tanks"} x ${(paymentSummaryCalculations.pesticide_per_acre_subsidy).toFixed(2)} Rs. per ${(paymentSummaryCalculations?.price_by == PRICE_BY_TYPES.ACRE || !paymentSummaryCalculations?.price_by) ? "Acre" : "Tank"})`}</span> */}
                            </div>
                            <div style={{ fontSize: "13px", color: "#32B338" }}>-₹ {invoice?.pesticides_subsidy ? `${invoice?.pesticides_subsidy}` : '00'}</div>
                        </div>
                    )}
                    {Number(invoice.applied_dsp_coupon_total_amount) != 0 && (
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <div style={{ fontSize: "13px", color: "#3B3B3B" }}>DSP Coupon</div>
                            <div style={{ fontSize: "13px", color: "#32B338" }}>-₹ {invoice.applied_dsp_coupon_total_amount ? (invoice.applied_dsp_coupon_total_amount).toFixed(2) : "00"}</div>
                        </div>
                    )}
                    {Number(invoice.applied_bhumeet_coupon_total_amount) != 0 && (
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <div style={{ fontSize: "13px", color: "#3B3B3B" }}>Bhumeet Coupon</div>
                            <div style={{ fontSize: "13px", color: "#32B338" }}>-₹ {invoice.applied_bhumeet_coupon_total_amount ? (invoice.applied_bhumeet_coupon_total_amount).toFixed(2) : "00"}</div>
                        </div>
                    )}
                    {Number(invoice.discount_amount) != 0 && (
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <div style={{ fontSize: "13px", color: "#3B3B3B" }}>Discount</div>
                            <div style={{ fontSize: "13px", color: "#32B338" }}>-₹ {invoice.discount_amount ? (invoice.discount_amount).toFixed(2) : "00"}</div>
                        </div>
                    )}
                    {Number(invoice.applied_csgt_amount) != 0 && (
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <div style={{ fontSize: "13px", color: "#3B3B3B" }}>CGST</div>
                            <div style={{ fontSize: "13px", color: "#3B3B3B" }}>₹ {invoice?.applied_csgt_amount ? (invoice?.applied_csgt_amount).toFixed(2) : '00'}</div>
                        </div>
                    )}
                    {Number(invoice.applied_sgst_amount) != 0 && (
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <div style={{ fontSize: "13px", color: "#3B3B3B" }}>SGST</div>
                            <div style={{ fontSize: "13px", color: "#3B3B3B" }}>₹ {invoice.applied_sgst_amount ? (invoice.applied_sgst_amount).toFixed(2) : '00'}</div>
                        </div>
                    )}

                </div>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "10px 0", fontSize: "14px", fontWeight: "600" }}>
                    <div>{(request.status == STATUS.PAID || request.status == STATUS.COMPLETED) ? 'Total Amount' : 'Estimated Amount'}</div>
                    <div> ₹ {invoice.total_amount ? (invoice.total_amount).toFixed(2) : "00"}</div>
                </div>
            </div>
        </div>
    </>);
})

export default RequestDiscount;
