import React, { useEffect, useState } from 'react';
import Calender from "../assets/blueCalendar.svg"
import DatePicker from "react-datepicker";
import { Controller, useFormContext } from 'react-hook-form';
import { findInputError } from '../utils/findInputError';
import { isFormInvalid } from '../utils/isFormValid';
import InputError from './InputError';

const DatePickerField = ({ label, name, defaultValue, validation, handleChange, containerStyle, labelStyle, showTimeSelect, showTimeSelectOnly, timeIntervals, timeCaption, dateFormat, minTime, maxTime, shouldCloseOnSelect, minDate, dependentDateFields, readOnly, withPortal, portalId }) => {
    const [showDatePicker, setShowDatePicker] = useState(false);
    const {
        formState: { errors, },
        control,
        clearErrors,
        watch,
        trigger,
    } = useFormContext({
    })

    const selectedValue = watch(name);

    useEffect(() => {
        if (handleChange && selectedValue) {
            handleChange(selectedValue)
        }
        if (dependentDateFields?.length > 0) {
            trigger(dependentDateFields)
        }
    }, [selectedValue, trigger]);

    const inputError = findInputError(errors, name)
    const isInvalid = isFormInvalid(inputError)

    return (
        <div className='input_field' style={{ gap: "5px", ...containerStyle, }}>
            <label htmlFor="expiryDate" style={{ fontSize: '13px', color: '#3B3B3B', ...labelStyle }}>{label}</label>
            <div
                className='input-date'
                style={{ position: 'relative', display: 'flex', flexDirection: 'column', rowGap: '5px', width: "100%", height: "35px", border: isInvalid ? '1.4px solid red' : '1.4px solid rgba(217, 217, 217, 0.84)', }}
                onClick={() => setShowDatePicker(true)}
            >
                <Controller
                    control={control}
                    name={name}
                    defaultValue={defaultValue}
                    rules={validation}
                    render={({ field }) => (
                        <DatePicker
                            onClickOutside={() => setShowDatePicker(false)}
                            open={showDatePicker}
                            shouldCloseOnSelect={shouldCloseOnSelect}
                            readOnly={readOnly}
                            showYearDropdown
                            showMonthDropdown
                            selected={field.value}
                            minDate={minDate || new Date()}
                            showTimeSelect={showTimeSelect}
                            showTimeSelectOnly={showTimeSelectOnly}
                            timeIntervals={timeIntervals}
                            timeCaption={timeCaption}
                            dateFormat={dateFormat}
                            minTime={minTime}
                            maxTime={maxTime}
                            onSelect={(selectedOption) => {
                                if (handleChange)
                                    handleChange(selectedOption)
                            }}
                            onChange={(selectedOption) => {
                                field.onChange(selectedOption);
                                clearErrors();
                                if (handleChange)
                                    handleChange(selectedOption)
                            }}
                            className="input-date-hidden"

                        />
                    )}
                />
                <img src={Calender} alt='Calender' style={{ width: "18px", position: "absolute", right: "15px" }} />
            </div>
            {isInvalid && (
                <InputError
                    inputError={inputError}
                />
            )}
        </div>
    )
}

export default DatePickerField