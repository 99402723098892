import React, { useEffect, useState } from 'react'
import dateFormat from "dateformat";
import { getFormatedDate, getFormattedTimeFromDate, getStatusColor, reduceLongName } from '../../../../ReusableFunctions/reusableFunctions'
import { getLocation, getLocations } from '../../../../apis/locations'
import Loading from '../../../../ReusableFunctions/Loader'
import Popup from '../../../../ReusableFunctions/Popup'
import NotFounds from '../../../../ReusableComponents/NotFounds'
import { AEROMEGH_SERVICES, PRICE_BY_TYPES, STATUS } from '../../../../appConstants'
import ViewRequestPopup from '../ViewRequest/SubComponents/ViewRequestPopup'
import CustomTooltip from '../../../../ReusableComponents/CustomTooltip';
import Tippy from '@tippyjs/react';
import { useNavigate } from "react-router-dom";
import { ROUTES } from '../../../../routes';


const CalenderViewRequestTable = ({ user, requests, date }) => {
    const navigate = useNavigate();
    const [locations, setLocations] = useState([])
    const [openRequest, setOpenRequest] = useState(false)
    const [selectedRequest, setSelectedRequest] = useState(null)
    const [selectedReqLocation, setSelectedReqLocation] = useState(null)
    const getLocationsFunc = async () => {
        try {
            const locations = await getLocations()
            setLocations(locations)
            Loading.set(false)
        } catch (e) {
            Popup.alert("Error", e, "ERROR")
            Loading.set(false)
        }
    }
    const findClickRequestLocation = (locId) => {
        return locations.find(loc => loc.id === locId)
    }
    const locationByID = (locationId) => {
        return locations.find(loc => loc.id === locationId)?.name
    }

    const sqkmToAcre = (sqKm) => { return (Number(sqKm) * 247.105).toFixed(2) }

    useEffect(() => {
        getLocationsFunc()
    }, [])

    return <>
        <div style={{ width: "100%", borderRadius: "5px", border: '1px solid rgba(191, 200, 217, 1)', background: "rgba(252, 252, 252, 1)", height: "calc(100% - 32px)", color: "#1e1e1e", display: "flex", flexDirection: "column", marginTop: user?.pilotId ? "25px" : "" }}>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", borderBottom: '2px solid rgb(217 217 217 / 58%)', padding: '20px' }}>
                <div style={{ fontSize: "15px", fontWeight: 400 }}>{dateFormat(date, 'DDDD - dd mmmm yyyy')}</div>
                <div style={{ fontSize: '12px', color: 'rgba(59, 59, 59, 1)' }}>{`(Total request ${requests.length})`}</div>
            </div>
            <div style={{ overflowY: 'auto', height: "calc(100% - 50px)" }}>
                {requests && requests.length > 0 ? requests.map((request) => <div className='table-row-hover' onClick={() => {
                    setSelectedReqLocation(findClickRequestLocation(request.location_id))
                    setSelectedRequest(request)
                    setOpenRequest(true)
                    navigate(`${ROUTES.REQUESTDETAILS}`, { state: { request: request } })
                }}>
                    <div className='table-row-hover' style={{ padding: '0px 20px 0px 0px', height: "60px", borderBottom: '2px solid rgb(217 217 217 / 58%)', display: 'flex', alignItems: 'center', justifyContent: 'space-between', }}>
                        <div style={{ width: '5px', borderRadius: "5px", height: "100%", background: request.type == AEROMEGH_SERVICES.SPRAYING ? "#238E28" : request.type === AEROMEGH_SERVICES.SURVEY ? "#C26900" : request.type === AEROMEGH_SERVICES.TRAINING ? "#5648B6" : "" }}></div>
                        <div style={{ width: "25%", textAlign: "left", paddingLeft: "40px", fontWeight: 500, fontSize: '14px' }}>{request.type.split(" ")[0]}</div>
                        <div style={{ width: "25%", textAlign: "left" }}>
                            <div style={{ textAlign: "left", fontSize: '12px', color: "#3b3b3b" }}>Location</div>
                            <Tippy allowHTML={true} content={<CustomTooltip content={locationByID(request.location_id)} customStyle={{ maxWidth: "230px" }} />} placement="top-start">
                                <span > {locationByID(request.location_id)?.length > 10 ? reduceLongName(locationByID(request.location_id), 10) : locationByID(request.location_id)}</span>
                            </Tippy>
                        </div>
                        {!user?.pilotId && <div style={{ width: "25%", textAlign: "left" }}>
                            <div style={{ textAlign: "left", fontSize: '12px', color: "#3b3b3b" }}>Pilot</div>
                            <Tippy allowHTML={true} content={<CustomTooltip content={request.pilot_name} customStyle={{ maxWidth: "230px" }} />} placement="top-start">
                                <span > {request.pilot_name?.length > 10 ? reduceLongName(request.pilot_name, 10) : request.pilot_name}</span>
                            </Tippy>
                        </div>}
                        <div style={{ width: "25%", textAlign: "left", }}>
                            <div style={{ textAlign: "left", fontSize: '12px', color: "#3b3b3b" }}>Start time</div>
                            <div style={{ fontWeight: 500, fontSize: '14px' }}>{getFormattedTimeFromDate(request.time)}</div>
                        </div>
                        <div style={{ width: "25%", textAlign: "left", }}>
                            <div style={{ textAlign: "left", fontSize: '12px', color: "#3b3b3b" }}>{(request?.price_by == PRICE_BY_TYPES.ACRE || request.type !== AEROMEGH_SERVICES.SPRAYING) ? 'Acre' : 'No. of tanks'}</div>

                            <div style={{ fontWeight: 500, fontSize: '14px' }}> {request.type === AEROMEGH_SERVICES.TRAINING ? "-" : (request?.price_by == PRICE_BY_TYPES.ACRE || request.type !== AEROMEGH_SERVICES.SPRAYING) ? `${sqkmToAcre(request.field_area)} Acre` : request.field_area}</div>
                        </div>
                        <div style={{ width: "15%", textAlign: "left", color: getStatusColor(request.status, true), fontWeight: 600, fontSize: '12px' }}>{request.status || "-"}</div>
                    </div>
                </div>
                ) :
                    <NotFounds msg={"OOPS, No Requests found!"} hideBtn />
                }
            </div>
        </div>
    </>
}

export default CalenderViewRequestTable