import React, { useCallback, useEffect, useRef, useState } from "react";
import Map from "../../LeafletMap/Map";
import Modal from "../../../ReusableComponents/Modal";
import MarkerIcon from "../../../assets/marker.png";
import L from "leaflet";
import { Button, CrossCloseButton } from "../../../Components/Helper";
import { GLOBAL_STYLES, LIMITS } from "../../../appConstants";
import { addLocation, updateLocation } from "../../../apis/locations";
import Input from "../../../ReusableComponents/Input";
import Popup from "../../../ReusableFunctions/Popup";
import Loading from "../../../ReusableFunctions/Loader";
import searchIcon from "../../../assets/searchIcon.png";
import { LocationCropsManager } from "../../MyServices/SubComponents/AddService/SubComponents/LocationCropsManager";
import { addLocationCropCost, deleteLocationCrop, updateLocationCropCost } from "../../../apis/crops";
import useWindowSize from "../../../hooks/useWindowSize";
import Locations from "../../../AdminComponents/Locations/Locations";
import SearchLocation from "../../../ReusableComponents/SearchLocation";
import debounce from 'lodash/debounce';
let mapInState = undefined;
let mapMarker = undefined;
let markerRadius = undefined
const SelectLocation = ({
    onLocationChange
}) => {
    const [map, setMap] = useState()
    const [center, setCenter] = useState({ lat: 19.993982, lng: 73.790416 });
    const lastFetchTime = useRef(0);
    const [locationName, setLocationName] = useState({
        name: "Nashik",
        address:
            "Maulana Abdul Kalam Azad Rd, Renuka Nagar, Nashik, Maharashtra"
    });
    const changeMarkerPosition = (mark) => {
        mapMarker.setLatLng(mark);
    };


    function getLocationString(locationString) {
        const cleanedString = locationString.replace(/^\S+\s/, '').trim();

        return cleanedString;
    }
    const onchangePlace = (e) => {
        setLocationName((prev) => ({ ...prev, name: "", address: e.target.value }))
        e.stopPropagation();
    };

    const initMap = (map) => {
        setMap(map)
        var markerOptions = {
            title: "MyLocation",
            clickable: true,
            draggable: true,
            icon: new L.Icon({
                iconUrl: MarkerIcon,
                iconSize: [20, 28],
                iconAnchor: [10, 28],
            }),
        };
        let marker1 = new L.Marker(center, markerOptions)
            .addTo(map)
        var bounds = L.latLngBounds([[center.lat, center.lng]]);

        // Fit the map to the marker bounds
        map.fitBounds(bounds, { maxZoom: 16 });
        mapMarker = marker1;



        marker1.on("drag", (e) => {
            const latlng = e.target._latlng;
            setCenter(latlng);
            // map.fitBounds(markerRadius.getBounds(), { maxZoom: map.getZoom() });
            changeMarkerPosition(latlng);
        })

        marker1.on("dragend", (e) => {
            const latlng = e.target._latlng;
            setCenter(latlng);
            changeMarkerPosition(latlng);
        })
        map.on("click", (e) => {
            const latlng = e.latlng;
            setCenter(() => latlng)
            changeMarkerPosition(latlng);
        });
    };
    const cooldownPeriod = 4000;

    const getAddress = useCallback(async ({ lat, lng }) => {
        const now = Date.now();
        if (now - lastFetchTime.current < cooldownPeriod) {
            return;
        }
        try {
            lastFetchTime.current = now;
            const res = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&location_type=ROOFTOP&result_type=street_address&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`);
            const { results } = await res.json();
            let addressName = results[0]?.plus_code?.compound_code;
            addressName = getLocationString(addressName);
            let location = { name: addressName, address: results[0]?.formatted_address }
            location.lat = lat;
            location.lng = lng
            setLocationName((prev) => ({ name: addressName, address: results[0]?.formatted_address }));
            onLocationChange(location)
        } catch (err) {
            console.error("Error fetching address:", err);
        }
    }, []);

    const debouncedGetAddress = useCallback(
        debounce((center) => {
            getAddress(center);
        }, 1000),
        [getAddress]
    );

    useEffect(() => {
        debouncedGetAddress(center);
        return () => {
            debouncedGetAddress.cancel();
        };
    }, [center, debouncedGetAddress]);



    return (
        <>
            <form style={{ width: "100%", display: 'flex', height: 'calc(100% - 65px)', position: 'relative' }} className="create-request-location">
                <div style={{ width: "100%", position: 'relative' }}>
                    <SearchLocation
                        value={locationName.address}
                        defaultValue={locationName.address}
                        onChangePlace={onchangePlace}
                        onPlaceSelected={(place) => {
                            try {
                                let lat = place.geometry.location.lat();
                                let lng = place.geometry.location.lng();
                                let cntr = {};
                                cntr.lat = parseFloat(lat);
                                cntr.lng = parseFloat(lng);
                                setCenter(cntr);
                                setLocationName({ name: place.address_components.find(loc => loc.types[0] === "locality")?.long_name || place.formatted_address.split(",")[0], address: place.formatted_address });
                                changeMarkerPosition(cntr);
                                mapInState.setView(cntr);
                            } catch { }
                        }}
                    />

                    <Map
                        initCenter={[center?.lat, center?.lng]}
                        initZoom={13}
                        handleMapClick={() => { }}
                        maxZoom={23}
                        style={{
                            height: "98.5%",
                            borderRadius: "0 0 5px 5px",
                        }}
                        mapInitialized={(map) => {
                            mapInState = map;
                            initMap(map);
                        }}
                    />
                </div>
            </form>
        </>
    );
};

export default SelectLocation;
