import React, { useState } from 'react'
import Modal from '../../ReusableComponents/Modal'
import { Button, CrossCloseButton } from '../../Components/Helper'
import { GLOBAL_STYLES, REWARD_ORDERS_STATUSES } from '../../appConstants'
import { getStatusColor } from '../../ReusableFunctions/reusableFunctions';
import SelectComponent from '../../ReusableComponents/SelectComponent';
import { updateRewardOrderDetails } from '../../apis/BhuMeetTeams/Oparations/RewardOrders'
import Loading from '../../ReusableFunctions/Loader';
import Popup from '../../ReusableFunctions/Popup';
export default function ViewOperationOrderDetails({
    open, onClose, order, callback,
}) {
    const [status, setStatus] = useState({ label: order.status, value: order.status });
    const [isLoading, setIsLoading] = useState(false);
    const [note, setNote] = useState(undefined);

    const updateRewardOrderFunc = async () => {
        try {
            setIsLoading(true);
            const res = await updateRewardOrderDetails({ id: order.id, status: status?.value, note: note || undefined })
            callback()
            Popup.alert("Order", "Order updated successfully", "SUCCESS", () => { onClose() });
            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
            Popup.alert("Error", err, 'ERROR')
        }
    }

    return (
        <Modal
            open={open}
            onClose={onClose}
            contentStyle={{ height: "auto", width: "820px", borderRadius: "10px", overflow: "hidden" }}>
            <div style={{ height: "100%", background: "rgb(244, 244, 244)", padding: "0px 0px 20px 0px" }}>
                <div style={{ display: "flex", justifyContent: "space-between", gap: "20px", background: "#D9D9D9D9", padding: "20px" }}>

                    <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                        <div style={{ fontSize: "14px" }}>Order </div>
                    </div>
                    <CrossCloseButton onClick={onClose} />
                </div>
                <div style={{ overflowY: "auto", height: "calc(100% - 50px)", fontSize: GLOBAL_STYLES.FONT_SIZE_MEDIUM, padding: "20px" }}>
                    <div style={{ fontWeight: 600, margin: "10px 0px" }}>Order details</div>
                    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "20px" }}>
                        <div style={{ display: "flex" }}>
                            <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Order Number</div>
                            <div style={{ margin: "0px 10px" }}>:</div>
                            <div style={{}}>{order?.order_number || "-"}</div>
                        </div>

                        <div style={{ display: "flex" }}>
                            <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Shipping Address</div>
                            <div style={{ margin: "0px 10px" }}>:</div>
                            <div style={{}}>{order?.shipping_address || "-"}</div>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Redeem Points</div>
                            <div style={{ margin: "0px 10px" }}>:</div>
                            <div style={{}}>{order?.redeem_points || "-"}</div>
                        </div>

                        <div style={{ display: "flex" }}>
                            <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Note</div>
                            <div style={{ margin: "0px 10px" }}>:</div>
                            <div style={{}}>{order?.note || "-"}</div>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Product Name</div>
                            <div style={{ margin: "0px 10px" }}>:</div>
                            <div style={{}}>{order?.name || "-"}</div>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Product Points</div>
                            <div style={{ margin: "0px 10px" }}>:</div>
                            <div style={{}}>{order?.points || "-"}</div>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Quantity</div>
                            <div style={{ margin: "0px 10px" }}>:</div>
                            <div style={{}}>{order?.quantity || "-"}</div>
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Status </div>
                            <div style={{ margin: "0px 10px" }}>:</div>
                            <div >
                                <SelectComponent
                                    controlStyle={{ height: "32px", width: "200px", minHeight: "20px", border: "none", boxShadow: "0px 2px 5px 1px #6273943d", color: "hsl(0deg 0% 20% / 64%)", fontSize: "10px" }}
                                    placeholder={"Select DSP..."}
                                    menuPlacement={"bottom"}
                                    list={Object.values(REWARD_ORDERS_STATUSES)?.map(O => {
                                        return ({
                                            value: O,
                                            label: O
                                        })
                                    })}
                                    value={status}
                                    onChange={(status) => {
                                        setStatus(() => ({
                                            label: status?.label,
                                            value: status?.value
                                        }))
                                    }}
                                />
                            </div>
                        </div>
                        {order.tracking_details ? <div style={{ display: "flex" }}>
                            <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Tracking Details</div>
                            <div style={{ margin: "0px 10px" }}>:</div>
                            <div style={{}}>{order?.tracking_details || "-"}</div>
                        </div> : <>{status?.value === REWARD_ORDERS_STATUSES.COMPLETED && (<div style={{ display: "flex", alignItems: "center" }}>
                            <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Tracking Details</div>
                            <div style={{ margin: "0px 10px" }}>:</div>
                            <div style={{}}>
                                <textarea

                                    style={{
                                        width: "250px",
                                        height: "65px",
                                        padding: "10px 8px",
                                        border: "1px solid lightgray",
                                        outline: "none",
                                        resize: "none"
                                    }}
                                    onChange={(e) => { setNote(e.target.value) }}
                                    value={note}
                                    placeholder='Enter tracking details ..'

                                />
                            </div>
                        </div>)}</>}

                    </div>

                    <br />
                    <div style={{ fontWeight: 600, margin: "10px 0px" }}>Pilot Details</div>
                    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "20px" }}>
                        <div style={{ display: "flex" }}>
                            <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Name</div>
                            <div style={{ margin: "0px 10px" }}>:</div>
                            <div style={{}}>{order?.pilot_name || "-"}</div>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Email</div>
                            <div style={{ margin: "0px 10px" }}>:</div>
                            <div style={{}}>{order?.pilot_email || "-"}</div>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Contact</div>
                            <div style={{ margin: "0px 10px" }}>:</div>
                            <div style={{}}>{order?.pilot_phone || "-"}</div>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Aadhar No</div>
                            <div style={{ margin: "0px 10px" }}>:</div>
                            <div style={{}}>{order?.pilot_aadhar_number || "-"}</div>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>PAN</div>
                            <div style={{ margin: "0px 10px" }}>:</div>
                            <div style={{}}>{order?.pilot_pan_number || "-"}</div>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>State</div>
                            <div style={{ margin: "0px 10px" }}>:</div>
                            <div style={{}}>{order?.pilot_state || "-"}</div>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>City</div>
                            <div style={{ margin: "0px 10px" }}>:</div>
                            <div style={{}}>{order?.pilot_city || "-"}</div>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>PinCode</div>
                            <div style={{ margin: "0px 10px" }}>:</div>
                            <div style={{}}>{order?.pilot_pincode || "-"}</div>
                        </div>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row-reverse",
                            padding: "10px 0px 15px",
                            gap: "20px",
                            marginRight: "30px",
                            marginTop: "10px"
                        }}
                    >
                        <Button
                            isDisabled={status.value === order.status || (status.value === REWARD_ORDERS_STATUSES.COMPLETED && !note) || isLoading}
                            text={"Update"}
                            className={"btn-large"}
                            type={"submit"}
                            onClick={updateRewardOrderFunc}
                        />
                    </div>
                </div>

            </div>
        </Modal>
    )
}
