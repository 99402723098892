import React, { useState } from 'react'
import DateTimePicker from '../../../ReusableComponents/DateTimePicker';
import { GLOBAL_STYLES } from '../../../appConstants';
import { Button } from '../../../Components/Helper';
import Modal from '../../../ReusableComponents/Modal';
import Filter from "../../../assets/filter.svg";
import LoaderComponent from '../../../ReusableComponents/LoaderComponent';

const UsersFilter = ({ filter, onFilterChange, open, onClose, setOpenFilter, loading }) => {
    const [usersFilter, setUsersFilter] = useState(filter);

    const handleFilterChange = (updatedFilter) => {
        setUsersFilter(updatedFilter);
    };

    return (
        <div>
            <Modal header={"Filter"} contentStyle={{ width: "730px", height: "350px" }} headingStyle={{ fontSize: "18px", fontWeight: 600, }} headerContainerStyle={{ padding: "20px" }} open={open} onClose={onClose}  >
                {loading ? <LoaderComponent /> :
                    <>
                        <div style={{ padding: "20px 30px", display: "flex", flexDirection: "column", width: "100%", gap: "20px" }}>
                            {/* Date Filter Section */}
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%", gap: "10%" }}>
                                <DateTimePicker
                                    title="From"
                                    hideNxt={new Date()}
                                    minTime={new Date().setHours(0, 0, 0, 0)}
                                    maxTime={new Date().setHours(23, 59)}
                                    selectedDateTime={usersFilter.fromDate}
                                    onChange={(date) => {
                                        handleFilterChange({
                                            ...usersFilter,
                                            fromDate: new Date(date).toISOString()
                                        });
                                    }}
                                    containerWidth={"144px"}
                                />
                                <DateTimePicker
                                    title="To"
                                    hidePrev={usersFilter.fromDate}
                                    selectedDateTime={usersFilter.toDate}
                                    minTime={new Date(usersFilter?.fromDate).toDateString() === new Date(usersFilter?.toDate).toDateString()
                                        ? new Date(new Date(usersFilter.fromDate).setMinutes(new Date(usersFilter.fromDate).getMinutes() + 1))
                                        : new Date().setHours(0, 0, 0, 0)}
                                    maxTime={new Date().setHours(23, 59)}
                                    onChange={(date) => {
                                        date = new Date(date);
                                        date.setHours(23, 59);
                                        if (new Date(usersFilter.fromDate) < new Date(date)) {
                                            handleFilterChange({
                                                ...usersFilter,
                                                toDate: new Date(date).toISOString()
                                            });
                                        } else {
                                            let toDate = usersFilter.toDate ? new Date(usersFilter.toDate).setHours(23, 59) : new Date().setHours(23, 59)
                                            handleFilterChange({
                                                ...usersFilter,
                                                toDate: new Date(toDate).toISOString()
                                            })
                                        }
                                    }}
                                    isDisable={!(usersFilter.fromDate)}
                                    containerWidth={"144px"}
                                    toMinDate={usersFilter.fromDate}
                                />
                            </div>

                        </div>

                        <div style={{ display: "flex", justifyContent: "space-between", padding: "20px 25px", marginTop: "100px", alignItems: "center", height: '60px', gap: "20px", borderTop: "1px solid rgba(191, 200, 217, 0.5)" }}>
                            <div
                                onClick={async () => {
                                    setUsersFilter({
                                        fromDate: null,
                                        toDate: null
                                    })
                                    onFilterChange({
                                        fromDate: null,
                                        toDate: null
                                    })
                                }} style={{ width: "54px", height: "38px", color: GLOBAL_STYLES.BG_ACTIVE, fontWeight: 600, fontSize: "14px", cursor: 'pointer', display: "flex", alignItems: "center", justifyContent: "start" }}>
                                Clear all
                            </div>
                            <Button
                                text={"Apply"}
                                style={{ width: "66px", height: "38px", fontSize: "15px" }}
                                onClick={() => {
                                    onFilterChange(usersFilter)
                                    setOpenFilter(false);
                                }}
                            />
                        </div>
                    </>
                }

            </Modal>
            {

                <Button
                    innerClassName={"filter-btn-inner"}
                    iconStyle={{ width: "18px", marginRight: "10px" }}
                    text={"Filter"}
                    className={"filter-btn"}
                    icon={Filter}
                    onClick={() => {
                        setOpenFilter(true)
                    }}
                    textClassName={"user-details-filter-text"}
                />

            }
        </div>
    )
}

export default UsersFilter