import React, { useEffect, useState } from 'react'
import MainContentWrapper from '../../Components/MainContentWrapper'
import Popup from '../../ReusableFunctions/Popup'
import Loading from '../../ReusableFunctions/Loader'
import DashBoardCard from '../../features/HomePage/SubComponents/DashBoardCard/DashBoardCard'
import { PDRL_ADMIN_ROUTES } from '../../routes'
import { getAdminDashboardOverview } from '../../apis/adminAPIs/overview'

const AdminDashBoard = () => {
    const [dashBoardData, setDashBoardData] = useState({})

    const getAdminDashboardOverviewFunc = async () => {
        Loading.set(true)
        try {
            const overview = await getAdminDashboardOverview({ is_dsp_active: true })
            setDashBoardData(overview)
            Loading.set(false)
        } catch (err) {
            Loading.set(false)
            Popup.alert("Error", err, 'ERROR')
        }
    }

    useEffect(() => {
        getAdminDashboardOverviewFunc()
        localStorage.setItem('dsp_id', "admin")
    }, [])

    return (dashBoardData.dsps && <MainContentWrapper>
        <div className="dashboard-first-section-second-div" style={{ width: "100%" }} >
            <div className="dashboard-first-section-second-div-one" style={{}} >


                <DashBoardCard
                    loading={false}
                    title="Finance"
                    // icon={droneCardIcon}
                    view={PDRL_ADMIN_ROUTES.FINANCE_DASHBOARD}
                />

                <DashBoardCard
                    loading={false}
                    title="Customer Success"
                    // icon={droneCardIcon}
                    view={PDRL_ADMIN_ROUTES.SUCCESS_ENGI_DASHBOARD}
                />

                <DashBoardCard
                    loading={false}
                    title="Operations"
                    // icon={droneCardIcon}
                    view={PDRL_ADMIN_ROUTES.OPEARTIONSDASHBOARD}
                />

                <DashBoardCard
                    view={PDRL_ADMIN_ROUTES.TEAMS}
                    loading={false}
                    title="BhuMeet Teams"
                // icon={droneCardIcon}
                />
                <DashBoardCard
                    view={PDRL_ADMIN_ROUTES.MARKETINGDASHBOARD}
                    loading={false}
                    title="Marketing"
                // icon={droneCardIcon}
                />
            </div>
        </div>
        {/* <div style={{ display: "flex", width: "100%", gap: "15px", alignItems: "", height: "100%", justifyContent: "start", padding: "10px 0px" }}>
            <Orders width="100%" filter />
        </div> */}
    </MainContentWrapper>
    )
}

export default AdminDashBoard