import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router';
import Loading from '../../ReusableFunctions/Loader';
import { deleteAdminCoupon, getAllAdminCoupons, getAllAdminCouponsCount, getAllCoupons } from '../../apis/coupons';
import MainContentWrapper from '../../Components/MainContentWrapper';
import TableView from '../../ReusableComponents/TableView/TableView';
import NotFounds from '../../ReusableComponents/NotFounds';
import Plus from "../../assets/Plus.svg";
import { Button } from '../../Components/Helper';
import AddAdminCouponForm from './AddAdminCouponForm';
import search from "../../assets/search.svg"
import Popup from '../../ReusableFunctions/Popup';
import _debounce from 'lodash/debounce';
const PAGE_SIZE = 15
export default function AdminCoupons() {
    const [currentPage, setCurrentPage] = useState(1)
    const [coupons, setCoupons] = useState([]);
    const [couponsCount, setCouponsCount] = useState(0)
    const [loading, setLoading] = useState(false);
    const [openCouponPopup, setOpenCouponPopup] = useState(false);
    const [couponId, setCouponId] = useState(null)
    const [searchString, setSearchString] = useState("")
    let srNo = (currentPage - 1) * PAGE_SIZE



    const onEdit = (coupon) => {
        setCouponId(coupon.id)
        setOpenCouponPopup(true)
    }


    const deleteCoupon = async (coupon) => {
        Loading.set(true)
        try {
            await deleteAdminCoupon(coupon.id)
            setCoupons(prev => prev.filter(c => c.id !== coupon.id))
            Popup.alert("Coupon deleted", "Coupon deleted successfully", "SUCCESS")
            Loading.set(false)
        } catch (e) {
            Loading.set(false)
            console.log(e)
            Popup.alert("ERROR", "Something went wrong while deleting coupon", "ERROR")
        }
    }
    const initData = _debounce(async () => {
        await getCoupons();
        await getCouponsCount()
    }, 500)
    useEffect(() => {
        setLoading(true)
        initData()
    }, [searchString])


    async function getCouponsCount() {
        try {
            const res = await getAllAdminCouponsCount(searchString)
            setCouponsCount(Number(res.coupons_count))
            Loading.set(false);
            setLoading(false)
        } catch (err) {
            Loading.set(false);
            Popup.alert("Error", err, 'ERROR')
        }
    }
    async function getCoupons() {
        try {
            const res = await getAllAdminCoupons(PAGE_SIZE, currentPage, searchString);
            setCoupons(res.coupons);
        } catch (err) {
            Loading.set(false);
            Popup.alert("Error", err, 'ERROR')
        }
    }
    useEffect(() => {
        if (currentPage) {
            getCoupons()
        }
    }, [currentPage])
    return (
        <MainContentWrapper header={`Manage Coupons(${coupons.length > 0 ? coupons.length : 0})`}
            rightContent={coupons && coupons.length > 0 && <Button type="submit" icon={Plus} text={"Add New"} onClick={() => {
                setOpenCouponPopup(true)
            }} style={{ overflow: "hidden", padding: "4px, 24px, 4px, 24px", height: "35px", width: "120px", fontSize: "14px", fontWeight: 500 }} />}>
            {(couponId || openCouponPopup) &&
                <AddAdminCouponForm
                    onRequestClose={() => {
                        setOpenCouponPopup(false)
                        setCouponId(null)
                        getCoupons()
                    }}
                    couponId={couponId}
                />
            }
            <div style={{
                width: "100%",
                height: "93%",
                display: "flex",
                flexDirection: "column",
                position: 'relative',
            }}>

                <div style={{ position: "absolute", height: "40px", width: "360px", top: '-52px', left: '270px' }}>
                    <img src={search} alt='search icon' style={{ position: 'absolute', left: "20px", top: "10px" }} />
                    <input
                        onChange={(e) => {
                            setSearchString(e.target.value)
                            setCurrentPage(1)
                        }}
                        placeholder='Search coupons by name,code,status...'
                        style={{ width: "100%", height: '100%', borderRadius: "4px", border: "1px solid #BFC8D9", padding: "10px 10px 10px 60px", fontSize: "12px" }} />
                </div>

                {coupons && coupons.length > 0 ?
                    <>
                        <TableView
                            onStateChange={async (changedState) => {
                                const { pageNumber } = changedState;
                                setCurrentPage(() => pageNumber);
                                // getDspcouponsByPage(pageNumber);
                            }}

                            isLoading={loading}
                            loaderHeight={'500px'}
                            columns={[
                                { Header: "Coupon Code", accessor: "coupon_code", },
                                { Header: "Discount Cost", accessor: "discount_cost", },
                                { Header: "Discount (%)", accessor: "discount_percentage" },
                                { Header: "Max Discount (Rs.)", accessor: "max_discount_value" },
                                { Header: "Usage Per User", accessor: "number_of_use_per_user" },
                                { Header: "Max Redemption Count ", accessor: "max_redemption_count" },
                                { Header: "Redeem Count", accessor: "redeemed_count" },
                                { Header: "Consumed Amount", accessor: "consumed_amount" },
                                { Header: "Min Order Price (Rs.)", accessor: "min_order_value" },
                                { Header: "Coupon Status", accessor: "coupon_status" },
                            ]}
                            data={coupons}
                            pageNumber={currentPage}
                            pageSize={PAGE_SIZE}
                            count={couponsCount}

                            activities={{ onClickDelete: (coupon) => Popup.alert("WARNING", "Are you sure , you want to delete this coupon?", "WARNING", () => deleteCoupon(coupon)), onClickEdit: onEdit, canEdit: true }}

                        />
                    </>
                    :
                    coupons && coupons.length === 0 && (
                        <NotFounds msg={"OOPS, No Coupons found  !"} onBtnClick={() => {
                            // setOpenTeamsPopup(true)
                            setOpenCouponPopup(true)
                        }} />
                    )
                }
            </div>
        </MainContentWrapper>
    )
}
