import React, { useState } from 'react'
import droneActive from "../../../assets/droneActive.svg";
import lockIcon from "../../../assets/Lock.svg";
import moment from 'moment';
import Tippy from '@tippyjs/react';
import { OptionMenu } from '../../../Components/Helper';
import more from "../../../assets/more.png";
export default function CouponCard({ coupon, onCouponEdit, onCouponDelete }) {
    const [activityPopup, setActivityPopup] = useState({
        id: null,
        isOpen: false
    })
    const openActicityPopup = (id) => {
        setActivityPopup((prev) => ({
            id: id,
            isOpen: prev.id !== id || !prev.isOpen, // Close the current popup and open the clicked row
        }));
    }
    const getBackgroundImage = (coupon) => {
        let url = undefined;
        if (coupon.coupon_status == "EXPIRED") {
            url = "url('/expireCoupons.svg'";
        } else if (coupon.is_public) {
            url = "url('/publicCoupon.svg'";
        } else if (!coupon.is_public) {
            url = "url('/privateCoupons.svg')";
        }

        return url;
    }
    return (
        <div className="coupon_card" style={{
            backgroundImage: getBackgroundImage(coupon),
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            width: '100%',
            height: '100%',
        }}>
            <div className='top_section'>

                <div>
                    <h2 className={`text-[18px] font-bold ${coupon.coupon_status == 'EXPIRED' ? "text-[#848484]" : "text-[#1E1E1E]"}`}>{coupon.coupon_code}</h2>
                    <p className="text-gray-600 text-[14px]  font-[500] mt-1">{coupon.description} </p>
                </div>
                <div>
                    <Tippy offset={[10, -15]} interactive
                        onShown={(instance) => {
                            document.querySelector('[data-tippy-root]').addEventListener('click', event => {
                                instance.hide();
                            })
                        }}
                        onShow={instance => {
                            const el = document.querySelector(".scroll-container")
                            if (el)
                                el.addEventListener('scroll', event => {
                                    instance.hide()
                                })
                        }}
                        className='tippy' trigger='click' arrow={false} content={
                            <OptionMenu
                                isLeft
                                onClickDelete={(e) => {
                                    e.preventDefault()
                                    onCouponDelete(coupon)
                                }}
                                onClickEdit={(e) => {
                                    e.preventDefault()
                                    onCouponEdit(coupon)
                                }}
                            />
                        } >
                        <img id='menu' src={more} alt='more' style={{ width: "22px", cursor: "pointer" }} onClick={(e) => {
                            e.preventDefault()
                            openActicityPopup(coupon?.id)
                        }} />
                    </Tippy>
                </div>

            </div>
            <div className=''>
                <div className='dotted_divider'></div>
                <div className="flex justify-between items-end -mb-2">
                    <p className="text-sm text-[#3B3B3B]">Valid Until {" "}{moment(coupon.expiry_date).format("DD MMMM YYYY")}</p>
                    <div className="flex items-center space-x-2">
                        <div className='text-xs text-[#3B3B3B] bg-[#1E1E1E1A] px-2 py-1 rounded flex gap-[5px] items-center'>
                            {!coupon.is_public && (
                                <img src={lockIcon} alt='lock' width={15} height={15} />
                            )}
                            <span className="">{coupon.is_public ? "Public" : "Private"}</span>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}
