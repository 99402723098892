import React, { useEffect, useState } from 'react'
import MainContentWrapper from '../../Components/MainContentWrapper'
import Form from '../../ReusableComponents/Form';
import InputField from '../../ReusableComponents/InputField';
import { Button } from '../../Components/Helper';
import Loading from '../../ReusableFunctions/Loader';
import { addDspBankDetails, getBankDetails, updateDspBankDetails } from '../../apis/bankDetails';
import { BankAccountNoValidation, IFSCValidation, bankNameValidation } from '../../utils/inputValidations';
import Popup from '../../ReusableFunctions/Popup';
import LoaderComponent from '../../ReusableComponents/LoaderComponent';
import WalletCard from './subcomponent/WalletCard';
import { getDspWalletBalance } from '../../apis/wallet';

export default function MyWallet() {
    const [bankDetails, setbankDetails] = useState({
        id: null,
        bank_name: "",
        account_no: "",
        ifsc_code: "",
        status: true,
    })
    const [isEditMode, setIsEditMode] = useState(false);
    const [loading, setLoading] = useState(false);
    const [walletBalance, setwalletBalance] = useState(0);
    useEffect(() => {
        document.title = "BhuMeet - My Wallet";
        fetchInitialData();
    }, [])

    const fetchInitialData = async () => {
        Loading.set(true);
        try {
            const bankData = await getBankDetails();
            const balance = await getDspWalletBalance();
            setwalletBalance(balance)
            if (bankData) {
                setbankDetails(() => bankData)
            } else {
                setbankDetails(() => ({
                    id: null,
                    bank_name: "",
                    account_no: "",
                    ifsc_code: "",
                    status: true,
                }))
            }
            setIsEditMode(() => !bankData?.id);
        } catch (err) {
            console.log("err", err)
        } finally {
            Loading.set(false);
        }
    }
    const handleEditClick = () => {
        setIsEditMode(true);
    };

    const handleUpdateBankDetails = async (bankDetails) => {
        setLoading(true)
        try {
            const data = await updateDspBankDetails(bankDetails.id, { bank_name: bankDetails.bank_name, account_no: bankDetails.account_no, ifsc_code: bankDetails.ifsc_code, status: bankDetails.status });
            Popup.alert("Bank details", data.message, 'SUCCESS')
            setbankDetails(() => data.updatedDetails)
            setIsEditMode(false);
        } catch (err) {
            Popup.alert("Error", err || "Something went wrong while adding bank details.", "ERROR");
        } finally {
            setLoading(false);
        }
    }

    const handleAddBankDetails = async (bankDetails) => {
        setLoading(true)
        try {
            const data = await addDspBankDetails(bankDetails);
            Popup.alert("Bank details", data?.message, 'SUCCESS')
            setbankDetails(() => data?.bankDetails)
            setIsEditMode(false);
        } catch (err) {
            Popup.alert("Error", err || "Something went wrong while adding bank details.", "ERROR");
        } finally {
            setLoading(false);
        }
    }

    const AddBankDetailsFrom = () => {
        return (
            <Form
                onSubmitForm={(bankDetails) => {
                    bankDetails.id ? handleUpdateBankDetails(bankDetails) : handleAddBankDetails(bankDetails)
                }}
                initialValues={bankDetails}
            >
                {(onSubmit, formValues) => (
                    <>
                        <div
                            className={`flex flex-col gap-2`}
                        >
                            <InputField
                                label={"Bank Name"}
                                type={"text"}
                                id={"bank_name"}
                                defaultValue={bankDetails?.bank_name}
                                placeholder={"Enter bank full name"}
                                name={"bank_name"}
                                containerStyle={{
                                    width: "100%",
                                    maxWidth: "100%",
                                    minHeight: "80px",
                                }}
                                validation={bankNameValidation}
                            />
                            <InputField
                                label={"Account Number"}
                                type={"text"}
                                id={"account_no"}
                                defaultValue={bankDetails?.account_no}
                                placeholder={"Enter bank account number"}
                                name={"account_no"}
                                containerStyle={{
                                    width: "100%",
                                    maxWidth: "100%",
                                    minHeight: "80px",
                                }}
                                validation={BankAccountNoValidation}
                            />
                            <InputField
                                label={"IFSC Code"}
                                type={"text"}
                                id={"ifsc_code"}
                                defaultValue={bankDetails?.ifsc_code}
                                placeholder={"Enter bank IFSC code"}
                                name={"ifsc_code"}
                                containerStyle={{
                                    width: "100%",
                                    maxWidth: "100%",
                                    minHeight: "80px",
                                }}
                                validation={IFSCValidation}
                            />
                            {/* <ToggleField
                                label={"Active"}
                                defaultValue={bankDetails?.status}
                                name={"status"}
                                onText={"yes"}
                                offText={"No"}
                            /> */}
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row-reverse",
                                padding: "10px 30px 15px",
                                gap: "20px"
                            }}
                        >
                            <Button
                                isDisabled={loading}
                                text={bankDetails.id ? "Update" : "Add"}
                                onClick={onSubmit}
                                className={"btn-large"}
                            />
                        </div>
                    </>
                )}
            </Form>
        )
    }

    const BankDetailsView = () => {
        return (
            <div className='flex flex-col gap-6 ' >
                <div className='flex flex-col gap-1'>
                    <h6>Bank Name</h6>
                    <div className=''>{bankDetails?.bank_name}</div>
                </div>
                <div className='flex flex-col gap-1'>
                    <h6>Account Number</h6>
                    <div className=''>{bankDetails?.account_no}</div>
                </div>
                <div className='flex flex-col gap-1'>
                    <h6>IFSC Code</h6>
                    <div className=''>{bankDetails?.ifsc_code}</div>
                </div>

                {/* <div className='flex flex-col gap-1'>
                    <h6>Active</h6>
                    <div className=''>{bankDetails?.status ? "Yes" : "No"}</div>
                </div> */}
            </div>
        )
    }

    return (
        <MainContentWrapper header={`My Wallet`}>
            <div className='h-[90%] bg-white rounded-md px-24 py-4'>
                <div className='w-full py-10 px-10 flex justify-between '>
                    <div className='flex justify-start w-[50%]'>
                        <WalletCard walletBalance={walletBalance} />
                    </div>
                    <div className=' w-[50%] p-8'>
                        <div className='flex justify-between items-center '>
                            <h1 className='text-xl tracking-normal'>Bank Details</h1>
                            {!isEditMode ? (
                                <Button
                                    onClick={handleEditClick}
                                    text={bankDetails?.id ? "Edit Details" : "Add Details"}
                                    className={"btn-large text-white"}
                                />
                            ) : bankDetails.id ? <Button
                                onClick={() => setIsEditMode(false)}
                                text={"Back"}
                                isDisabled={loading}
                                className={"btn-large text-white"}
                            /> : null}
                        </div>
                        <div className='py-6'>
                            {loading ?
                                <div className='w-full relative min-h-[400px]  flex justify-center items-center'>
                                    <LoaderComponent width={"8%"} height={"8%"} />
                                </div>
                                :
                                <>
                                    {
                                        isEditMode ? (
                                            <AddBankDetailsFrom bankDetails={bankDetails} />
                                        ) : (
                                            <BankDetailsView bankDetails={bankDetails} />
                                        )}
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </MainContentWrapper>
    )
}