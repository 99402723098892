import { SERVER_URLS } from "../appConstants";
import { getToken } from "./authentication";

export const getBankDetails = async () => {
    try {
        const token = getToken();
        const response = await fetch(
            SERVER_URLS.BACKEND_SERVER + `/dsp/bankDetails`,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        const res = await response.json();
        if (!response.ok) {
            throw (res.message || "Failed to fetch bank details.");
        }
        return res;
    } catch (err) {
        console.log("err", err);
    }
}

export const addDspBankDetails = async (bankDetails) => {
    try {
        const token = getToken();
        const response = await fetch(
            SERVER_URLS.BACKEND_SERVER + `/dsp/bankDetails`,
            {
                method: "POST",
                body: JSON.stringify({ bankDetails }),
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            }
        );
        const res = await response.json();
        if (!response.ok) {
            throw (res.message || "Failed to add bank details.");
        }
        return res;
    } catch (err) {
        throw err
    }
}


export const updateDspBankDetails = async (id, bankDetails) => {
    try {
        const token = getToken();
        const response = await fetch(
            SERVER_URLS.BACKEND_SERVER + `/dsp/bankDetails/${id}`,
            {
                method: "PUT",
                body: JSON.stringify({ bankDetails }),
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            }
        );
        const res = await response.json();
        if (!response.ok) {
            throw (res.message || "Failed to update bank details.");
        }
        return res;
    } catch (err) {
        console.log("err", err);
    }
}
