import { SERVER_URLS } from "../../appConstants";
import { getToken } from "../authentication";

export const getAllSupportEmails = (pageSize, pageNumber, searchQuery) => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = getToken();
            const response = await fetch(
                SERVER_URLS.BACKEND_SERVER + `/aggregator/supportEmails?pageSize=${pageSize || ""}&pageNumber=${pageNumber || ""
                }&searchQuery=${searchQuery || ""}`,
                {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const data = await response.json();
            if (response.ok) {
                resolve(data);
            } else {
                reject(
                    data?.message || "Something went wrong while getting support emails."
                );
            }
        } catch (error) {
            reject("Something went wrong while getting support emails.");
        }
    });
}


export const addSupportEmail = (email) => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = getToken();
            const response = await fetch(
                SERVER_URLS.BACKEND_SERVER + `/aggregator/supportEmails`,
                {
                    method: "POST",
                    body: JSON.stringify(email),
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }
            );
            const data = await response.json();
            if (response.ok) {
                resolve(data);
            } else {
                reject(
                    data?.message || "Something went wrong while adding support email."
                );
            }
        } catch (err) {
            reject("Something went wrong while adding support email.");
        }
    })
}


export const deleteSupportEmail = (id) => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = getToken();
            const response = await fetch(
                SERVER_URLS.BACKEND_SERVER + `/aggregator/supportEmails/${id}`,
                {
                    method: "DELETE",
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }
            );
            const data = await response.json();
            if (response.ok) {
                resolve(data);
            } else {
                reject(
                    data?.message || "Something went wrong while deleting support email."
                );
            }
        } catch (err) {
            reject("Something went wrong while deleting support email.");
        }
    })
}

export const updateSupportEmail = (email) => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = getToken();
            const response = await fetch(
                SERVER_URLS.BACKEND_SERVER + `/aggregator/supportEmails/${email.id}`,
                {
                    method: "PUT",
                    body: JSON.stringify(email),
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }
            );
            const data = await response.json();
            if (response.ok) {
                resolve(data);
            } else {
                reject(
                    data?.message || "Something went wrong while updating support email."
                );
            }
        } catch (err) {
            reject("Something went wrong while updating support email.");
        }
    })
}

export const getAllSupportEmailsCount = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = getToken();
            const response = await fetch(
                SERVER_URLS.BACKEND_SERVER + `/aggregator/supportEmails/count`,
                {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const data = await response.json();
            if (response.ok) {
                resolve(data);
            } else {
                reject(
                    data?.message || "Something went wrong while getting support emails count."
                );
            }
        } catch (error) {
            reject("Something went wrong while getting support emails count.");
        }
    });
}