import React from 'react'
import Loading from '../../../ReusableFunctions/Loader'
import { emailValidation } from '../../../utils/inputValidations'
import { addSupportEmail, updateSupportEmail } from '../../../apis/adminAPIs/supportEmails'
import Popup from '../../../ReusableFunctions/Popup'
import { Button } from '../../../Components/Helper'
import Modal from '../../../ReusableComponents/Modal'
import InputField from '../../../ReusableComponents/InputField'
import Form from '../../../ReusableComponents/Form'

const ViewEmail = ({ open, onClose, email, onEmailEdit }) => {
    const addSupportEmailFunc = async (email) => {
        try {
            Loading.set(true)
            const addedMail = await addSupportEmail(email)
            Loading.set(false)
            Popup.alert("Support Email", "Support Email added successfully", "SUCCESS");
            onEmailEdit(addedMail?.email)
        } catch (err) {
            console.log(err);
            Loading.set(false)
            Popup.alert("Error", err.errorMessage, "ERROR");
        }
    }

    const updateSupportEmailFunc = async (email) => {
        try {
            Loading.set(true)
            const updatedMail = await updateSupportEmail(email)
            Loading.set(false)
            Popup.alert("Support Email", "Support Email updated successfully", "SUCCESS");
            onEmailEdit(updatedMail?.email)
        } catch (err) {
            console.log(err);
            Loading.set(false)
            Popup.alert("Error", err.errorMessage, "ERROR");
        }
    }
    return (
        <Modal
            header={email?.id ? "Edit Support Email" : "Add Support Email"}
            open={open}
            onClose={onClose}
            contentStyle={{ width: "496px", height: "auto", borderRadius: "10px" }}
            headerContainerStyle={{ padding: "25px 30px" }}
        >
            <Form
                onSubmitForm={(email) => {
                    email?.id ? updateSupportEmailFunc(email) : addSupportEmailFunc(email)
                }}
                initialValues={email}
            >
                {(onsubmit) => (
                    <div style={{
                        display: "grid",
                        gridTemplateColumns: "1fr",
                        gap: "5px",
                        padding: "20px",
                    }}>
                        <InputField
                            label={"Email"}
                            type={"text"}
                            id={"type"}
                            placeholder={"Support email"}
                            name={"email"}
                            validation={emailValidation}
                        />
                        <div style={{
                            display: "flex",
                            flexDirection: "row-reverse",
                            gap: "20px",
                            padding: "20px",
                            height: "70px"
                        }}>
                            <Button
                                text={email?.id ? "Update" : "Add"}
                                onClick={onsubmit}
                                className={"btn-large"}
                            />
                            <Button
                                className={"btn-large lg-cancel-btn"}
                                text={"Cancel"}
                                onClick={onClose}
                                isBtnNotActive={true}
                            />
                        </div>
                    </div>
                )}
            </Form>
        </Modal>
    )
}

export default ViewEmail