import React from 'react'
import FilterCoupons from './FilterCoupons';


export default function CouponInfo({
    couponInfo,
    setFilters,
    appliedFilter,
}) {
    return (
        <div className='second_section_wrapper'>
            <div className='coupons_info'>
                {couponInfo?.map((d, i) => {
                    return <>
                        <div className='single_coupons_info'>
                            <h3>{d.name}</h3>
                            <span>{d.count}</span>

                        </div>
                        {i <= couponInfo.length - 2 && (
                            <div className='divider'></div>
                        )}
                    </>
                })}

            </div>
            <FilterCoupons appliedFilters={appliedFilter} onFilterApply={(filters) => {
                let visibilityValue = filters?.visibility?.value == "Public" ? true : filters?.visibility?.value === "Private" ? false : filters?.visibility?.value == "Both" ? null : undefined;
                setFilters((prev) => ({
                    ...prev,
                    is_public: visibilityValue,
                    from_date: filters.from_date,
                    to_date: filters.to_date,
                    type: filters.type,
                }))

            }} />
        </div>
    )
}
