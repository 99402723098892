import { SERVER_URLS } from "../../appConstants";
import { getToken } from "../authentication";

export const getAdminDashboardOverview = ({ is_dsp_active }) => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = getToken();
            const response = await fetch(
                SERVER_URLS.BACKEND_SERVER + `/aggregator/overview?temp=true${is_dsp_active ? `&is_dsp_active=true` : ""}`,
                {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const data = await response.json();
            if (response.ok) {
                resolve(data);
            } else {
                reject(
                    data?.message || "Something went wrong while getting overview."
                );
            }
        } catch (error) {
            reject("Something went wrong while getting overview.");
        }
    });
}

export const getStatusWiseRequestCount = ({ isDspActive, services, dsps, locations, statuses, searchQuery, fromDate, toDate, isFilterByServiceTime, paymentMethods, onlyAppliedBhuMeetCouponsRequests }) => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = getToken();
            const queryParams = new URLSearchParams();

            if (fromDate && toDate) {
                queryParams.append("fromDate", new Date(fromDate).toISOString());
                queryParams.append("toDate", new Date(toDate).toISOString());
            }

            if (isDspActive !== null && isDspActive !== undefined) {
                queryParams.append("isDspActive", isDspActive);
            }

            if (isFilterByServiceTime) {
                queryParams.append("isFilterByServiceTime", true);
            }

            if (onlyAppliedBhuMeetCouponsRequests) {
                queryParams.append("onlyAppliedBhuMeetCouponsRequests", true);
            }

            if (searchQuery) {
                queryParams.append("searchQuery", searchQuery);
            }

            if (paymentMethods) {
                queryParams.append("paymentMethods", JSON.stringify(paymentMethods));
            }

            if (services?.length > 0) {
                queryParams.append("services", encodeURIComponent(JSON.stringify(services)));
            }

            if (dsps?.length > 0) {
                queryParams.append("dsps", encodeURIComponent(JSON.stringify(dsps)));
            }

            if (statuses?.length > 0) {
                queryParams.append("statuses", encodeURIComponent(JSON.stringify(statuses)));
            }

            if (locations?.length > 0) {
                queryParams.append("locations", encodeURIComponent(JSON.stringify(locations)));
            }

            const url = `${SERVER_URLS.BACKEND_SERVER}/aggregator/overview/requests?${queryParams.toString()}`;

            const response = await fetch(url, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const data = await response.json();
            if (response.ok) {
                resolve(data);
            } else {
                reject(
                    data?.message || "Something went wrong while getting overview."
                );
            }
        } catch (error) {
            reject("Something went wrong while getting overview.");
        }
    });
}

