import React, { useEffect, useState } from 'react'
import { getDroneDetails } from '../../apis/BhuMeetTeams/CustomerSuccessEngineer/drones'
import Modal from '../../ReusableComponents/Modal'
import { CrossCloseButton } from '../../Components/Helper'
import { GLOBAL_STYLES } from '../../appConstants'
import Loading from '../../ReusableFunctions/Loader'
import Popup from '../../ReusableFunctions/Popup'

const DroneDetails = ({ droneId, open, onClose }) => {
    const [droneDetails, setDroneDetails] = useState({})
    const getDroneDetailsFunc = async () => {
        try {
            Loading.set(true)
            const drone = await getDroneDetails(droneId)
            console.log("drones:", drone)
            setDroneDetails(drone)
            Loading.set(false)
        } catch (error) {
            Loading.set(false)
            console.log('error:', error)
            Popup.alert("Error", "Failed to fetch drone details.", 'ERROR')
        }
    }

    useEffect(() => {
        getDroneDetailsFunc()
    }, [droneId])

    return (
        <Modal
            open={open}
            onClose={onClose}
            contentStyle={{ height: "500px", width: "700px", borderRadius: "10px", overflow: "hidden" }}
        >
            <div style={{ height: "100%", background: "rgb(244, 244, 244)", padding: "0px 0px 20px 0px" }}>
                <div style={{ display: "flex", justifyContent: "space-between", gap: "20px", background: "#D9D9D9D9", padding: "20px" }}>
                    <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                        <div style={{ fontSize: "14px" }}>Drone Details</div>
                    </div>
                    <CrossCloseButton onClick={onClose} />
                </div>
                <div style={{ overflowY: "auto", height: "calc(100% - 50px)", fontSize: GLOBAL_STYLES.FONT_SIZE_MEDIUM, padding: "20px" }}>
                    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "20px" }}>
                        <div style={{ display: "flex" }}>
                            <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }} >Name</div>
                            <div style={{ margin: "0px 10px" }}>:</div>
                            <div style={{}}>{droneDetails?.name || "-"}</div>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }} >DSP</div>
                            <div style={{ margin: "0px 10px" }}>:</div>
                            <div style={{}}>{droneDetails?.dsp_name || "-"}</div>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }} >Location</div>
                            <div style={{ margin: "0px 10px" }}>:</div>
                            <div style={{}}>{droneDetails?.location_name || "-"}</div>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }} >Model Name</div>
                            <div style={{ margin: "0px 10px" }}>:</div>
                            <div style={{}}>{droneDetails?.model_name || "-"}</div>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }} >UIN</div>
                            <div style={{ margin: "0px 10px" }}>:</div>
                            <div style={{}}>{droneDetails?.drone_uin || "-"}</div>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }} >Type</div>
                            <div style={{ margin: "0px 10px" }}>:</div>
                            <div style={{}}>{droneDetails?.type || "-"}</div>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }} >Manufacturer</div>
                            <div style={{ margin: "0px 10px" }}>:</div>
                            <div style={{}}>{droneDetails?.manufacturer || "-"}</div>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }} >Battery Capacity</div>
                            <div style={{ margin: "0px 10px" }}>:</div>
                            <div style={{}}>{droneDetails?.battery_capacity || "-"}</div>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }} >Endurance</div>
                            <div style={{ margin: "0px 10px" }}>:</div>
                            <div style={{}}>{droneDetails?.endurance || "-"}</div>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }} >Service Type</div>
                            <div style={{ margin: "0px 10px" }}>:</div>
                            <div style={{}}>{droneDetails?.service_type || "-"}</div>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }} >Tank Capacity</div>
                            <div style={{ margin: "0px 10px" }}>:</div>
                            <div style={{}}>{droneDetails?.tank_capacity || "-"}</div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default DroneDetails