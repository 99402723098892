import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { useState, useEffect } from "react";
import searchIcon from "../assets/searchIcon.png";
const SearchLocation = ({ placeHolder, value, defaultValue, onChangePlace, onPlaceSelected, style }) => {
    const [list, setList] = useState([])
    const {
        placesService,
        placePredictions,
        getPlacePredictions,
        isPlacePredictionsLoading,
    } = usePlacesService({
        apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    });

    const handleSelectLocation = ({ description, place_id }) => {
        if (place_id) {
            setList([])
            placesService?.getDetails(
                {
                    placeId: place_id,
                },
                (placeDetails) => {
                    onPlaceSelected(placeDetails)
                }
            );
        }
    }
    useEffect(() => {
        if (placePredictions) {
            setList(placePredictions)
        }
    }, [placePredictions?.[0]])
    return <div style={{
        position: "absolute",
        left: '10px',
        top: '10px',
        zIndex: 999,
        width: '95%',
        ...style
    }}>
        <div
            style={{
                display: "flex",
                width: "100%",
                height: "38px",
                alignItems: "center",
                background: "white",
                border: "1px solid rgb(217, 217, 217)",
                borderRadius: "5px",
                paddingRight: "10px",
                position:'relative'
            }}
        >
            <img
                src={searchIcon}
                style={{
                    height: "13px",
                    background: "white",
                    padding: "0 10px",
                }}
            />
            <input
                defaultValue={defaultValue}
                value={value}
                style={{ width: '100%', outline: 'none', fontSize: '12px', }}
                placeholder={placeHolder || "Enter place to search..."}
                onChange={(evt) => {
                    getPlacePredictions({ input: evt.target.value });
                    onChangePlace(evt)
                }}
                loading={isPlacePredictionsLoading}
            />

            {list?.length > 0 && !isPlacePredictionsLoading && <div style={{
                display: 'flex', flexDirection: 'column',
                background: 'white',
                fontSize: '12px',
                fontWeight: 500,
                border: "1px solid rgb(217, 217, 217)",
                borderRadius: "5px",
                top: "45px",
                width: '100%',
                position: 'absolute'
            }}>
                {list.map((item) => {
                    const { structured_formatting, description } = item
                    return <div
                        onClick={() => {
                            handleSelectLocation(item)
                        }}
                        className="search-location-item"
                        style={{ padding: "10px 20px", color: '#515151 ', cursor: 'pointer' }}
                    >
                        <span style={{ fontWeight: 700, color: "#2b2b2b", fontSize: '13px', width: "100%", marginRight: '5px' }}>{structured_formatting.main_text}</span>
                        <span style={{ width: '100%', fontSize: '11px' }}>{structured_formatting.secondary_text}</span>
                    </div>
                })}
            </div>}
        </div>

    </div>

}
export default SearchLocation