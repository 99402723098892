
import { buildUrl } from "../../ReusableFunctions/reusableFunctions";
import { SERVER_URLS } from "../../appConstants";
import { getToken } from "../authentication";

export const getTeams = async (pageNumber, pageSize) => {
    try {
        const token = getToken();
        const url = buildUrl(`${SERVER_URLS.BACKEND_SERVER}/bhumeet_team/admin/manage_team`, {
            pageSize: pageSize && pageNumber ? pageSize : undefined,
            pageNumber: pageSize && pageNumber ? pageNumber : undefined
        });
        const response = await fetch(
            url,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        const res = await response.json();
        if (!response.ok) {
            throw (res.message);
        }
        return res?.teamMembers;
    } catch (err) {
        throw err;
    }
}

export const addTeamMember = async (operator) => {
    try {
        const token = getToken();
        const response = await fetch(
            SERVER_URLS.BACKEND_SERVER + `/bhumeet_team/admin/manage_team`,
            {
                method: "POST",
                body: JSON.stringify(operator),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },

            }
        );
        const res = await response.json();
        if (!response.ok) {
            throw (res.message);
        }
        return res?.user;
    } catch (err) {
        throw err;
    }
}

export const updateTeamMember = async (id, operator) => {
    try {
        const token = getToken();
        const response = await fetch(
            SERVER_URLS.BACKEND_SERVER + `/bhumeet_team/admin/manage_team/${id}`,
            {
                method: "PUT",
                body: JSON.stringify(operator),
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${getToken()}`,
                },

            }
        );
        const res = await response.json();
        if (!response.ok) {
            throw (res.message);
        }
        return res?.updatedOperator;
    } catch (err) {
        throw err;
    }
}


export const deleteTeamMember = async (id) => {
    try {
        const token = getToken();
        const response = await fetch(
            SERVER_URLS.BACKEND_SERVER + `/bhumeet_team/admin/manage_team/${id}`,
            {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${getToken()}`,
                },

            }
        );
        const res = await response.json();
        if (!response.ok) {
            throw (res.message);
        }
        return res?.deletedOperator;
    } catch (err) {
        throw err;
    }
}