import { useEffect, useState } from "react"
import { PAYMENT_METHODS } from "../../../appConstants"


const PaymentMethodRadioButtons = ({
    onChangePaymentMethods,
    activePaymentMethods,
    isDisabled
}) => {

    useEffect(() => {
        if (isDisabled) onChangePaymentMethods(null)
        else onChangePaymentMethods(activePaymentMethods || [PAYMENT_METHODS.OFFLINE, PAYMENT_METHODS.UPI])
    }, [isDisabled])

    return <>
        <div style={{ fontSize: "16px", padding: "0 27px", fontWeight: 600, color: 'rgba(59, 59, 59, 0.8)', display: "flex", alignItems: 'center' }}>Payment methods</div>
        <div style={{ padding: "10px 30px", display: "flex", justifyContent: "space-between", alignItems: "center", width: "60%" }}>
            <label style={{ fontSize: "16px", fontWeight: 500, display: "flex", alignItems: "center", gap: "10px" }}>
                <input
                    type="radio"
                    name="dspStatus"
                    disabled={isDisabled}
                    value={PAYMENT_METHODS.UPI}
                    checked={activePaymentMethods?.length === 1 && activePaymentMethods[0] === PAYMENT_METHODS.UPI}
                    onChange={(e) => {
                        onChangePaymentMethods([PAYMENT_METHODS.UPI])
                    }}
                    style={{ cursor: isDisabled ? "not-allowed" : "pointer" }}
                />
                {PAYMENT_METHODS.UPI}
            </label>
            <label style={{ fontSize: "16px", fontWeight: 500, display: "flex", alignItems: "center", gap: "10px" }}>
                <input
                    type="radio"
                    name="dspStatus"
                    disabled={isDisabled}
                    value={PAYMENT_METHODS.OFFLINE}
                    checked={activePaymentMethods?.length === 1 && activePaymentMethods[0] === PAYMENT_METHODS.OFFLINE}
                    onChange={(e) => {
                        onChangePaymentMethods([PAYMENT_METHODS.OFFLINE])
                    }}
                    style={{ cursor: isDisabled ? "not-allowed" : "pointer" }}
                />
                Cash
            </label>
            <label style={{ fontSize: "16px", fontWeight: 500, display: "flex", alignItems: "center", gap: "10px" }}>
                <input
                    type="radio"
                    name="dspStatus"
                    value={null}
                    disabled={isDisabled}
                    checked={activePaymentMethods?.length === 2}
                    onChange={(e) => {
                        console.log(e)
                        onChangePaymentMethods([PAYMENT_METHODS.OFFLINE, PAYMENT_METHODS.UPI])
                    }}
                    style={{ cursor: isDisabled ? "not-allowed" : "pointer" }}
                />
                Both
            </label>
        </div>
    </>
}

const IsBhuMeetCouponApplied = ({
    onlyAppliedBhuMeetCouponsRequests,
    onChangeOnlyAppliedBhuMeetCouponsRequests,
}) => {
    return <>
        <div style={{ fontSize: "15px", fontWeight: 500, color: '#3B3B3B', display: "flex", alignItems: 'center' }}>
            <input
                style={{ margin: "0 10px", width: '16px' }}
                checked={onlyAppliedBhuMeetCouponsRequests}
                onChange={(e) => {
                    onChangeOnlyAppliedBhuMeetCouponsRequests(e.target.checked)
                }}
                value={onlyAppliedBhuMeetCouponsRequests}
                type={"checkbox"}
                className="input-checkbox"
            />BhuMeet Coupons applied requests</div>
    </>
}

export { PaymentMethodRadioButtons, IsBhuMeetCouponApplied }