import React, { useEffect, useState } from 'react'
import currencyIcon from '../../../assets/currency.svg'

const DspBankCard = ({ bankDetails }) => {
    return (
        <div className="bank_Details_info" >
            <div className='bankdetails_wrppaer'>
                <h3 className='heading'>Bank Details</h3>
                <div className='bank_details'>
                    <p>{bankDetails?.account_holder_name || ""}</p>
                    <p>{bankDetails?.bank_name || ""}</p>
                    <p><span>Acc No{" "}-</span>{bankDetails?.account_no || 0}</p>
                    <p><span>IFSC {" "}-</span>{bankDetails?.ifsc_code || ''}</p>
                </div>
            </div>
            <div className='first_currency_sign'>
                <img src={currencyIcon} alt='' width={"50px"} height={"50px"} />
            </div>
            <div className='second_currency_sign'>
                <img src={currencyIcon} alt='' width={"60px"} height={"60px"} />
            </div>
        </div >
    )
}

export default DspBankCard