import { SERVER_URLS } from "../../../appConstants";
import { buildUrl } from "../../../ReusableFunctions/reusableFunctions";
import { getToken } from "../../authentication";

const USER_INVOICES_BASE_URL = `${SERVER_URLS.BACKEND_SERVER}/bhumeet_team/finance/dsp/userInvoices`

export const getUserInvoices = async (fromDate, toDate, pageSize, pageNumber,orderType, statuses, searchString, paymentMode) => {
    try {
        const token = getToken();
        const url = buildUrl(USER_INVOICES_BASE_URL, {
            fromDate: fromDate ? fromDate : undefined,
            toDate: toDate ? toDate : undefined,
            pageSize: pageSize && pageNumber ? pageSize : undefined,
            pageNumber: pageSize && pageNumber ? pageNumber : undefined,
            orderType: orderType ? orderType : undefined,
            statuses: statuses ? statuses.toString() : undefined,
            searchString: searchString ? searchString : undefined,
            paymentMode: paymentMode ? paymentMode : undefined
        });
        const response = await fetch(
            url,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        const res = await response.json();
        if (!response.ok) {
            throw (res.message);
        }
        return res?.userInvoices;
    } catch (err) {
        throw err;
    }
}

export const getUserInvoicesStats = async (fromDate, toDate, searchString, paymentMode) => {
    try {
        const token = getToken();
        const url = buildUrl(USER_INVOICES_BASE_URL + '/stats', {
            fromDate: fromDate ? fromDate : undefined,
            toDate: toDate ? toDate : undefined,
            searchString: searchString ? searchString : undefined,
            paymentMode: paymentMode ? paymentMode : undefined
        });
        const response = await fetch(
            url,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        const res = await response.json();
        if (!response.ok) {
            throw (res.message);
        }
        return res?.invoicesStats;
    } catch (err) {
        throw err;
    }
}

export const getUserInvoiceDetails = async (requestId) => {
    try {
        const token = getToken();
        const url = USER_INVOICES_BASE_URL + `/${requestId}`
        const response = await fetch(
            url,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        const res = await response.json();
        if (!response.ok) {
            throw (res.message);
        }
        return res?.invoice;
    } catch (err) {
        throw err;
    }
}