import React, { useEffect, useState } from 'react'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { getOrdersGraphAPI } from '../../../../apis/dashboard';
import Popup from '../../../../ReusableFunctions/Popup';
import LoaderComponent from '../../../../ReusableComponents/LoaderComponent';
import { GLOBAL_STYLES } from '../../../../appConstants';
import { getAllOrdersGraph } from '../../../../apis/adminAPIs/graphs';
import SelectComponent from '../../../../ReusableComponents/SelectComponent';
import NotFounds from '../../../../ReusableComponents/NotFounds';
import noRequest from '../../../../assets/no-request.svg'
import Loading from '../../../../ReusableFunctions/Loader';
import { useSelector } from 'react-redux';
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    Title,
    Tooltip,
    Legend
);
const Orders = ({ filter, showAll, width, maxWidth }) => {
    const user = useSelector(state => state.user?.user)
    const weekLabels = [
        "SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT",
    ]
    const currDate = new Date()
    const monthLabels = getDaysInMonth(currDate.getFullYear(), currDate.getMonth())
    const yearlabels = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEPT", "OCT", "NOV", "DEC"]
    const [serviceType, setServiceType] = useState({ value: showAll ? 'All' : 'spraying_service_requests', label: showAll ? "All" : "Spraying" },)

    const [interval, setInterval] = useState({ value: 'Week', label: "This week" },)
    const [loading, setLoading] = useState(true)
    const [options, setOptions] = useState({
        maintainAspectRatio: false,
        aspectRatio: 1,
        tooltips: {
            filter: function (tooltipItem, data) {
                var label = data.labels[tooltipItem.index];
                if (label == "Total") {
                    return false;
                } else {
                    return true;
                }
            }
        },
        plugins: {
            title: {
                display: false,
                text: "Orders",
                position: "top", // Align the title text to the top
                align: "start", // Align the title text to the left within the top position
            },
            legend: {
                display: false,
            },
            centerText: false, // Enable the center text plugin
        },
        responsive: true,
        scales: {
            y: {
                beginAtZero: true,
                grid: {
                    display: false,
                },
                ticks: {
                    stepSize: 10,
                },
            },
            x: {
                grid: {
                    display: false,
                }
            },
        },
    })
    const [data, setData] = useState({
        labels: weekLabels,
        datasets: [
            {
                label: "Orders",
                data: [1, 3, 2, 0, 0, 0, 0],
                backgroundColor: "#0F5EFF",
                borderRadius: 50,
                barThickness: 10,
                base: 1,
                borderSkipped: false,
            },
            {
                label: "Total",
                data: [100, 100, 100, 100, 100, 100, 100],
                backgroundColor: "rgba(15, 94, 255, 0.1)",
                borderRadius: 50,
                barThickness: 10,
                base: 0,
                grouped: false,
                borderSkipped: false,
            },
        ],
    })

    function getDaysInMonth(year, month) {
        // Month is zero-based, so decrement it by 1
        var lastDay = new Date(year, month, 0).getDate();
        var daysArray = [];

        for (var day = 1; day <= lastDay; day++) {
            daysArray.push(day);
        }

        return daysArray;
    }
    const getOrdersGraphFunc = async () => {
        try {
            if (interval.value) {
                const orders = user.is_pdrl_admin ? await getAllOrdersGraph(serviceType.value, interval.value) : await getOrdersGraphAPI(serviceType.value, interval.value)
                Loading.set(false)
                const maxOrder = Math.max(...orders)
                const labels = interval.value === "Week" ? weekLabels : interval.value === "Month" ? monthLabels : interval.value === "Year" ? yearlabels : []
                setOptions({
                    maintainAspectRatio: false,
                    aspectRatio: 1,
                    tooltips: {
                        filter: function (tooltipItem, data) {
                            var label = data.labels[tooltipItem.index];
                            if (label == "Total") {
                                return false;
                            } else {
                                return true;
                            }
                        }
                    },
                    plugins: {
                        tooltip: {
                            backgroundColor: "#ffffff",
                            bodyColor: "#000",
                            borderColor: "#d9d9d9d9",
                            borderWidth: 2,
                            titleColor: "#000"
                        }
                        ,
                        title: {
                            display: false,
                            text: "Orders",
                            position: "top", // Align the title text to the top
                            align: "start", // Align the title text to the left within the top position
                        },
                        legend: {
                            display: false,
                        },
                        centerText: false, // Enable the center text plugin
                    },
                    responsive: true,
                    scales: {
                        y: {
                            beginAtZero: true,
                            ticks: {
                                color: "#b3b1b1",
                                stepSize: 10,
                                font: {
                                    size: 10,
                                }
                            },
                            grid: {
                                drawTicks: false,
                            },
                            border: {
                                dash: [5, 10],
                            },
                        },
                        x: {
                            grid: {
                                display: false,
                            },
                            ticks: {
                                color: "#727272",
                                font: {
                                    size: 9,
                                }
                            }
                        },
                    },
                })
                setData({
                    labels,
                    datasets: [
                        {
                            label: "Orders",
                            data: orders,
                            backgroundColor: "#0F5EFF",
                            borderRadius: 50,
                            barThickness: 10,
                            base: 0,
                            borderSkipped: false,
                        },
                    ],
                })

                setLoading(false)
            }

        } catch (e) {
            Popup.alert("Error", e, "ERROR");
        }
    }
    useEffect(() => {
        setLoading(true)
        getOrdersGraphFunc()
        let sum = data.datasets[0].data.reduce((acc, curr) => { return acc + curr }, 0);
    }, [serviceType.value, interval.value])
    return <div style={{
        width: width || "100%", maxWidth: maxWidth || "100%", height: "100%", background: "#FCFCFC", boxShadow: ' 0px 0px 8px 1px rgba(117, 117, 117, 0.15)', borderRadius: "15px", padding: "40px 20px 20px", position: "relative", display: "flex", flexDirection: "column",
    }}>
        <div style={{ position: "absolute", display: "flex", justifyContent: "space-between", width: "95%", top: "17px", left: "17px", fontSize: GLOBAL_STYLES.FONT_SIZE_SMALL }}>
            <div className="orders-cards-heading dashboard-graph-heading" >Orders</div>
            {
                filter &&
                <div style={{ display: "flex", gap: "0.8rem", alignItems: "center", position: "absolute", right: 0 }}>
                    <SelectComponent
                        controlStyle={{ height: "20px", width: "80px", minHeight: "20px", border: "none", boxShadow: "0px 2px 5px 1px #6273943d", color: "hsl(0deg 0% 20% / 64%)", fontSize: "10px" }}
                        valueControlStyle={{ padding: "2px 8px 3px 8px", margin: "0" }}
                        dropdownIndicatorStyle={{ padding: "0", margin: "0" }}
                        indicatorContainerStyle={{ width: "16px" }}
                        optionStyle={{ fontSize: '10px', display: "flex", alignItems: "center", padding: "0px 10px", height: "20px", minHeight: "20px" }}
                        singleValueStyle={{ color: "hsl(0deg 0% 20% / 64%)" }}
                        value={serviceType}
                        onChange={(selected) => setServiceType(selected)}
                        list={[
                            { value: "All", label: "All" },
                            { value: 'spraying_service_requests', label: "Spraying" },
                            { value: 'survey_service_requests', label: "Survey" },
                        ]}
                    />
                    <SelectComponent
                        controlStyle={{ height: "20px", minHeight: "20px", border: "none", boxShadow: "0px 2px 5px 1px #6273943d", color: "hsl(0deg 0% 20% / 64%)", fontSize: "11px" }}
                        valueControlStyle={{ padding: "2px 8px 4px 8px", margin: "0" }}
                        dropdownIndicatorStyle={{ padding: "0", margin: "0" }}
                        indicatorContainerStyle={{ width: "16px" }}
                        optionStyle={{ fontSize: '10px', display: "flex", alignItems: "center", padding: "0px 10px", height: "20px", minHeight: "20px" }}
                        singleValueStyle={{ color: "hsl(0deg 0% 20% / 64%)" }}
                        value={interval}
                        onChange={(selected) => setInterval(selected)}
                        list={[
                            { value: 'Week', label: "This Week" },
                            { value: 'Month', label: "This Month" },
                            { value: 'Year', label: "This Year" },
                        ]}
                    />
                </div>
            }
        </div>
        {data.datasets[0].data.reduce((acc, curr) => { return acc + curr }, 0) ?
            <>{loading ? <LoaderComponent />
                : <div className='canvas_container' style={{ padding: "10px 10px 0px 15px", height: "100%" }}>< Bar data={data} options={options} /></div>} </>
            : <NotFounds imgUrl={noRequest} msg={"Unfortunately , we don't have any data to show you , data will appear once you start getting request"} hideBtn imgStyle={{ width: "100px" }} />
        }
    </div>
}

export default Orders