import { buildUrl } from "../ReusableFunctions/reusableFunctions";
import { SERVER_URLS } from "../appConstants";
import { getToken } from "./authentication";

export const getDspWalletBalance = async () => {
    try {
        const token = getToken();
        const response = await fetch(
            SERVER_URLS.BACKEND_SERVER + `/dsp/wallet/balance`,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        const res = await response.json();
        if (!response.ok) {
            throw (res.message || "Failed to fetch bank details.");
        }
        return res?.walletBalance;
    } catch (err) {
        throw err;
    }
}


export const getWalletTransactions = async ({ from_date, to_date, searchString, pageSize, pageNumber }) => {

    try {
        const token = getToken();
        const url = buildUrl(`${SERVER_URLS.BACKEND_SERVER}/dsp/wallet/transactions`, {
            search: searchString ? searchString : undefined,
            fromDate: from_date ? from_date : undefined,
            toDate: to_date ? to_date : undefined,
            pageSize: pageSize && pageNumber ? pageSize : undefined,
            pageNumber: pageSize && pageNumber ? pageNumber : undefined
        });
        const response = await fetch(
            url,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        const res = await response.json();
        if (!response.ok) {
            throw (res.message || "Failed to fetch bank details.");
        }
        return res?.dspTransactions;
    } catch (err) {
        throw err;
    }
}


export const getWalletTransactionsCount = async () => {

    try {
        const token = getToken();
        const response = await fetch(`${SERVER_URLS.BACKEND_SERVER}/dsp/wallet/count`,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        const res = await response.json();
        if (!response.ok) {
            throw (res.message || "Failed to fetch bank details.");
        }
        return res.transactionCount;
    } catch (err) {
        throw err;
    }
}

