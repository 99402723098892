import React, { useEffect, useState } from 'react';
import Input from './Input';
import { Controller, useFormContext } from 'react-hook-form';
import { findInputError } from '../utils/findInputError';
import { isFormInvalid } from '../utils/isFormValid';
import InputError from './InputError';
import { capitalizeFirstLetter } from '../ReusableFunctions/reusableFunctions';

const TextAreaField = ({ label, type, id, placeholder, name, validation, style, containerStyle, labelStyle, disabled, handleChange, defaultValue }) => {
    const {
        register,
        formState: { errors },
        control,
        clearErrors,
        watch,
        setValue,
        setError
    } = useFormContext();
    const selectedValue = watch(name);

    const [prevValue, setPrevValue] = useState("")

    useEffect(() => {
        if (handleChange) {
            handleChange(selectedValue);
        }
    }, [selectedValue]);

    const handleInputChange = (event) => {
        const value = event.target.value;
        const transformedValue = validation?.isUpperCase ? value.toUpperCase() : value;

        if (transformedValue && validation?.format?.value) {
            if (validation?.format?.value.test(transformedValue)) {
                setValue(name, transformedValue, { shouldValidate: true });
                setPrevValue(transformedValue)
                clearErrors(name);
            }
            else {
                setError(name, {
                    type: 'manual',
                    message: validation?.format?.message,
                });
                setValue(name, prevValue, { shouldValidate: false });
            }
        } else {
            setValue(name, transformedValue, { shouldValidate: true });
            clearErrors(name);
            setPrevValue(transformedValue)
        }
    };

    const inputError = findInputError(errors, name);
    const isInvalid = isFormInvalid(inputError);

    return (
        <div style={{ position: 'relative', display: 'flex', flexDirection: 'column', rowGap: '5px', ...containerStyle }}>
            <div style={{ fontSize: '13px', color: '#3B3B3B', ...labelStyle }}>{capitalizeFirstLetter(label)}</div>
            <Controller
                name={name}
                control={control}
                defaultValue={defaultValue}
                rules={validation}
                render={({ field }) => (
                    <textarea
                        {...field}
                        id={id}
                        placeholder={placeholder}
                        disabled={disabled}
                        style={{
                            fontSize: '13px',
                            minHeight: '35px',
                            border: isInvalid ? '1.4px solid red' : '1.4px solid rgba(217, 217, 217, 0.84)',
                            opacity: disabled ? 0.6 : 1,
                            padding: '6px 8px',
                            background: "#F3F3F3",
                            outline: "none",
                            resize: "none",
                            ...style
                        }}
                        maxLength={validation?.maxLength?.value}
                        onChange={handleInputChange}
                    />
                )}
            />
            {isInvalid ? (
                <InputError
                    inputError={inputError}
                />
            ) : <div style={{ height: "12px" }} />}

        </div>
    );
};

export default TextAreaField;
