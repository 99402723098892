import { useLocation } from "react-router"
import bhumeet from "../../../assets/bhumeet.svg"
import { useEffect, useState } from "react"
import Loading from "../../../ReusableFunctions/Loader"
import Popup from "../../../ReusableFunctions/Popup"
import dateFormat from 'dateformat';
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import dashLine from "../../../assets/dashLine.svg"
import { formatToIndianRupees, squareKilometersToAcres } from "../../../ReusableFunctions/reusableFunctions"
import { getUserInvoiceDetails } from "../../../apis/adminAPIs/finance/userInvoices"
import { getInvoiceInDetail } from "../../../ReusableFunctions/RequestInvoice"
import { AEROMEGH_SERVICES } from "../../../appConstants"

const UserInvoiceDetails = () => {
    const { dsp, invoice } = useLocation()?.state
    const [settInvoice, setSettInvoice] = useState({})
    const [userInvoice, setUserInvoice] = useState({})

    const getUserInvoiceDetailsFunc = async () => {
        try {
            Loading.set(true)
            const userInvoice = await getUserInvoiceDetails(invoice?.request_id)
            if (userInvoice?.request?.service_completed_area && userInvoice?.request_type === AEROMEGH_SERVICES.SPRAYING) {
                const sprayedAcres = userInvoice?.request?.price_by === 'acre' ? `${squareKilometersToAcres(userInvoice?.request?.service_completed_area)}` : Number(userInvoice?.request?.service_completed_area)
                userInvoice.perAcreServiceAmount = Number(userInvoice.service_amount) / sprayedAcres
                userInvoice.sprayedAcres = sprayedAcres
            }
            setUserInvoice(userInvoice)
            const { pesticide_per_acre_subsidy, service_amount, total_amount, cgst, sgst, pesticides_subsidy, service_amount_with_subsidy, applied_coupons, applied_discounts } = userInvoice
            const { field_area, service_completed_area, price_by } = userInvoice?.request
            const involiceDetails = await getInvoiceInDetail(field_area, service_completed_area, price_by, pesticide_per_acre_subsidy, service_amount, total_amount, cgst, sgst, pesticides_subsidy, service_amount_with_subsidy, applied_coupons, applied_discounts)
            setSettInvoice(involiceDetails)
            Loading.set(false)
        } catch (error) {
            console.log('error:', error)
            Loading.set(false)
            Popup.alert("Error", error, "ERROR")
        }
    }

    const downloadPDF = () => {
        const input = document.querySelector('.myDiv');

        html2canvas(input, {
            scale: 3,
            useCORS: true,
            backgroundColor: null,
        }).then(canvas => {
            const imgWidth = 210;
            const pageHeight = 297;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            const heightLeft = imgHeight;

            const pdf = new jsPDF({
                orientation: 'portrait',
                unit: 'mm',
                format: 'a4',
            });

            let position = 0;

            pdf.addImage(
                canvas.toDataURL('image/png'),
                'PNG',
                0,
                position,
                imgWidth,
                imgHeight
            );

            let remainingHeight = heightLeft;
            while (remainingHeight > pageHeight) {
                position -= pageHeight;
                pdf.addPage();
                pdf.addImage(
                    canvas.toDataURL('image/png'),
                    'PNG',
                    0,
                    position,
                    imgWidth,
                    imgHeight
                );
                remainingHeight -= pageHeight;
            }

            pdf.save('invoice.pdf');
        });
    };

    useEffect(() => {
        getUserInvoiceDetailsFunc()
    }, [])

    const TermsOfService = () => {
        return (
            <div style={{ fontSize: '11px', color: '#848484' }}>
                <h2 style={{ textAlign: 'left', color: '#000', marginBottom: '5px' }}>Terms of Service</h2>
                <p>1. BhuMeet acts solely as a platform to connect farmers with drone service providers and does not directly offer or perform any agricultural services.</p>
                <p>2. BhuMeet collects payment from farmers on behalf of the service providers, which is transferred to the providers upon service completion.</p>
                <p>3. BhuMeet does not guarantee the quality, effectiveness, or safety of the services performed by the drone service providers.</p>
                <p>4. Farmers are responsible for verifying the agrochemicals used, including type and quantity, to ensure they meet their requirements and regulatory standards.</p>
                <p>5. The drone service providers are independent entities, and any issues or disputes regarding service quality should be addressed directly with the provider.</p>
                <p>6. Farmers are encouraged to provide honest feedback on the service received. Feedback will help enhance the reliability of the platform and assist other users in making informed decisions.</p>
                <p>7. BhuMeet is not liable for any adverse effects, crop damage, or other outcomes resulting from the services provided by the drone operators.</p>
            </div>
        );
    };

    return (
        settInvoice ?
            <div style={{ overflow: "auto", position: "relative" }}>
                <a
                    onClick={downloadPDF}
                    style={{
                        position: 'absolute',
                        top: '10px',
                        right: '340px',
                        textDecoration: 'underline',
                        color: '#007BFF',
                        cursor: 'pointer',
                        fontSize: '14px',
                    }}
                >
                    Download Invoice
                </a>
                <div style={{
                    width: '620px',
                    margin: '40px auto',
                    border: '1px solid rgba(215, 218, 224, 0.4)',
                    borderRadius: '5px',
                    boxShadow: '0 0 10px rgba(0,0,0,0.1)',
                    color: "#3B3B3B",
                    backgroundColor: '#fff'
                }}>
                    <div className='myDiv'>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'flex-start',
                            margin: '0px 30px',
                            padding: '20px 0px 5px 0',
                            borderBottom: '1px solid rgba(215, 218, 224, 0.3)',
                            fontSize: "14px"
                        }}>
                            <div>
                                <img src={bhumeet} alt="bhumeet" width={"75px"} />
                            </div>
                            <div style={{ textAlign: 'right' }}>
                                <p style={{ margin: '0', fontSize: "11px", }}><span style={{ fontWeight: 600, color: "#1A1C21" }}>No :</span> {invoice?.invoice_number || "-"}</p>
                                <p style={{ margin: '0', fontSize: "11px", }}><span style={{ fontWeight: 600, color: "#1A1C21" }}>Date :</span> {dateFormat(userInvoice?.service_completed_time || userInvoice?.created_at, "dd mmm yyyy")}</p>
                                {userInvoice?.payment_details?.pg_transaction_id && <p style={{ margin: '0', fontSize: "11px", }}><span style={{ fontWeight: 600, color: "#1A1C21" }}>Transaction Id :</span> {userInvoice?.payment_details?.pg_transaction_id}</p>}
                            </div>
                        </div>

                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            color: "#727272",
                            padding: "15px 30px",
                            fontSize: "14px"
                        }}>
                            <div style={{ width: "100%" }}>
                                <div style={{ marginBottom: '5px' }}>
                                    <p style={{ margin: '0', fontWeight: 500 }}>To</p>
                                    <p style={{ margin: '0', fontWeight: 'bold', color: "#1E1E1E" }}>{userInvoice?.to?.name || "-"}</p>
                                    {userInvoice?.to?.mobileNumber && <p style={{ margin: '0', fontWeight: 'bold', color: "#1E1E1E" }}>{userInvoice?.to?.mobileNumber}</p>}
                                    <p style={{ margin: '0' }}>{userInvoice?.to?.address || "-"}</p>
                                </div>
                                <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                    <div>
                                        <p style={{ margin: '0', fontWeight: 500 }}>Invoice from</p>
                                        <p style={{ margin: '0', fontWeight: 'bold', color: "#1E1E1E" }}>{userInvoice?.from?.name}</p>
                                        <p style={{ margin: '0' }}>{userInvoice?.from?.address}</p>
                                        <p style={{ margin: '0' }}>{userInvoice?.from?.gstin}</p>
                                    </div>
                                    <div>
                                        <p style={{ margin: '0', fontWeight: 500 }}>Services provided by</p>
                                        <p style={{ margin: '0', fontWeight: 'bold', color: "#1E1E1E" }}>{userInvoice?.serviced_by?.name}</p>
                                        <p style={{ margin: '0' }}>{userInvoice?.from?.address}</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div style={{
                            backgroundColor: '#D9D9D9',
                            padding: '10px 25px',
                            display: 'flex',
                            justifyContent: 'space-between',
                            fontWeight: 700,
                            color: '#1E1E1E',
                            height: "40px",
                            alignItems: "center",
                            fontSize: "14px"
                        }}>
                            <span>Payment Summary</span>
                            <span>Rates</span>
                        </div>

                        <div style={{ padding: '10px 30px 50px', fontSize: "14px" }}>
                            <div style={{ marginBottom: '5px' }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', fontWeight: 500 }}>
                                    <span>Service Charges</span>
                                    <span>₹ {formatToIndianRupees(userInvoice?.service_amount || 0)}</span>
                                </div>
                                {userInvoice?.sprayedAcres && <div style={{ fontSize: '12px', color: "#848484" }}>{userInvoice?.sprayedAcres} Acre(s) x {userInvoice?.perAcreServiceAmount} per acre</div>}
                            </div>
                            {userInvoice?.pesticides_subsidy > 0 &&
                                <div style={{ marginBottom: '5px' }}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', fontWeight: 500 }}>
                                        <span>Total Subsidy</span>
                                        <span style={{}}>- ₹ {formatToIndianRupees(userInvoice?.pesticides_subsidy || 0)}</span>
                                    </div>
                                    <div style={{ fontSize: '12`px', color: "#848484" }}>{userInvoice?.sprayedAcres} Acre(s) x {userInvoice?.pesticide_per_acre_subsidy} per acre</div>
                                </div>
                            }
                            {userInvoice?.applied_dsp_coupon_total_amount > 0 &&
                                <div style={{ marginBottom: '5px' }}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', fontWeight: 500 }}>
                                        <span>DSP Coupon</span>
                                        <span style={{}}>- ₹ {formatToIndianRupees(userInvoice?.applied_dsp_coupon_total_amount || 0)}</span>
                                    </div>
                                </div>
                            }
                            {userInvoice?.applied_bhumeet_coupon_total_amount > 0 &&
                                <div style={{ marginBottom: '5px' }}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', fontWeight: 500 }}>
                                        <span>Bhumeet Coupon</span>
                                        <span style={{}}>- ₹ {formatToIndianRupees(userInvoice?.applied_bhumeet_coupon_total_amount || 0)}</span>
                                    </div>
                                </div>
                            }
                            {userInvoice?.total_discounted_amount_via_dsp > 0 &&
                                <div style={{ marginBottom: '5px' }}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', fontWeight: 500 }}>
                                        <span>Discount</span>
                                        <span style={{}}>- ₹ {formatToIndianRupees(userInvoice?.total_discounted_amount_via_dsp || 0)}</span>
                                    </div>
                                </div>
                            }
                            {userInvoice?.applied_csgt_amount > 0 &&
                                <div style={{ marginBottom: '5px' }}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', fontWeight: 500 }}>
                                        <span>CGST</span>
                                        <span style={{}}>+ ₹ {formatToIndianRupees(userInvoice?.applied_csgt_amount || 0)}</span>
                                    </div>
                                </div>
                            }
                            {userInvoice?.applied_sgst_amount > 0 &&
                                <div style={{ marginBottom: '5px' }}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', fontWeight: 500 }}>
                                        <span>SGST</span>
                                        <span style={{}}>+ ₹ {formatToIndianRupees(userInvoice?.applied_sgst_amount || 0)}</span>
                                    </div>
                                </div>
                            }
                            {/* <img src={dashLine} alt="dashLine" style={{ maxWidth: "103%" }} /> */}
                            <div style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                paddingTop: '10px',
                                marginTop: '5px',
                                marginBottom: '10px',
                                fontWeight: 'bold',
                                borderTop: '1px solid rgba(215, 218, 224, 0.6)',
                                borderBottom: '1px solid rgba(215, 218, 224, 0.6)',
                                paddingBottom: '10px',
                                fontSize: "16px"
                            }}>
                                <span>Total Amount</span>
                                <span>₹ {formatToIndianRupees(userInvoice?.total_amount || 0)}</span>
                            </div>
                            <TermsOfService />
                        </div>
                    </div>
                </div>
            </div> : <></>
    )
}

export default UserInvoiceDetails