import React, { useEffect } from 'react'
import { Controller, useFormContext } from "react-hook-form"
import { findInputError } from '../utils/findInputError'
import { isFormInvalid } from '../utils/isFormValid'
import InputError from './InputError'
import { capitalizeFirstLetter } from '../ReusableFunctions/reusableFunctions'
const CustomToggle = ({ label, onText, offText, name, validation, defaultValue, labelStyle, headingLabelStyle, handleChange }) => {
    const {
        formState: { errors },
        control,
        clearErrors,
        watch
    } = useFormContext()

    const selectedValue = watch(name);

    useEffect(() => {
        if (handleChange && selectedValue?.value) {
            handleChange(selectedValue);
        }
    }, [selectedValue]);
    const inputError = findInputError(errors, name)
    const isInvalid = isFormInvalid(inputError)
    return (
        <div className="flex flex-col  w-[100%]">
            <div style={{ fontSize: '13px', color: '#3B3B3B', marginBottom: "5px", ...labelStyle }}>{capitalizeFirstLetter(label)}</div>
            <Controller
                name={name}
                control={control}
                rules={validation}
                defaultValue={defaultValue}
                render={({ field }) => (
                    <>
                        <div className="inline-flex rounded-full border border-gray-300  w-[100%]">
                            <button
                                type="button"
                                onClick={() => {
                                    clearErrors(name)
                                    field.onChange(true)
                                    if (handleChange) {

                                        handleChange(true)
                                    }
                                }}
                                className={`px-4 py-2 text-sm rounded-full transition-colors w-[50%] ${field.value
                                    ? 'bg-blue-100 text-blue-800 border border-[#0F5EFF]'
                                    : 'bg-transparent text-gray-700 hover:bg-gray-100'
                                    }`}
                            >
                                {onText}
                            </button>
                            <button
                                type="button"
                                onClick={() => {
                                    clearErrors(name)
                                    field.onChange(false)
                                    if (handleChange) {

                                        handleChange(false)
                                    }
                                }}
                                className={`px-4 py-2 text-sm rounded-full w-[50%] transition-colors ${!field.value
                                    ? 'bg-blue-100 text-blue-800 border border-[#0F5EFF]'
                                    : 'bg-transparent text-gray-700 hover:bg-gray-100'
                                    }`}
                            >
                                {offText}
                            </button>
                        </div>
                    </>
                )}
            />

        </div>
    )
}

export default CustomToggle