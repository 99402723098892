import React from 'react';

const TextAreaField = ({ label, value, name, onChange, placeholder, height, isMandotory, error }) => {
    return (
        <div style={{ height: height || "150px" }}>
            <label style={{ display: "block", height: "100%" }}>
                <span style={{
                    display: "block",
                    fontSize: "15px",
                    fontWeight: 400,
                    color: "#3b3b3b",
                    marginBottom: "5px"
                }}>
                    {label}
                    {isMandotory && (<span style={{ fontSize: "14px", marginLeft: "5px", color: "red" }}>*</span>)}
                </span>
                <textarea
                    value={value}
                    name={name}
                    onChange={onChange}
                    placeholder={placeholder}
                    style={{
                        resize: "none",
                        width: "100%",
                        height: "100%",
                        border: `1px solid #BFC8D9`,
                        background: "rgba(243, 243, 243, 1)",
                        borderRadius: "5px",
                        marginBottom: "-5px",
                        padding: "10px",
                        fontSize: "14px",
                        outline: "none",
                    }}
                />
                {error && <span style={{ color: "red", fontSize: "12px" }}>{error}</span>}
            </label>
        </div>

    );
};

export default TextAreaField;
