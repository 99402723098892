import React, { useState } from 'react'
import useWindowSize from '../../hooks/useWindowSize';
import Modal from '../../ReusableComponents/Modal';
import Form from '../../ReusableComponents/Form';
import { emailValidation, nameValidation, passwordValidation, selectFieldValidation } from '../../utils/inputValidations';
import InputField from '../../ReusableComponents/InputField';
import { Button } from '../../Components/Helper';
import { BHUMEET_TEAM_ROLES } from '../../appConstants';
import SelectField from '../../ReusableComponents/SelectField';
import { addTeamMember, updateTeamMember } from '../../apis/adminAPIs/teams';
import Loading from '../../ReusableFunctions/Loader';
import Popup from '../../ReusableFunctions/Popup';

export default function AddTeamMember({
    open,
    onClose,
    onOperatorAdd,
    operator
}) {
    const [operatorInfo, setOperatorInfo] = useState(operator?.id ?
        {
            ...operator,
            bhumeet_role: { label: operator.bhumeet_role, value: operator.bhumeet_role }
        }
        : {
            name: "",
            email: "",
            password: "",
            bhumeet_role: "",
        });
    const { currentHeight, currentWidth } = useWindowSize();
    const teamRolesOptions = Object.values(BHUMEET_TEAM_ROLES).map(role => ({
        label: role.NAME,
        value: role.NAME,
    }));


    const addTeamMemberFunc = async (operator) => {
        Loading.set(true);
        try {
            const addedMember = await addTeamMember(operator);
            Popup.alert("Team Member", "Team member added successfully", "SUCCESS")
            onOperatorAdd(addedMember)
            Loading.set(false);
        } catch (err) {
            Loading.set(false);
            Popup.alert("Error", err, 'ERROR')
        }
    }

    const updateTeamMemberFunc = async (operator) => {
        Loading.set(true);
        try {
            const updatedOperator = await updateTeamMember(operator?.id, operator);
            Popup.alert("Team Member", "Team member updated successfully", "SUCCESS")
            onOperatorAdd(updatedOperator)
            Loading.set(false);
        } catch (err) {
            Loading.set(false);
            Popup.alert("Error", err, 'ERROR')
        }
    }
    return (
        <Modal
            header={operator ? "Edit Team Member" : "Add Team Member"}
            open={open}
            onClose={onClose}
            contentStyle={{ width: "640px", height: "auto", borderRadius: "10px" }}
            headerContainerStyle={{ padding: currentHeight < 661 ? "15px 30px" : "25px 30px", }}
        >
            <Form
                onSubmitForm={(operatorDetails) => {
                    const operatorInfo = {
                        ...operatorDetails,
                        bhumeet_role: operatorDetails?.bhumeet_role?.value
                    }
                    operator?.id ? updateTeamMemberFunc(operatorInfo) : addTeamMemberFunc(operatorInfo);
                }}
                initialValues={operatorInfo}
            >
                {(onSubmit, formValues) => (
                    <div className="addDrone_form_wrapper" >
                        <div className="add_drone_form" style={{ padding: currentHeight < 661 ? "5px 30px" : "10px 30px" }}>
                            <InputField
                                defaultValue={operatorInfo.name}
                                label={"Name"}
                                type={"text"}
                                id={"type"}
                                placeholder={"Please Enter Name"}
                                name={"name"}
                                validation={nameValidation}
                                containerStyle={{
                                    width: "100%",
                                    maxWidth: "100%",
                                    minHeight: "80px",
                                }}
                            />
                            <InputField
                                defaultValue={operatorInfo.email}
                                label={"Email"}
                                type={"text"}
                                id={"type"}
                                placeholder={"Please Enter Email"}
                                name={"email"}
                                validation={emailValidation}
                                containerStyle={{
                                    width: "100%",
                                    maxWidth: "100%",
                                    minHeight: "80px",
                                }}
                            />
                            {!operator?.id && (
                                <InputField
                                    defaultValue={operatorInfo.password}
                                    label={"Password"}
                                    type={"text"}
                                    id={"type"}
                                    placeholder={"Please Enter Password"}
                                    name={"password"}
                                    validation={passwordValidation}
                                    containerStyle={{
                                        width: "100%",
                                        maxWidth: "100%",
                                        minHeight: "80px",
                                    }}
                                />
                            )}

                            <SelectField
                                name={"bhumeet_role"}
                                label={"Role"}
                                list={teamRolesOptions}
                                styles={{ width: "215px", height: "18px", fontSize: "13px", margin: "0 0 0 -1px" }}
                                validation={selectFieldValidation}
                                placeholderText={"Select role"}
                                defaultValue={operator?.id ? { label: operatorInfo?.bhumeet_role, value: operatorInfo?.bhumeet_role } : null}
                                containerStyle={{
                                    width: "100%",
                                    maxWidth: "100%",
                                    minHeight: "80px",
                                }}
                            />


                        </div>
                        <div
                            className="buttons_wrapper"
                            style={{ padding: currentHeight < 661 ? "5px 30px" : "10px 30px" }}
                        >
                            <Button
                                className={"btn-large"}
                                text={operator?.id ? "Update" : "Add"}
                                onClick={onSubmit}
                                style={{ width: "100px" }}
                            />
                            <Button
                                className={"btn-large lg-cancel-btn "}
                                text={"Cancel"}
                                onClick={onClose}
                                isBtnNotActive={true}
                            />
                        </div>
                    </div>
                )}
            </Form>
        </Modal>
    )
}
