import React, { useEffect, useRef, useState } from "react";
import Input from "../../../../../ReusableComponents/Input";
import DatePicker from "react-datepicker";
import dateFormat from 'dateformat';

import "react-datepicker/dist/react-datepicker.css";
import {
  acresToSquareKilometers,
  combineDateTime,
  getFormatedDate, getFormattedTimeFromDate,
} from "../../../../../ReusableFunctions/reusableFunctions";
import { Button } from "../../../../../Components/Helper";
import Modal from "../../../../../ReusableComponents/Modal";
import { applyDiscount, getRequest, updateRequest } from "../../../../../apis/requests";
import { DISCOUNT_TYPES, GLOBAL_STYLES, LIMITS, SERVICE_TYPES, STATUS, STATUS_MESSAGES } from "../../../../../appConstants";
import { getLocations } from "../../../../../apis/locations";
import { getDronePilots } from "../../../../../apis/dronePilots";
import Popup from "../../../../../ReusableFunctions/Popup";
import Loading from "../../../../../ReusableFunctions/Loader";
import { isBefore, setHours, setMinutes } from "date-fns";
import MainContentWrapper from "../../../../../Components/MainContentWrapper";
import Select from "../../../../../ReusableComponents/SelectComponent";
import Calender from "../../../../../assets/Calender2.svg"
import Clock from "../../../../../assets/clock.svg"
import { ROUTES } from "../../../../../routes";
import { Link, useNavigate } from "react-router-dom"
import { getDSPDrones } from "../../../../../apis/drones";
import SelectComponent from "../../../../../ReusableComponents/SelectComponent";
import { allStatus } from '../SubComponents/ViewSprayingRequest';
import RequestDiscount from "../../../../../ReusableComponents/RequestDiscount";
import VerticalStepper from "../../../../../ReusableComponents/VerticalStepper";

const ViewSurveyRequest = ({ user, req }) => {
  const invoiceRef = useRef(null)
  const navigate = useNavigate()
  const [surveyRequest, setSurveyRequest] = useState({ location_id: null });
  const [dronePilots, setDronePilots] = useState([]);
  const [drones, setDrones] = useState([]);
  const [droneType, setDroneType] = useState();
  const [selectedDroneUIN, setSelectedDroneUIN] = useState(null)
  const [showTimePicker, setShowTimePicker] = useState(false)
  const [showDatePicker, setShowDatePicker] = useState(false)
  const [noValueChanged, setNoValueChanged] = useState(true)
  const [statusList, setstatusList] = useState([]);
  const [isStatusChange, setisStatusChange] = useState(false);
  const [isInputEditable, setisInputEditable] = useState(false);
  const [isDiscountChanged, setIsDiscountChanged] = useState(false);
  const [appliedDiscount, setAppliedDiscount] = useState(0);
  const [anyInputChange, setAnyInputChange] = useState(false)
  const [disableUpdateBtn, setDisableUpdateBtn] = useState(true)
  const [steps, setSteps] = useState([])
  const [error, setError] = useState({
    completed_area: req.status == STATUS.COMPLETED ? null : "Required"
  })

  const oldStatus = req.status;

  const requestUpdate = () => {
    const invoice = invoiceRef?.current?.getInvoice()
    return new Promise((resolve, reject) => {
      updateRequest({
        ...surveyRequest,
        ...invoice,
        applied_coupons: invoice.applied_coupons,
        applied_discounts: [
          {
            discount_type: "dsp_request_discount",
            discount: Number(invoice.total_dsp_discount_percentage),
            discounted_amount: invoice.total_discounted_amount_via_dsp
          }
        ],
        service_completed_area: surveyRequest.price_by === "acre" ? acresToSquareKilometers(surveyRequest.service_completed_area) : surveyRequest.service_completed_area
        , status: surveyRequest.status == req.status ? null : surveyRequest.status
      }, req.type).then(res => { resolve(res) }).catch(err => { reject(err) })
    })
  }

  const discountApply = () => {
    return new Promise((resolve, reject) => {
      applyDiscount({ id: surveyRequest.id, dsp_discount: appliedDiscount }).then(res => resolve(res)).catch(err => { reject(err) })
    })
  }

  const saveRequestDetails = async () => {
    try {
      Loading.set(true);
      if (new Date(surveyRequest.time).getTime() <= new Date().getTime() && [STATUS.ACCEPTED, STATUS.RESCHEDULED].includes(surveyRequest.status)) {
        Loading.set(false);
        Popup.alert("Error", "Please select a date and time that is equal to or later than the current day and time!", "ERROR", () => { });
      }
      else {
        if (isDiscountChanged) { await discountApply() }
        await requestUpdate()
        Loading.set(false);
        Popup.alert(
          "Survey Request",
          "Request updated successfully",
          "SUCCESS",
          () => {
            const queryParams = [];
            if (user?.user?.pilotId) {
              queryParams.push(`pilotId=${user.user.pilotId}`);
            }
            if (user?.user?.pilotName) {
              queryParams.push(`pilotName=${user.user.pilotName}`);
            }
            if (user?.user?.isCalenderView) {
              queryParams.push(`isCalenderView=${user.user.isCalenderView}`);
            }
            if (user?.user?.userId) {
              queryParams.push(`userId=${user.user.userId}`);
            }
            const queryString = queryParams.length > 0 ? `?${queryParams.join('&')}` : '';
            navigate(user?.user?.userId ? ROUTES.CUSTOMER_DETAILS + queryString : ROUTES.MYREQUESTS + queryString);
          }
        );
      }


    }
    catch (err) {
      Loading.set(false);
      Popup.alert("Error", err, "ERROR", () => { });
      console.log(err);
    }
  }
  const handleDroneSelection = (e) => {
    const value = e.value;
    const selectedDrone = drones.find(drone => drone.drone_uin === value);
    setDroneType(selectedDrone.type)
    setSelectedDroneUIN(value);

    setSurveyRequest((prev) => ({
      ...prev,
      drone_id: selectedDrone.id,
    }));
    if (surveyRequest.drone_id != selectedDrone.id) setAnyInputChange(true)
  };

  const isUpdateButtonDisabled = () => {
    const drone = drones.find((d) => d.id === req.drone_id)
    let disabled = true;

    if (surveyRequest.status && req.status && req.status != surveyRequest.status) {
      if ((surveyRequest.status == STATUS.ACCEPTED || surveyRequest.status == STATUS.RESCHEDULED)) {
        if (!surveyRequest.drone_pilot || !selectedDroneUIN || !surveyRequest.time) {
          disabled = true
        }
        else { disabled = false }
      }
      else if (surveyRequest.status == STATUS.COMPLETED) {
        if (!surveyRequest.service_completed_area || error.completed_area) {
          disabled = true
        }
        else {
          disabled = false
        }
      }
      else disabled = false
    }
    else if ((surveyRequest.status == STATUS.ACCEPTED || surveyRequest.status == STATUS.RESCHEDULED)) {
      if (surveyRequest.drone_pilot != req.drone_pilot || drone?.drone_uin != selectedDroneUIN) {
        if (!surveyRequest.drone_pilot || !selectedDroneUIN || !surveyRequest.time) {
          disabled = true
        } else disabled = false
      }
      else disabled = true
    }
    if (isDiscountChanged) {
      if ((surveyRequest.status == STATUS.ACCEPTED || surveyRequest.status == STATUS.RESCHEDULED)) {
        if (!surveyRequest.drone_pilot || !selectedDroneUIN || !surveyRequest.time) {
          disabled = true
        }
        else { disabled = false }
      }
      else if (surveyRequest.status == STATUS.COMPLETED) {
        if (!surveyRequest.service_completed_area || error.completed_area) {
          disabled = true
        }
        else {
          disabled = false
        }
      }
      else disabled = false
    }

    return disabled
  }

  useEffect(() => {
    const d = isUpdateButtonDisabled()
    setDisableUpdateBtn(d)
  }, [error, surveyRequest.service_completed_area, surveyRequest.drone_pilot, selectedDroneUIN, surveyRequest.time, surveyRequest.status, req.status, anyInputChange, isDiscountChanged])

  useEffect(() => {
    const isEditableStatus = [STATUS.ACCEPTED, STATUS.RESCHEDULED, STATUS.OUT_FOR_SERVICE].includes(surveyRequest.status)
    setisInputEditable(isStatusChange && isEditableStatus);

    if (surveyRequest.status === STATUS.OUT_FOR_SERVICE) {
      setSurveyRequest((prev) => ({ ...prev, time: new Date() }))
    }

    const currentStatusIndex = allStatus.findIndex(st => st.value === req.status);
    let updatedStatusList = [];

    switch (req.status) {
      case STATUS.HOLD:
        updatedStatusList = [
          { value: STATUS.HOLD, label: STATUS.HOLD },
          { value: STATUS.INPROGRESS, label: STATUS.INPROGRESS },
          { value: STATUS.RESCHEDULED, label: STATUS.RESCHEDULED },
          { value: STATUS.CANCELLED, label: STATUS.CANCELLED },
        ];
        break;
      case STATUS.PLACED:
        updatedStatusList = [
          ...allStatus.slice(currentStatusIndex, currentStatusIndex + 2),
          { value: STATUS.CANCELLED, label: STATUS.CANCELLED },
        ];
        break;
      case STATUS.RESCHEDULED:
        updatedStatusList = [
          { value: STATUS.RESCHEDULED, label: STATUS.RESCHEDULED },
          { value: STATUS.OUT_FOR_SERVICE, label: STATUS.OUT_FOR_SERVICE },
          { value: STATUS.CANCELLED, label: STATUS.CANCELLED },
        ];
        break;
      case STATUS.COMPLETED:
        updatedStatusList = allStatus.slice(currentStatusIndex, currentStatusIndex + 2);
        break;
        case STATUS.INPROGRESS:
          updatedStatusList = [
            ...allStatus.slice(currentStatusIndex, currentStatusIndex + 2),
            { value: STATUS.HOLD, label: STATUS.HOLD },
            { value: STATUS.CANCELLED, label: STATUS.CANCELLED },
            { value: STATUS.RESCHEDULED, label: STATUS.RESCHEDULED },
          ];
          break;
      case STATUS.ACCEPTED:
      case STATUS.OUT_FOR_SERVICE:
        updatedStatusList = [
          ...allStatus.slice(currentStatusIndex, currentStatusIndex + 2),
          { value: STATUS.CANCELLED, label: STATUS.CANCELLED },
          { value: STATUS.RESCHEDULED, label: STATUS.RESCHEDULED },
        ];
        break;
      default:
        updatedStatusList = [];
        break;
    }

    setstatusList(updatedStatusList);
  }, [surveyRequest.status, isStatusChange])

  useEffect(() => {
    Loading.set(true);
    setSurveyRequest(req);
    setError({
      completed_area: req.status == STATUS.COMPLETED ? null : "Required"
    })

    setSteps(req.status_history.map(record => {
      return {
        label: record.updated_status,
        content: <>
          <div style={{
            color: "rgb(59, 59, 59)",
            fontSize: "13px",
            fontWeight: "500",
            wordBreak: "break-word",
            marginBottom: "6px",
          }}>{
              record.updated_status == STATUS.PLACED ? STATUS_MESSAGES.PLACED :
                record.updated_status == STATUS.ACCEPTED ? STATUS_MESSAGES.ACCEPTED :
                  record.updated_status == STATUS.RESCHEDULED ? STATUS_MESSAGES.RESCHEDULED :
                    record.updated_status == STATUS.OUT_FOR_SERVICE ? STATUS_MESSAGES.OUT_FOR_SERVICE :
                      record.updated_status == STATUS.INPROGRESS ? STATUS_MESSAGES.INPROGRESS :
                        record.updated_status == STATUS.COMPLETED ? STATUS_MESSAGES.COMPLETED :
                          record.updated_status == STATUS.PAID ? STATUS_MESSAGES.PAID :
                            record.updated_status == STATUS.CANCELLED ? STATUS_MESSAGES.CANCELLED : ""
            }</div>
          <div style={{ color: "#727272", fontSize: "13px", fontWeight: "600" }}>{`${dateFormat(new Date(record.time), "h : MM TT")}, ${dateFormat(new Date(record.time), "d mmmm yyyy")}`}</div>
        </>,
        statusColor: record.updated_status == STATUS.RESCHEDULED || record.updated_status == STATUS.CANCELLED ? "#EB0000" : "#32B338",
        highlightStatus: record.updated_status == STATUS.RESCHEDULED || record.updated_status == STATUS.CANCELLED
      }
    }))

    getDronePilots().then((res) => {
      setDronePilots(res.dronePilots.filter((d) => d.is_active));
    }).catch((err) => {
      Loading.set(false);
      console.log(err);
      Popup.alert("Error", err.errorMessage, "ERROR", () => { });
    });

    getDSPDrones().then((res) => {
      const drone = res.find((d) => d.id === req.drone_id)
      const drones = res?.filter((d) => d?.service_type === SERVICE_TYPES[1])
      setDrones(drones)
      if (drone) {
        setSelectedDroneUIN(drone?.drone_uin);
        setDroneType(drone?.type)
      }
      Loading.set(false);
    }).catch((err) => {
      Loading.set(false);
      console.log(err);
      Popup.alert("Error", err, "ERROR", () => { });
    });
  }, [req?.id]);
  return (
    <MainContentWrapper>
      <div>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "15px" }}>
          <div style={{ fontSize: "16px", fontWeight: 700 }}>My Request</div>
          <div style={{ display: "flex", gap: "1.5rem", marginTop: "8px" }}>
            <Link className='underlined-bg-active' style={{ display: "block", color: GLOBAL_STYLES.BG_ACTIVE, fontSize: '12px', fontWeight: 600, position: "relative" }} to={ROUTES.MYREQUESTS}>My requests</Link>
            <Link className='underlined-bg-active' style={{ display: "block", color: GLOBAL_STYLES.BG_ACTIVE, fontSize: '12px', fontWeight: 600, position: "relative" }} to={'/'}>Home</Link>
          </div>
        </div>
        <div style={{ background: "white", width: "100%", }}>
          <div style={{
            background: "#DDDDDD",
            background: "rgb(221, 221, 221)",
            display: "flex",
            justifyContent: "space-between",
            padding: "18px 30px",
          }}>
            <div style={{
              color: "rgb(30, 30, 30)",
              fontSize: "18px",
              fontWeight: "600",
            }}>
              <span style={{ marginRight: "5px", }}>Request Number</span>
              <span>:</span>

              <span style={{ marginLeft: "22px" }}>{"    " + surveyRequest?.req_number}</span>
            </div>

            <div >
              <Button onClick={saveRequestDetails} text={"Update"} style={{ width: "140px", height: "28px", fontSize: "16px" }}
                isDisabled={disableUpdateBtn} />
            </div>
          </div>
          <div style={{ background: "white", position: "relative", display: "flex", width: "100%" }}>

            <div style={{ width: "77%", padding: "25px", borderRight: "1px solid #8080803d" }}>
              {/* Customer details */}
              <div style={{ width: "100%", marginBottom: "20px" }}>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div style={{ fontSize: "16px", fontWeight: "600", marginBottom: "15px" }}>Customer Details</div>
                  <div style={{ fontSize: GLOBAL_STYLES.FONT_SIZE_MEDIUM, display: "flex", alignItems: "center" }}>
                    <span style={{ fontSize: "15px", fontWeight: "600" }}>Status</span>
                    <span style={{ marginRight: "10px", marginLeft: "5px" }}>:</span>
                    <SelectComponent
                      controlStyle={{
                        width: "150px",
                        // height: "100px",
                        fontSize: "12px",
                        fontWeight: "600",
                        borderRadius: "4px",
                        background: "#F3F3F3",
                        border: "1px solid #D9D9D9"
                      }}
                      list={statusList}
                      value={{ value: surveyRequest.status, label: surveyRequest.status === STATUS.PLACED ? STATUS.PLACED : surveyRequest.status }}
                      onChange={(e) => {
                        setisStatusChange(() => oldStatus !== e.value);
                        const status = e.value;
                        setAnyInputChange(false)
                        setSurveyRequest((prev) => ({
                          ...prev,
                          status: status,
                          time: req.time
                        }))
                      }
                      }
                    />
                  </div>
                </div>
                <div style={{ display: "flex", marginBottom: "10px" }}>
                  <div style={{ width: "50%", display: "flex", alignItems: "center" }}>
                    <div style={{ width: "25%", fontSize: "13px", color: "#3B3B3B" }}>Customer Name</div>
                    <span style={{ margin: "0 10px 0 20px", }}>:</span>
                    <div style={{ width: "75%", fontSize: "13px", color: "#1E1E1E", paddingTop: "1px" }}>{`${surveyRequest.user?.name}` || "-"}</div>
                  </div>
                  <div style={{ width: "50%", display: "flex", alignItems: "center" }}>
                    <div style={{ width: "25%", fontSize: "13px", color: "#3B3B3B" }}>Contact Number</div>
                    <span style={{ margin: "0 10px 0 20px", }}>:</span>
                    <div style={{ width: "75%", fontSize: "13px", color: "#1E1E1E", paddingTop: "1px" }}>{`${surveyRequest.user?.mobile_number}` || "-"}</div>
                  </div>
                </div>
                <div style={{ display: "flex", marginBottom: "10px" }}>
                  <div style={{ width: "50%", display: "flex", }}>
                    <div style={{ width: "25%", fontSize: "13px", color: "#3B3B3B" }}>Location</div>
                    <span style={{ margin: "0 10px 0 20px", }}>:</span>
                    <div style={{ width: "75%", fontSize: "13px", color: "#1E1E1E", paddingTop: "3px", wordBreak: "break-word" }}>{surveyRequest?.location_address || "-"}</div>
                  </div>
                  {/* <div style={{ width: "50%", display: "flex", alignItems: "baseline" }}>
                    <div style={{ width: "25%", fontSize: "13px", color: "#3B3B3B" }}>Email ID</div>
                    <span style={{ margin: "0 10px 0 20px", }}>:</span>
                    <div style={{ width: "75%", fontSize: "13px", color: "#1E1E1E", paddingTop: "1px" }}>{surveyRequest.user?.email || "-"}</div>
                  </div> */}
                </div>
              </div>

              {/* Service Details */}
              <div style={{ width: "100%", marginBottom: "20px" }}>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div style={{ fontSize: "16px", fontWeight: "600", marginBottom: "15px" }}>Service Details</div>
                </div>
                <div style={{ display: "flex", marginBottom: "10px" }}>
                  <div style={{ width: "50%", display: "flex", alignItems: "center" }}>
                    <div style={{ width: "25%", fontSize: "13px", color: "#3B3B3B" }}>Service</div>
                    <span style={{ margin: "0 10px 0 20px", }}>:</span>
                    <div style={{ width: "75%", fontSize: "13px", color: "#1E1E1E", paddingTop: "1px" }}>{surveyRequest?.type || "-"}</div>
                  </div>
                  <div style={{ width: "50%", display: "flex", alignItems: "center" }}>
                    <div style={{ width: "25%", fontSize: "13px", color: "#3B3B3B" }}>Service location</div>
                    <span style={{ margin: "0 10px 0 20px", }}>:</span>
                    <div style={{ width: "75%", fontSize: "13px", color: "#1E1E1E", paddingTop: "1px" }}>{surveyRequest?.field_address || "-"}</div>
                  </div>


                </div>
                <div style={{ width: "100%", display: "flex", alignItems: "baseline" }}>
                  <div style={{ width: "50%", display: "flex", alignItems: "center" }}>
                    <div style={{ width: "25%", fontSize: "13px", color: "#3B3B3B" }}>Requested Area</div>
                    <span style={{ margin: "0 10px 0 20px", }}>:</span>
                    <div style={{ width: "75%", fontSize: "13px", color: "#1E1E1E", paddingTop: "1px" }}>
                      {surveyRequest.field_area ? `${surveyRequest.field_area} Sq/km` : "-"}
                    </div>
                  </div>
                  <div style={{ display: "flex", width: "50%" }}>
                    {
                      isStatusChange && surveyRequest?.status === STATUS.COMPLETED ? (
                        <div style={{ display: "flex", width: "100%", alignItems: "baseline" }}>
                          <div style={{ color: "rgba(59, 59, 59, 0.8)", width: "fit-content", minWidth: "26.5%", fontSize: "13px", fontWeight: 400 }}>Completed Area</div>
                          <span style={{ marginRight: "10px" }}>:</span>
                          <div style={{ display: "flex", flexDirection: "column", gap: "0.2rem" }}>
                            <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                              <Input
                                type={"number"}
                                style={{ maxWidth: "200px" }}
                                value={surveyRequest.service_completed_area}
                                maxLength={LIMITS.COMPELETED_AREA}
                                className="text_input_field"
                                placeholder={"Completed Area"}
                                name={"Completed Area"}
                                onChange={(e) => {
                                  let value = e.target.value;
                                  if (!value) {
                                    setError((prev) => ({
                                      ...prev,
                                      completed_area: "Required"
                                    }));
                                    setSurveyRequest((prev) => ({
                                      ...prev,
                                      service_completed_area: null,
                                    }));
                                  }
                                  else if (value.match(/^\d*\.?\d*$/)) {
                                    const decimalValue = parseFloat(value);
                                    if (!isNaN(decimalValue) && decimalValue > 4.04686) {
                                      setError((prev) => ({
                                        ...prev,
                                        completed_area: "Completed area should be less than or equal to 4.04686"
                                      }));
                                    } else {
                                      setSurveyRequest((prev) => ({
                                        ...prev,
                                        service_completed_area: decimalValue,
                                      }));
                                      setError((prev) => ({ ...prev, completed_area: null }));
                                    }
                                  }
                                }}
                              />
                              <span style={{ fontSize: "14px", fontWeight: 500 }}>Sq/km</span>
                            </div>
                            <div className={"drone_add_error-messsage"}>{error.completed_area}</div>
                          </div>

                        </div>
                      ) : surveyRequest.service_completed_area !== null && surveyRequest.service_completed_area !== ''
                        ?
                        <div style={{ display: "flex", width: "100%", alignItems: "center" }}>
                          <div style={{ color: "rgba(59, 59, 59, 0.8)", width: "fit-content", minWidth: "26.5%", fontSize: "13px", fontWeight: 400 }}>Completed Area</div>
                          <span style={{ marginRight: "10px" }}>:</span>
                          <div style={{ overflowWrap: "break-word", wordWrap: "break-word", width: "350px", lineHeight: 1.3, marginLeft: "5px", fontSize: "13px", fontWeight: 400, overflowY: "auto", maxHeight: "70px", overflow: "auto", }}>{ }
                            <span>{surveyRequest.service_completed_area ? surveyRequest.service_completed_area : "-"}</span>
                            <span style={{ marginLeft: "10px" }}>Sq/km</span>
                          </div>
                        </div>
                        : null
                    }
                  </div>

                </div>
              </div>

              <div style={{ width: "100%", marginBottom: "20px", display: "flex" }}>
                {/* Resource Details */}
                <div style={{ width: "50%" }}>
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div style={{ fontSize: "16px", fontWeight: "600", marginBottom: "15px" }}>Resource Details</div>
                  </div>
                  <div style={{ display: "flex", marginBottom: "17px" }}>
                    <div style={{ width: "100%", display: "flex", alignItems: "center", height: "30px" }}>
                      <div style={{ width: "25%", fontSize: "13px", color: "#3B3B3B" }}>Assign Pilot</div>
                      <span style={{ margin: "0 10px 0 20px", }}>:</span>
                      <div style={{ width: "75%", fontSize: "13px", color: "#1E1E1E", paddingTop: "1px" }}>
                        {!(surveyRequest.status == STATUS.ACCEPTED || surveyRequest.status == STATUS.RESCHEDULED)
                          ? (dronePilots.find(pilot => pilot.id === surveyRequest.drone_pilot)?.first_name
                            ||
                            <span className="drone_add_error-messsage" style={{ fontSize: "13px" }}>Pilot not assigned yet.</span>
                          )
                          : dronePilots.length !== 0 ?
                            <SelectComponent
                              menuStyles={{ width: "176px" }}
                              controlStyle={{ width: "176px", height: "30px", background: "#F3F3F3", border: "1px solid #D9D9D9", borderRadius: "4px" }}
                              value={surveyRequest.drone_pilot ? { value: surveyRequest.drone_pilot, label: dronePilots.find(p => p.id === surveyRequest.drone_pilot)?.first_name } : ""}
                              onChange={(selectedPilot) => {

                                setSurveyRequest((prev) => ({
                                  ...prev,
                                  drone_pilot: selectedPilot.value,
                                }));
                                if (surveyRequest.drone_pilot != selectedPilot.value) setAnyInputChange(true)
                              }}
                              list={dronePilots.map(el => ({ value: el.id, label: el.first_name }))}
                              isSearchable={true}
                            />
                            : <div className="drone_add_error-messsage" style={{ fontSize: "13px" }}>
                              Pilots not exists. Please register pilots first.
                            </div>
                        }
                      </div>
                    </div>
                  </div>
                  <div style={{ display: "flex", marginBottom: "10px" }}>
                    <div style={{ width: "100%", display: "flex", alignItems: "center", height: "30px" }}>
                      <div style={{ width: "25%", fontSize: "13px", color: "#3B3B3B" }}>Assigned Drone UIN</div>
                      <span style={{ margin: "0 10px 0 20px", }}>:</span>
                      <div style={{ width: "75%", fontSize: "13px", color: "#1E1E1E", paddingTop: "1px" }}>
                        {!(surveyRequest.status == STATUS.ACCEPTED || surveyRequest.status == STATUS.RESCHEDULED)
                          ? (selectedDroneUIN || <span className="drone_add_error-messsage" style={{ fontSize: "13px" }}>Drone not assigned yet.</span>) :
                          drones.length !== 0 ?
                            <SelectComponent
                              controlStyle={{ width: "176px", height: "40px", background: "#F3F3F3", border: "1px solid #D9D9D9", borderRadius: "4px" }}
                              menuStyles={{ width: "176px" }}
                              value={selectedDroneUIN ? { value: selectedDroneUIN, label: selectedDroneUIN } : ""}
                              onChange={handleDroneSelection}
                              list={drones?.map(el => ({ value: el.drone_uin, label: el.drone_uin }))}
                              isSearchable={true}
                            />
                            : <div className="drone_add_error-messsage" style={{ fontSize: "13px" }}>
                              Drones not exists. Please register drones first.
                            </div>
                        }
                      </div>
                    </div>
                  </div>
                  <div style={{ display: "flex", marginBottom: "10px" }}>
                    <div style={{ width: "100%", display: "flex", alignItems: "center", height: "30px" }}>
                      <div style={{ width: "25%", fontSize: "13px", color: "#3B3B3B" }}>Assigned Drone Type</div>
                      <span style={{ margin: "0 10px 0 20px", }}>:</span>
                      <div style={{ width: "75%", fontSize: "13px", color: "#1E1E1E", paddingTop: "1px" }}>
                        {droneType ? droneType : "-"}
                      </div>
                    </div>
                  </div>
                </div>

                {/* Schedule */}
                <div style={{ width: "50%" }}>
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div style={{ fontSize: "16px", fontWeight: "600", marginBottom: "15px" }}>Schedule</div>
                  </div>
                  <div style={{ display: "flex", marginBottom: "10px" }}>
                    <div style={{ width: "50%", display: "flex", alignItems: "center", height: "30px" }}>
                      <div style={{ width: "25%", fontSize: "13px", color: "#3B3B3B" }}>Date</div>
                      <span style={{ margin: "0 10px 0 20px", }}>:</span>
                      <div style={{ width: "75%", fontSize: "13px", color: "#1E1E1E", paddingTop: "1px" }}>
                        {(!isInputEditable || surveyRequest.status === STATUS.OUT_FOR_SERVICE) ? getFormatedDate(surveyRequest.time) || "-"
                          : <div
                            className="input-date"
                            style={{ display: "flex", alignItems: "center", cursor: "pointer", height: "100%", width: "180px", position: "relative" }}
                            onClick={() => setShowDatePicker(true)}
                          >
                            <DatePicker
                              onClickOutside={() => setShowDatePicker(false)}
                              open={showDatePicker}
                              className="input-date-hidden"
                              readOnly
                              minDate={new Date()}
                              selected={new Date(surveyRequest.time)}
                              onChange={date => {
                                let newTime = combineDateTime((new Date(surveyRequest.time)), new Date(date))
                                setNoValueChanged(false)
                                setSurveyRequest(prev => ({ ...prev, time: newTime }))

                                if (surveyRequest.time != newTime) setAnyInputChange(true)
                              }} />
                            <img src={Calender} style={{ width: "18px", position: "absolute", right: "15px" }} />
                          </div>}
                      </div>
                    </div>
                  </div>
                  <div style={{ display: "flex", marginBottom: "10px" }}>
                    <div style={{ width: "50%", display: "flex", alignItems: "center", height: "30px" }}>
                      <div style={{ width: "25%", fontSize: "13px", color: "#3B3B3B" }}>Time</div>
                      <span style={{ margin: "0 10px 0 20px", }}>:</span>
                      <div style={{ width: "75%", fontSize: "13px", color: "#1E1E1E", paddingTop: "1px" }}>
                        {!isInputEditable ? getFormattedTimeFromDate(surveyRequest.time)
                          : <div
                            className="input-date"
                            style={{ display: "flex", alignItems: "center", cursor: "pointer", height: "100%", width: "180px", position: "relative" }}
                            onClick={() => setShowTimePicker(true)}
                          >
                            <DatePicker
                              onClickOutside={() => setShowTimePicker(false)}
                              open={showTimePicker}
                              readOnly
                              className="input-date-hidden"
                              minTime={(surveyRequest.time && (new Date(surveyRequest.time)).getDate() === (new Date()).getDate())
                                ? new Date((new Date()).getFullYear(), (new Date()).getMonth(), (new Date()).getDate(), (new Date()).getHours(), (new Date()).getMinutes())
                                : new Date((new Date()).getFullYear(), (new Date()).getMonth(), (new Date()).getDate(), 0, 0)
                              }
                              maxTime={setHours(setMinutes(new Date(), 59), 23)}
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={15}
                              timeCaption="Time"
                              dateFormat="h:mm aa"
                              selected={new Date(surveyRequest.time)}
                              onChange={date => {
                                setNoValueChanged(false)
                                setSurveyRequest(prev => ({ ...prev, time: combineDateTime((new Date(date)), new Date(surveyRequest.time)) }))
                              }} />
                            <img src={Clock} style={{ width: "18px", position: "absolute", right: "15px" }} />
                          </div>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Request Discount */}
              <div style={{ width: "35%", marginBottom: "20px" }}>
                {surveyRequest.id && <RequestDiscount
                  ref={invoiceRef}
                  oldRequest={req || {}}
                  request={{
                    ...surveyRequest,
                    service_completed_area: surveyRequest.service_completed_area && surveyRequest.price_by === 'acre' ? acresToSquareKilometers(Number(surveyRequest.service_completed_area)) : Number(surveyRequest.service_completed_area)
                  }}
                  changeValue={(value) => {
                    let discountData = req.invoice.applied_discounts.find(ad => ad.discount_type === DISCOUNT_TYPES.DSP_REQUEST_DISCOUNT);
                    if (discountData) {
                      if (Number(discountData.discount) == Number(value)) setIsDiscountChanged(false)
                      else setIsDiscountChanged(true)
                      setNoValueChanged(false)
                    }
                    else if (value > 0) {
                      setNoValueChanged(false)
                      setIsDiscountChanged(true)
                    }
                    else {
                      setNoValueChanged(true)
                      setIsDiscountChanged(false)
                    }
                    setAppliedDiscount(Number(value))
                  }} />}
              </div>
            </div>

            {steps.length > 0 && <div style={{ position: "relative", height: "100%", width: "23%", }}>
              <VerticalStepper steps={steps} />
            </div>}

          </div>
        </div>
      </div >
    </MainContentWrapper >
  );
};

export default ViewSurveyRequest;
