import React, { useEffect, useState } from "react";
import calender from "../assets/blueCalendar.svg"
import clock from "../assets/blueClock.svg"
import DatePicker from "react-datepicker";

const DateTimePicker = ({ title, selectedDateTime, includeFrom, hidePrev, hideNxt, onChange, isDisable, containerWidth, toMinDate, minTime }) => {
    const [showHide, setShowHide] = useState({
        showDate: false,
        showTime: false
    })
    const [dateTime, setDateTime] = useState(selectedDateTime ? new Date(selectedDateTime) : null)

    useEffect(() => {
        setDateTime(selectedDateTime ? new Date(selectedDateTime) : null)
        setShowHide({ showDate: false, showTime: false })
    }, [selectedDateTime,])

    return (
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "50%" }}>
            <div style={{ width: "100%" }}>
                <div style={{ fontSize: "15px" }}>{title || 'Date Time Picker'}</div>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                    <div className="input-date"
                        style={{
                            backgroundColor: "white",
                            display: "flex",
                            alignItems: "center",
                            cursor: "pointer",
                            width: containerWidth || "48%",
                            position: "relative",
                            opacity: dateTime || !(isDisable) ? "1" : "0.5",
                            cursor: dateTime || !(isDisable) ? "pointer" : "not-allowed",
                            zIndex: 99
                        }}

                        onClick={() => { if (!isDisable) setShowHide({ showTime: false, showDate: true }) }}
                    >
                        <DatePicker
                            dateFormat="dd/MM/yyyy"
                            open={showHide.showDate}
                            className="input-date-hidden"
                            maxDate={hideNxt ? new Date(hideNxt) : undefined}
                            minDate={hidePrev ? new Date(hidePrev) : undefined}
                            selected={dateTime}
                            readOnly

                            includeDateIntervals={includeFrom ? [
                                { start: new Date(includeFrom), end: new Date() },
                            ] : undefined}
                            onClickOutside={() => {
                                setShowHide({
                                    showDate: false,
                                    showTime: false
                                })
                            }}
                            onChange={date => {
                                setDateTime(date)
                                setShowHide({
                                    showDate: false,
                                    showTime: false
                                })
                                onChange(date)
                            }} />
                        {!(dateTime) && <div style={{ position: "absolute" }}>--/--/----</div>}
                        <img src={calender} style={{ width: "18px", position: "absolute", right: "15px" }} />
                    </div>
                    <div className="input-date"
                        style={{
                            backgroundColor: "white",
                            display: "flex",
                            alignItems: "center",
                            cursor: "pointer",
                            width: containerWidth || "48%",
                            position: "relative",
                            opacity: dateTime ? "1" : "0.5",
                            cursor: dateTime ? "pointer" : "not-allowed",
                            zIndex: 99
                        }}
                        onClick={() => { if (dateTime) setShowHide({ showDate: false, showTime: true }) }}
                    >
                        <DatePicker
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15} // Adjusts the time selection intervals
                            timeCaption="Time"
                            dateFormat="hh:mm a"
                            readOnly
                            onClickOutside={() => {
                                setShowHide({
                                    showDate: false,
                                    showTime: false
                                })
                            }}
                            open={showHide.showTime}
                            className="input-date-hidden"
                            minDate={toMinDate || new Date()}
                            minTime={minTime}
                            maxTime={new Date().setHours(23, 59)}
                            selected={dateTime}
                            onChange={date => {
                                const timeStr = new Date(date).toLocaleTimeString('en-US', { hour12: false })
                                const dateObj = new Date(dateTime);

                                const [hours, minutes, seconds] = timeStr.split(":");

                                dateObj.setHours(parseInt(hours));
                                dateObj.setMinutes(parseInt(minutes));
                                dateObj.setSeconds(parseInt(seconds));

                                setDateTime(dateObj)

                                onChange(dateObj)
                            }} />
                        {!(dateTime) && <div style={{ position: "absolute" }}>- - : - -</div>}
                        <img src={clock} style={{ width: "18px", position: "absolute", right: "15px" }} />
                    </div>
                </div>

            </div>
        </div>
    );
};

export default DateTimePicker;
