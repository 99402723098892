import React, { useEffect, useState } from 'react'
import DspWalletCard from '../subComponents/DspWalletCard';
import DspBankCard from '../subComponents/DspBankCard';
import DspWalletTransactionsInvoices from '../subComponents/DspWalletTransactionsInvoices';
import { useLocation } from 'react-router-dom'
import Loading from '../../../ReusableFunctions/Loader';
import { getAdminDspBankDetails, getAdminDspWalletBalance } from '../../../apis/adminAPIs/finance/wallet';
import Popup from '../../../ReusableFunctions/Popup';

const DspWallet = () => {
    const { state } = useLocation();
    const [bankDetails, setBankDetails] = useState({})
    const [walletBalance, setWalletBalance] = useState(null)

    const getDspBankDetailsFuc = async () => {
        try {
            Loading.set(true)
            const bank = await getAdminDspBankDetails(state?.dsp?.dsp_id)
            setBankDetails(bank || {})
            Loading.set(false)
        } catch (error) {
            Loading.set(false)
            Popup.alert("Error", error, 'ERROR')
        }
    }


    const getDspWalletBalanaceFuc = async () => {
        try {
            Loading.set(true)
            const balance = await getAdminDspWalletBalance(state?.dsp?.dsp_id)
            console.log('balance:', balance)
            setWalletBalance(balance)
            Loading.set(false)
        } catch (error) {
            Loading.set(false)
            Popup.alert("Error", error, 'ERROR')
        }
    }

    useEffect(() => {
        getDspBankDetailsFuc()
        getDspWalletBalanaceFuc()
    }, [])

    return (
        <div className='wallet_details' >
            <div className='flex gap-5 w-[100%]'>
                <DspWalletCard walletBalance={walletBalance} />
                <DspBankCard bankDetails={bankDetails} />
            </div>
            {state?.dsp?.dsp_id &&
                <DspWalletTransactionsInvoices
                    dsp={state?.dsp}
                    toBankDetails={bankDetails}
                />
            }
        </div>
    )
}

export default DspWallet