import dateFormat from 'dateformat';

// Function to convert JSON (Array of objects) to CSV
const arrayToCsv = (headers, data) => {
    const csvRows = [];

    // Add header row
    const headerValues = headers.map(header => header.label);
    csvRows.push(headerValues.join(','));

    // Format date helper function
    const formatDate = (dateStr) => {
        const date = new Date(dateStr);
        return dateFormat(date, "dd/mm/yyyy hh:MM TT");
    };

    // Process each row
    for (const row of data) {
        const rowValues = headers.map(header => {
            let value = row[header.key] || '';

            // Check if the column is a date and format it
            if (header.isDate && value) {
                value = formatDate(value);
            }

            // Escape double quotes in values
            const escaped = String(value).replace(/"/g, '""');
            return `"${escaped}"`;
        });

        csvRows.push(rowValues.join(','));
    }

    return csvRows.join('\n');
};


// Function to download the generated CSV as a .csv file
const download = (data, fileName) => {
    const blob = new Blob([data], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', fileName + '.csv');
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
}

// Wrapper function to generate and download CSV
export const generateCSV = (headers, data, filename) => {
    const csvData = arrayToCsv(headers, data);
    download(csvData, filename);
}