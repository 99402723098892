import { PRICE_BY_TYPES } from "../appConstants";
import { squareKilometersToAcres } from "./reusableFunctions";

export const getInvoiceInDetail = async (
    field_area,
    service_completed_area,
    price_by,
    pesticide_per_acre_subsidy,
    service_amount,
    total_amount,
    cgst,
    sgst,
    pesticides_subsidy,
    service_amount_with_subsidy,
    applied_coupons,
    applied_discounts
) => {
    service_completed_area = Number(service_completed_area)
    field_area = field_area
    const service_amount_per_acre = service_amount / field_area
    service_amount = service_amount_per_acre * (service_completed_area || field_area)
    pesticides_subsidy = price_by === PRICE_BY_TYPES.TANK ? (Number(pesticide_per_acre_subsidy) * Number(service_completed_area || field_area)) : Number(pesticide_per_acre_subsidy) * squareKilometersToAcres(Number(service_completed_area || field_area))
    service_amount_with_subsidy = Number(service_amount) - pesticides_subsidy
    applied_coupons = applied_coupons.filter(c => service_amount_with_subsidy >= Number(c.min_order_value))

    const bhumeetCoupons = applied_coupons.filter(ac => ac.coupon_type === "SYSTEM")
    const dspCoupons = applied_coupons.filter(ac => ac.coupon_type === "DSP")
    const applied_dsp_coupon_total_amount = getAppliedTotalAmountOfCoupon(service_amount_with_subsidy, dspCoupons[0])
    const applied_bhumeet_coupon_total_amount = getAppliedTotalAmountOfCoupon(service_amount_with_subsidy, bhumeetCoupons[0])
    const total_discounted_amount_via_coupons = applied_bhumeet_coupon_total_amount + applied_dsp_coupon_total_amount
    const total_dsp_discount_percentage = calculateTotalDiscount(applied_discounts)
    const total_discounted_amount_via_dsp = calculatePercentageTotal(service_amount_with_subsidy, total_dsp_discount_percentage)
    const service_amount_with_applied_discount_and_coupons = service_amount_with_subsidy - total_discounted_amount_via_coupons - total_discounted_amount_via_dsp
    const applied_csgt_amount = calculatePercentageTotal(service_amount_with_applied_discount_and_coupons, cgst)
    const applied_sgst_amount = calculatePercentageTotal(service_amount_with_applied_discount_and_coupons, sgst)
    const total_amount_with_applied_coupons_and_gst_and_discounts = service_amount_with_applied_discount_and_coupons + applied_csgt_amount + applied_sgst_amount


    return {
        service_amount: Number(service_amount).toFixed(2),
        total_dsp_discount_percentage: Number(total_dsp_discount_percentage).toFixed(2),
        applied_csgt_amount: Number(applied_csgt_amount).toFixed(2),
        pesticides_subsidy: Number(pesticides_subsidy).toFixed(2),
        applied_sgst_amount: Number(applied_sgst_amount).toFixed(2),
        service_amount_with_applied_discount_and_coupons: Number(service_amount_with_applied_discount_and_coupons).toFixed(2),
        total_discounted_amount_via_dsp: Number(total_discounted_amount_via_dsp).toFixed(2),
        total_amount_with_applied_coupons_and_gst_and_discounts: Number(total_amount_with_applied_coupons_and_gst_and_discounts).toFixed(2),
        discount_amount: Number(total_discounted_amount_via_dsp).toFixed(2),
        total_amount: Number(total_amount_with_applied_coupons_and_gst_and_discounts).toFixed(2),
        applied_dsp_coupon_total_amount: Number(applied_dsp_coupon_total_amount).toFixed(2),
        applied_bhumeet_coupon_total_amount: Number(applied_bhumeet_coupon_total_amount).toFixed(2),
        total_discounted_amount_via_coupons: Number(total_discounted_amount_via_coupons).toFixed(2),
        cgst: Number(cgst).toFixed(2),
        sgst: Number(sgst).toFixed(2),
        applied_coupons
    };
};

const getAppliedTotalAmountOfCoupon = (service_amount, coupon) => {
    if (coupon) {
        if (coupon.is_discount_in_percentage) {
            const totalAppliedAmountOfCouponViaPercentage = calculatePercentageTotal(service_amount, coupon.discount_percentage)
            return Number(totalAppliedAmountOfCouponViaPercentage < Number(coupon.max_discount_value) ? totalAppliedAmountOfCouponViaPercentage : coupon.max_discount_value)
        } else {
            return Number(coupon.discount_cost)
        }
    } else return 0
}

const calculateTotalDiscount = (applied_discounts) => {
    return applied_discounts.reduce((total, discountObj) => {
        return total + discountObj.discount;
    }, 0);
};

const calculatePercentageTotal = (value, percentage) => (value * percentage) / 100;

