import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom"
import Toggle from "react-toggle"
import Modal from '../../ReusableComponents/Modal'
import { CrossCloseButton } from '../../Components/Helper'
import { updateDSP } from '../../apis/adminAPIs/dsp'
import Popup from '../../ReusableFunctions/Popup'
import { getLocation } from '../../apis/locations'
import { getFormatedDate } from '../../ReusableFunctions/reusableFunctions'
import { GLOBAL_STYLES } from '../../appConstants'
import MainContentWrapper from '../../Components/MainContentWrapper'

const ViewOperationDsp = ({ dsp, callback, hideBankDetails }) => {
    const navigate = useNavigate()
    const [location, setLocation] = useState([])
    const getLocationFunc = async () => {
        try {

            const location = await getLocation(dsp.operator_location_id);
            setLocation(location)
        } catch (err) {
            Popup.alert("ERROR", err, "ERROR")
        }
    }
    const updateDSPFunc = async (dsp) => {
        try {
            await updateDSP({ id: dsp.dsp_id, is_active: dsp.dsp_is_active })
            callback(dsp);
        } catch (err) {
            Popup.alert("Error", err, 'ERROR')
        }
    }

    useEffect(() => {
        if (dsp.operator_location_id)
            getLocationFunc()
    }, [])
    return (
        <MainContentWrapper containerStyle={{
            height: "750px",
        }} >
            <div style={{ height: "100%", background: "rgb(244, 244, 244)", padding: "0px 0px 20px 0px" }}>
                <div style={{ display: "flex", justifyContent: "space-between", gap: "20px", background: "#D9D9D9D9", padding: "20px" }}>

                    <div style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center", gap: "10px" }}>
                        <div style={{ fontSize: "14px" }}>DSP Details</div>
                        <div style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
                            <label style={{ display: "flex", alignItems: "center", justifyContent: "center" }} onClick={(e) => e.stopPropagation()}>
                                <Toggle
                                    className='admin'
                                    checked={dsp.dsp_is_active}
                                    icons={false}
                                    onChange={(e) => {
                                        const provider = { ...dsp, dsp_is_active: e.target.checked }
                                        if (e.target.checked)
                                            updateDSPFunc(provider)
                                        else
                                            Popup.alert("Warning", "Are u sure u want to deactivate this account? Related teams accounts and app will be shut down!", "WARNING", () => updateDSPFunc(provider))
                                    }}
                                />
                            </label>
                            <span style={{ color: dsp.dsp_is_active ? "green" : "red", fontSize: GLOBAL_STYLES.FONT_SIZE_SMALL }}>{dsp.dsp_is_active ? "DSP Active" : "DSP Inactive"}</span>
                        </div>
                    </div>
                </div>
                <div style={{ overflowY: "auto", height: "calc(100% - 50px)", fontSize: GLOBAL_STYLES.FONT_SIZE_MEDIUM, padding: "20px" }}>
                    <div style={{ fontWeight: 600, marginBottom: "10px" }}>Owner Details</div>
                    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "20px" }}>

                        <div style={{ display: "flex" }}>
                            <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }} >Id</div>
                            <div style={{ margin: "0px 10px" }}>:</div>
                            <div style={{}}>{dsp.operator_id || "-"}</div>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Name</div>
                            <div style={{ margin: "0px 10px" }}>:</div>
                            <div style={{}}>{dsp.operator_name || "-"}</div>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Address</div>
                            <div style={{ margin: "0px 10px" }}>:</div>
                            <div style={{}}>{dsp.operator_address || "-"}</div>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Created On</div>
                            <div style={{ margin: "0px 10px" }}>:</div>
                            <div style={{}}>{getFormatedDate(dsp.dsp_date_created) || "-"}</div>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Phone number</div>
                            <div style={{ margin: "0px 10px" }}>:</div>
                            <div style={{}}>{dsp.operator_phone || "-"}</div>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Identity</div>
                            <div style={{ margin: "0px 10px" }}>:</div>
                            <div style={{}}>
                                {(dsp.operator_aadhar_url || dsp.operator_pan_url) ? <a target='_blank' style={{ color: "blue" }} href={dsp.operator_aadhar_url || dsp.operator_pan_url || ""}>{'View'}</a> : "-"}
                            </div>
                        </div>

                        <div style={{ display: "flex" }}>
                            <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Location</div>
                            <div style={{ margin: "0px 10px" }}>:</div>
                            <div style={{}}>{location.name || "-"}</div>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>PDRL admin</div>
                            <div style={{ margin: "0px 10px" }}>:</div>
                            <div style={{ color: dsp.operator_is_pdrl_admin ? "green" : "red" }}>{dsp.operator_is_pdrl_admin ? "Yes" : "No" || "-"}</div>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Admin</div>
                            <div style={{ margin: "0px 10px" }}>:</div>
                            <div style={{ color: dsp.operator_is_admin ? "green" : "red" }}>{dsp.operator_is_admin ? "Yes" : "No" || "-"}</div>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Account Status</div>
                            <div style={{ margin: "0px 10px" }}>:</div>
                            <div style={{ color: dsp.operator_is_active ? "green" : "red" }}>{dsp.operator_is_active ? "Active" : "Inactive" || "-"}</div>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Email</div>
                            <div style={{ margin: "0px 10px" }}>:</div>
                            <div style={{}}>{dsp.operator_email || "-"}</div>
                        </div>
                    </div>


                    <br />
                    <div style={{ fontWeight: 600, margin: "10px 0px" }}>Company details</div>
                    <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "20px" }}>

                        <div style={{ display: "flex" }}>
                            <div >Registration Number</div>
                            <div style={{ margin: "0px 10px" }}>:</div>
                            <div style={{}}>{dsp.dsp_registration_number || "-"}</div>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Dsp Id</div>
                            <div style={{ margin: "0px 10px" }}>:</div>
                            <div style={{}}>{dsp.dsp_id || "-"}</div>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Name</div>
                            <div style={{ margin: "0px 10px" }}>:</div>
                            <div style={{}}>{dsp.dsp_name || "-"}</div>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Logo</div>
                            <div style={{ margin: "0px 10px" }}>:</div>
                            <div style={{ display: "flex" }}>
                                {dsp.dsp_logo_url ? <a target='_blank' style={{ color: "blue" }} href={dsp.dsp_logo_url || ""}>View</a> : "-"}
                            </div>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Verified</div>
                            <div style={{ margin: "0px 10px" }}>:</div>
                            <div style={{ color: dsp.dsp_is_verified ? "green" : "red" }}>{dsp.dsp_is_verified ? "Yes" : "No" || "-"}</div>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Status</div>
                            <div style={{ margin: "0px 10px" }}>:</div>
                            <div style={{ color: dsp.dsp_is_active ? "green" : "red" }}>{dsp.dsp_is_active ? "Active" : "Inactive" || "-"}</div>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Information</div>
                            <div style={{ margin: "0px 10px" }}>:</div>
                            <div style={{}}>{dsp.dsp_information || "-"}</div>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Created on</div>
                            <div style={{ margin: "0px 10px" }}>:</div>
                            <div style={{}}>{getFormatedDate(dsp.dsp_date_created) || "-"}</div>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Address</div>
                            <div style={{ margin: "0px 10px" }}>:</div>
                            <div style={{}}>{dsp.dsp_address || "-"}</div>
                        </div>
                        <div style={{ display: "flex" }}>
                            <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>PAN No.</div>
                            <div style={{ margin: "0px 10px" }}>:</div>
                            <div style={{}}>
                                {dsp?.operator_pan_url ? <a target='_blank' style={{ color: "blue" }} href={dsp?.operator_pan_url || ""}>{'View'}</a> : "-"}
                            </div>
                        </div>
                    </div>
                    <br />
                    {!hideBankDetails && (
                        <>
                            <div style={{ fontWeight: 600, margin: "10px 0px" }}>Bank details</div>
                            <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "20px" }}>

                                <div style={{ display: "flex" }}>
                                    <div >Bank Name</div>
                                    <div style={{ margin: "0px 10px" }}>:</div>
                                    <div style={{}}>{dsp.bank_name || "-"}</div>
                                </div>
                                <div style={{ display: "flex" }}>
                                    <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>IFSC Code</div>
                                    <div style={{ margin: "0px 10px" }}>:</div>
                                    <div style={{}}>{dsp.ifsc_code || "-"}</div>
                                </div>
                                <div style={{ display: "flex" }}>
                                    <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Account No.</div>
                                    <div style={{ margin: "0px 10px" }}>:</div>
                                    <div style={{}}>{dsp.account_no || "-"}</div>
                                </div>
                                <div style={{ display: "flex" }}>
                                    <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Account Holder Name</div>
                                    <div style={{ margin: "0px 10px" }}>:</div>
                                    <div style={{}}>{dsp.account_holder_name || "-"}</div>
                                </div>
                                <div style={{ display: "flex" }}>
                                    <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Account Type</div>
                                    <div style={{ margin: "0px 10px" }}>:</div>
                                    <div style={{}}>{dsp.account_type || "-"}</div>
                                </div>
                                <div style={{ display: "flex" }}>
                                    <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Branch Address</div>
                                    <div style={{ margin: "0px 10px" }}>:</div>
                                    <div style={{}}>{dsp.branch_address || "-"}</div>
                                </div>
                                <div style={{ display: "flex" }}>
                                    <div style={{ color: GLOBAL_STYLES.FONT_COLOR_SUB }}>Account Status</div>
                                    <div style={{ margin: "0px 10px" }}>:</div>
                                    <div style={{ color: dsp.acc_status ? "green" : "red" }}>{dsp.acc_status ? "Active" : "Inactive" || "-"}</div>
                                </div>
                            </div>
                        </>

                    )}
                </div>
            </div>
        </MainContentWrapper>
    )
}

export default ViewOperationDsp