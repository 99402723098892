import { buildUrl } from "../../../ReusableFunctions/reusableFunctions";
import { SERVER_URLS } from "../../../appConstants";
import { getToken } from "../../authentication";

const DSP_WALLET_BASE_URL = `${SERVER_URLS.BACKEND_SERVER}/bhumeet_team/finance/dsp/wallet`

export const getAdminDspWalletBalance = async (dspId) => {
    try {
        const token = getToken();
        const response = await fetch(
            DSP_WALLET_BASE_URL + `/balance?dspId=${dspId}`,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        const res = await response.json();
        if (!response.ok) {
            throw (res.message);
        }

        return res?.dspWalletBalance;
    } catch (err) {
        throw err;
    }
}

export const getAdminDspBankDetails = async (dspId) => {
    try {
        const token = getToken();
        const response = await fetch(
            DSP_WALLET_BASE_URL + `/bankdetails?dspId=${dspId}`,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        const res = await response.json();
        if (!response.ok) {
            throw (res.message);
        }
        return res?.bankDetails;
    } catch (err) {
        throw err;
    }
}