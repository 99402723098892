import React, { useEffect, useState } from "react";
import { getRequests } from "../../apis/requests";
import { useLocation, useNavigate } from "react-router";
import Popup from "../../ReusableFunctions/Popup";
import Loading from "../../ReusableFunctions/Loader";
import MainContentWrapper from "../../Components/MainContentWrapper"
import RequestOverView from "./SubComponents/RequestOverView/RequestOverView";
import calender from "../../assets/calender-new.svg"
import calenderActive from "../../assets/calender-active.svg"
import lines from "../../assets/lines.png"
import lineActive from "../../assets/lineActive.png"
import CalenderComponent from "./Calender/CalenderComponent";
import RequestTable from "./SubComponents/RequestTable/RequestTable";
import CalenderViewRequestTable from "./SubComponents/CalenderViewRequestTable/CalenderViewRequestTable";
import { toDate } from "date-fns";
import { Button } from "../../Components/Helper";
import CreateRequest from "./CreateRequest";

const MyRequests = () => {
  const { state } = useLocation();
  const { search } = useLocation();
  const params = new URLSearchParams(search)
  const requestPerPage = 6
  const [requests, setRequests] = useState([]);
  const [isCalenderView, setIsCalenderView] = useState(state?.isCalenderView || params?.get('isCalenderView'))
  const [pilotId, setPilotId] = useState(state?.pilotId || params?.get('pilotId'))
  const [pilotName, setPilotName] = useState(state?.pilotName || params?.get('pilotName'))
  const [isGraphView, setIsGraphView] = useState(!(state?.isCalenderView || params?.get('isCalenderView')))
  const [selectedDate, setSelectedDate] = useState(new Date())
  const [loading, setLoading] = useState(true);
  const [filetrs, setFilters] = useState({ services: [], statuses: [], locations: [] })
  const [pageNumber, setpageNumber] = useState(undefined);
  const [filterDateTime, setFilterDateTime] = useState({ fromDate: undefined, toDate: undefined })
  const [searchString, setSearchString] = useState('')
  const [openCreateRequestPopup, setOpenCreateRequestPopup] = useState(false)

  const getRequestsAPI = async (pageSize, pageNumber, filters, searchQuery, fromDate, toDate) => {
    try {
      setLoading(true)
      const data = await getRequests(state?.userId, filters, pageSize, pageNumber, searchQuery, fromDate, toDate)
      setRequests(data.requests);
      setLoading(false)
    } catch (err) {
      console.log("errr:", err)
      setLoading(false)
      Popup.alert("Error", err, "ERROR");
    }
  };

  const getRequest = async () => {
    Loading.set(true);
    setLoading(true);
    await getRequestsAPI(requestPerPage, 1, null)
    Loading.set(false);
    setLoading(false);
  }

  useEffect(() => {
    document.title = "BhuMeet - My Requests";
    if (!pilotId && !isCalenderView)
      getRequest()
  }, [state?.userId, isGraphView, isCalenderView, pilotId]);

  return (
    <>
      <MainContentWrapper header={pilotId ? "" : 'My Requests'}
        rightContent={
          (pilotId) ? <></> :
            <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
              <Button
                style={{ height: '33px', fontSize: '14px' }}
                text={"Create Request"}
                onClick={() => {
                  setOpenCreateRequestPopup(true)
                }}
              />
              <div style={{ display: "flex", gap: "", alignItems: "center", border: '1px solid rgb(132 132 132 / 39%)', borderRadius: "3px", justifyContent: 'center' }}>
                <div style={{ background: isGraphView ? "" : "rgba(15, 94, 255, 0.1)", width: "30px", height: "30px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <img src={isCalenderView ? calenderActive : calender} alt="calender icon" style={{ width: "16px", cursor: "pointer", }} onClick={() => {
                    if (!isCalenderView) {
                      setIsCalenderView(true)
                      setRequests([])
                      setIsGraphView(false)
                    }
                  }} />
                </div>
                <div style={{ width: '1px', background: 'rgb(132 132 132 / 39%)', height: '100%' }}></div>
                <div style={{ background: isCalenderView ? "" : "rgba(15, 94, 255, 0.1)", height: "100%", display: "flex", alignItems: 'center', width: '30px', justifyContent: "center", height: "30px" }}>
                  {isCalenderView ?
                    <div style={{ margin: 0, padding: 0 }}>
                      <img src={isGraphView ? lineActive : lines} alt="lines icon" style={{ width: "18px", cursor: "pointer", }} onClick={() => {
                        setIsGraphView(true)
                        setIsCalenderView(false)
                      }} />
                    </div> :
                    <img src={isGraphView ? lineActive : lines} alt="lines icon" style={{ width: "18px", cursor: "pointer", padding: '' }} onClick={() => {
                      setIsGraphView(true)
                      setIsCalenderView(false)
                    }} />
                  }
                </div>
              </div>
            </div>
        }>
        {openCreateRequestPopup &&
          <CreateRequest
            onClose={() => {
              setOpenCreateRequestPopup(false);
              getRequest()
            }}
          />
        }
        <div style={{ width: "100%", maxWidth: "100%", height: "93%", display: "flex", flexDirection: isGraphView ? "column" : "row", gap: "20px" }}>
          {state?.userId ?
            "" :
            isGraphView ?
              <RequestOverView /> :
              <div style={{ width: "50%", height: "max-content" }}>
                <CalenderComponent
                  user={{ ...state, pilotId, pilotName, isCalenderView: true }}
                  onDateChange={(req, date, requestCount) => {
                    setRequests(req)
                    setSelectedDate(date)
                  }}
                />
              </div>
          }
          <div style={{ height: isGraphView ? 'calc(100% - 390px)' : "100%", width: isGraphView ? "100%" : "50%" }}>
            {isGraphView ?
              <RequestTable
                pageNumber={pageNumber}
                requests={requests}
                dateTime={filterDateTime}
                onFilterChange={(filters, dateTime) => {
                  setFilters(filters)
                  setFilterDateTime(dateTime)
                  setpageNumber(1)
                  getRequestsAPI(requestPerPage, 1, filters, searchString, dateTime ? new Date(dateTime.fromDate).toISOString() : undefined, dateTime ? new Date(dateTime.toDate).toISOString() : undefined)
                }}
                loading={loading}
                setLoading={setLoading}
                onSearch={(searchQuery) => {
                  setSearchString(searchQuery)
                  getRequestsAPI(requestPerPage,
                    1,
                    filetrs,
                    searchQuery,
                    filterDateTime && filterDateTime.fromDate ? new Date(filterDateTime.fromDate).toISOString() : undefined,
                    filterDateTime && filterDateTime.toDate ? new Date(filterDateTime.toDate).toISOString() : undefined)
                }}
                tabelContainerHeight={"401px"}
                onCurrentPageChange={(pageNumber) => {
                  setpageNumber(pageNumber)
                  getRequestsAPI(requestPerPage,
                    pageNumber,
                    filetrs,
                    searchString,
                    filterDateTime && filterDateTime.fromDate ? new Date(filterDateTime.fromDate).toISOString() : undefined,
                    filterDateTime && filterDateTime.toDate ? new Date(filterDateTime.toDate).toISOString() : undefined)
                }}
                totalCount={requests[0]?.total_count || 0}
                pageSize={requestPerPage}
              /> :
              <CalenderViewRequestTable
                user={{ ...state, pilotId, pilotName, isCalenderView: true }}
                requests={requests}
                date={selectedDate}
              />
            }
          </div>
        </div>
      </MainContentWrapper >
    </>
  );
};

export default MyRequests;