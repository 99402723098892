import React from 'react';
import { Controller, useFormContext } from "react-hook-form";
import { findInputError } from '../utils/findInputError';
import { isFormInvalid } from '../utils/isFormValid';
import { capitalizeFirstLetter } from '../ReusableFunctions/reusableFunctions';

export default function CustomSelect({ name, validation, label, labelStyle, onChange, value }) {
    const {
        control,
        formState: { errors },
        clearErrors,
    } = useFormContext();

    const inputError = findInputError(errors, name);
    const isInvalid = isFormInvalid(inputError);

    return (
        <div className="flex flex-col  w-full">
            <div style={{ fontSize: '13px', color: '#3B3B3B', marginBottom: "5px", ...labelStyle }}>{capitalizeFirstLetter(label)}</div>
            <Controller
                name={name}
                value={value}
                control={control}
                rules={validation}
                render={({ field }) => (
                    <div className="flex px-[0.5px] space-x-6 min-h-[40px] ">
                        <label className="flex items-center">
                            <input
                                type="radio"
                                value="true"
                                checked={value === true}
                                onChange={() => onChange(true)}
                                className="form-radio w-5 h-5 outline-none focus:outline-none text-blue-600 transition-colors"
                            />
                            <span className="ml-2 text-[15px] font-[500] text-[#1E1E1E]">Public</span>
                        </label>
                        <label className="flex items-center">
                            <input
                                type="radio"
                                value="false"
                                checked={value === false}
                                onChange={() => onChange(false)}
                                className="form-radio w-5 h-5 outline-none focus:outline-none text-blue-600 transition-colors"
                            />
                            <span className="ml-2 text-[15px] font-[500] text-[#1E1E1E]">Private</span>
                        </label>
                    </div>
                )}
            />
            {isInvalid && (
                <p className="text-sm text-red-600 mt-1">{inputError.message}</p>
            )}
        </div>
    );
}

