import React, { useEffect } from 'react'
import { Controller, useFormContext } from "react-hook-form"
import { GLOBAL_STYLES } from '../appConstants'
import Select from "react-select"
import { findInputError } from '../utils/findInputError'
import { isFormInvalid } from '../utils/isFormValid'
import InputError from './InputError'
import { capitalizeFirstLetter } from '../ReusableFunctions/reusableFunctions'

const SelectField = ({
    name,
    validation,
    label,
    className,
    list,
    isMulti,
    closeMenuOnSelect,
    menuPortalTarget,
    isLoading,
    isSearchable = false,
    isDisabled,
    defaultValue,
    handleChange,
    styles,
    controlStyle,
    placeholderText,
    ...restProps
}) => {
    const {
        formState: { errors },
        control,
        clearErrors,
        watch
    } = useFormContext()

    const selectedValue = watch(name);

    useEffect(() => {
        if (handleChange && selectedValue?.value) {
            handleChange(selectedValue);
        }
    }, [selectedValue?.value]);

    const inputError = findInputError(errors, name)
    const isInvalid = isFormInvalid(inputError)

    const defaultStyles = {
        control: (provided, state) => ({
            ...provided,
            fontSize: "12px",
            background: "#F3F3F3",
            borderRadius: "5px",
            borderColor: isInvalid ? 'red' : GLOBAL_STYLES.BORDER_COLOR,
            outline: "none",
            fontWeight: 600,
            color: "black",
            height: "35px",
            ...controlStyle
        }),
        option: (provided, state) => ({
            ...provided,
            fontSize: "12px",
            boxShdow: GLOBAL_STYLES.BOX_SHADOW,
            color: state.isFocused && !state.isSelected ? "black" : state.isSelected ? "white" : state.isSelected && state.isFocused ? "white" : "black",
            backgroundColor: state.isFocused && !state.isSelected ? "rgba(0, 0, 0, 0.1)" : state.isSelected ? GLOBAL_STYLES.BG_ACTIVE : GLOBAL_STYLES.BG_INACTIVE,
            minHeight: "35px",
            ...restProps.optionStyle
        }),
        valueContainer: (provided, state) => ({
            ...provided,
            ...restProps.valueContainerStyle
        }),
        menu: (provided, state) => ({
            ...provided,
            ...restProps.menuStyles,
        }),
        menuList: (provided, state) => ({
            ...provided,
            ...restProps.menuListStyles,
        }),
        indicatorsContainer: (provided, state) => ({
            ...provided,
            ...restProps.indicatorsContainerStyle
        }),
        indicatorContainer: (provided, state) => ({
            ...provided,
            ...restProps.indicatorContainerStyle
        }),
        dropdownIndicator: (provided, state) => ({
            ...provided,
            ...restProps.dropdownIndicatorStyle
        }),
        singleValue: (provided, state) => ({
            ...provided,
            ...restProps.singleValueStyle
        }),
        loadingIndicator: (provided, state) => ({
            ...provided,
            color: "#a1bffd",
            fontSize: "5px",
            ...restProps.loadingIndicatorStyle,
        }),
        menuPortal: (base) => ({ ...base, zIndex: 9999 })
    };
    return (
        <div style={{ display: "flex", flexDirection: "column", rowGap: "5px", position: "relative", ...restProps.containerStyle }}>
            {label && (
                <div style={{ fontSize: "13px", color: "#3B3B3B" }}>
                    {capitalizeFirstLetter(label)}
                </div>
            )}
            <Controller
                name={name}
                control={control}
                defaultValue={defaultValue}
                rules={validation}
                render={({ field }) => (
                    <Select
                        name={name}
                        menuPlacement='auto'
                        className={className || ""}
                        classNamePrefix="react-select"
                        options={list}
                        isMulti={isMulti}
                        closeMenuOnSelect={closeMenuOnSelect}
                        menuPortalTarget={menuPortalTarget}
                        styles={defaultStyles}
                        placeholder={placeholderText}
                        isLoading={isLoading}
                        onChange={(selectedOption) => {
                            field.onChange(selectedOption);
                            handleChange(selectedOption)
                            clearErrors();
                        }}
                        theme={(theme) => ({
                            ...theme,
                            colors: {
                                ...theme.colors,
                                primary25: GLOBAL_STYLES.BG_ACTIVE,
                                primary: GLOBAL_STYLES.BG_ACTIVE
                            }
                        })}
                        {...field}
                        isSearchable={isSearchable}
                        isDisabled={isDisabled}
                        {...restProps}
                    />
                )}
            />
            {isInvalid && (
                <InputError
                    inputError={inputError}
                />
            )}
        </div>
    )
}

export default SelectField