import React, { useCallback, useEffect, useState } from 'react'
import Plus from "../../../assets/Plus.svg";
import sprayIcon from "../../../assets/sprayingicon.svg";
import { Button } from '../../../Components/Helper';


export default function CouponFilters({
    setCouponId,
    setopenCouponPopup,
}) {
    return (
        <div className='first_Section_Wrapper'>
            <div className='subheader'>
                <p> All Coupons</p>
                <div className='spraying_icon'>
                    <div className='flex gap-1'>
                        <img src={sprayIcon} alt={"sparying"} width={"35px"} height={"35px"} />
                        <span style={{ color: "#0F5EFF", fontWeight: 700 }}>Spraying</span>
                    </div>
                </div>
            </div>
            <div className='create_coupon_button'>
                {
                    (
                        <Button type="submit" icon={Plus} className={"btn-large"}
                            style={{ width: '135px', fontSize: "12px", height: "38px" }} text={"Create Coupon"} onClick={() => {
                                setCouponId(null);
                                setopenCouponPopup(true)
                            }} />
                    )
                }
            </div>

        </div>
    )
}
