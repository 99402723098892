import React from 'react'
import currencyIcon from '../../../assets/currency.svg'
import Tippy from '@tippyjs/react';
import { OptionMenu } from '../../../Components/Helper';
import more from "../../../assets/whiteDots.svg"

export default function AddBankAccCard({ setOpenbankdetailsPopup, bankdetails }) {
    return (
        !bankdetails?.id ?
            <div className="add_bank_account_wrapper" onClick={() => {
                setOpenbankdetailsPopup(true)
            }}>

                <h3>Add Bank Account  +</h3>
                <div className='first_currency_sign'>
                    <img src={currencyIcon} alt='' width={"50px"} height={"50px"} />
                </div>
                <div className='second_currency_sign'>
                    <img src={currencyIcon} alt='' width={"60px"} height={"60px"} />
                </div>
            </div> :
            <div className="bank_Details_info" >
                <div className='bankdetails_wrppaer'>
                    <h3 className='heading'>Bank Details</h3>
                    <div className='bank_details'>
                        <p>{bankdetails?.account_holder_name}</p>
                        <p>{bankdetails?.bank_name}</p>
                        <p><span>Acc No{" "}-</span>{bankdetails?.account_no}</p>
                        <p><span>IFSC {" "}-</span>{bankdetails?.ifsc_code}</p>
                    </div>

                </div>
                <div className='menus'>
                    <Tippy offset={[-10, 5]} interactive onShown={(instance) => {
                        document.querySelector('[data-tippy-root]').addEventListener('click', event => {
                            instance.hide();
                        })
                    }} className='tippy' trigger='click' arrow={false} content={
                        <OptionMenu
                            isLeft
                            onClickEdit={() => {
                                setOpenbankdetailsPopup(true);
                            }}
                        />
                    } >
                        <img id='menu' src={more} alt='more' style={{ width: "20px", cursor: "pointer" }} onClick={(e) => {
                            e.preventDefault()
                        }} />
                    </Tippy>
                </div>
                <div className='first_currency_sign'>
                    <img src={currencyIcon} alt='' width={"50px"} height={"50px"} />
                </div>
                <div className='second_currency_sign'>
                    <img src={currencyIcon} alt='' width={"60px"} height={"60px"} />
                </div>
            </div >
    )
}
