import { SERVER_URLS } from "../../../appConstants";
import { getToken } from "../../authentication";

export const getOrdersStatsAPI = ({ dsps, statuses, fromDate, toDate, isFilterByServiceTime, paymentMethods, onlyAppliedBhuMeetCouponsRequests }) => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = getToken();
            const queryParams = new URLSearchParams();

            if (fromDate && toDate) {
                queryParams.append("fromDate", new Date(fromDate).toISOString());
                queryParams.append("toDate", new Date(toDate).toISOString());
            }

            if (paymentMethods) {
                queryParams.append("paymentMethods", JSON.stringify(paymentMethods));
            }

            if (dsps?.length > 0) {
                queryParams.append("dsps", JSON.stringify(dsps));
            }
            if (statuses?.length > 0) {
                queryParams.append("statuses", JSON.stringify(statuses));
            }

            if (isFilterByServiceTime) {
                queryParams.append("isFilterByServiceTime", true);
            }

            if (onlyAppliedBhuMeetCouponsRequests) {
                queryParams.append("onlyAppliedBhuMeetCouponsRequests", true);
            }

            const url = `${SERVER_URLS.BACKEND_SERVER}/bhumeet_team/finance/orders/stats?${queryParams.toString()}`;

            const response = await fetch(url, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const data = await response.json();
            if (response.ok) {
                resolve(data);
            } else {
                reject(
                    data?.message || "Something went wrong while getting overview."
                );
            }
        } catch (error) {
            reject("Something went wrong while getting overview.");
        }
    });
}
