
import InputField from "../../ReusableComponents/InputField";
import SelectField from "../../ReusableComponents/SelectField";
import { AEROMEGH_SERVICES, PRICE_BY_TYPES } from "../../appConstants";
import { Button } from "../../Components/Helper";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import Form from "../../ReusableComponents/Form";
import Popup from "../../ReusableFunctions/Popup";
import { createRequestFromDSP } from "../../apis/requests";
import { getDronePilots } from "../../apis/dronePilots";
import { getDSPDrones } from "../../apis/drones";
import { getSprayingServiceDetails } from "../../apis/services";
import { getCropsByLocation } from "../../apis/crops";
import { getLocations } from "../../apis/locations";
import { getUserFromMobileNumber } from "../../apis/users";
import SelectLocation from "../MyLocations/SubComponents/SelectLocation";
import CustomToggle from "../../ReusableComponents/CustomToggle";
import Modal from "../../ReusableComponents/Modal";
import { modelNameValidation, nameValidation, phoneNumValidation, selectFieldValidation } from "../../utils/inputValidations";
import { setHours, setMinutes, isSameDay } from "date-fns"; // Assuming you're using date-fns for date utilities
import { acresToSquareKilometers } from "../../ReusableFunctions/reusableFunctions";
import CreatableSelectField from "../../ReusableComponents/CreatableSelectField";
import { v4 as uuid } from 'uuid'
import Calender from "../../assets/blueCalendar.svg"
import Clock from "../../assets/blueClock.svg"
import Loading from "../../ReusableFunctions/Loader";
const CreateRequest = ({ onClose }) => {

    function setNext15MinuteInterval() {
        const date = new Date(); // Get the current date and time

        const minutes = date.getMinutes(); // Get the current minutes
        const nextInterval = Math.ceil(minutes / 15) * 15; // Calculate the next 15-min interval

        // If the next interval is 60, move to the next hour
        if (nextInterval === 60) {
            date.setHours(date.getHours() + 1); // Increment the hour
            date.setMinutes(0); // Set minutes to 0 (start of next hour)
        } else {
            date.setMinutes(nextInterval); // Set the minutes to the next interval
        }

        date.setSeconds(0); // Reset seconds to 0
        date.setMilliseconds(0); // Reset milliseconds to 0

        return date;
    }
    // Set the time to 11:00 AM
    const [request, setRequest] = useState({
        mobile_number: "",
        time: new Date(setNext15MinuteInterval()),
        field_lat: "",
        field_lng: "",
        field_name: { label: "", value: "" },
        field_address: "",
        field_area: null,
        price_by: false,
        crop: { label: "", value: "" },
        crop_stage: { label: "", value: "" },
        pesticides: { label: "", value: "" },
        location_id: { label: "", value: "" },
        service_amount: null,
        cgst: null,
        sgst: null,
        total_amount: null,
        name: "",
    })
    const [crops, setCrops] = useState([])
    const [cropStages, setCropStages] = useState([])
    const [locations, setLocations] = useState([])
    const [pilots, setPilots] = useState([])
    const [drones, setDrones] = useState([])
    const [pesticides, setPesticides] = useState([])
    const [userLocations, setUserLocations] = useState([])
    const [mapLocation, setMapLocation] = useState(null)
    const [disableName, setDisableName] = useState(false)
    const [isFetchingUser, setIsFetchingUser] = useState(false)
    const [showDatePicker, setShowDatePicker] = useState(false)
    const [showTimePicker, setShowTimePicker] = useState(false)
    const [loading, setLoading] = useState(true)
    const [cropsLoading, setCropsLoading] = useState(false)
    const handleAddNewAgroChemical = (newOption) => {
        if (newOption) {
            const newAgro = {
                id: uuid(),
                chemical_name: newOption,
                subsidy: null
            }
            setPesticides(prev => ([...prev, newAgro]))
            setRequest(prev => ({ ...prev, pesticides: { label: newAgro.chemical_name, value: newAgro.id } }))
        }
    }
    const initData = async () => {
        try {
            const { dronePilots } = await getDronePilots()
            setPilots(dronePilots)
            const drones = await getDSPDrones()
            setDrones(drones)
            const { parameters } = await getSprayingServiceDetails()
            const { crop_stages, pesticides, cgst, sgst } = parameters
            setRequest(prev => ({ ...prev, cgst: Number(cgst), sgst: Number(sgst) }))
            setCropStages(crop_stages)
            setRequest(prev => ({ ...prev, crop_stage: { label: crop_stages[0], value: crop_stages[0] } }))
            setPesticides(pesticides)
            if (pesticides.length > 0) {
                setRequest(prev => ({ ...prev, pesticides: { label: pesticides[0].chemical_name, value: pesticides[0].id } }))
            }
            const locations = await getLocations({ is_active: true, })
            if (locations.length > 0) {
                const firstLoc = locations[0]
                const { locationcropslist } = await getCropsByLocation(firstLoc.id)
                setRequest(prev => ({ ...prev, location_id: { label: firstLoc.name, value: firstLoc.id, } }))
                if (locationcropslist.length > 0) {
                    const firstCrop = locationcropslist[0]
                    setCrops(locationcropslist || [])
                    setRequest(prev => ({ ...prev, crop: { label: firstCrop.crop_name, value: firstCrop.crop_id, } }))
                }
            }
            setLocations(locations)
            setLoading(false)
        } catch (e) {
            setLoading(false)
            console.log(e)
            Popup.alert("Something went wrong", e?.message, "ERROR")
        }
    }
    const getUserFromMobileNumberFunc = async (mobileNo) => {
        try {
            setIsFetchingUser(true)
            const { user } = await getUserFromMobileNumber(mobileNo)
            if (user) {
                setRequest(prev => ({ ...prev, name: user.user_name, mobile_number: mobileNo, }))
                setDisableName(true)
                setUserLocations(user.locations)
            } else {
                setDisableName(false)
            }
            setIsFetchingUser(false)
        } catch (e) {
            setIsFetchingUser(false)
            setDisableName(false)
            console.log(e)
        }
    }

    const handleCreateRequest = async (updatedReq) => {
        Loading.set(true)
        try {

            let finalReq = { ...request }
            finalReq.price_by = updatedReq.price_by == true ? PRICE_BY_TYPES.TANK : PRICE_BY_TYPES.ACRE
            finalReq.field_area = updatedReq.field_area;
            finalReq.crop_name = crops.find(c => c.crop_id == updatedReq?.crop?.value)?.crop_name || "";
            finalReq.crop_stage = cropStages.find(c => c == updatedReq?.crop_stage?.value)
            //add field addres, name ,lat lng etc
            finalReq.location_id = updatedReq?.location_id?.value;
            finalReq.mobile_number = updatedReq.mobile_number;
            finalReq.name = updatedReq.name
            finalReq.time = new Date(updatedReq.time).toISOString() //check this
            //calculate service and total amount
            finalReq.cgst = updatedReq.cgst;
            finalReq.sgst = updatedReq.sgst;



            // CALCULATIONS
            const field_area = Number(finalReq.field_area)
            console.log('field_area',field_area)
            const crops_price = Number((crops.find(c => c.crop_id == finalReq?.crop?.value)?.cost) || 0)
            console.log('crops_price',crops_price)
            const service_amount = field_area * crops_price
            console.log('service_amount',service_amount)
            const subsidy_per_acre = Number(pesticides.find(p => p.id == finalReq?.pesticides?.value)?.subsidy || 0)

            let total_subsidy = subsidy_per_acre * field_area
            console.log('total_subsidy',total_subsidy)
            const service_amount_with_subsidy = service_amount - total_subsidy
            let service_amount_with_cgst_sgst = service_amount_with_subsidy;
            let cgst_amount = 0;
            console.log('service_amount_with_cgst_sgst',service_amount_with_cgst_sgst)
            if (finalReq.cgst && Number(finalReq.cgst)) {
                cgst_amount = (service_amount_with_subsidy * Number(finalReq.cgst) / 100)
            }
            let sgst_amount = 0;
            if (finalReq.sgst && Number(finalReq.sgst)) {
                sgst_amount = (service_amount_with_subsidy * Number(finalReq.sgst) / 100)
            }
            service_amount_with_cgst_sgst = service_amount_with_subsidy + cgst_amount + sgst_amount
            console.log('service_amount_with_cgst_sgst',service_amount_with_cgst_sgst)
            let total_amount = service_amount_with_cgst_sgst
            total_amount = total_amount <= 0 ? 0 : total_amount

            finalReq.pesticides = pesticides.find(p => p.id == updatedReq?.pesticides?.value)?.chemical_name || "";
            finalReq.service_amount = service_amount;
            finalReq.total_amount = total_amount
            finalReq.field_name = mapLocation.name
            finalReq.field_address = mapLocation.address;
            finalReq.field_lat = mapLocation.lat;
            finalReq.field_lng = mapLocation.lng;
            finalReq.request_type = AEROMEGH_SERVICES.SPRAYING
            finalReq.field_area = finalReq.price_by == PRICE_BY_TYPES.ACRE ? acresToSquareKilometers(field_area) : field_area
            setRequest(updatedReq)
            await createRequestFromDSP(finalReq)
            Loading.set(false)
            Popup.alert('SUCCESS', 'Request created successfully', 'SUCCESS', onClose)
        }
        catch (err) {
            Loading.set(false)
            console.log(err)
            Popup.alert("ERROR", err || "Something went wrong while creating request", 'ERROR')
        }
    }
    useEffect(() => {
        initData()
    }, [])
    return <Modal
        header={"Create Request"}
        open={true}
        headerContainerStyle={{ padding: "20px 30px" }}
        onClose={onClose}
        closeOnDocumentClick={false}
        contentStyle={{ height: "675px", width: "1200px", boxShadow: " 0px 1px 4px 3px #76767626", border: "1px solid #BFC8D9", borderRadius: "8px", position: "relative" }}
    >
        <div style={{ display: 'flex', width: '100%', height: '100%' }}>
            <div style={{ width: "800px", }}>
                <SelectLocation
                    onLocationChange={(location) => {
                        setMapLocation(location)
                    }}
                />
            </div>
            <Form

                onSubmitForm={(req) => {
                    handleCreateRequest(req)
                }}
                initialValues={request}
                style={{ display: 'flex', flexDirection: 'column', width: "100%", height: 'calc(100% - 65px)', padding: "20px 30px", gap: '20px' }}
            >
                {(onSubmit, formValue) => (
                    <>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '100%' }}>
                            <div style={{ fontWeight: 500 }}>Customer Details</div>
                            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', gap: '30px' }}>
                                <InputField
                                    label={"Mobile Number"}
                                    placeholder={"Enter Mobile Number "}
                                    name={'mobile_number'}
                                    validation={phoneNumValidation}
                                    containerStyle={{ width: '100%' }}
                                    type={'text'}
                                    handleChange={(value) => {
                                        if (value.length === 10) {
                                            getUserFromMobileNumberFunc(value)
                                        } else {
                                            setDisableName(false)
                                        }
                                        setRequest(prev => ({ ...prev, mobile_number: value }))
                                    }}
                                />
                                <InputField
                                    containerStyle={{ width: '100%' }}
                                    label={"Name"}
                                    placeholder={"Enter Customer Name "}
                                    name={'name'}
                                    validation={nameValidation}
                                    type={'text'}
                                    disabled={disableName}
                                    handleChange={(value) => {
                                        setRequest(prev => ({ ...prev, name: value }))
                                    }}
                                />
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', width: "100%", height: '100%', gap: '10px' }}>
                            <div style={{ fontWeight: 500 }}>Service Details</div>
                            <div
                                id='coupen-form'
                                style={{
                                    width: "100%",
                                    height: '100%',
                                    display: 'grid',
                                    gridTemplateColumns: '1fr 1fr',
                                    gridAutoRows: 'auto',
                                    gap: '5px 30px',
                                    overflow: 'auto'
                                }} >
                                <SelectField
                                    isLoading={loading}
                                    isDisabled={isFetchingUser}
                                    label={'Location'}
                                    name='location_id'
                                    styles={{ width: "215px", height: "18px", fontSize: "13px", }}
                                    list={locations.map(l => ({ label: l.name, value: l.id }))}
                                    containerStyle={{
                                        width: "100%",
                                        maxWidth: "100%",
                                        minHeight: "80px",
                                    }}
                                    menuPortalTarget={document.getElementById('modalHolder')}
                                    controlStyle={{ height: "auto" }}
                                    handleChange={async (value) => {
                                        setCropsLoading(true)
                                        setRequest(prev => ({ ...prev, location_id: value, crop: null }))
                                        const { locationcropslist } = await getCropsByLocation(value.value)
                                        setCrops(locationcropslist || [])
                                        setCropsLoading(false)
                                    }}
                                    validation={selectFieldValidation}
                                />

                                <div style={{ display: 'flex', width: '100%', gap: '10px', alignItems: 'start' }}>
                                    <div className='input_field' style={{ gap: "5px", width: '100%' }}>
                                        <label htmlFor="expiryDate" style={{ fontSize: '13px', color: '#3B3B3B', }}>{'Date'}</label>
                                        <div
                                            className='input-date'
                                            style={{ position: 'relative', display: 'flex', flexDirection: 'column', rowGap: '5px', justifyContent: 'center', width: "100%", height: "35px", border: '1.4px solid rgba(217, 217, 217, 0.84)', }}
                                            onClick={() => setShowDatePicker(true)}
                                        >
                                            <DatePicker
                                                readOnly
                                                minDate={new Date()}
                                                onClickOutside={() => setShowDatePicker(false)}
                                                open={showDatePicker}
                                                value={new Date(request.time)}
                                                selected={new Date(request.time)}
                                                className="input-date-hidden"
                                                containerStyle={{ width: '100%' }}
                                                onChange={(date, e) => {
                                                    e.stopPropagation()
                                                    const selectedDate = new Date(date)
                                                    setRequest(prev => {
                                                        const updatedTime = prev.time ? new Date(prev.time) : new Date();  // Get current time if not selected
                                                        // Merge the selected date with the stored time
                                                        let updatedDateTime = new Date(
                                                            selectedDate.setHours(updatedTime.getHours(), updatedTime.getMinutes())
                                                        );
                                                        return { ...prev, time: new Date(updatedDateTime) }
                                                    })
                                                    setShowDatePicker(false)
                                                }}
                                            />
                                            <img src={Calender} alt='Calender' style={{ width: "18px", position: "absolute", right: "15px" }} />
                                        </div>
                                    </div>

                                    {/* <div className='input_field' style={{ gap: "5px", width: '100%' }}>
                                        <label htmlFor="expiryDate" style={{ fontSize: '13px', color: '#3B3B3B', }}>{'Time'}</label>
                                        <div
                                            className='input-date'
                                            style={{ position: 'relative', display: 'flex', flexDirection: 'column', rowGap: '5px', justifyContent: 'center', width: "100%", height: "35px", border: '1.4px solid rgba(217, 217, 217, 0.84)', }}
                                            onClick={() => setShowTimePicker(true)}
                                        >
                                            <DatePicker
                                                readOnly
                                                open={showTimePicker}
                                                onClickOutside={() => setShowTimePicker(false)}
                                                value={new Date(request.time)}
                                                selected={new Date(request.time)}
                                                className="input-date-hidden"
                                                shouldCloseOnSelect={true}
                                                showTimeSelect
                                                showTimeSelectOnly
                                                timeIntervals={15}
                                                timeCaption="Time"
                                                dateFormat="h:mm aa"
                                                minTime={
                                                    request.time && isSameDay(new Date(request.time), new Date()) // Check if selected date is today
                                                        ? new Date() // If today, disable times before the current time
                                                        : setHours(setMinutes(new Date(), 0), 0) // Else enable from 00:00
                                                }
                                                maxTime={setHours(setMinutes(new Date(), 59), 23)} // Max time is always 23:59
                                                containerStyle={{ width: '100%' }}
                                                onChange={date => {
                                                    const selectedTime = new Date(date);
                                                    setRequest(prev => {
                                                        const selectedDate = new Date(prev.time) || new Date(); // Use the selected date or today's date
                                                        // Merge the stored date with the selected time
                                                        const updatedDateTime = new Date(
                                                            selectedDate.setHours(selectedTime.getHours(), selectedTime.getMinutes())
                                                        );
                                                        return { ...prev, time: new Date(updatedDateTime) }
                                                    });
                                                }}
                                            />
                                            <img src={Clock} alt='Clock' style={{ width: "18px", position: "absolute", right: "15px" }} />
                                        </div>
                                    </div> */}
                                </div>

                                <CreatableSelectField
                                    isLoading={loading}
                                    onCreateOption={handleAddNewAgroChemical}
                                    isDisabled={isFetchingUser}
                                    label={'Agrochemical'}
                                    name={'pesticides'}
                                    styles={{ width: "215px", height: "18px", fontSize: "13px", }}
                                    list={pesticides.map(l => ({ label: l.chemical_name, value: l.id }))}
                                    containerStyle={{
                                        width: "100%",
                                        maxWidth: "100%",
                                        minHeight: "80px",
                                    }}
                                    menuPortalTarget={document.getElementById('modalHolder')}
                                    controlStyle={{ height: "auto" }}
                                    handleChange={(value) => {
                                        setRequest(prev => ({ ...prev, pesticides: value, }))
                                    }}
                                    validation={modelNameValidation}
                                />
                                {/* <SelectField
                                    isLoading={loading}
                                    isDisabled={isFetchingUser}
                                    label={'Crop Stage'}
                                    name={'crop_stage'}
                                    styles={{ width: "215px", height: "18px", fontSize: "13px", }}
                                    list={cropStages.map(l => ({ label: l, value: l }))}
                                    containerStyle={{
                                        width: "100%",
                                        maxWidth: "100%",
                                        minHeight: "80px",
                                    }}
                                    menuPortalTarget={document.getElementById('modalHolder')}
                                    controlStyle={{ height: "auto" }}
                                    validation={selectFieldValidation}
                                    handleChange={(value) => {
                                        setRequest(prev => ({ ...prev, crop_stage: value }))
                                    }}
                                /> */}
                                <div style={{ display: 'flex', alignItems: "center", width: '100%' }}>
                                    <CustomToggle

                                        onText={"Tank"}
                                        offText={'Acre'}
                                        name={"price_by"}
                                        handleChange={(value) => {
                                            setRequest(prev => ({ ...prev, price_by: value, field_area: null })) //this is required real time to change inputs of acre and tank
                                        }}
                                        defaultValue={request.price_by}
                                    />
                                </div>

                                <SelectField
                                    isLoading={loading || cropsLoading}
                                    isDisabled={isFetchingUser}
                                    label={'Crop'}
                                    name='crop'
                                    styles={{ width: "215px", height: "18px", fontSize: "13px", }}
                                    list={crops.map(l => ({ label: l.crop_name, value: l.crop_id }))}
                                    containerStyle={{
                                        width: "100%",
                                        maxWidth: "100%",
                                        minHeight: "80px",
                                    }}
                                    menuPortalTarget={document.getElementById('modalHolder')}
                                    controlStyle={{ height: "auto" }}
                                    handleChange={async (value) => {
                                        setRequest(prev => ({ ...prev, crop: value }))
                                    }}
                                    validation={selectFieldValidation}
                                />

                                {request.price_by ?
                                    <InputField
                                        handleChange={value => {
                                            setRequest(prev => ({ ...prev, field_area: value }))
                                        }}
                                        disabled={isFetchingUser}
                                        label={"No. of Tanks"}
                                        placeholder={"Enter no. of tanks"}
                                        name={'field_area'}
                                        validation={{
                                            required: 'Required',
                                            maxLength: {
                                                value: 3,
                                                message: 'Max allowed number is 999.',
                                            },
                                            validate: (value) => {
                                                if (isNaN(value)) {
                                                    return 'Must be a number.';
                                                } else if (value <= 0 || value > 999) {
                                                    return 'No. of tanks should be between 1 to 999';
                                                }
                                            },
                                            format: {
                                                value: /^[0-9]+$/,
                                                message: 'Must be a number.',
                                            },
                                        }}
                                        type={'number'}
                                    /> :
                                    <InputField
                                        handleChange={value => {
                                            setRequest(prev => ({ ...prev, field_area: value }))
                                        }}
                                        disabled={isFetchingUser}
                                        label={"Area (Acre)"}
                                        placeholder={"Enter field area "}
                                        name={'field_area'}
                                        // validation={NoOfUserValidation} //add validation
                                        validation={{
                                            required: 'Required',
                                            validate: (value) => {
                                                if (value == "") {
                                                    return 'Required'
                                                }
                                                else
                                                    if (value.match(/^\d*\.?\d*$/)) {
                                                        const decimalValue = parseFloat(value);
                                                        if (!isNaN(decimalValue) && (decimalValue < 0.1 || decimalValue > 1000.0)) {
                                                            return "Field area in the range of 0.1 to 1000"
                                                        }
                                                    } else if (value < 0) {
                                                        return "Field area in the range of 0.1 to 1000"
                                                    }
                                            }
                                        }}
                                        type={'number'}
                                    />

                                }
                            </div>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row-reverse",
                                padding: "10px 0px 15px",
                                gap: "20px"
                            }}
                        >
                            <Button
                                text={"Create"}
                                className={"btn-large"}
                                type={"Save"}
                                onClick={onSubmit}
                                isDisabled={!request.location_id?.value || !request.crop?.value || !request.crop_stage?.value || !request.pesticides?.value || isFetchingUser}
                            />
                            <Button
                                text={"Cancel"}
                                type={"button"}
                                onClick={onClose}
                                isBtnNotActive={true}
                                className={"btn-large "}
                            />
                        </div>
                    </>
                )}
            </Form>
        </div>
    </Modal>
}
export default CreateRequest