import React, { useEffect, useState } from 'react';
import Input from './Input';
import { Controller, useFormContext } from 'react-hook-form';
import { findInputError } from '../utils/findInputError';
import { isFormInvalid } from '../utils/isFormValid';
import InputError from './InputError';
import { capitalizeFirstLetter } from '../ReusableFunctions/reusableFunctions';

const InputField = ({ label, type, id, placeholder, name, validation, style, icon, onClickIcon, containerStyle, labelStyle, inputStyle, disabled, handleChange, defaultValue, value, showUnit, unitImage }) => {
    const {
        register,
        formState: { errors },
        control,
        clearErrors,
        watch,
        setValue,
        setError
    } = useFormContext();
    const selectedValue = watch(name);

    const [prevValue, setPrevValue] = useState("")

    useEffect(() => {
        if (handleChange) {
            handleChange(selectedValue);
        }
    }, [selectedValue]);

    const handleInputChange = (event) => {
        const value = event.target.value;
        const transformedValue = validation?.isUpperCase ? value.toUpperCase() : value;

        if (transformedValue && validation?.format?.value) {
            if (validation?.format?.value.test(transformedValue)) {
                setValue(name, transformedValue, { shouldValidate: true });
                setPrevValue(transformedValue)
                clearErrors(name);
            }
            else {
                setError(name, {
                    type: 'manual',
                    message: validation?.format?.message,
                });
                setValue(name, prevValue, { shouldValidate: false });
            }
        } else {
            setValue(name, transformedValue, { shouldValidate: true });
            clearErrors(name);
            setPrevValue(transformedValue)
        }
    };

    const inputError = findInputError(errors, name);
    const isInvalid = isFormInvalid(inputError);

    return (
        <div style={{ position: 'relative', display: 'flex', flexDirection: 'column', rowGap: '5px', ...containerStyle }}>
            <div style={{ fontSize: '13px', color: '#3B3B3B', ...labelStyle }}>{capitalizeFirstLetter(label)}</div>
            <Controller
                name={name}
                control={control}
                defaultValue={defaultValue}
                rules={validation}
                render={({ field }) => (
                    <div style={{ position: "relative" }}>
                        <Input
                            id={id}
                            type={type}
                            value={value}
                            name={name}
                            disabled={disabled}
                            placeholder={placeholder}
                            maxLength={validation?.maxLength?.value}
                            onChange={handleInputChange}
                            style={{
                                fontSize: '13px',
                                height: '35px',
                                border: isInvalid ? '1.4px solid red' : '1.4px solid rgba(217, 217, 217, 0.84)',
                                opacity: disabled ? 0.6 : 1,
                                paddingRight: showUnit ? "22px" : "",
                                ...style
                            }}
                            register={{ ...register(name, validation) }}
                            icon={icon}
                            onClickIcon={onClickIcon}
                            inputStyle={inputStyle}
                            max={validation?.max?.value}
                        />
                        {showUnit && (
                            <div style={{ position: "absolute", top: 4, right: 4, display: "flex", borderLeft: "1px solid lightgray", padding: "2px 5px" }}>
                                <img src={unitImage} style={{ opacity: "0.5" }} alt='' width={22} />
                            </div>
                        )}

                    </div>
                )}
            />
            {isInvalid ? (
                <InputError
                    inputError={inputError}
                />
            ) : <div style={{ height: "12px" }} />}

        </div>
    );
};

export default InputField;