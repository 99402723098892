import React from 'react';
import CouponsLogoActive from "../assets/sidebaricons/coupons-active.svg";
import CouponsLogoInactive from "../assets/sidebaricons/coupons-inactive.svg";

const CouponsLogo = (location) => {
    return (
        <img src={location.location === "/coupons" ? CouponsLogoActive : CouponsLogoInactive} className={location.location === "/coupons" ? "sideBarIcon" : "sideBarIcon-not-selected"} alt='' />
    )
}

export default CouponsLogo