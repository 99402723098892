import { SERVER_URLS } from "../../../appConstants";
import { getToken } from "../../authentication";

export const sendNotifications = (notificationsToSend) => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = getToken();
            const url = SERVER_URLS.BACKEND_SERVER + "/bhumeet_team/marketing/notifications/send"
            const response = await fetch(url,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({ notificationsToSend })
                }
            );
            const data = await response.json();
            if (response.ok) {
                resolve(data?.revenueOverview);
            } else {
                reject(
                    data?.message || "Something went wrong while sending notifications."
                );
            }
        } catch (error) {
            reject("Something went wrong while sending notifications.");
        }
    });
};