import { getDSPId } from "../ReusableFunctions/reusableFunctions";
import { SERVER_URLS } from "../appConstants";
import { getToken } from "./authentication";

export const getAllUsers = (pageSize, pageNumber, searchQuery) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = getToken();
      const response = await fetch(
        SERVER_URLS.BACKEND_SERVER +
        "/dsp/users" +
        `?pageSize=${pageSize || ""}&pageNumber=${pageNumber || ""
        }&searchQuery=${searchQuery || ""}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      if (response.ok) {
        resolve(data.users);
      } else {
        reject(
          data?.message || "Something went wrong while getting users data."
        );
      }
    } catch (error) {
      reject("Something went wrong while getting users data.");
    }
  });
};

export const getAllCustomersCount = (searchQuery) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = getToken();
      const response = await fetch(
        SERVER_URLS.BACKEND_SERVER + `/dsp/users/usersCount?searchQuery=${searchQuery || ""}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      if (response.ok) {
        resolve(data.usersCount);
      } else {
        reject(
          data?.message || "Something went wrong while getting requests."
        );
      }
    } catch (error) {
      reject("Something went wrong while getting requests.");
    }
  });
}

export const getUserDetails = (userId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = getToken();
      const response = await fetch(
        SERVER_URLS.BACKEND_SERVER + "/dsp/users/" + userId,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      if (response.ok) {
        resolve(data.user);
      } else {
        reject(
          data?.message || "Something went wrong while getting users data."
        );
      }
    } catch (error) {
      reject("Something went wrong while getting users data.");
    }
  });
};
export const updateUserData = (updateduser) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = getToken();
      const response = await fetch(
        SERVER_URLS.BACKEND_SERVER + "/dsp/operator",
        {
          method: "PUT",
          body: JSON.stringify({
            operator: updateduser,
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      if (response.ok) {
        resolve(data);
      }
      else if (response.status === 403) {
        reject(data?.message || "Email or Phone already exists.")
      }
      else {
        reject(
          data?.message || "Something went wrong while updating user profile."
        );
      }
    } catch (error) {
      reject("Something went wrong while updating user profile.");
    }
  });
};
export const uploadCompanyLogo = (logo) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = getToken();
      const response = await fetch(
        SERVER_URLS.BACKEND_SERVER + "/dsp/uploadCompanyLogo",
        {
          method: "POST",
          body: logo,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      if (response.ok) {
        resolve(data);
      } else {
        reject(
          data?.message || "Something went wrong while uploading user logo."
        );
      }
    } catch (error) {
      reject("Something went wrong while uploading user logo.");
    }
  });
};
export const uploadProfilePic = (profilePic) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = getToken();
      const response = await fetch(
        SERVER_URLS.BACKEND_SERVER + "/dsp/operator/uploadProfilePic",
        {
          method: "POST",
          body: profilePic,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      if (response.ok) {
        resolve(data);
      } else {
        reject(
          data?.message || "Something went wrong while uploading user logo."
        );
      }
    } catch (error) {
      reject("Something went wrong while uploading user logo.");
    }
  });
};

export const uploadIdentity = (identityType, identity) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = getToken();
      const response = await fetch(
        SERVER_URLS.BACKEND_SERVER +
        `/dsp/operator/uploadIdentityCard/${identityType}`,
        {
          method: "POST",
          body: identity,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      if (response.ok) {
        resolve(data);
      } else {
        reject(
          data?.message || "Something went wrong while uploading user logo."
        );
      }
    } catch (error) {
      reject("Something went wrong while uploading user logo.");
    }
  });
};

export const updateCompany = (dspData) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = getToken();
      const response = await fetch(SERVER_URLS.BACKEND_SERVER + `/dsp`, {
        method: "PUT",
        body: JSON.stringify({
          dspDetails: {
            id: dspData.id,
            name: dspData.name,
            address: dspData.address,
          },
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      if (response.ok) {
        resolve(data);
      } else {
        reject(
          data?.message || "Something went wrong while uploading company data."
        );
      }
    } catch (error) {
      reject("Something went wrong while uploading company data.");
    }
  });
};

export const shareAppLinkAPI = (emails) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = getToken();
      const response = await fetch(SERVER_URLS.BACKEND_SERVER + `/dsp/users/sendAppInvitations`, {
        method: "POST",
        body: JSON.stringify({
          emails
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      if (response.ok) {
        resolve(data);
      } else {
        reject(
          data?.message || "Something went wrong while sharing app link."
        );
      }
    } catch (error) {
      reject("Something went wrong while sharing app link.");
    }
  });
};

export const updateDSPSettingsFun = (settings) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = getToken();
      const response = await fetch(SERVER_URLS.BACKEND_SERVER + `/dsp/updateDSPSettings`, {
        method: "POST",
        body: JSON.stringify({
          settings
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      if (response.ok) {
        resolve(data);
      } else {
        reject(
          data?.message || "Something went wrong while uploading company data."
        );
      }
    } catch (error) {
      reject("Something went wrong while uploading company data.");
    }
  });
};



export const getUserFromMobileNumber = (mobileNo) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = getToken();
      const response = await fetch(SERVER_URLS.BACKEND_SERVER + `/dsp/users/mobile/${mobileNo}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      if (response.ok) {
        resolve(data);
      } else {
        reject(
          data?.message || "Something went wrong while getting user from mobile number."
        );
      }
    } catch (error) {
      reject("Something went wrong while getting user from mobile number.");
    }
  });
};


export const getUserListForMap = (searchQuery, fromDate, toDate) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = getToken();
      const response = await fetch(SERVER_URLS.BACKEND_SERVER + `/bhumeet_team/admin/users/getUserListForMap?searchQuery=${searchQuery && searchQuery != "" ? searchQuery : ' '}${(fromDate && toDate) ? `&fromDate=${new Date(fromDate).toISOString()}&toDate=${new Date(toDate).toISOString()}` : ''}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
      const data = await response.json();
      if (response.ok) {
        resolve(data);
      } else {
        reject(
          data?.message || "Something went wrong while getting user from mobile number."
        );
      }
    } catch (error) {
      reject("Something went wrong while getting user from mobile number.");
    }
  });
};