import React, { useEffect, useState } from 'react'
import MainContentWrapper from '../../Components/MainContentWrapper'
import SelectComponent from '../../ReusableComponents/SelectComponent'
import { getDrones } from '../../apis/BhuMeetTeams/CustomerSuccessEngineer/drones'
import Loading from '../../ReusableFunctions/Loader'
import TableView from '../../ReusableComponents/TableView/TableView'
import Popup from '../../ReusableFunctions/Popup'
import { getRewardOrders } from '../../apis/BhuMeetTeams/Oparations/RewardOrders'
import { getDronePilots } from '../../apis/BhuMeetTeams/Oparations/pilots'
import { getStatusColor } from '../../ReusableFunctions/reusableFunctions'
import ViewOperationOrderDetails from './ViewOperationOrderDetails'

const RewardOrders = ({
    pilot,
}) => {
    const [isLoading, setIsLoading] = useState(false)
    const [rewardOrders, setRewardOrders] = useState([])
    const [dronePilots, setdronePilots] = useState([])
    const [hideInfo, setHideInfo] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState(undefined)
    const [filters, setFilters] = useState({
        pageNumber: 1,
        pageSize: 15,
        selectedPilot: { value: null, label: "All" }
    })

    const getRewardOrdersFunc = async () => {
        try {
            setIsLoading(true)
            Loading.set(true)
            const orders = await getRewardOrders(pilot?.id || filters.selectedPilot.value, filters.pageSize, filters.pageNumber);
            setRewardOrders(orders);
            Loading.set(false)
            setIsLoading(false)
        } catch (error) {
            Loading.set(false)
            setIsLoading(false)
            Popup.alert("ERROR", "We cant get orders right now, Please try again later.")
        }
    }

    const getActivePilots = async () => {
        try {
            const pilots = await getDronePilots();
            setdronePilots([{ value: null, label: "All" }, ...pilots.map(p => ({
                value: p.id,
                label: `${p.first_name} ${p.last_name}`
            }))])
        } catch (err) {
            Popup.alert("ERROR", "We cant get pilots right now, Please try again later.")
        }
    }

    useEffect(() => {
        if (pilot?.id) {
            setHideInfo(true);
        }
        getRewardOrdersFunc()
    }, [filters.pageNumber, filters.pageSize, filters.selectedPilot])

    useEffect(() => {
        getActivePilots();
    }, [])
    return (
        <MainContentWrapper header={`Orders ${!hideInfo ? `(${filters.selectedPilot.label})` : `(${rewardOrders[0]?.pilots_count})`} `} rightContent={<div style={{ display: "flex", justifyContent: "flex-end", paddingBottom: "20px" }}>
            {!hideInfo && (<SelectComponent
                controlStyle={{ height: "40px", width: "200px", minHeight: "20px", border: "none", boxShadow: "0px 2px 5px 1px #6273943d", color: "hsl(0deg 0% 20% / 64%)", fontSize: "10px" }}
                isSearchable={true}
                placeholder={"Select pilot..."}
                menuPlacement={"bottom"}
                list={dronePilots}
                value={filters.selectedPilot}
                onChange={(pilot) => {
                    setFilters({ ...filters, selectedPilot: pilot })
                }}
            />)}
        </div>
        }>
            {selectedOrder &&
                <ViewOperationOrderDetails
                    open={selectedOrder?.id}
                    isLoading={isLoading}
                    onClose={() => {
                        setSelectedOrder(undefined);
                    }}
                    order={selectedOrder}
                    callback={() => {
                        setSelectedOrder(undefined);
                        getRewardOrdersFunc();
                    }}
                />
            }
            <div style={{
                margin: "20px 0",
                height: "90%"
            }}>
                <TableView
                    loadingHeight={"500px"}
                    isLoading={isLoading}
                    onStateChange={async (changedState) => {
                        const { pageNumber } = changedState;
                        setFilters({ ...filters, pageNumber });
                    }}
                    onClickRow={(data) => {
                        setSelectedOrder(data);
                    }}
                    columns={[
                        { Header: "Order Number", accessor: "order_number", width: "9%" },
                        { Header: "Redeem Points", accessor: "redeem_points", width: "9%" },
                        { Header: "Shipping Address", accessor: "shipping_address", width: "15%" },
                        { Header: "Product Name", accessor: "name", width: "9%", },
                        { Header: "Quantity", accessor: "quantity", width: "9%" },
                        { Header: "Note", accessor: "note", width: "10%" },
                        { Header: "Pilot Name", accessor: "pilot_name", width: "9%" },
                        { Header: "Contact No", accessor: "pilot_phone", width: "9%" },
                        { Header: "Orderd Date", accessor: "created_at", isDate: true, format: "dd/mm/yyyy", width: "10%" },
                        { Header: "Status", accessor: "status", showColor: true, width: "15%" }
                    ]}
                    count={rewardOrders[0]?.orders_count}
                    pageSize={filters.pageSize}
                    pageNumber={filters.pageNumber}
                    data={rewardOrders.map((o) => ({ ...o, color: getStatusColor(o.status, true) }))}
                    tableContainerHeight={"100%"}
                    hidePagination={false}
                    headerStyle={{ zIndex: 0 }}
                />
            </div>
        </MainContentWrapper>
    )
}

export default RewardOrders