import { buildUrl } from "../../../ReusableFunctions/reusableFunctions";
import { SERVER_URLS } from "../../../appConstants";
import { getToken } from "../../authentication";

export const getRewardOrders = (pilotId, pageSize, pageNumber) => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = getToken();
            const url = buildUrl(SERVER_URLS.BACKEND_SERVER + "/bhumeet_team/operations/rewardOrders", {
                pageNumber: pageNumber ? pageNumber : undefined,
                pageSize: pageSize ? pageSize : undefined,
                pilotId: pilotId ? pilotId : undefined
            })
            const response = await fetch(url,
                {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const data = await response.json();
            if (response.ok) {
                resolve(data?.orders);
            } else {
                reject(
                    data?.message || "Something went wrong while getting orders."
                );
            }
        } catch (error) {
            reject("Something went wrong while getting orders.");
        }
    });
};


export const updateRewardOrderDetails = async (rewardOrderDetails) => {
    try {
        const token = getToken();
        const response = await fetch(
            SERVER_URLS.BACKEND_SERVER + `/bhumeet_team/operations/rewardOrders`,
            {
                method: "PUT",
                body: JSON.stringify(rewardOrderDetails),
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            }
        );
        const res = await response.json();
        if (!response.ok) {
            throw (res.message || "Failed to update order details.");
        }
        return res;
    } catch (err) {
        throw (err);
    }
}

export const getOrdersCount = async () => {
    try {
        const token = getToken();
        const response = await fetch(
            SERVER_URLS.BACKEND_SERVER + `/bhumeet_team/operations/rewardOrders/count`,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            }
        );
        const res = await response.json();
        if (!response.ok) {
            throw (res.message || "Something went wrong while getting orders count.");
        }
        return res;
    } catch (err) {
        throw (err)
    }
}