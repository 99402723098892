import React, { useEffect, useState } from 'react'
import Modal from '../ReusableComponents/Modal';
import { createDSPOperator, getOperator, updateDSPOperator } from '../apis/operator';
import { getLocation, getLocations } from '../apis/locations';
import Loading from '../ReusableFunctions/Loader';
import Popup from '../ReusableFunctions/Popup';
import InputField from '../ReusableComponents/InputField';
import Form from '../ReusableComponents/Form';
import ToggleField from '../ReusableComponents/ToggleField';
import SelectField from '../ReusableComponents/SelectField';
import { addressValidation, emailValidation, nameValidation, phoneNumValidation, selectFieldValidation } from '../utils/inputValidations';
import { Button } from './Helper';

const AddDSPOpeartor = ({ open, onClose, operatorId, onOperatorAdd, user }) => {
    const [operatorInfo, setOperatorInfo] = useState({
        name: "",
        address: "",
        phone: "",
        email: "",
        is_admin: false,
        is_active: true,
        location_id: null
    });
    const [locations, setLocations] = useState();

    const addDSPOperator = (op) => {
        Loading.set(true);
        createDSPOperator({ ...op, dsp_name: user.name })
            .then((operator) => {
                Loading.set(false);
                onOperatorAdd(operator)
            })
            .catch((err) => {
                Loading.set(false);
                Popup.alert(
                    "Team member",
                    err,
                    "ERROR",
                    () => { }
                );
            });
    };

    const updateDSPOperatorInfo = (op) => {
        Loading.set(true);
        updateDSPOperator(op)
            .then((operator) => {
                Loading.set(false);
                onOperatorAdd(operator.updatedDspOperator)
                onClose()
            })
            .catch((err) => {
                Loading.set(false);
                Popup.alert("Team member", err, "ERROR", () => { });
            });
    }

    useEffect(() => {
        Loading.set(true);
        getLocations()
            .then((locations) => {
                setLocations(locations)
                if (operatorId) {
                    getOperator(operatorId)
                        .then((res) => {
                            const operator = res.operator
                            setOperatorInfo(operator)
                            if (operator.location_id) {
                                getLocation(operator.location_id)
                                    .then((res) => {
                                        const operatorLocation = res.location
                                        setOperatorInfo((prev) => ({
                                            ...prev,
                                            location_id: { value: operatorLocation.id, label: operatorLocation.name },
                                        }))
                                        Loading.set(false);
                                    })
                                    .catch((e) => {
                                        console.log(e);
                                        Loading.set(false);
                                        Popup.alert("Error", e, "ERROR");
                                    })
                            }
                        })
                        .catch((e) => {
                            console.log(e);
                            Loading.set(false);
                            Popup.alert("Error", e, "ERROR");
                        })
                }
            })
            .catch((err) => {
                console.log(err);
                Popup.alert("Error", err, "ERROR");
            }).finally(() => {
                Loading.set(false);
            })

    }, [operatorId])
    return (
        <Modal
            header={operatorId ? "Update Member" : "Add Member"}
            open={open}
            onClose={onClose}
            contentStyle={{ width: "575px", height: 'auto' }}
        >
            <Form
                onSubmitForm={(operator) => {
                    const operatorInfo = { ...operator, location_id: operator?.location_id?.value };
                    operatorId ? updateDSPOperatorInfo(operatorInfo) : addDSPOperator(operatorInfo);
                }}
                initialValues={operatorInfo}
            >
                {(onSubmit, formValues) => (
                    <>
                        <div
                            style={{
                                display: "grid",
                                gridTemplateColumns: "1fr 1fr",
                                columnGap: "15px",
                                fontSize: "11px",
                                padding: "20px 30px 10px",
                                rowGap: "10px"
                            }}
                        >
                            <InputField
                                label={"Name"}
                                type={"text"}
                                id={"name"}
                                placeholder={"Please Enter Name..."}
                                name={"name"}
                                validation={nameValidation}
                                containerStyle={{
                                    width: "100%",
                                    maxWidth: "100%",
                                    minHeight: "80px",
                                }}
                            />
                            <InputField
                                label={"Address"}
                                type={"text"}
                                id={"Address"}
                                placeholder={"Please Enter Address..."}
                                name={"address"}
                                containerStyle={{
                                    width: "100%",
                                    maxWidth: "100%",
                                    minHeight: "80px",
                                }}
                                validation={addressValidation}
                            />
                            <InputField
                                label={"Mobile No"}
                                type={"text"}
                                id={"phone"}
                                placeholder={"Please Enter Mobile No..."}
                                name={"phone"}
                                containerStyle={{
                                    width: "100%",
                                    maxWidth: "100%",
                                    minHeight: "80px",
                                }}
                                validation={phoneNumValidation}
                            />
                            <InputField
                                label={"E-mail"}
                                type={"text"}
                                id={"email"}
                                defaultValue={operatorInfo.name}
                                placeholder={"Please Enter Email Id..."}
                                name={"email"}
                                containerStyle={{
                                    width: "100%",
                                    maxWidth: "100%",
                                    minHeight: "80px",
                                }}
                                validation={emailValidation}
                            />
                            <ToggleField
                                label={"Admin"}
                                defaultValue={operatorInfo.is_admin}
                                name={"is_admin"}
                                onText={"Admin"}
                                offText={"Location Manager"}
                            />
                            <ToggleField
                                label={"Status"}
                                name={"is_active"}
                                onText={"Active"}
                                offText={"Inactive"}
                                defaultValue={operatorInfo.is_active}
                            />
                            {!formValues.is_admin &&
                                <SelectField
                                    name={"location_id"}
                                    label={"Location"}
                                    list={locations?.map(location => ({ value: location.id, label: location.name }))}
                                    styles={{ width: "215px", height: "18px", fontSize: "13px", margin: "0 0 0 -1px" }}
                                    validation={selectFieldValidation}
                                    defaultValue={operatorInfo.location_id}
                                    menuListStyles={{
                                        maxHeight: "150px",
                                    }}
                                    containerStyle={{
                                        width: "100%",
                                        maxWidth: "100%",
                                        minHeight: "80px",
                                    }}
                                />
                            }

                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row-reverse",
                                padding: "10px 30px 15px",
                                gap: "20px"
                            }}
                        >
                            <Button
                                text={operatorId ? "Update" : "Add"}
                                onClick={onSubmit}
                                className={"btn-large"}
                            />
                            <Button
                                text={"Cancel"}
                                onClick={onClose}
                                isBtnNotActive={true}
                                className={"btn-large "}
                            />
                        </div>
                    </>
                )}
            </Form>
        </Modal>
    );
}

export default AddDSPOpeartor