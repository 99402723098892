import React, { useEffect, useState } from 'react'
import { getAdminDashboardOverview } from '../../apis/adminAPIs/overview'
import Loading from '../../ReusableFunctions/Loader'
import Popup from '../../ReusableFunctions/Popup'
import DashBoardCard from '../../features/HomePage/SubComponents/DashBoardCard/DashBoardCard'
import { addLeadingZero } from '../../ReusableFunctions/reusableFunctions'
import droneCardIcon from "../../assets/droneCardIcon.svg"
import locationCardIcon from "../../assets/locationCardIcon.svg"
import pilotCardIcon from '../../assets/pilotsCardIcon.svg'
import DspCardIcon from '../../assets/DspCardIcon.svg'
import CustomerIcon from '../../assets/customerCardIcon.svg'
import cropIcon from "../../assets/crop.png"
import couponIcon from "../../assets/coupon.png"
import mail from "../../assets/mail.png"
import { PDRL_ADMIN_ROUTES } from '../../routes'
import MainContentWrapper from '../../Components/MainContentWrapper'
import { getOperationsDashboardOverview } from '../../apis/BhuMeetTeams/BhuMeetAdmin/dashboard'
import { getStoresCount } from '../../apis/BhuMeetTeams/Oparations/store'

const OperationsDashboard = () => {
    const [dashBoardData, setDashBoardData] = useState({})
    const [storeCount, setStoreCount] = useState(0);

    const getAdminDashboardOverviewFunc = async () => {
        Loading.set(true)
        try {
            const overview = await getOperationsDashboardOverview()
            setDashBoardData(overview?.overviewData)
            Loading.set(false)
        } catch (err) {
            Loading.set(false)
            Popup.alert("Error", err, 'ERROR')
        }
    }

    const getStoresCountFunc = async () => {
        try {
            let storesCount = await getStoresCount();
            setStoreCount(storesCount);
        } catch (err) {
            Popup.alert("ERROR", "We cant get stores count now, Please try again later.")
        }
    }


    useEffect(() => {
        getAdminDashboardOverviewFunc()
        getStoresCountFunc()
        localStorage.setItem('dsp_id', "admin")
    }, [])

    return (
        <MainContentWrapper>
            <div className="dashboard-first-section-second-div" style={{ width: "100%" }} >
                <div className="dashboard-first-section-second-div-one" style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr" }} >
                    {/* ---- Locations ---- */}
                    <DashBoardCard
                        loading={false}
                        view={PDRL_ADMIN_ROUTES.LOCATIONS}
                        title="Locations"
                        data={addLeadingZero(dashBoardData?.locations)}
                        icon={locationCardIcon}
                    />

                    {/* ---- Manufactures ---- */}
                    <DashBoardCard
                        loading={false}
                        view={PDRL_ADMIN_ROUTES.MANUFACTURES}
                        title="Manufactures"
                        data={addLeadingZero(dashBoardData?.manufacturers)}
                        icon={droneCardIcon}
                    />

                    {/* ---- Crops ---- */}
                    <DashBoardCard
                        loading={false}
                        view={PDRL_ADMIN_ROUTES.CROPS}
                        title="Crops"
                        data={addLeadingZero(dashBoardData?.crops)}
                        icon={cropIcon}
                    />

                    {/* ---- Coupons ---- */}
                    <DashBoardCard
                        loading={false}
                        view={PDRL_ADMIN_ROUTES.COUPONS}
                        title="Coupons"
                        data={addLeadingZero(dashBoardData?.coupons)}
                        icon={couponIcon}
                        iconStyle={{ height: '39px' }}
                    />

                    {/* ---- Support Emails ---- */}
                    <DashBoardCard
                        loading={false}
                        view={PDRL_ADMIN_ROUTES.SUPPORTEMAILS}
                        title="Support E-mails"
                        data={addLeadingZero(dashBoardData?.supportemails || 0)}
                        icon={mail}
                        iconStyle={{ height: '39px' }}
                    />

                    <DashBoardCard
                        loading={false}
                        title="Total Sprayed Area (Acres)"
                        data={addLeadingZero((dashBoardData?.totalsprayedarea)?.toFixed(2) || 0)}
                        icon={cropIcon}
                        iconStyle={{ height: '39px' }}
                    />

                    <DashBoardCard
                        loading={false}
                        title="Total Drones"
                        data={addLeadingZero(dashBoardData?.totaldronesaddedbydsps || 0)}
                        icon={droneCardIcon}
                        iconStyle={{ height: '39px' }}
                        view={PDRL_ADMIN_ROUTES.ALLDRONES}
                    />

                    <DashBoardCard
                        loading={false}
                        view={PDRL_ADMIN_ROUTES.OPERATIONDSPS}
                        title="DSPs"
                        data={addLeadingZero(dashBoardData?.dsps)}
                        icon={DspCardIcon}
                    />

                    <DashBoardCard
                        loading={false}
                        view={PDRL_ADMIN_ROUTES.OPERATIONSUSERS}
                        title="Users"
                        data={addLeadingZero(dashBoardData?.total_users)}
                        icon={CustomerIcon}
                    />
                    <DashBoardCard
                        loading={false}
                        view={PDRL_ADMIN_ROUTES.OPEARTIONSPILOTS}
                        title="Pilots"
                        icon={pilotCardIcon}
                        data={addLeadingZero(dashBoardData?.pilots)}
                    />
                    <DashBoardCard
                        loading={false}
                        view={PDRL_ADMIN_ROUTES.REWARDPROGRAM}
                        title="Reward program"
                    />

                    <DashBoardCard
                        loading={false}
                        view={PDRL_ADMIN_ROUTES.STORES}
                        title="Stores"
                        data={storeCount}
                    />
                </div>
            </div>
        </MainContentWrapper>
    )
}

export default OperationsDashboard