import { SERVER_URLS } from "../../../appConstants";
import { getToken } from "../../authentication";

export const getOperationsDashboardOverview = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const token = getToken();
            const response = await fetch(

                SERVER_URLS.BACKEND_SERVER + "/bhumeet_team/admin/dsp/overviewData",
                {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            const data = await response.json();
            if (response.ok) {
                resolve(data);
            } else {
                reject(
                    data?.message || "Something went wrong while getting dashboard data."
                );
            }
        } catch (error) {
            reject("Something went wrong while getting dashboard data.");
        }
    });
};