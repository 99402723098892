import { forwardRef, useImperativeHandle, useState } from "react"

const LocationDSPList = forwardRef(({ dsps, onClickDSP }, ref) => {
    const [selectedDSP, setSelectedDSP] = useState(null)
    useImperativeHandle(ref, () => ({
        clearSelectedDSP: () => setSelectedDSP(null),
        getSelectedDSP: () => selectedDSP
    }))
    return <div style={{ position: 'absolute', zIndex: 999, right: '20px', top: '20px', height: "500px", width: "350px", display: 'flex', flexDirection: 'column', background: 'white' }}>
        <div style={{ padding: "10px", boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.15)', fontWeight: 500 }}>Drone Service Providers</div>
        <div style={{ display: 'flex', flexDirection: 'column', overflowY: 'auto' }}>
            {dsps.map(dsp => <div
                onClick={() => {
                    onClickDSP(dsp, selectedDSP?.dsp_id == dsp.dsp_id ? false : true)
                    setSelectedDSP(selectedDSP?.dsp_id == dsp.dsp_id ? null : dsp)
                }}
                style={{ cursor: 'pointer', display: 'flex', gap: '20px', borderBottom: '1px solid rgb(60 60 60 / 14%)', alignItems: 'center', color: "#3b3b3b", fontSize: '14px', paddingRight: "20px", background: selectedDSP?.dsp_id == dsp.dsp_id ? "#bccaff52" : "transparent" }}>
                <div style={{ minWidth: '5px', height: "100%", background: dsp.color, cursor: 'pointer' }}></div>
                <div style={{ padding: "5px 10px", wordWrap: 'break-word', display: "flex", overflowWrap: 'anywhere' }}>
                    {dsp.dsp_name}
                </div>
                <div style={{ marginLeft: 'auto', fontWeight: 500 }}>{dsp?.total_locations || 0}</div>
            </div>)}
        </div>
    </div>
})
export default LocationDSPList