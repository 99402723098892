import React from 'react'
import walletIcon from '../../../assets/walletsecondary.svg'
import arrowIcon from '../../../assets/arrowIcon.svg'

const DspWalletCard = ({ walletBalance }) => {
    return (
        <div className="walllet_card">
            <div className="first_section">
                <div>
                    <h2 className="subtitle">TOTAL BALANCE</h2>
                    <div className="amount">₹ {Number(walletBalance?.balance)?.toFixed(2) || "0"}</div>
                </div>
                <div className="icon_wrapper">
                    <img src={walletIcon} alt="wallet" width={"50px"} height={"50px"} />
                </div>
            </div>
            <div >
                <div className="section_section">
                    <img src={arrowIcon} alt={""} width={"25px"} height={"25px"} />
                    <span>Last settlement amount</span>
                </div>
                <div className="last_settlement_amount">₹ {(walletBalance?.total_dsp_amount_to_be_paid)?.toFixed(2) || 0}</div>
            </div>
        </div>
    )
}

export default DspWalletCard