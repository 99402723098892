import React, { useEffect, useRef, useState } from 'react'
import DatePicker from 'react-datepicker';
import calender from "../../../assets/blueCalendar.svg";
import LoaderComponent from '../../../ReusableComponents/LoaderComponent';
import TableViewPagination from '../../../ReusableComponents/TableView/TableViewPagination';
import _debounce from 'lodash/debounce';
import Loading from '../../../ReusableFunctions/Loader';
import dateFormat from 'dateformat';
import { getDSPSettlementInvoices } from '../../../apis/adminAPIs/finance/dspInvoices';
import Popup from '../../../ReusableFunctions/Popup';
import { useNavigate } from 'react-router'
import { PDRL_ADMIN_ROUTES } from '../../../routes';
import noRequest from '../../../assets/no-request.svg'
import NotFounds from '../../../ReusableComponents/NotFounds';

const DspWalletTransactionsInvoices = ({ dsp, toBankDetails }) => {
    const navigate = useNavigate();
    const isFirstRender = useRef(true);
    const [openDatePicker, setOpenDatePicker] = useState({
        fromDate: false,
        toDate: false,
    })
    const [filters, setFilters] = useState({
        fromDate: undefined,
        toDate: undefined,
        pageNumber: 1,
        pageSize: 15
    })
    const [tableLoading, setTableLoading] = useState(false)
    const [settlementInvoices, setSettlementInvoices] = useState([])

    const getDspSettlementInvoicesFunc = async () => {
        try {
            Loading.set(true)
            setTableLoading(true)
            const invoices = await getDSPSettlementInvoices(dsp?.dsp_id, filters.fromDate, filters.toDate, filters.pageSize, filters.pageNumber)
            setSettlementInvoices(invoices)
            setTableLoading(false)
            Loading.set(false)
        } catch (error) {
            Loading.set(false)
            setTableLoading(false)
            Popup.alert("Error", error, 'ERROR')
        }
    }

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }

        const debouncedGetSettelmentInvoicesDSPs = _debounce(() => {
            getDspSettlementInvoicesFunc();
        }, 3000);

        debouncedGetSettelmentInvoicesDSPs();

        return () => {
            debouncedGetSettelmentInvoicesDSPs.cancel();
        };
    }, [filters.pageNumber, filters.fromDate, filters.toDate]);

    useEffect(() => {
        getDspSettlementInvoicesFunc()
    }, [])

    return (
        <>
            <div className='transaction_history_wrapper'>
                <div className='transaction_history_wrapper_header'>
                    <h4>Invoices History</h4>
                </div>
                <div className='date_Filters'>
                    <div
                        className="input-date "
                        style={{ display: "flex", alignItems: "center", cursor: "pointer", height: "34px", width: "150px", position: "relative", background: "transparent" }}
                        onClick={() => setOpenDatePicker((prev) => ({ ...prev, fromDate: true }))}
                    >
                        <DatePicker
                            onClickOutside={() => setOpenDatePicker((prev) => ({ ...prev, fromDate: false }))}
                            open={openDatePicker.fromDate}
                            placeholderText='From Date'
                            maxDate={filters?.toDate && new Date(filters?.toDate)}
                            readOnly
                            className="input-date-hidden"
                            selected={filters.fromDate ? new Date(filters.fromDate) : null}
                            onChange={date => {
                                if (date) {
                                    let fromDate = new Date(date).setHours(0, 0, 0, 0)
                                    fromDate = new Date(fromDate).toISOString();
                                    setFilters((prev) => ({ ...prev, fromDate: fromDate }))
                                }
                            }}
                        />
                        <img src={calender} style={{ width: "18px", position: "absolute", right: "15px" }} />
                    </div>
                    <div className='text-xl'>-</div>
                    <div className="input-date  "
                        style={{ display: "flex", alignItems: "center", cursor: "pointer", height: "34px", width: "150px", position: "relative", background: "transparent" }}
                        onClick={() => {
                            if (filters?.fromDate) {
                                setOpenDatePicker((prev) => ({ ...prev, toDate: true }))
                            }
                        }}
                    >
                        <DatePicker
                            placeholderText='To Date'
                            onClickOutside={() => setOpenDatePicker((prev) => ({ ...prev, toDate: false }))}
                            open={openDatePicker.toDate}
                            disabled={!filters.fromDate}
                            minDate={filters?.fromDate && new Date(filters?.fromDate)}
                            maxDate={new Date()}
                            readOnly
                            className="input-date-hidden "
                            selected={filters.toDate ? new Date(filters.toDate) : null}
                            onChange={date => {
                                if (date) {
                                    let todate = new Date(date).setHours(23, 59, 59, 999)
                                    todate = new Date(todate).toISOString();
                                    setFilters(prev => ({ ...prev, toDate: todate }));
                                }
                            }} />
                        <img src={calender} style={{ width: "18px", position: "absolute", right: "15px" }} />
                    </div>
                </div>
            </div>
            {!tableLoading ?
                <div style={{ width: "100%" }}>
                    <table className='transactionTable'>
                        <thead className='w-full'>
                            <tr className='text-[#3B3B3B] text-[14px]'>
                                <th>Invoice No.</th>
                                {/* <th>Service Amount</th>
                                <th>BhuMeet Coupon Total Amout</th> */}
                                {/* <th>Incentive</th>
                                <th>Total Amount</th>
                                <th>{`Platform Fee (${settlementInvoices[0]?.platform_fees_percentage}%)`}</th>
                                <th>Total Amount With Fees</th>
                                <th>{`TDS (${settlementInvoices[0]?.tds_charges_percentages}%)`}</th> */}
                                <th>Amount To Be Paid</th>
                                <th>Generated for Date</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <div className='transaction_table_body'>
                            <tbody >
                                {settlementInvoices?.length === 0 ?
                                    <tr className="reusable-table-view-data-row" align="center" >
                                        <td colSpan={6} style={{ height: "228px" }}>
                                            <NotFounds imgUrl={noRequest} msg={"No data available!"} hideBtn imgStyle={{ width: "100px" }} />
                                        </td>
                                    </tr> :
                                    settlementInvoices && settlementInvoices?.map((invoice, index) => (
                                        <tr className='row-hover' style={{ cursor: "pointer" }} key={index} onClick={() => {
                                            navigate(`${PDRL_ADMIN_ROUTES.PAYMENTINVOICE}`, {
                                                state: {
                                                    dsp,
                                                    toBankDetails,
                                                    invoice
                                                }
                                            })
                                        }}>
                                            <td>{invoice?.invoice_number}</td>
                                            {/* <td>₹ {invoice?.service_amount}</td> */}
                                            {/* <td>₹ {invoice?.bhumeet_coupons_amount}</td> */}
                                            {/* <td>{invoice?.incentive_from_bhumeet_amount}</td>
                                            <td>{invoice?.total_dsp_amount}</td>
                                            <td>{invoice?.platform_fees_amount}</td>
                                            <td>{invoice?.total_dsp_amount_with_fees}</td>
                                            <td>{invoice?.tds_charges}</td> */}
                                            <td>₹ {invoice?.total_dsp_amount_to_be_paid}</td>
                                            <td>{dateFormat(invoice?.generated_for_date, "dd mmm yyyy")}</td>
                                            {/* <td>{dateFormat(invoice?.generated_time, "hh:MM TT")}</td> */}
                                            <td>{invoice?.status}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>

                        </div>
                    </table>
                </div> :
                <div className='wallet_loader_wrapper' >
                    <LoaderComponent
                        width={"2%"}
                        height={"2%"}
                    />
                </div>
            }

            {settlementInvoices?.length === 0 ?
                <></> :
                <div className='flex w-full justify-end mt-5'>
                    <TableViewPagination
                        isLoading={false}
                        currentPage={filters?.pageNumber || 1}
                        totalCount={10}
                        pageSize={filters.pageSize}
                        padding="10px 5px"
                        onPageChange={(page) => {
                            setFilters((prev) => ({ ...prev, pageNumber: page }))
                        }}
                    />
                </div>
            }
        </>
    )
}

export default DspWalletTransactionsInvoices