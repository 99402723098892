import { useEffect, useRef, useState } from "react";
import Map from "../../features/LeafletMap/Map"
import Popup from "../../ReusableFunctions/Popup";
import MarkerIcon from "../../assets/marker.png";
import L from 'leaflet'
import roadMapView from '../../assets/roadMapView.png'
import sateliteView from '../../assets/sateliteView.png'
import { MAP_URLS } from "../../appConstants";
import { getUserListForMap } from '../../apis/users';
import '../../AdminComponents/Locations/Location.css';
import Loading from "../../ReusableFunctions/Loader";
import 'leaflet/dist/leaflet.css';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import 'leaflet.markercluster';
import moment from 'moment';
import fullScreenIcon from '../../assets/fullScreenIcon.png';
import collapseIcon from '../../assets/collapse.png';
import LoaderComponent from "../../ReusableComponents/LoaderComponent";
let baseLayer

const ViewRequestsOnMap = ({
    mapData,
    isExpandable,
    isLoading
}) => {
    const [activeBaseLayer, setActiveBaseLayer] = useState(baseLayer)
    const clusterRef = useRef(null)
    const [isFullScreen, setIsFullScreen] = useState(false);
    const mapContainerRef = useRef(null);
    const [center, setCenter] = useState(
        { lat: 19.993982, lng: 73.790416 }
    );
    const [map, setMap] = useState(null)

    const markerIcon = L.icon({
        iconUrl: MarkerIcon,
        iconSize: [24, 32],
    });
    const initMap = (map) => {
        setMap(map)
    };

    const switchBaseLayer = () => {
        const activeLayer = activeBaseLayer == "satelite" ? "terrain" : "satelite"
        setActiveBaseLayer(activeLayer)
        if (baseLayer) {
            baseLayer.setUrl(activeLayer === "satelite" ? MAP_URLS.SATELITE : MAP_URLS.ROAD_MAP)
        }
    }

    const initData = async () => {
        drawRequestOnMapFn(mapData);
    }
    const clearExistingMarkers = () => {
        if (clusterRef.current) {
            map.removeLayer(clusterRef.current)
        }
    }
    const drawRequestOnMapFn = (data) => {
        clearExistingMarkers();
        var markerOptions = {
            clickable: true,
            draggable: false,
            icon: markerIcon,
        };

        let cluster = new L.markerClusterGroup({
            spiderfyOnMaxZoom: true,
            showCoverageOnHover: false,
            zoomToBoundsOnClick: true,
            iconCreateFunction: (cluster) => {
                let count = cluster.getChildCount()
                return L.divIcon({
                    html: '<div style="background-color:green; color:white;"><span>' + count + '</span></div>',
                    className: 'marker-cluster marker-cluster-medium active_cluster',
                    iconSize: L.point(45, 45)
                });
            }

        })
        data.map(d => {
            const center = { lat: d.field_lat, lng: d.field_lng };

            let marker1 = new L.Marker(center, markerOptions)
            marker1.on('click', function () {
                const currentZoom = Math.min(map.getZoom() + 1, map.getMaxZoom());
                map.flyTo(center, currentZoom);
            });
            const tooltipContent = `
                <div style="display:flex; height:150px; min-width:200px; ">
                    <div style="padding:10px;">
                     <div>Request No: <span style="font-weight:600;">${d.req_number} </span></div>
                     <div>Request Status: <span style="font-weight:600;">${d.status || "-"} </span></div>
                      <div>Total Amount: <span style="font-weight:600;">${d.total_amount || "-"} </span></div>
                     <div>Placed Date: <span style="font-weight:600;">${d.date_created ? moment(d.date_created).format('DD MMMM, YYYY') : "-"} </span></div>
                      <div>Completed Date: <span style="font-weight:600;">${d.completed_time ? moment(d.completed_time).format('DD MMMM, YYYY') : "-"} </span></div>
                    <div>User Name: <span style="font-weight:600;">${d.user_name || "-"} </span></div>
                    <div>Dsp Name: <span style="font-weight:600;">${d.dsp_name || "-"} </span></div>

                    </div>
                </div>
                `
            marker1.bindTooltip(tooltipContent, {
                className: 'location-tooltip',
                permanent: false,
            });
            cluster.addLayer(marker1)

            return { ...d, markerLeafletLayer: marker1 }
        })
        map?.addLayer(cluster)
        clusterRef.current = cluster
    }

    const toggleFullScreen = () => {
        if (!document.fullscreenElement) {
            mapContainerRef.current.requestFullscreen().catch(err => {
                console.error(`Error attempting to enable full-screen mode: ${err.message}`);
            });
        } else {
            document.exitFullscreen();
        }
    }

    useEffect(() => {
        const handleFullscreenChange = () => {
            setIsFullScreen(!!document.fullscreenElement);
            if (map) {
                setTimeout(() => {
                    map.invalidateSize();
                }, 0);
            }
        };
        document.addEventListener('fullscreenchange', handleFullscreenChange);
        return () => {
            document.removeEventListener('fullscreenchange', handleFullscreenChange);
        };
    }, [map]);

    useEffect(() => {
        initData()
    }, [mapData, map])

    return <div ref={mapContainerRef} style={{ width: "100%", height: "100%", position: 'relative', borderRadius: "5px", border: "1px solid gray", padding: "2px", minHeight: "450px" }}>
        <Map
            isLoading={isLoading}
            setBaseLayerToState={(bL) => {
                baseLayer = bL
            }}
            initZoom={6}
            initCenter={center}
            handleMapClick={() => { }}
            maxZoom={25}
            style={{
                height: "100%",
                borderRadius: "0 0 5px 5px",
            }}
            mapInitialized={(map) => {
                initMap(map);
            }}
        />
        <div style={{ border: 'solid 1px rgb(102, 102, 102, 0.3)', height: '25px', backgroundColor: 'rgba(255, 255, 255, 0.9)', borderRadius: '5px', cursor: 'pointer', zIndex: '999', position: "absolute", right: "10px", bottom: '24px' }}
            onClick={switchBaseLayer}>
            <img style={{ width: '80px', height: '25px', borderRadius: '4px' }} src={activeBaseLayer === "satelite" ? roadMapView : sateliteView} />
        </div>

        {isLoading && (<div className="absolute inset-0 flex items-center justify-center bg-background/80 backdrop-blur-sm z-[1000]">
            <LoaderComponent width={"2%"} height={"2%"} />
        </div>)}
        {isExpandable && (
            <div style={{ position: "absolute", bottom: "5px", left: "15px", height: '50px', zIndex: '999', cursor: "pointer" }}>
                <img src={isFullScreen ? collapseIcon : fullScreenIcon} onClick={toggleFullScreen} alt="" width={isFullScreen ? "30px" : "25px"} height={"25px"} />
            </div>
        )}
    </div>
}
export default ViewRequestsOnMap